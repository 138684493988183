var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agenda-date-picker-month" }, [
    _c(
      "div",
      {
        staticClass: "button",
        on: {
          click: function($event) {
            return _vm.goToPreviousMonth()
          }
        }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.chevronLeft } })],
      1
    ),
    _c("div", [_vm._v(_vm._s(_vm.monthName) + " " + _vm._s(_vm.year))]),
    _c(
      "div",
      {
        staticClass: "button",
        on: {
          click: function($event) {
            return _vm.goToNextMonth()
          }
        }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.chevronRight } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }