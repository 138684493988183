import api from './';

export default {
  search: (text, signal) => api.post('autocomplete', {
    json: {
      text,
      type: 'branded-drug',
      size: 10,
    },
    signal,
  }).json(),
};
