import Products from '../../api/products';

export default {
  state: {
    all: [],
    isFetchingAll: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isReordering: false,
  },
  mutations: {
    PRODUCTS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    PRODUCTS_FETCH_ALL_SUCCESS(state, { products }) {
      state.isFetchingAll = false;
      state.all = products;
    },
    PRODUCTS_FETCH_ALL_FAIL(state) {
      state.isFetchingAll = false;
    },
    PRODUCTS_REPLACE_ALL(state, { products }) {
      state.all = products;
    },
    PRODUCTS_CREATE(state) {
      state.isCreating = true;
    },
    PRODUCTS_CREATE_SUCCESS(state, { product }) {
      state.isCreating = false;
      state.all.push(product);
    },
    PRODUCTS_CREATE_FAIL(state) {
      state.isCreating = false;
    },
    PRODUCTS_UPDATE(state) {
      state.isUpdating = true;
    },
    PRODUCTS_UPDATE_SUCCESS(state, { product }) {
      state.isUpdating = false;
      state.all = state.all.map(existingProduct => existingProduct.id === product.id ? product : existingProduct);
    },
    PRODUCTS_UPDATE_FAIL(state) {
      state.isUpdating = false;
    },
    PRODUCTS_DELETE(state) {
      state.isDeleting = true;
    },
    PRODUCTS_DELETE_SUCCESS(state, { id }) {
      state.isDeleting = false;
      state.all = state.all.filter(existingProduct => existingProduct.id !== id);
    },
    PRODUCTS_DELETE_FAIL(state) {
      state.isDeleting = false;
    },
    PRODUCTS_REORDER(state) {
      state.isReordering = true;
    },
    PRODUCTS_REORDER_SUCCESS(state) {
      state.isReordering = false;
    },
    PRODUCTS_REORDER_FAIL(state) {
      state.isReordering = false;
    },
  },
  actions: {
    async productsFetchAll({ commit }) {
      commit('PRODUCTS_FETCH_ALL');
      try {
        const products = await Products.fetchAll();
        commit('PRODUCTS_FETCH_ALL_SUCCESS', { products });
      } catch (e) {
        commit('PRODUCTS_FETCH_ALL_FAIL');
        throw e;
      }
    },
    async productsReplaceAll({ commit }, { products }) {
      commit('PRODUCTS_REPLACE_ALL', { products });
    },
    async productsCreate({ commit }, data) {
      commit('PRODUCTS_CREATE');
      try {
        const product = await Products.create(data);
        commit('PRODUCTS_CREATE_SUCCESS', { product });
      } catch (e) {
        commit('PRODUCTS_CREATE_FAIL');
        throw e;
      }
    },
    async productsUpdate({ commit }, { id, data }) {
      commit('PRODUCTS_UPDATE');
      try {
        const product = await Products.update(id, data);
        commit('PRODUCTS_UPDATE_SUCCESS', { product });
      } catch (e) {
        commit('PRODUCTS_UPDATE_FAIL');
        throw e;
      }
    },
    async productsDelete({ commit }, { id }) {
      commit('PRODUCTS_DELETE');
      try {
        await Products.delete(id);
        commit('PRODUCTS_DELETE_SUCCESS', { id });
      } catch (e) {
        commit('PRODUCTS_DELETE_FAIL');
        throw e;
      }
    },
    async productsReorder({ commit, dispatch }, { productId, newIndex }) {
      commit('PRODUCTS_REORDER');
      try {
        await Products.updateRank(productId, newIndex);
        await dispatch('productsFetchAll');
        commit('PRODUCTS_REORDER_SUCCESS');
      } catch (e) {
        commit('PRODUCTS_REORDER_FAIL', { changes });
      }
    },
  },
};
