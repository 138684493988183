var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    { attrs: { to: "app-pop-in" } },
    [
      _c("transition", { attrs: { duration: 600 } }, [
        _vm.opened
          ? _c("div", { staticClass: "app-pop-in" }, [
              _c("div", {
                staticClass: "backdrop",
                class: { listened: _vm.hasCloseListener },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "container",
                  style: { "--maxWidth": _vm.maxWidth }
                },
                [_c("div", { staticClass: "content" }, [_vm._t("default")], 2)]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }