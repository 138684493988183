<template>
  <AppLayout :header="false" :footer="false">
    <main class="consultation-report-confirmed fullscreen">
      <FontAwesomeIcon :icon="$icons.thumbsUp" class="icon" size="4x" />
      <SectionTitle center light>
        Compte-rendu enregistré
      </SectionTitle>
      <p class="text">
        Votre compte-rendu a bien été enregistré, vous pouvez le consulter depuis la fiche rendez-vous liée à celui-ci.
      </p>
      <div class="actions single">
        <ButtonGreen class="action" :link-to="linkTo">
          Continuer
        </ButtonGreen>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import ButtonGreen from '../components/Button/ButtonGreen.vue';
  import AppLayout from '../components/AppLayout';

  export default {
    name: 'ConsultationReportConfirmedPage',
    components: { SectionTitle, ButtonGreen, AppLayout },
    computed: {
      linkTo() {
        let routeName = this.$route.query.to;

        if (routeName === 'dashboard' || !['agenda-view', 'appointments'].includes(routeName)) {
          return { name: 'dashboard' };
        }

        return {
          name: routeName,
          query: { 'appointment-detail': this.$route.query.appointmentId },
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .consultation-report-confirmed {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: $gradientBackground;
    padding: 0;
  }

  .icon {
    color: #FFFFFF;
  }

  .section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .text {
    max-width: 38rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #FFFFFF;
    margin: 3rem 0 4rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 60rem;

    @include onDesktop {
      flex-direction: row;
    }
  }

  .action {
    margin: 0.5rem 1rem;
    flex: 1;

    .single & {
      flex: none;
    }
  }
</style>
