var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment
    ? _c("div", { staticClass: "appointment-panel-patient" }, [
        _c(
          "h4",
          { class: { busy: _vm.isReplacingPatient } },
          [
            _c("FontAwesomeIcon", {
              staticClass: "icon",
              attrs: {
                icon: _vm.isReplacingPatient
                  ? _vm.$icons.spinner
                  : _vm.$icons.user,
                spin: _vm.isReplacingPatient,
                "fixed-width": ""
              }
            }),
            _c("strong", [_vm._v(_vm._s(_vm.patientFullName))]),
            _vm.canReplacePatient &&
            !_vm.isReplacingPatient &&
            (this.patient.firstName || this.patient.lastName)
              ? _c("span", { staticClass: "replace-patient" }, [
                  _vm._v("\n      ( "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$confirm(_vm.$refs.replacePatient)
                        }
                      }
                    },
                    [_vm._v("Modifier")]
                  ),
                  _vm._v(" )\n    ")
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "AppConfirmation",
              {
                ref: "replacePatient",
                attrs: { icon: _vm.$icons.user, title: "Choix du patient" }
              },
              [
                _c("BeneficiaryList", {
                  attrs: {
                    patient: _vm.patient.parent || _vm.patient,
                    selected: _vm.appointment.payload.patient,
                    cancelable: ""
                  },
                  on: {
                    cancel: function($event) {
                      return _vm.$refs.replacePatient.close()
                    },
                    change: _vm.handleReplacePatient
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.patient.email
          ? _c("div", [_vm._v(_vm._s(_vm.patient.email))])
          : _vm._e(),
        _vm.patient.phone
          ? _c("div", [_vm._v(_vm._s(_vm._f("phone")(_vm.patient.phone)))])
          : _vm._e(),
        _vm.patient.ssn
          ? _c("div", [_vm._v(_vm._s(_vm._f("ssn")(_vm.patient.ssn)))])
          : _vm._e(),
        _vm.patient.mutualName || _vm.patient.mutualNumber
          ? _c("div", [
              _vm._v(
                "Mutuelle : " +
                  _vm._s(_vm._f("mutualName")(_vm.patient.mutualName)) +
                  " " +
                  _vm._s(_vm._f("mutualNumber")(_vm.patient.mutualNumber))
              )
            ])
          : _vm._e(),
        _vm.patient.birthDate
          ? _c("div", [
              _vm._v(
                "Date de naissance : " +
                  _vm._s(_vm._f("date")(_vm.patient.birthDate, "DD/MM/YYYY")) +
                  " (" +
                  _vm._s(_vm._f("age")(_vm.patient.birthDate)) +
                  ") "
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }