<template>
  <AppLoader v-if="isLoadingPatient">
    Chargement du patient en cours...
  </AppLoader>
  <AppLayout v-else>
    <main>
      <div class="container">
        <PatientTabBar :patient-id="patient.id" />
          <div class="row">
          <div class="col _7">
            <EditProfil :patient="patient"/>
          </div>
              <div class="col _1"></div>
          <div class="col _4">
            <GroupTitle :icon="$icons.calendar">Nouveau rendez-vous</GroupTitle>
            <NewAppointmentForm :forced-patient="patient" />
          </div>
        </div>
          <hr/>
          <Observations :patient="patient" :collapse-block="collapseBlock"/>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import EditProfil from '@/components/EditProfil.vue';
  import AppLoader from '@/components/AppLoader';
  import AppPanel from '@/components/AppPanel.vue';
  import NewAppointmentForm from '@/components/Dashboard/NewAppointmentForm.vue';
  import FormInput from '@/components/Form/FormInput.vue';
  import FormText from '@/components/Form/FormText.vue';
  import FormWidget from '@/components/Form/FormWidget';
  import SelectWrapper from '@/components/Form/SelectWrapper.vue';
  import GroupTitle from '@/components/GroupTitle.vue';
  import PatientTabBar from '@/components/PatientTabBar.vue';
  import AppLayout from '../components/AppLayout';
  import HorizontalDivider from '../components/HorizontalDivider';
  import SectionTitle from '../components/SectionTitle';
  import UserAvatar from '../components/UserAvatar';
  import moment from "moment";
  import {age} from "@/helpers/format";
  import ButtonGreen from "../components/Button/ButtonGreen.vue";
  import { convertDateToDash } from '@/helpers/format';
  import {checkDate, checkSsn} from "@/helpers/validation";
  import {integer} from "vuelidate/lib/validators";
  import Observations from "@/components/Consultation/CallingInterface/Observations.vue";

  export default {
    name: 'PatientsPage',
    components: {
      Observations,
      ButtonGreen,
      PatientTabBar,
      FormText,
      FormInput,
      SelectWrapper,
      GroupTitle,
      NewAppointmentForm,
      AppPanel,
      FormWidget,
      AppLoader,
      UserAvatar,
      HorizontalDivider,
      SectionTitle,
      AppLayout,
      EditProfil
    },
    data() {
      return {
        filter: '',
        form: {
          ssn: null,
          birthDate: null,
          bloodGroup: null,
          height: null,
          weight: null,
          gender: null,
        },
        submitErrors: null,
        collapseBlock: true
      };
    },
    validations: {
      form: {
        height: {
          integer
        },
        ssn: {
          format: checkSsn,
        },
        birthDate: {
          checkDate
        },
      }
    },
      async beforeMount() {
      await this.$store.dispatch('patientsFetchOne', { id: this.$route.params.id });
        this.form = {
          ssn: this.patient.ssn,
          birthDate: this.patient.birthDate ? moment(this.patient.birthDate).format('DD/MM/YYYY') : null,
          bloodGroup: this.patient.bloodGroup,
          height: this.patient.height,
          weight: this.patient.weight,
          gender: this.patient.gender,
          measure: this.patient.measure
        }
    },
    computed: {
      isLoadingPatient() {
        return this.$store.state.patients.isFetchingOne;
      },
      patient() {
        return this.$store.state.patients.current;
      },
      birthDate() {
        return `${moment(this.patient.birthDate).format('DD/MM/YYYY')} (${age(this.patient.birthDate)})`;
      }
    },
    methods: {
      async handleSubmit() {
       this.$v.$touch();
        let errors = [];
        if (this.$v.$anyError) {
          this.$addError('Le formulaire contient une ou plusieurs erreurs');
          return false;
        }
        const data = {
          ssn: this.form.ssn,
          birthDate: convertDateToDash(this.form.birthDate),
          bloodGroup: this.form.bloodGroup,
          height: parseInt(this.form.height),
          weight: this.form.weight,
          gender: this.form.gender,
        }
        const id =  this.patient.id;
        try {
          await this.$store.dispatch('patientEditProfile', {data,id});
          if (errors.length) {
            this.$addSuccess('Le reste du profil du patient a été mis à jour.');
          } else {
            this.$addSuccess('Le profil du patient a été mis à jour.');
          }
        } catch (e) {
          errors = ['Une erreur est survenue lors de la mise à jour du profil patient'];
          if (e.response) {
            this.submitErrors = await e.response.json();
          }
        }
        errors.forEach(error => this.$addError(error));
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../assets/grid";

  .row {
    margin-bottom: 2rem;
  }

  .patient-tab-bar {
    margin-bottom: 4rem;
  }
</style>
