import './config/filters';
import './config/font_awesome';
import './config/datetime_picker';
import './config/moment';
import './config/media_query';
import './config/mixins';
import './config/inputmask';
import './config/form-validation';
import './config/guide';
import './config/lazy_load';
import './config/portal';
import './config/autoclick';
import './config/signature_pad';
// import i18n from './config/i18n'; // Uncomment to use i18n
import router from './config/router';
import store from './config/store';

import Vue from 'vue';
import App from './App.vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { currentEnvironment, getSetting, isProduction } from './helpers/tools.js';

if (getSetting('SENTRY_ENABLED')) {
  Sentry.init({
    environment: currentEnvironment,
    dsn: 'https://8bfd054de55b4f3792f41d91fbf4a4a7@o393256.ingest.sentry.io/5242065',
    integrations: [new VueIntegration({ Vue, logErrors: !isProduction })],
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  // i18n, // Uncomment to use i18n
  render: h => h(App),
}).$mount('#app');
