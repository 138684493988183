<template>
  <router-view />
</template>

<script>
  import { getLocalStorage } from '../helpers/tools';

  function dispatch() {
    if ('agenda' !== this.$route.name) {
      return;
    }

    const name = getLocalStorage('preferred-agenda-view') || 'agenda-view';

    this.$router.replace({ name, query: this.$route.query });
  }

  export default {
    name: 'AgendaPage',
    created: dispatch,
    updated: dispatch,
  };
</script>
