<template>
  <AppLayout class="profile-access-page">
    <main>
      <ProfileTabBar />
      <div class="container">
        <p class="information bordered">
          Veuillez activer les options souhaitées pour personnaliser votre solution Hellocare.
        </p>
        <div class="settings">
          <div class="settings-left">
            <GroupTitle :icon="$icons.settings">
              Gérez les accès externes
            </GroupTitle>
            <ButtonGreen @click="open" v-if="!isSecretary"> Ajouter une secrétaire </ButtonGreen>
<!--            <p v-if="accessTokens.length === 0" class="information">-->
<!--              Aucun accès externe n'a été activé pour le moment-->
<!--            </p>-->
            <div v-for="accessToken in accessTokens" v-else class="setting">
              <img :src="accessToken.partner.logo" alt="" class="setting-icon" />
              <div class="settings-content">
                <div class="setting-label">
                  <label :for="accessToken.id">Active la synchronisation avec {{ accessToken.partner.name }}</label>
                  <div class="setting-switch">
                    <FormSwitch
                      :id="accessToken.id"
                      :busy="updating[accessToken.id]"
                      :checked="accessToken.active"
                      @change="changeAccessTokenStatus(accessToken.id, !accessToken.active)"
                    />
                  </div>
                </div>
                <p class="setting-description">
                  {{ accessToken.partner.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="secretary" v-if="isOpen">
        <div class="container">
          <div>
            Pour permettre à votre secrétaire d'acceder à votre agenda, veuillez-lui transmettre le lien suivant :
          </div>
          <div class="secretary-link hc-border">
            <a href="#">
              {{getSecretaryUrl}}
            </a>
            <span class="secretary-button">
              <ButtonGreen @click="copyLink">
                {{ isCopied ? "Copié !" : "Copier" }}
              </ButtonGreen>
            </span>
          </div>
        </div>
      </div>
      <div class="secretary" v-if="isSecretary">
        <div class="container">
          <SectionTitle>Mes praticiens connectés</SectionTitle>
          <div v-for="secretaryOf in secretariesOf">
            <p class="setting-description">
              {{getFullName(secretaryOf.first_name, secretaryOf.last_name)}}
            </p>
            <ButtonGreen @click="disconnectPractitioner(secretaryOf.id)">Déconnecter</ButtonGreen>
          </div>
        </div>
      </div>
      <div class="secretary" v-if="!isSecretary">
        <div class="container">
          <SectionTitle>Mes secrétaires</SectionTitle>
          <div v-for="secretary in secretaries">
              <p class="setting-description">
                {{getFullName(secretary.first_name, secretary.last_name)}}
              </p>
            <ButtonGreen @click="disconnectSecretary(secretary.id)">Déconnecter</ButtonGreen>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import SectionTitle from '@/components/SectionTitle';
import AppLayout from '@/components/AppLayout';
import ProfileTabBar from '@/components/ProfileTabBar';
import FormSwitch from '@/components/Form/FormSwitch';
import GroupTitle from '@/components/GroupTitle';
import ButtonGreen from "@/components/Button/ButtonGreen.vue";
import {getFullName} from "@/helpers/format";

export default {
    name: 'ProfileAccessPage',
    components: {ButtonGreen, GroupTitle, FormSwitch, ProfileTabBar, AppLayout, SectionTitle },
    data() {
      return {
        updating: {},
        isOpen: false,
        isCopied: false,
      };
    },
    async mounted() {
      await this.loadData();

      if (!this.accessTokenId || !this.accessTokenAction) {
        return;
      }

      const accessToken = this.accessTokens.find(accessToken => accessToken.id === this.accessTokenId);

      if (!accessToken) {
        return this.$addError('Ce partenaire n\'existe pas');
      }

      const status = this.accessTokenAction === 'enable';

      if (accessToken.active === status) {
        return;
      }

      await this.changeAccessTokenStatus(this.accessTokenId, status);
      this.$addSuccess(`Le partenaire a été ${status ? 'activé' : 'désactivé'}`);
    },
    props: {
      accessTokenId: {
        type: String,
        required: false,
      },
      accessTokenAction: {
        type: String,
        required: false,
        validator: function (action) {
          return ['enable', 'disable'].indexOf(action) !== -1;
        },
      },
    },
    computed: {
      accessTokens() {
        return this.$store.state.accessTokens.all;
      },
      secretariesOf() {
        return this.$store.state.practitioner.profile.secretariesOf;
      },
      secretaries() {
        return this.$store.state.practitioner.profile.secretaries;
      },
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      getSecretaryUrl() {
        let port = window.location.port
        if(port === "443" || port === "80")
          port = null
        else
          port = ":" + port
        return `https://${window.location.hostname}${port}/profile/link/` + this.$store.state.practitioner.profile.id;
      },
    },
    methods: {
      getFullName,
      async disconnectPractitioner(practitionerId) {
        await this.$store.dispatch('practitionerEditProfile', {secretariesOfToDelete:[practitionerId]});
        await this.$store.dispatch('practitionerFetchProfile');
      },
      async disconnectSecretary(secretaryId) {
        await this.$store.dispatch('practitionerEditProfile', {secretariesToDelete:[secretaryId]});
        await this.$store.dispatch('practitionerFetchProfile');
      },
      async copyLink() {
        try {
          await navigator.clipboard.writeText(this.getSecretaryUrl);
          this.isCopied = true;
        } catch($e) {
          console.error($e)
        }
      },
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      async loadData() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('accessTokensFetchAll');
          this.accessTokens.forEach(accessToken => {
            this.$set(this.updating, accessToken.id, false);
          });

        } finally {
          this.$store.dispatch('appLoaderHide');
        }
      },
      async changeAccessTokenStatus(id, status) {
        try {
          this.updating[id] = true;
          await this.$store.dispatch('accessTokenChangeStatus', { id, status });
        } catch (e) {
          this.$forceUpdate();
          throw e;
        } finally {
          this.updating[id] = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    display: block;
    max-width: 98rem;
    margin: 0 auto;
  }

  .information {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 3rem;
    text-align: justify;

    &.bordered {
      padding: 2rem 0 3rem;
      border-bottom: 1px solid $borderColor;
    }
  }

  .settings-right {
    padding-top: 3rem;
    border-top: 1px solid $borderColor;
  }

  @include onDesktop {
    .settings {
      display: flex;
    }

    .settings-left {
      padding-right: 6.2rem;
      border-right: 1px solid $borderColor;
    }

    .settings-right {
      min-width: 36rem;
      max-width: 36rem;
      padding-top: 0;
      padding-left: 6.2rem;
      border: none;
    }
  }

  .settings-left {
    .setting-description {
      margin-right: 6rem;
    }
  }

  .setting {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  }

  .settings-content {
    flex: 1;
  }

  .secretary {
    margin-top: 3rem;
    padding: 3rem;
    font-size: 1.5rem;
    background-color: #EFEFEF;
  }

  .secretary-link {
    margin-top: 3rem;
    padding-left: 1rem;
    width: max-content;
    background-color: white;
  }

  .secretary-button {
    margin-left: 1rem;
  }

  .setting-label {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      padding-right: 3rem;
      cursor: pointer;
    }
  }

  .setting-icon {
    max-width: 5rem;
    max-height: 5rem;
    margin-right: 1rem;
  }

  .setting-description {
    font-weight: 400;
    font-style: italic;
    margin-top: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
