import { render, staticRenderFns } from "./ConsultationReportPage.vue?vue&type=template&id=269dd6f0&scoped=true&"
import script from "./ConsultationReportPage.vue?vue&type=script&lang=js&"
export * from "./ConsultationReportPage.vue?vue&type=script&lang=js&"
import style0 from "./ConsultationReportPage.vue?vue&type=style&index=0&id=269dd6f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "269dd6f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('269dd6f0')) {
      api.createRecord('269dd6f0', component.options)
    } else {
      api.reload('269dd6f0', component.options)
    }
    module.hot.accept("./ConsultationReportPage.vue?vue&type=template&id=269dd6f0&scoped=true&", function () {
      api.rerender('269dd6f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ConsultationReportPage.vue"
export default component.exports