<template>
  <ButtonGreen :busy="isBusy" :icon="$icons.accept" outlined @click="handleClick">
    <slot>Valider</slot>
  </ButtonGreen>
</template>

<script>
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';

  export default {
    name: 'ConfirmButton',
    components: { ButtonGreen },
    inject: ['appConfirmationSetBusy', 'appConfirmationClose', 'appConfirmationStatus'],
    computed: {
      isBusy() {
        return this.appConfirmationStatus.isBusy;
      },
    },
    methods: {
      async handleClick() {
        this.appConfirmationSetBusy(true);

        try {
          await this.$listeners.click(this.appConfirmationStatus.payload);
          this.$emit('success');
        } catch (e) {
          this.$emit('error');
        } finally {
          await this.appConfirmationClose();
        }
      },
    },
  };
</script>
