var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-cards" },
    [
      _vm.isLoadingCards
        ? _c("div", [_vm._v("Vérification de la carte bancaire...")])
        : [
            _c("CreditCardsPictograms", { attrs: { small: "" } }),
            _vm.card && !_vm.showReplaceCardForm
              ? _c("CreditCardDisplayForm", {
                  attrs: {
                    "card-expiry":
                      _vm.card.expirationMonth + "/" + _vm.card.expirationYear,
                    "card-number": "**** **** **** " + _vm.card.lastFourDigit
                  },
                  on: {
                    replace: function($event) {
                      _vm.showReplaceCardForm = true
                    }
                  }
                })
              : _c("CreditCardReplaceForm", {
                  attrs: { "previous-card-id": _vm.card && _vm.card.id },
                  on: {
                    close: function($event) {
                      _vm.showReplaceCardForm = false
                    },
                    replace: _vm.handleReplaced
                  }
                })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }