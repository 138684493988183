<template>
  <div v-if="isVisible" class="agenda-calendar-availability-slot" :style="{ top, height }" />
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'AgendaCalendarAvailabilitySlot',
    props: {
      startHour: {
        type: String,
        required: true,
      },
      endHour: {
        type: String,
        required: true,
      },
      day: {},
    },
    computed: {
      startOfDay() {
        return moment(this.startTime).startOf('day');
      },
      startTime() {
        let [hours, minutes] = this.startHour.split(':');
        const time = moment(this.day).hours(hours).minutes(minutes);

        if (time.isBefore(this.$store.state.minimumNewAppointmentStartAt)) {
          return this.$store.state.minimumNewAppointmentStartAt;
        }

        return time;
      },
      endTime() {
        let [hours, minutes] = this.endHour.split(':');
        return moment(this.day).hours(hours).minutes(minutes);
      },
      isVisible() {
        return this.$store.state.minimumNewAppointmentStartAt.isBefore(this.endTime);
      },
      top() {
        return this.$minutesToPixels(this.startTime.diff(this.startOfDay, 'minutes'));
      },
      height() {
        return this.$minutesToPixels(this.endTime.diff(this.startTime, 'minutes'));
      },
    },
  };
</script>

<style scoped>
  .agenda-calendar-availability-slot {
    position: absolute;
    left: 0.1rem;
    right: 0.1rem;
    background-color: white;
  }
</style>
