var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { attrs: { fullscreen: "" } }, [
    _c("main", { staticClass: "incompatible-browser" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "icons" },
            [
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.chrome } }),
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.safari } }),
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.firefox } })
            ],
            1
          ),
          _c("h1", [_vm._v("Navigateurs compatibles")]),
          _c("p", [
            _vm._v(
              "\n                Pour que votre expérience soit la plus complète\n                et la plus réussie possible et pour que votre santé vous dise merci, nous vous invitons à utliser un\n                navigateur compatible.\n            "
            )
          ]),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href:
                  "http://support.hellocare.com/fr/articles/2010972-navigateurs-compatibles"
              }
            },
            [
              _c("span", [
                _vm._v(
                  "\n                    Télécharger un navigateur compatible\n               "
                )
              ])
            ]
          ),
          _c("router-link", { attrs: { to: "/dashboard" } }, [
            _c("span", [
              _vm._v(
                "\n                    Retour au tableau de bord\n               "
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }