<template>
  <AppLayout>
    <main class="agenda-availabilities-page">
      <SectionTitle>Mes disponibilités</SectionTitle>
      <Calendar />
      <div class="agenda-description">
        <div class="item-description">
          <img src="../../assets/images/picto-flexible.svg" alt="">
          <h3>FLEXIBLE</h3>
          <p>
            Un imprévu s’est glissé dans votre semaine ?
            Vous pouvez à tout moment modifier votre planning
            de disponibilité et ainsi ne jamais être bloqué.
          </p>
        </div>
        <div class="item-description">
          <img src="../../assets/images/picto-sur-mesure.svg" alt="">
          <h3>SUR MESURE</h3>
          <p>
            Votres emploi du temps est compliqué et vous
            n’avez jamais les mêmes disponibilités ?
            Paramétrez vos disponibilités à votre guise.
          </p>
          <a target="_blank" href="https://kb.hellocare.pro/support/renseigner-mes-disponibilites">Comment ça marche
            ?</a>
        </div>
        <div class="item-description">
          <img src="../../assets/images/picto-pratique.svg" alt="">
          <h3>PRATIQUE</h3>
          <p>
            En combinant votre planning et le temps de vos consultations,
            vos disponibilités sont immédiatement visibles par vos patients.
          </p>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../../components/AppLayout';
  import SectionTitle from '../../components/SectionTitle';
  import Calendar from '../../components/Availability/Calendar';

  export default {
    name: 'agenda-availabilities-page',
    components: { SectionTitle, Calendar, AppLayout },
    data() {
      return {
        startCalendar: {},
      };
    },
    watch: {
      startMonth(days) {
        this.startCalendar = days;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .agenda-availabilities-page {
    margin: 0 auto;
  }

  .full-panel.agenda-availabilities-page {
    position: fixed;
  }

  .agenda-description {
    display: flex;

    .item-description {
      flex-basis: 33%;
      text-align: center;
      padding: 15px;

      h3 {
        font-size: 1.5em;
      }

      img {
        padding: 20px;
      }

      p {
        font-weight: 200;
      }

      a {
        font-size: 1.5em;
        text-decoration: underline;
        color: $mainColor;
        font-weight: bold;
      }
    }
  }
</style>
