<template>
  <div v-if="appointment" class="appointment-panel-patient">
    <h4 :class="{ busy: isReplacingPatient }">
      <FontAwesomeIcon
        :icon="isReplacingPatient ? $icons.spinner : $icons.user"
        :spin="isReplacingPatient"
        class="icon"
        fixed-width
      />
      <strong>{{ patientFullName }}</strong>
      <span v-if="canReplacePatient && !isReplacingPatient && (this.patient.firstName || this.patient.lastName)" class="replace-patient">
        ( <a href="#" @click.prevent="$confirm($refs.replacePatient)">Modifier</a> )
      </span>
    </h4>
    <div>
      <AppConfirmation ref="replacePatient" :icon="$icons.user" title="Choix du patient">
        <BeneficiaryList
          :patient="patient.parent || patient"
          :selected="appointment.payload.patient"
          cancelable
          @cancel="$refs.replacePatient.close()"
          @change="handleReplacePatient"
        />
      </AppConfirmation>
    </div>
    <div v-if="patient.email">{{ patient.email }}</div>
    <div v-if="patient.phone">{{ patient.phone | phone }}</div>
    <div v-if="patient.ssn">{{ patient.ssn | ssn }}</div>
    <div v-if="patient.mutualName || patient.mutualNumber">Mutuelle : {{ patient.mutualName | mutualName }} {{ patient.mutualNumber | mutualNumber }}</div>
    <div v-if="patient.birthDate">Date de naissance : {{ patient.birthDate | date('DD/MM/YYYY') }} ({{ patient.birthDate | age }}) </div>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation.vue';
  import BeneficiaryList from '@/components/BeneficiaryList.vue';
  import { getFullName } from '@/helpers/format';

  export default {
    name: 'AppointmentPanelPatient',
    components: { BeneficiaryList, AppConfirmation },
    data() {
      return {
        isPatientReplacementPanelOpen: false,
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      patient() {
        return this.$store.state.patients.current;
      },
      patientFullName() {
        if (!this.patient.firstName && !this.patient.lastName) {
          return 'Nouveau patient';
        }

        return getFullName(
          this.patient.firstName,
          this.patient.lastName,
        );
      },
      canReplacePatient() {
        return !this.appointment.payload.encounters?.[0];
      },
      isReplacingPatient() {
        return this.$store.state.appointments.isReplacingPatient;
      },
    },
    methods: {
      async handleReplacePatient(beneficiary) {
        this.$refs.replacePatient.close();

        if (beneficiary.id === this.appointment.payload.patient.id) {
          return;
        }

        try {
          await this.$store.dispatch('appointmentsReplacePatient', {
            appointmentId: this.appointment.payload.id,
            patientId: beneficiary.id,
          });

          await this.$store.dispatch('patientsFetchOne', {
            id: beneficiary.id
          })

          await this.$addSuccess('Le patient a bien été remplacé');
        } catch (error) {
          await this.$addError('Une erreur s\'est produite lors de la modification du patient');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-patient {
    h4 {
      font-size: 1.6rem;

      .icon {
        margin-right: 0.5rem;
      }
    }

    div {
      margin: 1rem 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .replace-patient {
    margin-left: 1rem;
    color: $grey;

    a {
      text-decoration: none;
      color: $successColor;
    }
  }

  .beneficiary-list {
    color: $lightTextColor;

    ::v-deep {
      .beneficiaries {
        overflow: auto;
        max-height: calc(100vh - 24rem);
      }

      .beneficiary {
        &:hover, &.selected {
          color: $textColor;
        }
      }

      .add-beneficiary, .cancel {
        font-size: 1.6rem;
      }
    }
  }

  .busy {
    font-style: italic;
    color: $grey;
  }
</style>
