var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "progress-circle",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 36 36" }
    },
    [
      _c("path", {
        staticClass: "progress-circle-background",
        attrs: {
          stroke: _vm.backgroundColor,
          d:
            "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        }
      }),
      _c("path", {
        staticClass: "progress-circle-fill",
        attrs: {
          "stroke-dasharray": _vm.percentage + ", 100",
          stroke: _vm.color,
          d:
            "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        }
      }),
      _c(
        "text",
        {
          staticClass: "progress-circle-text",
          attrs: { x: "18.2", y: "21.35", fill: _vm.textColor }
        },
        [_vm._v(_vm._s(_vm.percentage) + "%")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }