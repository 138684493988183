<template>
  <AppLayout class="profile-settings-page">
    <main>
      <ProfileTabBar />
      <div class="container">
        <p class="information bordered">
          Personnalisez votre solution Hellocare&nbsp;!<br>
          Ces paramètres sont inclus dans le prix de votre abonnement et vous permettent d'adapter la solution à votre
          pratique.<br>
        </p>
        <div class="settings">
          <div class="settings-general">
            <GroupTitle :icon="$icons.settings">Gérez vos paramètres</GroupTitle>
            <ProfileSetting
              :disabled="(profile.searchable && profile.instantEncountersEnabled) || !profile.practiceAddress || !profile.mainSpecialty"
              property="searchable"
            >
              J’active la prise de RDV en ligne
              <template #description>
                <p v-if="!profile.practiceAddress" class="setting-error">
                  <FontAwesomeIcon :icon="$icons.inputError" />
                  Vous devez <router-link :to="{ name: 'profile-edit', query: { hl: 'practiceAddress' } }">renseigner l'adresse de votre cabinet</router-link> pour activer la prise de RDV en ligne
                </p>
                <p v-if="!profile.mainSpecialty" class="setting-error">
                  <FontAwesomeIcon :icon="$icons.inputError" />
                  Vous devez <router-link :to="{ name: 'profile-edit', query: { hl: 'mainSpecialty' } }">renseigner votre spécialité principale</router-link> pour activer la prise de RDV en ligne
                </p>
                <p v-if="profile.instantEncountersEnabled && profile.searchable" class="setting-error">
                  <FontAwesomeIcon :icon="$icons.inputError" />
                  Désactivez les RDV hors patientèle pour désactiver la prise de RDV en ligne
                </p>
                Permet à vos patients de vous trouver via notre moteur de recherche et de prendre rendez-vous en ligne
                directement.
                <a href="https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#prise-de-RDV"
                   target="_blank">
                  En savoir plus
                </a>
              </template>
            </ProfileSetting>

            <ProfileSetting :disabled="!profile.searchable &&!profile.instantEncountersEnabled" property="instantEncountersEnabled">
              J’accepte les RDV hors patientèle
              <template #description>
                <p v-if="!profile.searchable" class="setting-error">
                  <FontAwesomeIcon :icon="$icons.inputError" />
                  Activez la prise de RDV en ligne pour accepter les RDV hors patientèle
                </p>
                Permet de développer vos revenus en recevant des patients supplémentaires.
                <template v-if="profile.newPatientFee">
                  Dans le cadre d'une téléconsultation, Hellocare percevra
                  <strong class="important">{{ profile.newPatientFee }}%</strong> du montant de celle-ci.
                </template>
                <a href="https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#patient-plus"
                   target="_blank">En savoir plus</a>
              </template>
            </ProfileSetting>

            <ProfileSetting v-if="profile.type === 'doctor'" :disabled="!profile.uploadedSignature" property="claimFormEnabled">
              J’active les feuilles de soins
              <template #description>
                <p v-if="!profile.uploadedSignature" class="setting-error">
                  <FontAwesomeIcon :icon="$icons.inputError" />
                  Ajoutez une signature à votre profil pour activer l'édition de la feuille de soin en fin de
                  téléconsultation.
                </p>
                Permet d’éditer et mettre à disposition du patient une feuille de soin en fin de téléconsultation.
              </template>
            </ProfileSetting>

            <ProfileSetting property="smsEnabled">
              J’active l'option "Rappels SMS"
              <template #description>
                Permet de rappeler à vos patients leurs RDV par SMS. Cette option permet de réduire significativement
                les consultations non honorées.
                <a href="https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#rappels-SMS"
                   target="_blank">En savoir plus</a>
              </template>
            </ProfileSetting>
          </div>
          <div class="settings-payment">
            <GroupTitle :icon="$icons.creditCard">
              Paiement par carte bancaire
            </GroupTitle>

            <ProfileSetting property="cardPaymentActivated">
              Accepter le paiement CB
              <template #description>
                <p>
                  En acceptant le paiement CB j'atteste avoir pris connaissance des
                  <a href="https://stripe.com/fr/legal#translation" target="_blank">conditions générales
                    d'utilisation</a>
                  associées.
                </p>

                <p>
                  Commission de 1,2% au titre des frais de traitement bancaire en sus.
                </p>

                <p>
                  Cette option n'est pas disponible pour les praticiens basés en Polynésie Française, Wallis-et-Futuna,
                  Nouvelle-Calédonie, Saint-Barthélemy et Saint-Pierre-et-Miquelon.
                </p>

                <div :class="paymentStatusClass" class="payment-status">
                  <FontAwesomeIcon :icon="paymentStatusIcon" class="icon" />
                  <p>{{ paymentStatusText }}</p>
                </div>
              </template>
            </ProfileSetting>

            <ButtonGreen class="payment-informations-button" outlined rounded @click="goToStripeSetup">
              {{ paymentEnabled ? 'Modifier' : 'Compléter' }} mes informations
            </ButtonGreen>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../../components/AppLayout';
  import GroupTitle from '../../components/GroupTitle';
  import ProfileTabBar from '../../components/ProfileTabBar';
  import FormSwitch from '../../components/Form/FormSwitch';
  import FormCheckbox from '../../components/Form/FormCheckbox';
  import ButtonGreen from '../../components/Button/ButtonGreen.vue';
  import Practitioners from '../../api-platform/practitioners';
  import ButtonRed from '../../components/Button/ButtonRed.vue';
  import FadeTransition from '../../components/FadeTransition';
  import ProfileSetting from '@/components/ProfileSetting';

  export default {
    name: 'ProfileSettingsPage',
    components: {
      GroupTitle,
      ProfileSetting,
      FadeTransition,
      ButtonRed,
      ButtonGreen,
      FormCheckbox,
      FormSwitch,
      ProfileTabBar,
      AppLayout,
    },
    data() {
      return {
        form: {
          cgu: false,
        },
      };
    },
    mounted() {
      this.form.cgu = this.profile.cardPaymentActivated;
    },
    computed: {
      profile() {
        return this.$store.state.practitioner.profile;
      },
      paymentEnabled() {
        return this.profile.acceptPayment && this.profile.acceptTransfer;
      },
      paymentPartial() {
        return this.profile.acceptPayment && !this.profile.acceptTransfer;
      },
      paymentDisabled() {
        return !this.profile.acceptPayment && !this.profile.acceptTransfer;
      },
      paymentStatusClass() {
        return this.paymentEnabled
          ? 'payment-status-success'
          : 'payment-status-error';
      },
      paymentStatusIcon() {
        if (this.paymentEnabled) {
          return this.$icons.paymentEnabled;
        }

        return this.paymentPartial
          ? this.$icons.paymentPartial
          : this.$icons.paymentDisabled;
      },
      paymentStatusText() {
        if (this.paymentEnabled) {
          return 'Informations renseignées';
        }

        return this.paymentPartial
          ? 'Des informations supplémentaires sont requises pour pouvoir virer les paiements sur votre compte bancaire'
          : 'Des informations supplémentaires sont requises pour activer le paiement';

      },
    },
    methods: {
      async goToStripeSetup() {
        const { link } = await Practitioners.getAccountLink(this.$route.fullPath);
        window.location.href = link;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .container {
    display: block;
    max-width: 98rem;
    margin: 0 auto;

    a {
      text-decoration: underline;
    }
  }

  .important {
    font-weight: bold;
  }

  .information {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 3rem;
    text-align: justify;

    &.bordered {
      padding: 2rem 0 3rem;
      border-bottom: 1px solid $borderColor;
    }
  }

  .settings-payment {
    padding-top: 3rem;
    border-top: 1px solid $borderColor;
  }

  .setting-error {
    color: $errorColor;
  }

  @include onDesktop {
    .settings {
      display: flex;
    }

    .settings-general {
      padding-right: 6.2rem;
      border-right: 1px solid $borderColor;

      ::v-deep .description {
        margin-right: 10rem;
      }
    }

    .settings-payment {
      min-width: 36rem;
      max-width: 36rem;
      padding-top: 0;
      padding-left: 6.2rem;
      border: none;
    }
  }

  .payment-agreement {
    font-size: 1.5rem;
    align-items: flex-start;
    margin-bottom: 3rem;
  }

  .payment-informations-button {
    margin-bottom: 2rem;
  }

  .payment-status {
    display: flex;

    p {
      font-weight: bold;
      margin: 0 0 0 1rem;
      color: $textColor;
    }

    .icon {
      margin-top: 0.2rem;
    }

    &-success .icon {
      color: $successColor;
    }

    &-error .icon {
      color: $errorColor;
    }
  }
</style>
