<template>
  <AppLayout>
    <main class="dashboard-page">
      <div class="appointments">
        <SectionTitle>Vos rendez-vous du jour</SectionTitle>
        <FormWidget>
          <label for="select-input">Type de rendez-vous</label>
          <SelectWrapper>
            <select id="select-input" v-model="selectedOption" @change="toggleAppointments">
              <option v-for="option in options" :value="option.value">{{ option.text }}</option>
            </select>
          </SelectWrapper>
        </FormWidget>
        <hr/>
        <ModuleLoader v-if="isFetchingAppointments" />
        <div v-else-if="hasAppointments" class="active-appointments">
          <template v-for="(appointments, index) in [appointmentsByExpired[false], appointmentsByExpired[true]]">
            <AppointmentListItem
              v-for="appointment in appointments"
              :key="appointment.payload.id"
              :appointment-id="appointment.payload.id"
              :can-be-started="canBeStarted(appointment.payload.startAt)"
              :channel="appointment.payload.product.channel"
              :date="appointment.dateShort"
              :day-of-week="appointment.dayOfWeekShort"
              :description="appointment.payload.description"
              :expired="appointment.expired"
              :patient="appointment.payload.patient"
              :status="appointment.payload.status"
              :time="appointment.time"
            />
            <hr v-if="index === 0">
          </template>
        </div>
        <p v-else>Vous n’avez pas de rendez-vous à venir.</p>
      </div>
      <hr class="vertical separator" />
      <div class="new-appointment">
        <SectionTitle>Proposer un rendez-vous</SectionTitle>
          <p class="reminder grey-reminder" v-if="shouldDisplayReminder()">
              Rappel : selon les récentes MAJ de nos
              <a class="underline grey-reminder"
                 href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu"
                 target="_blank"
              >
                  CGUV Patient
              </a>,
              à compter
              du 20/12/23, chaque téléconsultation entraînera des frais
              de service de 3€ à la charge du patient.
          </p>
        <NewAppointmentForm ref="newAppointmentForm" @success="loadAppointments" />
      </div>
      <transition :duration="600">
        <router-view />
      </transition>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout.vue';
  import AppAnimatedLogo from '@/components/AppLogoAnimated.vue';
  import AppointmentListItem from '@/components/AppointmentListItem.vue';
  import NewAppointmentForm from '@/components/Dashboard/NewAppointmentForm.vue';
  import ModuleLoader from '@/components/ModuleLoader.vue';
  import SectionTitle from '@/components/SectionTitle.vue';
  import { pickElement, wait } from '@/helpers/tools.js';
  import { groupBy } from 'lodash-es';
  import moment from 'moment';
  import ButtonGrey from "../components/Button/ButtonGrey.vue";
  import FormWidget from "../components/Form/FormWidget.vue";
  import SelectWrapper from "../components/Form/SelectWrapper.vue";

  export default {
    name: 'DashboardPage',
    components: {
      SelectWrapper,
      FormWidget,
      ButtonGrey, AppLayout, ModuleLoader, AppAnimatedLogo, NewAppointmentForm, SectionTitle, AppointmentListItem },
    data() {
      return {
        byTransaction: false,
        toggleLoader: false,
        selectedOption: 'all',
        options: [
          { text: 'Tous les rendez-vous', value: 'all' },
          { text: 'Les rendez-vous avec une empreinte bancaire', value: 'captured' },
        ]
      }
    },
    mounted() {
      this.$setInterval(this.loadAppointments, 60000, true);
      this.setupGuide();
    },
    computed: {
      isFetchingAppointments() {
        return this.$store.state.appointments.isFindingAll;
      },
      appointmentsByExpired() {
        return groupBy(this.$store.getters.foundAppointmentsWithMeta, a => a.expired);
      },
      hasAppointments() {
        return Object.keys(this.appointmentsByExpired).length > 0;
      },
    },
    methods: {
      toggleAppointments() {
        if(this.selectedOption==='all') {
          this.byTransaction = false
        }
        if(this.selectedOption==='captured') {
          this.byTransaction = true
        }
        this.toggleLoader = true;
        this.loadAppointments()
        this.toggleLoader = false;
      },
      async loadAppointments() {

        await this.$store.dispatch('appointmentsFind', {
          startDate: moment().startOf('day'),
          endDate: moment().endOf('day'),
          byTransaction: this.byTransaction
        });

        const hasPaidAppointment = this.$store.state.appointments.found.some(appointment => {
          return appointment.order?.paymentStatus === 'paid';
        });

        hasPaidAppointment
          ? this.$store.dispatch('vueGuideActivateDeferredStep', 'nav-payment')
          : this.$store.dispatch('vueGuideCancelDeferredStep', 'nav-payment');
      },
      canBeStarted(startAt) {
        const minimumStartTime = moment(startAt).subtract(15, 'm');

        return moment().isAfter(minimumStartTime);
      },
      async setupGuide() {
        await this.$store.dispatch('vueGuideAddStep', {
          id: 'nav-profile',
          title: 'Bienvenue sur votre tableau de bord',
          content: 'Pensez à mettre à jour votre profil',
          setup() {
            const navItem = document.querySelector('.app-navigation > .nav-item:nth-of-type(2)');

            // Define context
            return {
              navItemLabel: navItem.querySelector('.nav-item-label'),
              navItemChild: navItem.querySelector('.nav-item-child-label:nth-child(1)'),
            };
          },
          target: ({ navItemChild }) => navItemChild,
          before: async ({ navItemLabel }) => {
            if (!this.$store.state.appNavigation.isExpanded) {
              await this.$store.dispatch('appNavigationExpand');
              await wait(450);
            }
            navItemLabel.classList.add('expanded');
          },
          after: ({ navItemLabel }, terminated) => {
            navItemLabel.classList.remove('expanded');
            if (terminated) {
              this.$store.dispatch('appNavigationShrink');
            }
          },
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'nav-agenda',
          target: '.app-navigation > .nav-item:nth-of-type(3)',
          content: 'Vous retrouverez dans votre agenda toutes vos consultations passées et à venir',
          before: async () => {
            if (!this.$store.state.appNavigation.isExpanded) {
              await this.$store.dispatch('appNavigationExpand');
              await wait(450);
            }
          },
          after: (context, terminated) => {
            if (terminated) {
              this.$store.dispatch('appNavigationShrink');
            }
          },
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'new-appointment-form',
          target: '.new-appointment',
          content: 'Invitez vos patients directement par email',
          offset: 10,
          before: async () => {
            if (this.$store.state.appNavigation.isExpanded) {
              await this.$store.dispatch('appNavigationShrink');
            }
          },
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'advanced-fields',
          target: '.advanced-fields',
          content: 'Les options avancées vous permettrons d\'ajouter un motif de RDV ainsi qu\'une note privée',
          offset: 10,
          actions: [
            {
              label: 'Essayer',
              do: () => this.$refs.newAppointmentForm.areAdvancedFieldsVisible = true,
              undo: () => this.$refs.newAppointmentForm.areAdvancedFieldsVisible = false,
            },
          ],
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'nav-payment',
          setup() {
            const navItem = document.querySelector('.app-navigation > .nav-item:nth-of-type(2)');

            // Define context
            return {
              navItemLabel: navItem.querySelector('.nav-item-label'),
              navItemChild: navItem.querySelector('.nav-item-child-label:nth-child(2)'),
            };
          },
          content: 'Vous pouvez suivre vos paiements dans l\'onglet dédié',
          target: ({ navItemChild }) => navItemChild,
          before: async ({ navItemLabel }) => {
            if (!this.$store.state.appNavigation.isExpanded) {
              await this.$store.dispatch('appNavigationExpand');
              await wait(450);
            }
            navItemLabel.classList.add('expanded');
          },
          after: ({ navItemLabel }, terminated) => {
            navItemLabel.classList.remove('expanded');
            if (terminated) {
              this.$store.dispatch('appNavigationShrink');
            }
          },
          defer: true,
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'chat-icon',
          target: pickElement('#front-chat-holder > div > div'),
          content: 'N\'hésitez pas à nous poser vos questions via le tchat !',
          offset: 10,
        });

        pickElement('.appointment-list-item.closed')
          .then(target => {
            this.$store.dispatch('vueGuideAddStep', {
              id: 'dashboard-appointment-closed',
              target,
              content: 'Vos documents sont accessibles sur le détail du rendez-vous',
              offset: 10,
            });
          });
      },
      shouldDisplayReminder() {
          const now = new Date();
          const year = now.getFullYear();

          return year < 2024;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-page {
    display: flex;
    flex-direction: column-reverse;
    max-width: 1080px;
    margin: 0 auto;

    @include onDesktop {
      flex-direction: row;
    }
  }

  .appointments,
  .new-appointment {
    flex-basis: 50%;

    @include onDesktop {
      max-width: 50%;
    }
  }

  .separator {
    margin: 2rem 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #EDEDED;

    @include onDesktop {
      &.vertical {
        height: auto;
        margin: 0 6rem;
        border-width: 0 0 0 1px;
      }
    }
  }

  .inactive-appointments {
    overflow: auto;
    max-height: 33rem;
  }

  .reminder {
    font-size: 1.37rem;
  }

  .grey-reminder {
    color: #969696;
  }

  .underline {
    text-decoration: underline;
  }

</style>
