<!--
Emitted events:
- removed
-->
<template>
  <div class="file" :title="label" @click="download">
    <div class="thumbnail">
      <FontAwesomeIcon
        class="file-icon"
        :icon="removing ? $icons.spinner : $icons.file"
        @click.stop="download"
        :spin="removing"
      />
      <!-- DISABLE UNTIL FIXED -->
<!--      <div class="signit-icon" @click.stop="signIt">
        <FontAwesomeIcon :icon="$icons.pen" />
      </div>-->
      <div v-if="removable" class="remove-icon" @click.stop="remove">
        <FontAwesomeIcon :icon="$icons.trashOutlined" />
      </div>
      <div class="plus-icon" @click.stop="toggleDisplay">
        <FontAwesomeIcon :icon="$icons.eye" />
      </div>
    </div>
    <div class="label">
      <slot />
    </div>
    <AppPopIn v-if="showFile&&base64File" @close="close" max-width="50rem">
      <img :src="`data:image/png;base64,` + base64File" alt="file preview" id="file-preview" />
      <ButtonRed @click="close">Quitter</ButtonRed>
    </AppPopIn>
  </div>
</template>

<script>
  import Files from '../../../api/files';
  import download from 'downloadjs';
  import AppPopIn from "../../AppPopIn.vue";
  import ButtonRed from "../../Button/ButtonRed.vue";

  export default {
    name: 'File',
    components: {ButtonRed, AppPopIn},
    props: {
      id: {
        type: String,
        required: true,
      },
      path: {
        type: String,
        required: false,
      },
      appointmentId: {
        type: String,
        required: false
      },
      removable: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        removing: false,
        showFile: false,
        base64File: null
      };
    },
    mounted() {
      Files.asBase64(this.id).then(response => {
        this.base64File = response;
      })
    },
    computed: {
      label() {
        if (!this.$slots.default[0] || !this.$slots.default[0].text) {
          return;
        }

        return this.$slots.default[0].text.trim();
      },
    },
    methods: {
      async download() {
        if(!this.id) {
          return;
        }

        try {
          const file = await Files.download(this.id);
          download(file, this.label);
        } catch (e) {
          this.$addError('Une erreur est survenue lors de la tentative de téléchargement du fichier');
          throw e;
        }
      },
      async remove() {
        try {
          this.removing = true;
          await Files.remove(this.id);
          this.$emit('removed');
        } catch (e) {
          this.$addError('Une erreur est survenue lors de la tentative de suppression du fichier');
          throw e;
        }
      },
      async toggleDisplay() {
        try {
          this.showFile = !this.showFile
        } catch (e) {
          this.$addError('Une erreur est survenue lors de la tentative de suppression du fichier');
          throw e;
        }
      },
      close() {
        this.showFile = !this.showFile
      },
      async signIt()
      {
        return this.$router.push({ name: 'document-sign', params: { appointmentid: this.appointmentId, id: this.id } });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .file {
    cursor: pointer;
    margin: 1rem 1rem 0;
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 12rem;
    height: 9rem;
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 0.5rem;
  }

  .remove-icon {
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $errorColor;
    color: $lightTextColor;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }
  .plus-icon {
    position: absolute;
    right: 3rem;
    bottom: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;
    color: $lightTextColor;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .signit-icon {
    position: absolute;
    right: 6.5rem;
    bottom: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $altColor;
    color: $lightTextColor;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .label {
    text-align: center;
    margin-top: 1rem;
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-icon {
    font-size: 4rem;
    color: $borderColor;
  }
  #file-preview {
    border: 1px solid lightgray;
    max-width: 100%;
  }
</style>
