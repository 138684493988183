var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agenda-date-picker-day" },
    [
      _c(
        "div",
        { staticClass: "initials" },
        _vm._l(["L", "M", "M", "J", "V", "S", "D"], function(initial) {
          return _c("div", { staticClass: "initial" }, [
            _vm._v(_vm._s(initial))
          ])
        }),
        0
      ),
      _vm._l(_vm.weeks, function(week) {
        return _c(
          "div",
          {
            staticClass: "week",
            class: { selected: _vm.isWeekSelected(week) }
          },
          _vm._l(week, function(day) {
            return _c(
              "div",
              {
                staticClass: "day",
                class: { fade: _vm.shouldFade(day), today: _vm.isToday(day) },
                on: {
                  click: function($event) {
                    return _vm.selectDay(day)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(day.date()) + "\n    ")]
            )
          }),
          0
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }