import api from './';
import { normalizeEncounter } from '../helpers/normalizers';

export default {
  create: (appointmentId, patientId, productId) => api.post('encounters', {
    json: {
      appointment: appointmentId,
      patient: patientId,
      products: productId ? [productId] : [],
    },
  }).json().then(normalizeEncounter),
  update: (id, data) => api.patch(`encounters/${id}`, { json: data}),
  getOne: (id) => api.get(`encounters/${id}`).json().then(normalizeEncounter),
  rate: (id, rate, sound_issue, video_issue, comment_issue ) => api.post(`encounters/${id}/rate`, { json: { rate, sound_issue, video_issue, comment_issue } }),
  getPrescription: (id) => api.get(`encounters/${id}/prescription`).blob(),
  getPatientAdvice: (id) => api.get(`encounters/${id}/report`).blob(),
  getClaim: (id) => api.get(`claims/${id}`).blob(),
  report: (id, patientId, medicalObservation, patientAdvice, motives) => api.patch(`encounters/${id}`, {
    json: {
      patientId,
      medicalObservation,
      patientAdvice,
      motives
    }
  }).json(),
  validate: (id) => api.post(`encounters/${id}/payment/validate`).json(),
  join: (id) => api.post(`encounters/${id}/join`).json(),
};
