<template>
  <AppLayout>
    <div v-if="practitioner.id" class="profile-view">
      <main class="container-body">
        <div class="profile">
          <SectionTitle>Mon profil</SectionTitle>
          <div class="userProfile">
            <UserAvatar
              :size="$media.desktop ? '13rem' : '8rem'"
              :src="avatarURL"
              :user-name="practitionerFullName"
              class="avatar"
              editable
            />
            <div class="name">
              <p>{{ practitioner.firstName | capitalize }}</p>
              <p>{{ practitioner.lastName | uppercase }}</p>
              <p v-if="practitioner.mainSpecialty" class="specialty">{{ practitioner.mainSpecialty.name }}</p>
            </div>
          </div>
          <ButtonGreen bold class="edit" link-to="/profile/edit" long small>Gérer mon profil</ButtonGreen>
        </div>
        <div v-if="practitioner.description" class="bio">
          <SectionTitle>A propos de moi</SectionTitle>
          <p>{{ practitioner.description }}</p>
        </div>
        <div class="infos">
          <SectionTitle>Informations professionnelles</SectionTitle>

          <p v-if="practitioner.searchable" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.link" class="profile-label-icon" />
            <a :href="publicURL" target="_blank">Ma page publique</a>
          </p>

          <p class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.territorialOrganization" class="profile-label-icon" />
            <small> <abbr title="Organisation Territoriale">O.T.</abbr> - </small>
            <span v-if="practitioner.territorialOrganizationType">{{ practitioner.territorialOrganizationName }}</span>
            ({{ practitioner.territorialOrganizationType | territorialOrganization }})
          </p>

          <p v-if="practitioner.rpps" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.idBadge" class="profile-label-icon" />
            <small> RPPS -</small>
            {{ practitioner.rpps }}
          </p>

          <p v-if="practitioner.adeli" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.addressCard" class="profile-label-icon" />
            <small> ADELI -</small>
            {{ practitioner.adeli }}
          </p>

          <p v-if="practitioner.phone" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.mobile" class="profile-label-icon" />
            {{ practitioner.phone | phone }}
          </p>

          <p v-if="practitioner.practicePhone" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.phone" class="profile-label-icon" />
            {{ practitioner.practicePhone | phone }}
          </p>

          <p v-if="practitioner.email" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.mail" class="profile-label-icon" />
            {{ practitioner.email }}
          </p>

          <p v-if="practitioner.securedEmail" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.protectedMail" class="profile-label-icon" />
            {{ practitioner.securedEmail }}
          </p>

          <p v-if="isAddressComplete" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.mapMarker" class="profile-label-icon" />
            {{ practitioner.practiceAddress }} - {{ practitioner.practiceZipCode }} {{ practitioner.practiceCity }}
            {{ practitioner.country }}
          </p>

          <p class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.globe" class="profile-label-icon" />
            <small>FUSEAU HORAIRE&nbsp;-</small> {{ practitioner.timezone }}
          </p>

          <p v-if="practitioner.convention" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.bookmark" class="profile-label-icon" />
            {{ practitioner.convention.name }}
          </p>

          <p v-if="practitioner.specialties.length" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.medic" class="profile-label-icon" />
            <span
              v-for="specialty in practitioner.specialties"
              class="profile-tag-specialty"
            >
              {{ specialty.name }}
            </span>
          </p>
        </div>
      </main>
      <AppFooter class="container-footer">
        <SectionTitle class="progress" light>Votre Profil</SectionTitle>
        <div class="gestion">
          <div>
            <p>
              Pour accéder à l’ensemble de nos services, nous vous invitons à compléter votre profil.
            </p>
            <ButtonGreen class="edit" link-to="/profile/edit" smallbold>Gérer mon profil</ButtonGreen>
          </div>
          <div class="progress-circle">
            <ProgressCircle :percentage="profileCompletionPercentage" />
          </div>
        </div>
        <SectionTitle class="signaletique" light>Informer mes patients</SectionTitle>
        <div class="accompagnement">
          <p>
            Pour vous aider à indiquer à vos patients que vous faites de la téléconsultation, un kit est à votre
            disposition.
          </p>
          <ButtonWhite
            link-to="https://kb.hellocare.pro/support/informer-mes-patients-pour-la-teleconsultation"
            outlined
            smallbold
          >
            Ça m'intéresse !
          </ButtonWhite>
        </div>
      </AppFooter>
    </div>
  </AppLayout>
</template>

<script>
  import SectionTitle from '@/components/SectionTitle';
  import ProgressCircle from '@/components/ProgressCircle';
  import AppFooter from '@/components/AppFooter';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonWhite from '@/components/Button/ButtonWhite.vue';
  import UserAvatar from '@/components/UserAvatar';
  import { getFullName } from '@/helpers/format';
  import AppLayout from '@/components/AppLayout';
  import { getSetting } from '@/helpers/tools';

  function objectToArray(obj, ...keys) {
    return keys.map(key => {
      if (Array.isArray(key)) {
        return key[1](obj[key[0]]);
      }

      return obj[key];
    });
  }

  export default {
    name: 'ProfileViewPage',
    components: { AppLayout, UserAvatar, ButtonWhite, ButtonGreen, SectionTitle, ProgressCircle, AppFooter },
    computed: {
      profileCompletionPercentage() {
        const profileData = [
          this.practitioner.civility,
          this.practitioner.birthDate,
          this.practitioner.convention,
          this.practitioner.description,
          this.practitioner.specialties.length,
          this.practitioner.adeli || this.practitioner.rpps,
          this.practitioner.iban,
          // this.practitioner.uploadedEmploymentAttestation,
          this.practitioner.uploadedLegalDocument,
          this.practitioner.uploadedSignature,
          this.practitioner.practiceAddress && this.practitioner.practiceZipCode && this.practitioner.practiceCity,
          this.practitioner.products.length > 1,
        ];

        const total = profileData.length;
        const defined = profileData.filter(val => !!val).length;
        return Math.round((defined * 100) / total);
      },
      practitioner() {
        return this.$store.state.practitioner.profile;
      },
      practitionerFullName() {
        return getFullName(this.practitioner.firstName, this.practitioner.lastName);
      },
      isAddressComplete() {
        const { practiceAddress, practiceZipCode, practiceCity } = this.$store.state.practitioner.profile;
        return practiceAddress && practiceZipCode && practiceCity;
      },
      avatarURL() {
        return this.practitioner.picture;
      },
      publicURL() {
        return `${getSetting('URL_DIRECTORY')}${this.practitioner.slug}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container-body {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;

    .profile {
      margin: 15px;

      .userProfile {
        position: relative;
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 25px;
        }

        .name p {
          font-size: 2.5em;
          margin: 0;

          @include onDesktop {
            font-size: 3em;
          }

          &:first-child {
            font-weight: bold;
          }

          &.specialty {
            font-size: 1.4em;
            text-transform: capitalize;
            color: #43A37B;
          }
        }
      }

      .edit {
        margin-top: 30px;
      }
    }

    .bio {
      margin: 15px;
    }

    .infos {
      margin: 15px;

      .profile-tag-specialty {
        line-height: 13px;
        display: inline-block;
        margin: 0 5px;
        padding: 5px 8px;
        border-radius: 5px;
        background: $lightTextColor;
      }

      .profile-label-text {
        line-height: 45px;
      }

      .profile-tag-specialty {
        margin: 0 5px;
        padding: 5px 8px;
        border-radius: 5px;
        background: $lightTextColor;
      }
    }
  }

  .container-footer {
    .progress, .signaletique {
      margin-bottom: 0;
    }

    svg {
      width: 14em;
    }
  }

  @include onMobile {
    .container-body {
      padding-left: 1.8rem;
      grid-template-columns: 1fr;

      .profile {
        padding-bottom: 40px;
        border-bottom: 1px solid $lightTextColor;
        grid-area: 1 / 1 / 2 / 2;
      }

      .infos {
        border: 0;
        border-bottom: 1px solid $lightTextColor;
        grid-area: 2 / 1 / 4 / 2;

        .profile-label-text {
          line-height: 45px;
        }

        .profile-label-icon {
          width: 25px;
          margin-right: 10px;
        }
      }

      .bio {
        padding-top: 10px;
        grid-area: 4 / 1 / 5 / 2;
      }
    }
    .container-footer {
      grid-template-columns: 1fr;

      .progress {
        grid-area: 1 / 1 / 2 / 2;
      }

      .gestion {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #31687D;
        grid-area: 3 / 1 / 4 / 2;
      }

      .progress-circle {
        grid-area: 2 / 1 / 3 / 2;

      }

      .signaletique {
        grid-area: 4 / 1 / 5 / 2;
      }
    }
  }

  @include onDesktop {
    .container-body {
      padding-left: 13rem;
      grid-template-columns: 1.2fr .8fr 1fr;

      .profile {
        grid-area: 1 / 1 / 2 / 2;
      }

      .infos {
        padding-left: 40px;
        border-left: 1px solid $lightTextColor;
        grid-area: 1 / 2 / 4 / 4;

        .profile-label-icon {
          width: 45px;
          margin-right: 40px;
        }
      }

      .bio {
        padding-top: 40px;
        border-top: 1px solid $lightTextColor;
        grid-area: 2 / 1 / 3 / 2;
      }
    }
    .container-footer {
      grid-template-columns: 1.2fr .8fr 1fr;

      .progress {
        grid-area: 1 / 1 / 2 / 2;
      }

      .gestion {
        display: flex;
        grid-area: 2 / 1 / 3 / 2;
      }

      .progress-circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-area: 1 / 2 / 3 / 3;
      }

      .signaletique {
        grid-area: 1 / 3 / 2 / 4;
      }

      .accompagnement {
        grid-area: 2 / 3 / 3 / 4;
      }
    }
  }
</style>
