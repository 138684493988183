var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-cards" },
    [
      _vm.isLoadingSepa
        ? _c("div", [_vm._v("Vérification de l'iban...")])
        : [
            _c("br"),
            _vm.noSepa
              ? _c("div", [_c("SepaDebitReplaceForm")], 1)
              : _c(
                  "div",
                  [
                    _vm.sepaDebit && !_vm.showReplaceSepaForm
                      ? _c("SepaDebitDisplay", {
                          attrs: {
                            iban:
                              _vm.sepaDebit.country +
                              " ** " +
                              _vm.sepaDebit.bankCode +
                              " ******* " +
                              _vm.sepaDebit.last4
                          },
                          on: {
                            replace: function($event) {
                              _vm.showReplaceSepaForm = true
                            }
                          }
                        })
                      : _c("SepaDebitReplaceForm", {
                          attrs: {
                            "previous-iban-id":
                              _vm.sepaDebit && _vm.sepaDebit.id
                          },
                          on: {
                            close: function($event) {
                              _vm.showReplaceSepaForm = false
                            },
                            replace: _vm.handleReplaced
                          }
                        })
                  ],
                  1
                )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }