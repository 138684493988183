import api from './';

export default {
    get: (code) => {
        let searchParams = new URLSearchParams();
        searchParams.set('code', code);
        return api.get('Tesly', {searchParams}).json()
    },
    download: (code, filename) => {
        return api.get(`Tesly/${code}/${filename}`).blob();
    }
};
