<!--
Emitted events:
- close
-->
<template>
  <AppPanel
      :opened="opened"
      max-width="120rem"
      title="Aide à la prescription"
      @close="closePanel"
  >
    <form @submit.prevent="handleDrugSubmit">
      <HorizontalDivider class="synapse-logo-container" left>
        Prescription médicamenteuse
        <img alt="" class="synapse-logo" src="../assets/images/picto_solution_synapse.svg" />
      </HorizontalDivider>
      <div class="row">
        <div class="col _10">
          <FormWidget>
            <label for="drug">Nom du médicament</label>
            <AutoCompletedInput
                id="drug"
                ref="drug"
                v-slot="{ result }"
                v-model="drug"
                :search-method="searchDrugs"
                :searching.sync="isSearchingDrug"
                :typed-text.sync="typedDrug"
                @change="focusDosage"
            >
              {{ result.entity.label }}
            </AutoCompletedInput>
            <template v-if="$v.drug.$error" #error>
              <span v-if="!$v.drug.required">Veuillez choisir un médicament</span>
            </template>
            <template #help>
              <span v-if="isSearchingDrug">
                <FontAwesomeIcon :icon="$icons.spinner" spin />
                Recherche en cours...
              </span>
              &nbsp;
            </template>
          </FormWidget>
        </div>
      </div>
      <div class="row">
        <div class="col _4">
          <FormWidget>
            <label for="dosage">Dosage journalier</label>
            <input
                id="dosage"
                type="number"
                ref="dosage"
                v-model="dosage"
            >
            <template v-if="$v.dosage.$error" #error>
              <span v-if="!$v.dosage.required">Veuillez entrer le dosage</span>
            </template>
            <template #help>&nbsp;</template>
          </FormWidget>
        </div>
        <div class="col _6">
          <FormWidget>
            <div class="row">
              <FormCheckbox v-model="dailyDosage.morning" class="col _6">
                Matin
              </FormCheckbox>
              <FormCheckbox v-model="dailyDosage.evening" class="col _6">
                Soir
              </FormCheckbox>
            </div>
            <div class="row">
              <FormCheckbox v-model="dailyDosage.noon" class="col _6">
                Midi
              </FormCheckbox>
              <FormCheckbox v-model="dailyDosage.night" class="col _6">
                Couché
              </FormCheckbox>
            </div>
          </FormWidget>
        </div>
      </div>
      <div class="row">
        <div class="col _3">
          <p class="mr-lg-2 center">Tous les </p>
        </div>
        <div class="col _3">
          <FormWidget>
            <label for="frequency">Fréquence</label>
            <input
                id="frequency"
                type="number"
                ref="frequency"
                v-model="frequency"
            >
            <template v-if="$v.frequency.$error" #error>
                      <span v-if="!$v.frequency.required">
                          Veuillez entrer la fréquence
                      </span>
            </template>
            <template #help>&nbsp;</template>
          </FormWidget>
        </div>
        <div class="col _4">
          <FormWidget>
            <label for="frequencyUnit">Unité de fréquence</label>
            <SelectWrapper>
              <select id="frequencyUnit" v-model="frequencyUnit">
                <option :value="null" v-if="frequencyUnit === ''" />
                <option value="DAILY">Jour</option>
                <option value="WEEKLY">Semaine</option>
                <option value="MONTHLY">Mois</option>
              </select>
            </SelectWrapper>
            <template v-if="$v.frequencyUnit.$error" #error>
                      <span v-if="!$v.frequencyUnit.required">
                          Veuillez entrer une unité de fréquence
                      </span>
            </template>
            <template #help>&nbsp;</template>
          </FormWidget>
        </div>
      </div>
      <div class="row">
        <div class="col _3">
          <p class="mr-lg-2 center">Pendant </p>
        </div>
        <div class="col _3">
          <FormWidget>
            <label for="duration">Durée</label>
            <input
                id="duration"
                type="number"
                ref="duration"
                v-model="duration"
            >
          </FormWidget>
        </div>
        <div class="col _4">
          <FormWidget>
            <label for="durationUnit">Période</label>
            <SelectWrapper>
              <select id="durationUnit" v-model="durationUnit">
                <option :value="null" v-if="durationUnit === ''" />
                <option value="DAY">Jour</option>
                <option value="WEEK">Semaine</option>
                <option value="MONTH">Mois</option>
              </select>
            </SelectWrapper>
          </FormWidget>
        </div>
        <div class="col _2 center">
          <ButtonGreen block class="add-button" type="submit">Ajouter</ButtonGreen>
        </div>
      </div>
    </form>
    <div class="drugs">
      <div v-for="(line, index) in prescriptionLines" class="row prescription-line">
        <div class="col _7">
          <FontAwesomeIcon class="icon" :icon="$icons.pills" />
          {{ line.drug.entity.label }}
        </div>
        <div class="col _4">
          {{ line.dosageLabel }}
        </div>
        <div class="col _1 center">
          <FontAwesomeIcon
              :icon="$icons.removePrescriptionLine"
              class="delete-icon"
              @click="removeLine(index)"
          />
        </div>
      </div>
    </div>
    <hr>
    <HorizontalDivider left>Prescription libre</HorizontalDivider>
    <FormWidget>
      <label for="free-text-prescription">Prescription libre</label>
      <AutoHeightTextarea
          id="free-text-prescription"
          v-model="prescriptionFreeText"
      />
    </FormWidget>
    <hr>
    <div class="pharmacy-checkbox">
      <input type="checkbox" v-model="isForPharmacy" id="isForPharmacy">
      <label for="isForPharmacy">Prescription à destination d'une officine ?</label>
    </div>
    <hr>
    <div class="submit">
      <ButtonRed :busy="isSubmitting" @click="closePanel">Annuler</ButtonRed>
      <ButtonGreen :busy="isSubmitting" :disabled="!canBeSubmitted" @click="handleSubmit">Enregistrer l'ordonnance</ButtonGreen>
    </div>

    <iframe
        v-if="widgetData.length > 0"
        ref="widgets"
        class="widgets-frame"
        src="/synapse-widgets.html"
        @load="handleWidgetsLoaded"
    />
  </AppPanel>
</template>

<script>
import AppPanel from './AppPanel';
import FormWidget from './Form/FormWidget';
import AutoHeightTextarea from './Form/AutoHeightTextarea';
import ButtonGreen from './Button/ButtonGreen.vue';
import HorizontalDivider from './HorizontalDivider';
import AutoCompletedInput from './Form/AutoCompletedInput';
import Autocomplete from '@/api-synapse/autocomplete';
import { required } from '@byscripts/vuelidate/src/validators';
import { getSynapseJWT, refreshSynapseJWT } from '@/helpers/auth';
import Prescriptions from '@/api-platform/prescriptions';
import ButtonRed from '@/components/Button/ButtonRed.vue';
import SelectWrapper from "@/components/Form/SelectWrapper.vue";
import FormCheckbox from "@/components/Form/FormCheckbox.vue";

export default {
  name: 'PrescriptionPanel',
  components: {
    FormCheckbox,
    SelectWrapper,
    ButtonRed,
    AutoCompletedInput,
    HorizontalDivider,
    ButtonGreen,
    AutoHeightTextarea,
    FormWidget,
    AppPanel,
  },
  props: {
    prescriptionId: {
      type: String,
      required: false,
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
    birthDate: {
      required: true,
    },
  },
  validations: {
    drug: {
      required,
    },
    dosage: {
      required,
    },
    frequency: {
      required
    },
    frequencyUnit: {
      required,
    },
  },
  data() {
    return {
      isLoading: false,
      drug: null,
      typedDrug: '',
      dosage: null,
      dailyDosage: {
        morning: false,
        noon: false,
        evening: false,
        night: false,
      },
      frequency: null,
      frequencyUnit: '',
      duration: null,
      durationUnit: '',
      isSearchingDrug: false,
      currentMetadata: null,
      prescriptionLines: [],
      prescriptionFreeText: '',
      isForPharmacy: true
    };
  },
  watch: {
    async prescriptionId(prescriptionId) {
      if (!prescriptionId) {
        this.currentMetadata = null;
        this.prescriptionLines = [];
        this.prescriptionFreeText = '';
        return;
      }

      this.isLoading = true;

      const loadedPrescription = await Prescriptions.getOne(prescriptionId);

      if (!loadedPrescription.metadata) {
        return;
      }

      this.currentMetadata = JSON.parse(loadedPrescription.metadata);
      this.prescriptionLines = this.currentMetadata.prescriptionLines || [];
      this.prescriptionFreeText = this.currentMetadata.prescriptionFreeText || '';
      this.isLoading = false;
    },
    drug(drug) {
      if (drug) {
        this.typedDrug = drug.entity.label;
      }
    },
    prescriptionLines() {
      if (this.$refs.widgets) {
        this.$refs.widgets.contentWindow.updateEntities(this.widgetData);
      }
    },
  },
  methods: {
    handleDrugSubmit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      const labelDose = this.drug.entity.label_dose.split(' ')
      const quantityDosage = labelDose[0];

      Object.keys(this.dailyDosage).map((momentOfDaily) => {
        this.dailyDosage[momentOfDaily] = this.dailyDosage[momentOfDaily] ?
            parseFloat(quantityDosage) * this.dosage
            :
            0;
      });

      const frequencyUnitMappedInFR = {
        'DAILY': 'jours',
        'WEEKLY': 'semaines',
        'MONTHLY': 'mois',
      }

      const durationUnitMappedInFR = {
        'DAY': 'jour',
        'WEEK': 'semaine',
        'MONTH': 'mois',
      }

      const dosageLabel =
          this.dosage + (this.frequency > 1 || this.frequencyUnit !== 'DAILY' ?
                  (this.frequencyUnit === 'WEEKLY' ? ' toutes les ' : ' tous les ') + (this.frequency > 1 ? this.frequency + ' ' : '')
                  : ' par '
          )
          + frequencyUnitMappedInFR[this.frequencyUnit]
          + (this.duration ?
                  ' pendant ' + this.duration + ' ' + durationUnitMappedInFR[this.durationUnit]
                  + (this.duration > 1 && this.durationUnit !== 'MONTH' ? 's' : '')
                  : ''
          );

      const doseUnit = labelDose[1];

      this.prescriptionLines.push({
        drug: this.drug,
        dosageLabel,
        detailedPosology: {
          "type": 'PRESCRIPTION',
          "posologies": [{
            "_id": this.drug.entity.drug_id,
            "duration": parseInt(this.duration),
            "durationUnit": this.durationUnit,
            "frequency": parseInt(this.frequency ?? 0),
            "frequencyUnit": this.frequencyUnit,
            "doseUnit": doseUnit,
            "dosage": this.dailyDosage
          }],
        }
      });

      this.typedDrug = '';
      this.drug = null;
      this.dosage = null;
      this.frequency = null;
      this.dailyDosage = {
        morning: false,
        noon: false,
        evening: false,
        night: false,
      };
      this.frequencyUnit = '';
      this.duration = null;
      this.durationUnit = '';
      this.$v.$reset();
      this.$nextTick(() => {
        this.$refs.drug.focus();
      });
    },
    async handleSubmit() {
      try {
        const metadata = JSON.stringify({
          ...this.currentMetadata,
          prescriptionLines: this.prescriptionLines,
          prescriptionFreeText: this.prescriptionFreeText,
          isForPharmacy: this.isForPharmacy
        });
        if (!this.prescriptionId) {
          await this.$store.dispatch('appointmentsPrescriptionsCreate', {
            appointmentId: this.appointment.id,
            content: this.compiledPrescription,
            metadata
          });

          this.$addSuccess('La prescription a été ajoutée');
          this.closePanel();
        } else {
          await this.$store.dispatch('appointmentsPrescriptionsUpdate', {
            id: this.prescriptionId,
            content: this.compiledPrescription,
            metadata,
          });
          this.$addSuccess('La prescription a été mise à jour');
          this.closePanel();
        }
      } catch (e) {
        this.$addError('Une erreur est survenue lors de l\'enregistrement de la prescription');
        throw e;
      }
    },
    handleWidgetsLoaded() {
      this.$setInterval(() => {
        if (!this.$refs.widgets) { return; }

        const newHeight = this.$refs.widgets.contentWindow.document.body.scrollHeight + 100;

        if (this.$refs.widgets.height < newHeight) {
          this.$refs.widgets.height = newHeight;
        }
      }, 500);

      this.$refs.widgets.contentWindow.initSynapse(
          getSynapseJWT(),
          refreshSynapseJWT,
          this.widgetData,
          this.birthDate
      );
    },
    async searchDrugs(typedName, abortSignal) {
      return await Autocomplete.search(typedName, abortSignal);
    },
    focusDosage() {
      this.$nextTick(() => this.$refs.dosage.focus());
    },
    removeLine(index) {
      this.prescriptionLines.splice(index, 1);
    },
    closePanel() {
      this.prescriptionLines = [];
      this.prescriptionFreeText = '';
      this.drug = null;
      this.typedDrug = '';
      this.dosage = null;
      this.currentMetadata = null;

      this.$emit('close');
    },
  },
  computed: {
    appointment() {
      return this.$store.state.appointments.current;
    },
    canBeSubmitted() {
      return !!this.prescriptionFreeText.replace(/^\s+|\s+$/g, '') || this.prescriptionLines.length > 0;
    },
    isSubmitting() {
      return this.$store.state.appointments.isCreatingPrescription || this.$store.state.appointments.isUpdatingPrescription;
    },
    compiledPrescription() {
      let content = this.prescriptionLines.map(line => {
        let label = line.drug.entity.label;
        const moieties = [];

        if (line.drug.entity.moieties.length <= 3) {
          line.drug.entity.moieties.forEach(moiety => {
            moieties.push(`${moiety.label} ${moiety.dose}`);
          });

          return `${label} / ${moieties.join(', ')} : ${line.dosageLabel}`;
        }

        return `${label} : ${line.dosageLabel}`;
      }).join('\n\n');

      if (content && this.prescriptionFreeText) {
        content = `${content}\n\n${this.prescriptionFreeText}`;
      } else if (this.prescriptionFreeText) {
        content = this.prescriptionFreeText;
      }

      return content;
    },
    widgetData() {
      return this.prescriptionLines.map(line => ({
        ...line.drug.entity,
        detailedPosology: line.detailedPosology,
        dosageLabel: line.dosageLabel,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/grid";

.drugs {
  font-size: 1.2rem;

  .row:hover {
    background-color: $lightTextColor;
  }

  .col {
    padding: 1rem 0;
  }
}

form {
  margin-top: 2rem;
}

.delete-column {
  text-align: center;
}

.add-button {
  position: relative;
  top: 1.4rem;
  padding: 1rem 0;
}

.delete-icon {
  font-size: 2rem;
  cursor: pointer;
  color: $errorColor;
}

.center {
  text-align: center;
}

.horizontal-divider {
  margin-bottom: 2rem;
}

.synapse-logo-container {
  position: relative;
  padding-right: 13rem;
}

.synapse-logo {
  position: absolute;
  right: 0;
  width: 12rem;
  transform: translate3d(0, -0.2rem, 0);
}

.submit {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.widgets-frame {
  width: 100%;
  overflow: hidden;
  margin-top: 2rem;
  border: none;
}

.prescription-line {
  border-left: 2px solid $successColor;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  .icon {
    margin-right: 0.7rem;
  }
}
.pharmacy-checkbox{
  label,input{
    cursor: pointer;
  }
}

.d-inherit {
  display: inherit;
}
</style>
