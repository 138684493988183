var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FontAwesomeIcon", {
    staticClass: "appointment-status-icon",
    attrs: { icon: _vm.icon, "fixed-width": "" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }