export function normalizeAppointments(appointments) {
  return appointments.map(normalizeAppointment);
}

export function normalizeAppointment(appointment) {
  return {
    id: appointment.id,
    status: appointment.status,
    practitioner: appointment.practitioner ? {
      id: appointment.practitioner.id,
      civility: appointment.practitioner.civility,
      title: appointment.practitioner.title,
      firstName: appointment.practitioner.first_name,
      lastName: appointment.practitioner.last_name,
    } : null,
    patient: appointment.patient ? {
      id: appointment.patient.id,
      firstName: appointment.patient.first_name,
      lastName: appointment.patient.last_name,
      email: appointment.patient.email,
      phone: appointment.patient.phone,
      ssn: appointment.patient.ssn,
      birthDate: appointment.patient.birth_date,
      height: appointment.patient.height,
      gender: appointment.patient.gender,
      weight: appointment.patient.weight,
      bloodGroup: appointment.patient.blood_group,
      isOwner: true,
      children: normalizePatientChildren(appointment.patient.children || []),
      measure: appointment.patient.measure
    } : {
      id: appointment.email,
      email: appointment.email
    },
    product: appointment.product ? {
      channel: appointment.product.channel,
      id: appointment.product.id,
      name: appointment.product.name,
      unitPrice: appointment.product.unit_price,
      duration: appointment.product.duration,
    } : null,
    hasReport: appointment.has_report,
    hasPrescription: appointment.has_prescription,
    hasClaim: appointment.has_claim,
    description: appointment.message,
    privateNote: appointment.private_note,
    startAt: appointment.start_at,
    endAt: appointment.end_at,
    encounters: appointment.encounters ? normalizeEncounters(appointment.encounters) : null,
    prescriptions: appointment.prescriptions || [],
    order: appointment.order ? normalizeOrder(appointment.order) : null,
    cancelReason: appointment.cancel_reason,
  };
}

export function normalizePatientChildren(patientChildren) {
  return patientChildren.map(normalizePatientChild);
}

export function normalizePatientChild(patientChild) {
  return {
    id: patientChild.id,
    firstName: patientChild.first_name,
    lastName: patientChild.last_name,
    birthDate: patientChild.birth_date,
    gender: patientChild.gender,
    parent: patientChild.parent,
    height: patientChild.height,
    weight: patientChild.weight,
    isOwner: false,
  }
}

export function normalizeOrder(order) {
  return {
    id: order.id,
    paymentStatus: order.payment_status,
    total: order.total,
    transactions: order.transactions ? normalizeTransactions(order.transactions) : []
  };
}

export function normalizePatientSearchResult(patient) {
  return {
    id: patient.id,
    email: patient.email,
    firstName: patient.first_name,
    lastName: patient.last_name,
    ssn: patient.ssn,
    appointments: patient.appointments,
    phone: patient.phone,
    picture: patient.picture,
    birthDate: patient.birth_date,
    mutualName: patient.mutual_name,
    mutualNumber: patient.mutual_number,
    bloodGroup: patient.blood_group,
    gender: patient.gender,
    height: patient.height,
    weight: patient.weight,
    parent: patient.parent ? normalizePatientSearchResult(patient.parent) : null,
    children: patient.children?.length ? patient.children.map((child) => normalizePatientSearchResult(child)) : [],
    measure: patient.measure
  };
}

export function normalizePatientSearchResults(patients) {
  return patients.map(normalizePatientSearchResult);
}

export function normalizePatient(patient) {
  return {
    id: patient.id,
    firstName: patient.first_name,
    lastName: patient.last_name,
    ssn: patient.ssn,
    birthDate: patient.birth_date,
    bloodGroup: patient.blood_group,
    height: patient.height,
    weight: patient.weight,
    gender: patient.gender,
    email: patient.email,
    phone: patient.phone,
    acceptEmailCommunication: patient.accept_email_communication,
    acceptSmsCommunication: patient.accept_sms_communication,
    timezone: patient.timezone,
    addresses: patient.addresses,
    isRestricted: patient.is_restricted,
    isBeta: patient.is_beta,
    picture: patient.picture,
    measure: patient.measure
  };
}

export function normalizeProduct(product) {
  return {
    id: product.id,
    name: product.name,
    channel: product.channel,
    duration: product.duration,
    unitPrice: product.unit_price,
    visible: product.visible,
    information: product.rules,
    rank: product.rank,
  };
}

export function normalizeProducts(products) {
  return products.map(normalizeProduct).sort((a, b) => a.rank - b.rank);
}

export function normalizePractitioner(practitioner) {
  return {

    products: practitioner.products ? normalizeProducts(practitioner.products) : [],
    convention: practitioner.convention,
    firstName: practitioner.first_name,
    lastName: practitioner.last_name,
    civility: practitioner.civility,
    birthDate: practitioner.birth_date,
    email: practitioner.email,
    phone: practitioner.phone,
    address: practitioner.address,
    zipCode: practitioner.zip_code,
    city: practitioner.city,
    country: practitioner.country,
    rpps: practitioner.rpps,
    secretaries: practitioner.secretaries,
    secretariesOf: practitioner.secretaries_of,
    secretariesOfToDelete: practitioner.secretaries_of_to_delete,
    secretariesToDelete: practitioner.secretaries_to_delete,
    adeli: practitioner.adeli,
    description: practitioner.description,
    siren: practitioner.siren,
    iban: practitioner.iban,
    companyName: practitioner.company_name,
    companyAddress: practitioner.company_address,
    companyZipCode: practitioner.company_zip_code,
    companyCity: practitioner.company_city,
    companyCountry: practitioner.company_country,
    practiceAddress: practitioner.practice_address,
    practiceZipCode: practitioner.practice_zip_code,
    practiceCity: practitioner.practice_city,
    practiceCountry: practitioner.practice_country,
    practicePhone: practitioner.practice_phone,
    securedEmail: practitioner.secured_email,
    cardPaymentActivated: practitioner.card_payment_activated,
    searchable: practitioner.searchable,
    claimFormEnabled: practitioner.claim_form_enabled,
    autoConfirmAppointment: practitioner.auto_confirm_appointment,
    instantEncountersEnabled: practitioner.instant_encounters_enabled,
    smsEnabled: practitioner.sms_enabled,
    slug: practitioner.slug,
    timezone: practitioner.timezone,
    mainSpecialty: practitioner.main_specialty,
    specialties: practitioner.specialties,
    languages: practitioner.languages,
    uploadedSignature: practitioner.uploaded_signature,
    uploadedLegalDocument: practitioner.uploaded_legal_document,
    uploadedEmploymentAttestation: practitioner.uploaded_employment_attestation,
    id: practitioner.id,
    isRestricted: practitioner.is_restricted,
    isBeta: practitioner.is_beta,
    acceptPayment: practitioner.accept_payment,
    acceptTransfer: practitioner.accept_transfer,
    picture: practitioner.picture,
    territorialOrganizationType: practitioner.territorial_organization_type,
    territorialOrganizationName: practitioner.territorial_organization_name,
    subscriptions: normalizeSubscriptions(practitioner.subscriptions),
    type: practitioner.practitioner_type,
    newPatientFee: practitioner.new_patient_fee,
    metadata: practitioner.metadata || {},
    deletedAt: practitioner.deleted_at,
  };
}

export function normalizePractitioners(practitioners) {
  return practitioners.map(normalizePractitioner);
}

export function normalizeSubscription(subscription) {
  return {
    id: subscription.id,
    paymentStatus: subscription.payment_status,
    endsAt: subscription.ends_at,
    active: subscription.active,
    stripeActive: subscription.stripe_active,
    cancelRequested: subscription.cancel_requested,
    plan: subscription.plan ? normalizePlan(subscription.plan) : null
  }
}

export function normalizeSubscriptions(subscriptions) {
  return subscriptions.map(normalizeSubscription);
}

export function normalizeEncounter(encounter) {
  return {
    practitionerOnline: encounter.practitioner_online,
    channel: encounter.channel,
    sid: encounter.sid,
    id: encounter.id,
    organization: encounter.organization ? {
      code: encounter.organization.code,
      sid: encounter.organization.sid,
      name: encounter.organization.name,
      isAuthorizationRequired: encounter.organization.is_authorization_required,
      stripePercentFee: encounter.organization.stripe_percent_fee,
      stripeFixedFee: encounter.organization.stripe_fixed_fee,
    } : null,
    claims: encounter.claims,
    paymentStatus: encounter.payment_status,
    status: encounter.status,
    start: encounter.start,
    end: encounter.end,
    length: encounter.length,
    reasons: encounter.reasons,
    diagnosis: encounter.diagnosis,
    deleted: encounter.deleted,
    isTest: encounter.is_test,
    patientOnline: encounter.patient_online,
    isAuthorized: encounter.authorized,
    authorizationMetadata: encounter.authorization_metadata,
    appointment: encounter.appointment,
    createdAt: encounter.created_at,
    updatedAt: encounter.updated_at,
    metadata: encounter.metadata,
    prescription: encounter.prescription,
    patientAdvice: encounter.report,
    motives: encounter.motives,
    medicalObservation: encounter.medical_observation,
    patient: encounter.patient ? {
      id: encounter.patient.id,
      picture: encounter.patient.picture,
    } : {},
    doc2uData: encounter.doc2u_data ? {
      token: encounter.doc2u_data[0]?.token_key,
      room: encounter.doc2u_data[0]?.room,
    } : {}
  };
}

export function normalizeEncounters(encounters) {
  return encounters.map(normalizeEncounter);
}

export function normalizeTransaction(transaction) {
  const patient = transaction.order?.appointment?.patient;
  return {
    id: transaction.id,
    amount: transaction.amount,
    paymentStatus: transaction.payment_status,
    amountRefunded: transaction.refunded,
    appointment: transaction.order?.appointment,
    patient: patient ? {
      id: patient.id,
      firstName: patient.first_name,
      lastName: patient.last_name,
      birthDate: patient.birth_date,
    } : null,
    practitioner: transaction.order?.appointment?.practitioner,
    createdAt: transaction.created_at,
  };
}

export function normalizeTransactions(transactions) {
  return transactions.map(normalizeTransaction);
}

export function normalizePlan(plan) {
  return {
    id: plan.id,
    name: plan.name,
    price: plan.price,
    tiers: plan.tiers ? normalizePlanTiers(plan.tiers) : null,
    stripePriceId: plan.stripe_price_id,
    stripeProductId: plan.stripe_product_id,
  }
}

export function normalizePlanTier(tier) {
  return {
    flatAmount: tier.flat_amount,
    unitAmount: tier.unit_amount,
    upTo: tier.up_to,
  };
}

export function normalizePlanTiers(tiers) {
  return tiers.map(normalizePlanTier);
}

export function normalizePlans(plans) {
  return plans.map(normalizePlan);
}

export function normalizeSetupIntent(setupIntent) {
  return {
    customerId: setupIntent.customer_id,
    clientSecret: setupIntent.client_secret,
  };
}

export function normalizePaymentMethod(paymentMethod) {
  return {
    id: paymentMethod.id,
    brand: paymentMethod.brand,
    lastFourDigit: paymentMethod.last4,
    expirationMonth: paymentMethod.exp_month,
    expirationYear: paymentMethod.exp_year,
  };
}

export function normalizePaymentMethods(paymentMethods) {
  return paymentMethods.map(normalizePaymentMethod);
}

export function normalizePaymentMethodSEPA(paymentMethod) {
  return {
    id: paymentMethod.id,
    bankCode: paymentMethod.bank_code,
    country: paymentMethod.country,
    last4: paymentMethod.last4,
  };
}

export function normalizePaymentMethodsSEPA(paymentMethods) {
  return paymentMethods.map(normalizePaymentMethodSEPA);
}
export function normalizeObservation(observation) {
  return {
    id: observation.id,
    patient: observation.patient,
    status: observation.status,
    code: observation.code,
    value: observation.value,
    display_value: observation.display_value,
    encounter: observation.encounter,
    created_at: observation.created_at
  }
}

export function normalizeObservations(observations) {
  return observations.map(normalizeObservation);
}

export function normalizeMedicalesObservations(observation) {
  return {
/*
    measure: observation.measure
*/
      tensionArterielle: observation.tensionArterielle,
      priseDePouls: observation.priseDePouls,
      frequencesRespiratoires: observation.frequencesRespiratoires,
      poulsPeripheriques: observation.poulsPeripheriques,
      temperatureCentrale: observation.temperatureCentrale,
      saturationOxygene: observation.saturationOxygene,
      glycemieCapillaire: observation.glycemieCapillaire,
      examenCutanee: observation.examenCutanee,
      examenGorge: observation.examenGorge,
      bandeletteUrinaire: observation.bandeletteUrinaire,
      oscultationPoumons: observation.oscultationPoumons,
      caracterisationToux: observation.caracterisationToux

    }
}