<!--
Emitted events:
- close
-->
<template>
<!--
  <portal to="app-panel">
-->
    <transition :duration="600" @after-leave="canBeClosed = true">
      <div v-if="opened" class="app-panel">
        <div :class="{ transparent }" class="backdrop" @click="close" />
        <div :style="{ '--maxWidth': maxWidth }" class="panel">
          <div class="content">
            <FontAwesomeIcon
              :icon="$icons.closePanel"
              class="close-panel-icon"
              @click="close"
            />
            <slot />
          </div>
        </div>
      </div>
    </transition>
<!--
  </portal>
-->
</template>

<script>
  import SectionTitle from './SectionTitle';

  export default {
    name: 'AppPanel',
    components: { SectionTitle },
    props: {
      transparent: {
        type: Boolean,
        default: false,
      },
      opened: {
        type: Boolean,
        required: false,
        default: true,
      },
      maxWidth: {
        type: String,
        required: false,
        default: '70rem',
      },
    },
    data() {
      return {
        canBeClosed: true,
      };
    },
    methods: {
      close() {
        if (!this.canBeClosed) {
          return;
        }

        this.canBeClosed = false;

        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-panel {
    font-size: 1.4rem;
    font-weight: 200;
    position: fixed;
    z-index: 2010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.6s;
    opacity: 0.9;
    background: $gradientBackground;

    &.transparent {
      opacity: 0.05;
    }

    .v-enter &,
    .v-leave-to & {
      opacity: 0;
    }
  }

  .panel {
    position: absolute;
    top: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    max-width: var(--maxWidth);
    height: 100vh;
    transition: transform 0.6s;
    transform: translate3d(0, 0, 0);
    background-color: #FFFFFF;

    .v-enter &,
    .v-leave-to & {
      transform: translate3d(var(--maxWidth), 0px, 0px);
    }

    @media (max-width: 1000px) {
      width: calc(100vw - 7.4rem);

      .v-enter &,
      .v-leave-to & {
        transform: translate3d(calc(100vw - 7.4rem), 0px, 0px);
      }
    }

    @media (max-width: 768px) {
      width: 100vw;

      .v-enter &,
      .v-leave-to & {
        transform: translate3d(100vw, 0px, 0px);
      }
    }
  }

  .close-panel-icon {
    font-size: 3rem;
    position: absolute;
    z-index: 1;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    color: #F46868;
  }

  @include onDesktop {
    .close-panel-icon {
      top: 7rem;
      right: 7rem;
    }
  }

  .content {
    padding: 3.5rem;

    @include onDesktop {
      padding: 10rem;
    }
  }
</style>
