var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "form-checkbox", class: { disabled: _vm.disabled } },
    [
      _c("span", [_vm._t("default")], 2),
      _c("input", {
        staticClass: "form-checkbox-input",
        attrs: {
          type: "checkbox",
          disabled: _vm.disabled,
          "aria-checked": _vm.checked,
          "aria-disabled": _vm.disabled
        },
        domProps: { checked: _vm.checked },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event.target.checked)
          }
        }
      }),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkbox" }, [
      _c("span", { staticClass: "checkbox-mark" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }