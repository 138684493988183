<template>
  <div v-if="appointment && hasPatientAdvice">
    <ButtonRed
      :busy="isDownloading"
      :icon="$icons.pdf"
      small
      @click="downloadPatientAdvice"
    >
      Télécharger le compte-rendu
    </ButtonRed>
  </div>
</template>

<script>
  import Encounters from '@/api/encounters';
  import download from 'downloadjs';
  import ButtonRed from '@/components/Button/ButtonRed.vue';

  export default {
    name: 'AppointmentPanelPatientAdvice',
    components: { ButtonRed },
    data() {
      return {
        isDownloading: false,
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      encounter() {
        return this.$store.state.encounters.current;
      },
      shortEncounterId() {
        return this.encounter.id.substring(0, 8);
      },
      hasPatientAdvice() {
        return this.appointment.payload.hasReport;
      },
    },
    methods: {
      async downloadPatientAdvice() {
        this.isDownloading = true;
        const patientAdvice = await Encounters.getPatientAdvice(this.encounter.id);
        download(patientAdvice, `compte-rendu-${this.shortEncounterId}.pdf`);
        this.isDownloading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .base-button {
    width: 100%;
  }
</style>
