<template>
  <FullscreenNotification :icon="$icons.thumbsUp" title="Demande de rendez-vous acceptée">
    <p>Vous avez accepté le rendez-vous demandé par votre patient.</p>
    <p>Un mail de confirmation lui est envoyé.</p>
    <p>Le rendez-vous est d'ores et déjà ajouté à votre planning.</p>
    <template #buttons>
      <ButtonGreen :link-to="{ name: 'dashboard' }">Continuer</ButtonGreen>
    </template>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import ButtonGreen from '../components/Button/ButtonGreen.vue';

  export default {
    name: 'AppointmentApprovedPage',
    components: { ButtonGreen, FullscreenNotification, AppLayout, SectionTitle },
  };
</script>

<style lang="scss" scoped>
</style>
