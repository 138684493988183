var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "week-day",
      class: _vm.classes,
      attrs: {
        to: {
          name: "add-availabilities",
          query: { date: _vm.day.date.format("YYYY-MM-DD") }
        }
      },
      on: { click: _vm.handleClick }
    },
    [
      _c("div", { staticClass: "day-number" }, [
        _vm._v("\n    " + _vm._s(_vm.day.monthDay) + "\n  ")
      ]),
      _c("CalendarEvent", { attrs: { "events-day": _vm.filteredEvents } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }