<template>
  <div class="agenda-calendar-day-column-header" :class="{today, fade }">
    {{ shortDayName }} {{ date }}
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'AgendaCalendarDayColumnHeader',
    props: {
      day: {
        type: moment,
        required: true,
      },
    },
    computed: {
      today() {
        return this.day.isSame(this.$store.state.currentDay, 'day');
      },
      fade() {
        return this.day.isBefore(this.$store.state.currentDay, 'day')
          || [0, 6].includes(this.day.day());
      },
      shortDayName() {
        return ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'][this.day.day()];
      },
      date() {
        return this.day.format('DD/MM');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .agenda-calendar-day-column-header {
    flex: 1;
    text-align: center;
    padding: 4px 0;
    font-size: 1.6rem;
    background-color: #FFFFFF;
    border-bottom: 1px solid $lighterGrey;
  }

  .today {
    font-weight: bold;
    color: $successColor;
  }

  .fade {
    color: #CCCCCC;
  }
</style>
