<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" class="progress-circle">
        <path
            class="progress-circle-background"
            :stroke="backgroundColor"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        ></path>
        <path
            class="progress-circle-fill"
            :stroke-dasharray="`${percentage}, 100`"
            :stroke="color"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        ></path>
        <text x="18.2" y="21.35" :fill="textColor" class="progress-circle-text">{{ percentage }}%</text>
    </svg>
</template>

<script>
    export default {
        name: "ProgressCircle",
        props: {
            color: {
                type: String,
                default: "#27d7ab"
            },
            backgroundColor: {
                type: String,
                default: "#1F1F1F"
            },
            textColor: {
                type: String,
                default: "#fff"
            },
            percentage: {
                type: Number,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .progress-circle-fill {
        fill: none;
        stroke-width: 1.2;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .progress-circle-background {
        fill: none;
        stroke-width: 1.2;
    }

    .progress-circle-text {
        font-family: $titleFont;
        font-weight: bold;
        font-size: 10px;
        text-anchor: middle;
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0, 100;
        }
    }
</style>
