<script>
  import BaseButton from './BaseButton';

  export default {
    name: 'ButtonBlue',
    extends: BaseButton,
  };
</script>

<style lang="scss" scoped src="../../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
    background-color: $blueOlaqin;

    &.outlined {
      border-color: $blueOlaqin;
      color: $blueOlaqin;
    }
  }
</style>
