<template>
  <AppTabBar class="profile-tab-bar">
    <router-link :to="{ name: 'profile-edit' }">Mon Profil</router-link>
    <router-link :to="{ name: 'profile-act' }" v-if="!isSecretary">Mes Tarifs</router-link>
    <router-link :to="{ name: 'profile-settings' }" v-if="!isSecretary">Mes paramètres</router-link>
    <router-link :to="{ name: 'profile-subscription' }" v-if="!isSecretary">Mon abonnement</router-link>
    <router-link :to="{ name: 'profile-access' }">Mes accès</router-link>
  </AppTabBar>
</template>

<script>
  import AppTabBar from './AppTabBar';

  export default {
    computed: {
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
    },
    name: 'ProfileTabBar',
    components: { AppTabBar },
  };
</script>
