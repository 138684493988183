<template>
  <main class="full-panel">
    <div class="control-panel">
      <p>
        <router-link :to="{ name:'availabilities' }">
          <FontAwesomeIcon :icon="$icons.closePanel" />
          Fermer
        </router-link>
      </p>
    </div>
    <div class="container">
      <div class="container-link">
        <router-link :to="{ name:'add-availabilities', query: { date: date.format('YYYY-MM-DD') } }">
          <div class="top-link">Disponibilités</div>
        </router-link>
        <router-link :to="{ name:'add-unavailabilities', query: { date: date.format('YYYY-MM-DD') } }">
          <div class="top-link active">Indisponibilités</div>
        </router-link>
      </div>

      <AppConfirmation
        ref="removeAvailability"
        title="Êtes-vous sûr de vouloir supprimer cette indisponibilité ?"
        @confirm="deleteUnavailability"
      />

      <form @submit.prevent="handleSubmit">
        <div :id="value.sid" class="container-availabilities" v-for="(value, index) in slots">
          <FormWidget class="form-control hours-control">
            <label :for="'start'+index">De</label>
            <input :id="'start'+index" type="text" v-model="value.start_hour" v-mask="['#:##', '##:##']" disabled>
            <template v-slot:error v-if="!validHour(value.start_hour)"><span>Format d'heure incorrect</span></template>
          </FormWidget>

          <FormWidget class="form-control hours-control">
            <label :for="'end'+index">A</label>
            <input :id="'end'+index" type="text" v-model="value.end_hour" v-mask="['#:##', '##:##']" disabled>
            <template v-slot:error v-if="!validHour(value.end_hour)"><span>Format d'heure incorrect</span></template>
          </FormWidget>

          <FormWidget class="form-control">
            <label :for="'channel'+index">Canal</label>
            <SelectWrapper>
              <select :id="'channel'+index" v-model="value.channel" disabled>
                <option value="remote">Téléconsultation</option>
                <option value="medical_office">En cabinet</option>
                <option value="home">À domicile</option>
              </select>
            </SelectWrapper>
          </FormWidget>

          <div class="center" @click="$confirm($refs.removeAvailability, index)">
            <FontAwesomeIcon :icon="$icons.trashOutlined" />
          </div>
        </div>

        <div class="container-options">
          <hr class="separator">
          <ButtonGreen
            class="btn-submit save"
            small
            type="submit"
            :icon="$icons.chevronRight"
            :disabled="statusButton"
          >
            Enregistrer
          </ButtonGreen>

          <ButtonRed
            class="btn-submit"
            small
            @click="setUnavailable"
          >
            Indisponible toute la journée
            <FontAwesomeIcon :icon="$icons.saveOutlined" />
          </ButtonRed>

          <ButtonTransparent
            class="cancel btn-submit"
            small
            @click="cancel"
          >
            Annuler
          </ButtonTransparent>

        </div>
      </form>
    </div>
  </main>
</template>

<script>
  import FormWidget from '../../components/Form/FormWidget';
  import ButtonGreen from '../../components/Button/ButtonGreen.vue';
  import { cloneDeep } from 'lodash-es';
  import moment from 'moment';
  import ButtonRed from '../../components/Button/ButtonRed.vue';
  import SelectWrapper from '../../components/Form/SelectWrapper';
  import ButtonTransparent from '../../components/Button/ButtonTransparent.vue';
  import AppConfirmation from '@/components/AppConfirmation';

  export default {
    name: 'add-unavailabilities-page',
    components: { AppConfirmation, ButtonTransparent, SelectWrapper, ButtonGreen, FormWidget, ButtonRed },
    props: {
      date: {
        type: moment,
        required: true,
      },
    },
    data() {
      return {
        selectSlot: Number,
        showModal: false,
        slots: [],
        selectDays: [],
        intervalDays: {},
        type: 'once',
        form: {
          new: [],
          delete: [],
          update: [],
        },
      };
    },
    async mounted() {
      await this.getAvailabilitiesDetails();
    },
    computed: {
      availabilities() {
        return this.$store.state.availability.availabilities;
      },
      statusButton() {
        if (this.form.delete.length > 0) {
          return false;
        }
        if (this.form.new.length > 0) {
          return false;
        }
        if (this.slots) {
          for (let slot of this.slots) {
            if (!slot.sid) {
              if (slot.start_hour && slot.end_hour) {
                return false;
              }
            }
          }
        }
        return true;
      },
    },
    methods: {
      validHour(value) {
        value = value.replace(/_+/g, '');
        if (value.length >= 4) {
          let regex = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
          return regex.test(value);
        }
        return true;
      },
      init() {
        this.slots = cloneDeep(this.availabilities);
        this.slots.forEach(slot => {
          slot.start_date = moment(slot.start_date).format('YYYY-MM-DD');
          slot.end_date = slot.end_date ? moment(slot.end_date).format('YYYY-MM-DD') : null;
          slot.start_hour = this.getHour(slot.start_hour);
          slot.end_hour = this.getHour(slot.end_hour);
        });
      },
      getHour(data) {
        let date = data.split('+');
        return moment(date[0]).format('HH:mm');
      },
      setUnavailable() {
        let day = this.date.format('YYYY-MM-DD');
        let parseUnavailability = {
          start_date: day,
          end_date: day,
          type: 'unavailable',
          slots: [
            {
              start_hour: '00:00',
              end_hour: '23:59',
            },
          ],
        };

        this.form.new.push(parseUnavailability);
        this.sendAvailabilities();
      },
      deleteUnavailability(index) {
        this.showModal = false;
        if (this.slots[index].sid) {
          this.form.delete.push(this.slots[index].sid);
        }
        this.slots.splice(index, 1);
      },
      handleSubmit() {
        if (this.slots.length > 0) {
          if (this.form.new.length > 0) {
            this.sendAvailabilities();
            this.form.new = [];
          }
        }
        if (this.form.delete.length > 0) {
          this.deleteAvailabilities();
          this.form.delete = [];
        }
      },
      slotsChanged(availability) {
        return !this.availabilities.some(el => {
          return el.sid === availability.sid &&
            this.formatHours(el.start_hour) === availability.start_hour &&
            this.formatHours(el.end_hour) === availability.end_hour &&
            el.channel === availability.channel;
        });
      },
      formatHours(data) {
        let date = data.split('+');
        return moment(date[0]).format('HH:mm');
      },
      async sendAvailabilities() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerCreateAvailabilities', this.form.new);
          await this.$router.push({ name: 'availabilities' });
        } finally {}
      },
      async deleteAvailabilities() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerDeleteAvailabilities', this.form.delete);
          await this.$router.push({ name: 'availabilities' });
        } finally {}
      },
      async getAvailabilitiesDetails() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerDetailsAvailabilities', {
            date: this.date.format('YYYY-MM-DD'),
            type: 'unavailable',
          });
          await this.init();
        } finally {
          this.$store.dispatch('appLoaderHide');
        }
      },
      cancel() {
        this.$router.push({ name: 'availabilities' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  form {
    display: contents;
  }

  .full-panel {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    transform: translate3d(0, 0, 0);
    transition: transform 0.6s;
    padding: 0 !important;
  }

  .control-panel {
    position: absolute;
    right: 10%;
    top: 15%;
    text-align: right;
    cursor: pointer;

    a {
      color: #000;
    }

  }

  .container-link {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .top-link {
      font-family: $titleFont;
      font-weight: bold;
      font-size: 2em;
      color: #D8D8D8;
      padding: 0.6rem 1.1em;

      &.active {
        color: #1F1F1F;
        border-bottom: solid .5rem #27D7AB;
      }
    }
  }

  .container {
    margin-bottom: 70px;
    display: block;
    overflow: auto;
    height: 100vh;

    .container-options {
      width: 35%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-control {
    margin-top: 15px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #27D7AB;
    cursor: pointer;
  }

  .container-availabilities {
    display: flex;
    margin: 15px;
    justify-content: center;

    .form-control {
      margin-right: 15px
    }

    .hours-control {
      width: 13rem;
    }

    .availability {
      display: block;
    }
  }

  .addAvailability {
    color: #27D7AB;
    font-weight: 400;
    cursor: pointer;
  }

  .separator {
    margin: 40px 0 0;
    border: 0;
    border-top: 1px solid #D8D8D8;
  }

  .button {
    display: inline-flex;
    justify-content: center;
    font-family: "Gotham Rounded", sans-serif;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 0.3rem;
    padding: 1.6rem 3.2rem;
    transition: all 0.6s ease-out 0s;
    outline: none;
    border: 1px solid #D8D8D8;
    color: #D8D8D8;
    width: 100%;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    border: 1px solid #27D7AB;
    color: #27D7AB;
  }

  .pick-weekly-day {
    padding-bottom: 30px;

    p {
      display: block;
      margin: 0;
      text-align: center;
      margin-bottom: 15px;
    }

    .week {
      display: flex;
      justify-content: center;
    }

    .day {
      display: inline-block;
    }

    .pick-a-day {
      display: block;
      font-size: 1.5em;
      font-weight: 400;
      padding: 14px;
      background: transparent;
      border: 1px solid #D8D8D8;
      cursor: pointer;
      user-select: none;
    }

    input[type="checkbox"] {
      display: none;
    }

    :checked + label {
      border: 1px solid #27D7AB;
      background: #DFF9F3;
    }

    .datetime-widget {
      width: 43%;
      margin: 10px;
      display: inline-block;
    }
  }

  .container-link {
    padding-top: 100px;
  }

  .cancel {
    padding-bottom: 100px !important;
  }

  .save {
    margin-top: 40px !important;
  }

  .btn-submit {
    margin-top: 20px;
    width: 100%;
  }

</style>

