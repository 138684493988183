import Vue from 'vue';
import {
  buildDelayedFunction,
  getLocalStorage,
  getSetting,
  hasLocalStorage,
  isDevelopment,
  removeLocalStorage,
  setLocalStorage,
} from '@/helpers/tools';

Vue.mixin({
  computed: {
    $isDev() {
      return isDevelopment;
    },
    $isBeta() {
      return this.$store.state.practitioner.profile?.isBeta;
    },
    $isPrescriptionHelperEnabled() {
      return getSetting('PRESCRIPTION_HELPER_ENABLED');
    },
  },
  methods: {
    $confirm(appConfirmation, payload) {
      appConfirmation.open(payload);
    },
    $minutesToPixels(minutes, withUnit = true) {
      const result = minutes * this.$store.state.agenda.minuteToPixelsRatio / 100;

      if (withUnit) {
        return `${result}px`;
      }

      return result;
    },
    $pixelsToMinutes(pixels) {
      return pixels / (this.$store.state.agenda.minuteToPixelsRatio / 100);
    },
    $linkToAppointment(appointmentId) {
      return { query: { ...this.$route.query, 'appointment-detail': appointmentId } };
    },
    $setTimeout: buildDelayedFunction(window.setTimeout, window.clearTimeout),
    $setInterval: buildDelayedFunction(window.setInterval, window.clearInterval),
    $hasLocalStorage: hasLocalStorage,
    $getLocalStorage: getLocalStorage,
    $setLocalStorage: setLocalStorage,
    $removeLocalStorage: removeLocalStorage,
    /**
     * @param   {string}  message        The message to be displayed
     * @param   {boolean} afterRedirect  Whether the message should wait redirection to be displayed
     * @param   {number}  duration       The notification duration in milliseconds.
     * @param   {boolean} asHTML         If true, message will be rendered as HTML
     * @returns {Promise<any>}
     */
    $addError(message, afterRedirect = false, duration = 14000, asHTML = false) {
      return this.$store.dispatch('appNotificationAddError', {
        message,
        afterRedirect,
        duration,
        asHTML,
      });
    },
    /**
     * @param   {string}  message        The message to be displayed
     * @param   {boolean} afterRedirect  Whether the message should wait redirection to be displayed
     * @param   {number}  duration       The notification duration in milliseconds.
     * @param   {boolean} asHTML         If true, message will be rendered as HTML
     * @returns {Promise<any>}
     */
    $addSuccess(message, afterRedirect = false, duration = 7000, asHTML = false) {
      return this.$store.dispatch('appNotificationAddSuccess', {
        message,
        afterRedirect,
        duration,
        asHTML,
      });
    },
    $inputToPrice(input) {
      return input.replace(/[^0-9,.]/g, '').replace(',', '.') || '0';
    },
    $inputToWeight(input) {
      return parseFloat(input.replace(/[^0-9,.]/g, '').replace(',', '.')) || '';
    },
    $inputToHeight(input) {
      return parseFloat(input.replace(/[^0-9,.]/g, '').replace(',', '.')) || '';
    },
  },
});
