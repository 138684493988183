<!--
Emitted events:
- accept
- cancel
-->

<template>
  <div v-if="appointment" class="appointment-panel-actions">
    <div v-if="isNewByPractitioner" class="new-or-cancelled">
      Votre patient n'a toujours pas validé le rendez-vous
    </div>
    <div v-else-if="isNewByPatient && !isAccountRestricted">
      <AppConfirmation
        ref="acceptAppointment"
        title="Êtes-vous sûr de vouloir accepter ce rendez-vous ?"
        @confirm="acceptAppointment"
      />

      <ButtonGreen :icon="$icons.accept" @click="$confirm($refs.acceptAppointment)">
        Accepter ce rendez-vous
      </ButtonGreen>
    </div>
    <div v-else-if="canBeStarted">
      <ButtonGreen
        :busy="goingToConsultation"
        class="start-encounter"
        @click="goToConsultation"
      >
        Votre rendez-vous est accessible
        <span class="go">
          J'y vais !
          <FontAwesomeIcon :icon="$icons.arrowRight" />
        </span>
      </ButtonGreen>
    </div>

    <div v-if="canBeCancelled" class="cancel-postpone">
      <AppConfirmation
        ref="cancelAppointment"
        :title="cancelAppointmentDialogMessage"
        @confirm="cancelAppointment"
      >
        <template #description>
          <p>Attention, cette action entraîne l'annulation définitive du rendez-vous.</p>
          <AutoHeightTextarea
            v-model="cancelReason"
            class="cancel-reason"
            placeholder="Décrivez la raison de votre annulation"
            :max-length="$options.cancelReasonMaxLength"
          />
          <div class="cancel-reason-tips">{{ cancelReason.length }}/{{ $options.cancelReasonMaxLength }} caractères maximum</div>
        </template>
      </AppConfirmation>

      <span class="link-with-icon cancel-appointment" @click="$confirm($refs.cancelAppointment)">
        <span>{{ cancelAppointmentButtonMessage }}</span>
        <FontAwesomeIcon :icon="$icons.appointmentStatusCancelled" />
      </span>
    </div>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import Encounters from '@/api/encounters';

  export default {
    name: 'AppointmentPanelActions',
    components: { AutoHeightTextarea, AppConfirmation, ButtonGreen },
    cancelReasonMaxLength: 300,
    props: {
      canBeStarted: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    data() {
      return {
        goingToConsultation: false,
        cancelReason: '',
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      appointmentId() {
        return this.appointment.payload.id;
      },
      hasEncounter() {
        return !!this.appointment.payload.encounters?.[0];
      },
      status() {
        return this.appointment.payload.status;
      },
      isNewByPractitioner() {
        return this.status === 'NEW_BY_PRACTITIONER';
      },
      isNewByPatient() {
        return this.status === 'NEW_BY_PATIENT';
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
      isCancelledByPractitioner() {
        return this.status === 'CANCELLED_BY_PRACTITIONER';
      },
      isCancelledByPatient() {
        return this.status === 'CANCELLED_BY_PATIENT';
      },
      cancelAppointmentDialogMessage() {
        return this.isNewByPatient
          ? 'Êtes-vous sûr de vouloir refuser ce rendez-vous ?'
          : 'Êtes-vous sûr de vouloir annuler votre rendez-vous ?';
      },
      cancelAppointmentButtonMessage() {
        return this.isNewByPatient
          ? 'Refuser ce rendez-vous'
          : 'Annuler mon rendez-vous';
      },
      canBeCancelled() {
        // TODO : Encounter has no status on Connect API
        if (this.hasEncounter && this.appointment.payload.encounters?.[0].status === 'finished') {
          return false;
        }

        if (this.isAccountRestricted) {
          return false;
        }

        return ['NEW_BY_PATIENT', 'NEW_BY_PRACTITIONER', 'CONFIRMED'].includes(this.status);
      },
    },
    methods: {
      async goToConsultation() {
        try {
          this.goingToConsultation = true;
          await this.$router.push({
            name: 'consultation',
            params: { appointmentId: this.appointmentId },
            query: { to: this.$route.name },
          });
        } finally {
          this.goingToConsultation = false;
        }
      },
      async acceptAppointment() {
        await this.$store.dispatch('appointmentsAccept', { id: this.appointmentId });
        this.$emit('accept');
        await this.$store.dispatch('appointmentsFetchPage', { page: this.$store.state.appointments.page });
      },
      async cancelAppointment() {
        try {
          await this.$store.dispatch('appointmentsCancel', { id: this.appointmentId, reason: this.cancelReason });
          this.cancelReason = '';
          this.$emit('cancel');
          await this.$store.dispatch('appointmentsFetchPage', { page: this.$store.state.appointments.page });
        } catch (e) {
          await this.$addError(`Une erreur est survenue lors ${this.isNewByPatient ? 'du refus' : 'de l\'annulation'} du rendez-vous.`);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-actions {
    position: sticky;
    bottom: 0;
    padding: 2rem 0;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 -1.6rem 1rem -1.4rem #00000030;
  }

  .user-avatar {
    margin-right: 1rem;
    border: none;
  }

  .download-buttons > div {
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 2rem;
  }

  .appointment-details {
    color: $textColor;

    svg {
      margin-right: 1rem;
    }

    .duration {
      margin-left: 2rem;
    }
  }

  .new-or-cancelled {
    font-size: 1.4rem;
    padding: 2rem 0;
    text-align: center;
    color: $errorColor;
    border: 1px solid $errorColor;
    border-radius: 1rem;
  }

  .link-with-icon {
    cursor: pointer;

    span {
      text-decoration: underline;
    }

    svg {
      margin-left: 1rem;
    }
  }

  .start-encounter {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.8rem 3.2rem;
    border-radius: 3rem;

    ::v-deep > span {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    .go {
      margin-left: 2rem;
      padding: 1rem;
      white-space: nowrap;
      color: $textColor;
      border-radius: 3rem;
      background-color: white;

      svg {
        margin-left: 1rem;
      }
    }
  }

  .cancel-appointment {
    color: $errorColor;
  }

  .cancel-postpone {
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .cancel-reason {
    width: 100%;
    padding: 0.5rem;
  }

  .cancel-reason-tips {
    text-align: left;
  }
</style>
