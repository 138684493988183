var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agenda-calendar-day-column",
      on: {
        mousemove: _vm.handleMouseMove,
        mouseenter: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          _vm.isNewAppointmentLinkVisible = true
        },
        mouseleave: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          _vm.isNewAppointmentLinkVisible = false
        }
      }
    },
    [
      _vm._l(_vm.availabilitySlots, function(slot) {
        return _c("AgendaCalendarAvailabilitySlot", {
          key: _vm.day.unix() + "-" + slot.start + "-" + slot.end,
          attrs: {
            day: _vm.day,
            "start-hour": slot.start,
            "end-hour": slot.end
          }
        })
      }),
      _vm._l(_vm.appointmentsGroups, function(appointmentsGroup) {
        return _c("AgendaCalendarAppointmentGroup", {
          key: appointmentsGroup.startAt.unix(),
          attrs: {
            appointments: appointmentsGroup.appointments,
            "start-at": appointmentsGroup.startAt,
            "end-at": appointmentsGroup.endAt
          }
        })
      }),
      _vm.canAddAppointment
        ? _c(
            "div",
            {
              staticClass: "new-appointment",
              style: {
                top: _vm.newAppointmentLinkTop,
                height: _vm.newAppointmentLinkHeight
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click", _vm.newAppointmentStartAt)
                }
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.newAppointmentLinkLabel) + "\n  ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }