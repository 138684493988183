<template>
  <header v-if="practitioner.id" class="wallet-header">
    <div class="practitioner">
      <div>
        <div class="practitioner-item">
          <FontAwesomeIcon :icon="$icons.userLock" fixed-width />
          {{ practitioner.firstName | capitalize }} <strong>{{ practitioner.lastName | uppercase }}</strong>
        </div>
        <div class="practitioner-item">
          <FontAwesomeIcon :icon="$icons.mapMarker" fixed-width />
          {{ practitioner.practiceAddress }} - {{ practitioner.practiceZipCode }} {{ practitioner.practiceCity }}
        </div>
      </div>
      <div>
        <div class="practitioner-item">
          <FontAwesomeIcon :icon="$icons.IBAN" fixed-width />
          <span :class="{ unknown: !practitioner.iban }">
            {{ practitioner.iban || 'IBAN non renseigné' }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div>
        <FontAwesomeIcon :icon="$icons.transaction" size="2x" />
      </div>
      <p>Vous recevrez un virement chaque lundi si des consultations ont été réalisées la semaine précédente.</p>
    </div>
    <div>
      <div>
        <FontAwesomeIcon :icon="$icons.lock" size="2x" />
      </div>
      <p>Votre portefeuille vous permet de voir les mouvements d’argent de votre cabinet virtuel vers votre
        compte bancaire. Ces informations sont sécurisées</p>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'WalletHeader',
    props: {
      practitioner: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wallet-header {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: #FFFFFF;
    border-radius: 1rem;
    background-color: $altColor;

    @include onDesktop {
      flex-direction: row;
    }

    & > div:nth-child(1) {
      flex: 1;
      min-width: 25rem;
      border-bottom: 1px dashed $mainColor;

      @include onDesktop {
        border-right: 1px dashed $mainColor;
        border-bottom: none;
      }
    }

    & > div:nth-child(2) {
      padding: 2rem 3rem 1rem;
      text-align: center;
      border-bottom: 1px dashed $mainColor;

      @include onDesktop {
        flex-basis: 40rem;
        border-right: 1px dashed $mainColor;
        border-bottom: none;
      }
    }

    & > div:nth-child(3) {
      padding: 2rem 3rem 0;
      text-align: center;

      @include onDesktop {
        flex-basis: 30rem;
        padding: 0 3rem;
      }
    }
  }

  .practitioner {
    justify-content: space-between;

    @include onDesktop {
      padding: 0 3rem;
    }
  }

  .practitioner-item {
    margin-bottom: 2rem;

    & > svg {
      margin-right: 2rem;
    }
  }

  .unknown {
    font-style: italic;
    opacity: 0.4;
  }
</style>
