<template>
  <div v-if="appointment && appointment.payload.prescriptions.length > 0" class="appointment-panel-prescriptions">
    <div v-for="(prescription, index) in appointment.payload.prescriptions">
      <ButtonRed
          v-if="prescription.status==='completed'"
          :busy="downloadingPrescriptionId === prescription.id"
        :icon="$icons.pdf"
        small
        @click="downloadPrescription(prescription.id)"
      >
        Télécharger l'ordonnance n°{{ index + 1 }}
      </ButtonRed>
    </div>
  </div>
</template>

<script>
  import Prescriptions from '@/api-platform/prescriptions';
  import download from 'downloadjs';
  import ButtonRed from '@/components/Button/ButtonRed.vue';

  export default {
    name: 'AppointmentPanelPrescriptions',
    components: { ButtonRed },
    data() {
      return {
        downloadingPrescriptionId: null,
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
    }, methods: {
      async downloadPrescription(id) {
        this.downloadingPrescriptionId = id;
        const prescription = await Prescriptions.download(id);
        download(prescription, `ordonnance-${id.substring(0, 8)}.pdf`);
        this.downloadingPrescriptionId = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .base-button {
    width: 100%;
  }
</style>
