var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "timezone-selector",
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    },
    [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9),
      _vm._m(10)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "US (Common)" } }, [
      _c("option", { attrs: { value: "America/Puerto_Rico" } }, [
        _vm._v("Puerto Rico (Atlantic)")
      ]),
      _c("option", { attrs: { value: "America/New_York" } }, [
        _vm._v("New York (Eastern)")
      ]),
      _c("option", { attrs: { value: "America/Chicago" } }, [
        _vm._v("Chicago (Central)")
      ]),
      _c("option", { attrs: { value: "America/Denver" } }, [
        _vm._v("Denver (Mountain)")
      ]),
      _c("option", { attrs: { value: "America/Phoenix" } }, [
        _vm._v("Phoenix (MST)")
      ]),
      _c("option", { attrs: { value: "America/Los_Angeles" } }, [
        _vm._v("Los Angeles (Pacific)")
      ]),
      _c("option", { attrs: { value: "America/Anchorage" } }, [
        _vm._v("Anchorage (Alaska)")
      ]),
      _c("option", { attrs: { value: "Pacific/Honolulu" } }, [
        _vm._v("Honolulu (Hawaii)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "America" } }, [
      _c("option", { attrs: { value: "America/Adak" } }, [_vm._v("Adak")]),
      _c("option", { attrs: { value: "America/Anchorage" } }, [
        _vm._v("Anchorage")
      ]),
      _c("option", { attrs: { value: "America/Anguilla" } }, [
        _vm._v("Anguilla")
      ]),
      _c("option", { attrs: { value: "America/Antigua" } }, [
        _vm._v("Antigua")
      ]),
      _c("option", { attrs: { value: "America/Araguaina" } }, [
        _vm._v("Araguaina")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Buenos_Aires" } }, [
        _vm._v("Argentina - Buenos Aires")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Catamarca" } }, [
        _vm._v("Argentina - Catamarca")
      ]),
      _c("option", { attrs: { value: "America/Argentina/ComodRivadavia" } }, [
        _vm._v("Argentina - ComodRivadavia")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Cordoba" } }, [
        _vm._v("Argentina - Cordoba")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Jujuy" } }, [
        _vm._v("Argentina - Jujuy")
      ]),
      _c("option", { attrs: { value: "America/Argentina/La_Rioja" } }, [
        _vm._v("Argentina - La Rioja")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Mendoza" } }, [
        _vm._v("Argentina - Mendoza")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Rio_Gallegos" } }, [
        _vm._v("Argentina - Rio Gallegos")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Salta" } }, [
        _vm._v("Argentina - Salta")
      ]),
      _c("option", { attrs: { value: "America/Argentina/San_Juan" } }, [
        _vm._v("Argentina - San Juan")
      ]),
      _c("option", { attrs: { value: "America/Argentina/San_Luis" } }, [
        _vm._v("Argentina - San Luis")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Tucuman" } }, [
        _vm._v("Argentina - Tucuman")
      ]),
      _c("option", { attrs: { value: "America/Argentina/Ushuaia" } }, [
        _vm._v("Argentina - Ushuaia")
      ]),
      _c("option", { attrs: { value: "America/Aruba" } }, [_vm._v("Aruba")]),
      _c("option", { attrs: { value: "America/Asuncion" } }, [
        _vm._v("Asuncion")
      ]),
      _c("option", { attrs: { value: "America/Atikokan" } }, [
        _vm._v("Atikokan")
      ]),
      _c("option", { attrs: { value: "America/Atka" } }, [_vm._v("Atka")]),
      _c("option", { attrs: { value: "America/Bahia" } }, [_vm._v("Bahia")]),
      _c("option", { attrs: { value: "America/Barbados" } }, [
        _vm._v("Barbados")
      ]),
      _c("option", { attrs: { value: "America/Belem" } }, [_vm._v("Belem")]),
      _c("option", { attrs: { value: "America/Belize" } }, [_vm._v("Belize")]),
      _c("option", { attrs: { value: "America/Blanc-Sablon" } }, [
        _vm._v("Blanc-Sablon")
      ]),
      _c("option", { attrs: { value: "America/Boa_Vista" } }, [
        _vm._v("Boa Vista")
      ]),
      _c("option", { attrs: { value: "America/Bogota" } }, [_vm._v("Bogota")]),
      _c("option", { attrs: { value: "America/Boise" } }, [_vm._v("Boise")]),
      _c("option", { attrs: { value: "America/Buenos_Aires" } }, [
        _vm._v("Buenos Aires")
      ]),
      _c("option", { attrs: { value: "America/Cambridge_Bay" } }, [
        _vm._v("Cambridge Bay")
      ]),
      _c("option", { attrs: { value: "America/Campo_Grande" } }, [
        _vm._v("Campo Grande")
      ]),
      _c("option", { attrs: { value: "America/Cancun" } }, [_vm._v("Cancun")]),
      _c("option", { attrs: { value: "America/Caracas" } }, [
        _vm._v("Caracas")
      ]),
      _c("option", { attrs: { value: "America/Catamarca" } }, [
        _vm._v("Catamarca")
      ]),
      _c("option", { attrs: { value: "America/Cayenne" } }, [
        _vm._v("Cayenne")
      ]),
      _c("option", { attrs: { value: "America/Cayman" } }, [_vm._v("Cayman")]),
      _c("option", { attrs: { value: "America/Chicago" } }, [
        _vm._v("Chicago")
      ]),
      _c("option", { attrs: { value: "America/Chihuahua" } }, [
        _vm._v("Chihuahua")
      ]),
      _c("option", { attrs: { value: "America/Coral_Harbour" } }, [
        _vm._v("Coral Harbour")
      ]),
      _c("option", { attrs: { value: "America/Cordoba" } }, [
        _vm._v("Cordoba")
      ]),
      _c("option", { attrs: { value: "America/Costa_Rica" } }, [
        _vm._v("Costa Rica")
      ]),
      _c("option", { attrs: { value: "America/Cuiaba" } }, [_vm._v("Cuiaba")]),
      _c("option", { attrs: { value: "America/Curacao" } }, [
        _vm._v("Curacao")
      ]),
      _c("option", { attrs: { value: "America/Danmarkshavn" } }, [
        _vm._v("Danmarkshavn")
      ]),
      _c("option", { attrs: { value: "America/Dawson" } }, [_vm._v("Dawson")]),
      _c("option", { attrs: { value: "America/Dawson_Creek" } }, [
        _vm._v("Dawson Creek")
      ]),
      _c("option", { attrs: { value: "America/Denver" } }, [_vm._v("Denver")]),
      _c("option", { attrs: { value: "America/Detroit" } }, [
        _vm._v("Detroit")
      ]),
      _c("option", { attrs: { value: "America/Dominica" } }, [
        _vm._v("Dominica")
      ]),
      _c("option", { attrs: { value: "America/Edmonton" } }, [
        _vm._v("Edmonton")
      ]),
      _c("option", { attrs: { value: "America/Eirunepe" } }, [
        _vm._v("Eirunepe")
      ]),
      _c("option", { attrs: { value: "America/El_Salvador" } }, [
        _vm._v("El Salvador")
      ]),
      _c("option", { attrs: { value: "America/Ensenada" } }, [
        _vm._v("Ensenada")
      ]),
      _c("option", { attrs: { value: "America/Fortaleza" } }, [
        _vm._v("Fortaleza")
      ]),
      _c("option", { attrs: { value: "America/Fort_Wayne" } }, [
        _vm._v("Fort Wayne")
      ]),
      _c("option", { attrs: { value: "America/Glace_Bay" } }, [
        _vm._v("Glace Bay")
      ]),
      _c("option", { attrs: { value: "America/Godthab" } }, [
        _vm._v("Godthab")
      ]),
      _c("option", { attrs: { value: "America/Goose_Bay" } }, [
        _vm._v("Goose Bay")
      ]),
      _c("option", { attrs: { value: "America/Grand_Turk" } }, [
        _vm._v("Grand Turk")
      ]),
      _c("option", { attrs: { value: "America/Grenada" } }, [
        _vm._v("Grenada")
      ]),
      _c("option", { attrs: { value: "America/Guadeloupe" } }, [
        _vm._v("Guadeloupe")
      ]),
      _c("option", { attrs: { value: "America/Guatemala" } }, [
        _vm._v("Guatemala")
      ]),
      _c("option", { attrs: { value: "America/Guayaquil" } }, [
        _vm._v("Guayaquil")
      ]),
      _c("option", { attrs: { value: "America/Guyana" } }, [_vm._v("Guyana")]),
      _c("option", { attrs: { value: "America/Halifax" } }, [
        _vm._v("Halifax")
      ]),
      _c("option", { attrs: { value: "America/Havana" } }, [_vm._v("Havana")]),
      _c("option", { attrs: { value: "America/Hermosillo" } }, [
        _vm._v("Hermosillo")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Indianapolis" } }, [
        _vm._v("Indiana - Indianapolis")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Knox" } }, [
        _vm._v("Indiana - Knox")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Marengo" } }, [
        _vm._v("Indiana - Marengo")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Petersburg" } }, [
        _vm._v("Indiana - Petersburg")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Tell_City" } }, [
        _vm._v("Indiana - Tell City")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Vevay" } }, [
        _vm._v("Indiana - Vevay")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Vincennes" } }, [
        _vm._v("Indiana - Vincennes")
      ]),
      _c("option", { attrs: { value: "America/Indiana/Winamac" } }, [
        _vm._v("Indiana - Winamac")
      ]),
      _c("option", { attrs: { value: "America/Indianapolis" } }, [
        _vm._v("Indianapolis")
      ]),
      _c("option", { attrs: { value: "America/Inuvik" } }, [_vm._v("Inuvik")]),
      _c("option", { attrs: { value: "America/Iqaluit" } }, [
        _vm._v("Iqaluit")
      ]),
      _c("option", { attrs: { value: "America/Jamaica" } }, [
        _vm._v("Jamaica")
      ]),
      _c("option", { attrs: { value: "America/Jujuy" } }, [_vm._v("Jujuy")]),
      _c("option", { attrs: { value: "America/Juneau" } }, [_vm._v("Juneau")]),
      _c("option", { attrs: { value: "America/Kentucky/Louisville" } }, [
        _vm._v("Kentucky - Louisville")
      ]),
      _c("option", { attrs: { value: "America/Kentucky/Monticello" } }, [
        _vm._v("Kentucky - Monticello")
      ]),
      _c("option", { attrs: { value: "America/Knox_IN" } }, [
        _vm._v("Knox IN")
      ]),
      _c("option", { attrs: { value: "America/La_Paz" } }, [_vm._v("La Paz")]),
      _c("option", { attrs: { value: "America/Lima" } }, [_vm._v("Lima")]),
      _c("option", { attrs: { value: "America/Los_Angeles" } }, [
        _vm._v("Los Angeles")
      ]),
      _c("option", { attrs: { value: "America/Louisville" } }, [
        _vm._v("Louisville")
      ]),
      _c("option", { attrs: { value: "America/Maceio" } }, [_vm._v("Maceio")]),
      _c("option", { attrs: { value: "America/Managua" } }, [
        _vm._v("Managua")
      ]),
      _c("option", { attrs: { value: "America/Manaus" } }, [_vm._v("Manaus")]),
      _c("option", { attrs: { value: "America/Marigot" } }, [
        _vm._v("Marigot")
      ]),
      _c("option", { attrs: { value: "America/Martinique" } }, [
        _vm._v("Martinique")
      ]),
      _c("option", { attrs: { value: "America/Matamoros" } }, [
        _vm._v("Matamoros")
      ]),
      _c("option", { attrs: { value: "America/Mazatlan" } }, [
        _vm._v("Mazatlan")
      ]),
      _c("option", { attrs: { value: "America/Mendoza" } }, [
        _vm._v("Mendoza")
      ]),
      _c("option", { attrs: { value: "America/Menominee" } }, [
        _vm._v("Menominee")
      ]),
      _c("option", { attrs: { value: "America/Merida" } }, [_vm._v("Merida")]),
      _c("option", { attrs: { value: "America/Mexico_City" } }, [
        _vm._v("Mexico City")
      ]),
      _c("option", { attrs: { value: "America/Miquelon" } }, [
        _vm._v("Miquelon")
      ]),
      _c("option", { attrs: { value: "America/Moncton" } }, [
        _vm._v("Moncton")
      ]),
      _c("option", { attrs: { value: "America/Monterrey" } }, [
        _vm._v("Monterrey")
      ]),
      _c("option", { attrs: { value: "America/Montevideo" } }, [
        _vm._v("Montevideo")
      ]),
      _c("option", { attrs: { value: "America/Montreal" } }, [
        _vm._v("Montreal")
      ]),
      _c("option", { attrs: { value: "America/Montserrat" } }, [
        _vm._v("Montserrat")
      ]),
      _c("option", { attrs: { value: "America/Nassau" } }, [_vm._v("Nassau")]),
      _c("option", { attrs: { value: "America/New_York" } }, [
        _vm._v("New York")
      ]),
      _c("option", { attrs: { value: "America/Nipigon" } }, [
        _vm._v("Nipigon")
      ]),
      _c("option", { attrs: { value: "America/Nome" } }, [_vm._v("Nome")]),
      _c("option", { attrs: { value: "America/Noronha" } }, [
        _vm._v("Noronha")
      ]),
      _c("option", { attrs: { value: "America/North_Dakota/Center" } }, [
        _vm._v("North Dakota - Center")
      ]),
      _c("option", { attrs: { value: "America/North_Dakota/New_Salem" } }, [
        _vm._v("North Dakota - New Salem")
      ]),
      _c("option", { attrs: { value: "America/Ojinaga" } }, [
        _vm._v("Ojinaga")
      ]),
      _c("option", { attrs: { value: "America/Panama" } }, [_vm._v("Panama")]),
      _c("option", { attrs: { value: "America/Pangnirtung" } }, [
        _vm._v("Pangnirtung")
      ]),
      _c("option", { attrs: { value: "America/Paramaribo" } }, [
        _vm._v("Paramaribo")
      ]),
      _c("option", { attrs: { value: "America/Phoenix" } }, [
        _vm._v("Phoenix")
      ]),
      _c("option", { attrs: { value: "America/Port-au-Prince" } }, [
        _vm._v("Port-au-Prince")
      ]),
      _c("option", { attrs: { value: "America/Porto_Acre" } }, [
        _vm._v("Porto Acre")
      ]),
      _c("option", { attrs: { value: "America/Port_of_Spain" } }, [
        _vm._v("Port of Spain")
      ]),
      _c("option", { attrs: { value: "America/Porto_Velho" } }, [
        _vm._v("Porto Velho")
      ]),
      _c("option", { attrs: { value: "America/Puerto_Rico" } }, [
        _vm._v("Puerto Rico")
      ]),
      _c("option", { attrs: { value: "America/Rainy_River" } }, [
        _vm._v("Rainy River")
      ]),
      _c("option", { attrs: { value: "America/Rankin_Inlet" } }, [
        _vm._v("Rankin Inlet")
      ]),
      _c("option", { attrs: { value: "America/Recife" } }, [_vm._v("Recife")]),
      _c("option", { attrs: { value: "America/Regina" } }, [_vm._v("Regina")]),
      _c("option", { attrs: { value: "America/Resolute" } }, [
        _vm._v("Resolute")
      ]),
      _c("option", { attrs: { value: "America/Rio_Branco" } }, [
        _vm._v("Rio Branco")
      ]),
      _c("option", { attrs: { value: "America/Rosario" } }, [
        _vm._v("Rosario")
      ]),
      _c("option", { attrs: { value: "America/Santa_Isabel" } }, [
        _vm._v("Santa Isabel")
      ]),
      _c("option", { attrs: { value: "America/Santarem" } }, [
        _vm._v("Santarem")
      ]),
      _c("option", { attrs: { value: "America/Santiago" } }, [
        _vm._v("Santiago")
      ]),
      _c("option", { attrs: { value: "America/Santo_Domingo" } }, [
        _vm._v("Santo Domingo")
      ]),
      _c("option", { attrs: { value: "America/Sao_Paulo" } }, [
        _vm._v("Sao Paulo")
      ]),
      _c("option", { attrs: { value: "America/Scoresbysund" } }, [
        _vm._v("Scoresbysund")
      ]),
      _c("option", { attrs: { value: "America/Shiprock" } }, [
        _vm._v("Shiprock")
      ]),
      _c("option", { attrs: { value: "America/St_Barthelemy" } }, [
        _vm._v("St Barthelemy")
      ]),
      _c("option", { attrs: { value: "America/St_Johns" } }, [
        _vm._v("St Johns")
      ]),
      _c("option", { attrs: { value: "America/St_Kitts" } }, [
        _vm._v("St Kitts")
      ]),
      _c("option", { attrs: { value: "America/St_Lucia" } }, [
        _vm._v("St Lucia")
      ]),
      _c("option", { attrs: { value: "America/St_Thomas" } }, [
        _vm._v("St Thomas")
      ]),
      _c("option", { attrs: { value: "America/St_Vincent" } }, [
        _vm._v("St Vincent")
      ]),
      _c("option", { attrs: { value: "America/Swift_Current" } }, [
        _vm._v("Swift Current")
      ]),
      _c("option", { attrs: { value: "America/Tegucigalpa" } }, [
        _vm._v("Tegucigalpa")
      ]),
      _c("option", { attrs: { value: "America/Thule" } }, [_vm._v("Thule")]),
      _c("option", { attrs: { value: "America/Thunder_Bay" } }, [
        _vm._v("Thunder Bay")
      ]),
      _c("option", { attrs: { value: "America/Tijuana" } }, [
        _vm._v("Tijuana")
      ]),
      _c("option", { attrs: { value: "America/Toronto" } }, [
        _vm._v("Toronto")
      ]),
      _c("option", { attrs: { value: "America/Tortola" } }, [
        _vm._v("Tortola")
      ]),
      _c("option", { attrs: { value: "America/Vancouver" } }, [
        _vm._v("Vancouver")
      ]),
      _c("option", { attrs: { value: "America/Virgin" } }, [_vm._v("Virgin")]),
      _c("option", { attrs: { value: "America/Whitehorse" } }, [
        _vm._v("Whitehorse")
      ]),
      _c("option", { attrs: { value: "America/Winnipeg" } }, [
        _vm._v("Winnipeg")
      ]),
      _c("option", { attrs: { value: "America/Yakutat" } }, [
        _vm._v("Yakutat")
      ]),
      _c("option", { attrs: { value: "America/Yellowknife" } }, [
        _vm._v("Yellowknife")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Europe" } }, [
      _c("option", { attrs: { value: "Europe/Amsterdam" } }, [
        _vm._v("Amsterdam")
      ]),
      _c("option", { attrs: { value: "Europe/Andorra" } }, [_vm._v("Andorra")]),
      _c("option", { attrs: { value: "Europe/Athens" } }, [_vm._v("Athens")]),
      _c("option", { attrs: { value: "Europe/Belfast" } }, [_vm._v("Belfast")]),
      _c("option", { attrs: { value: "Europe/Belgrade" } }, [
        _vm._v("Belgrade")
      ]),
      _c("option", { attrs: { value: "Europe/Berlin" } }, [_vm._v("Berlin")]),
      _c("option", { attrs: { value: "Europe/Bratislava" } }, [
        _vm._v("Bratislava")
      ]),
      _c("option", { attrs: { value: "Europe/Brussels" } }, [
        _vm._v("Brussels")
      ]),
      _c("option", { attrs: { value: "Europe/Bucharest" } }, [
        _vm._v("Bucharest")
      ]),
      _c("option", { attrs: { value: "Europe/Budapest" } }, [
        _vm._v("Budapest")
      ]),
      _c("option", { attrs: { value: "Europe/Chisinau" } }, [
        _vm._v("Chisinau")
      ]),
      _c("option", { attrs: { value: "Europe/Copenhagen" } }, [
        _vm._v("Copenhagen")
      ]),
      _c("option", { attrs: { value: "Europe/Dublin" } }, [_vm._v("Dublin")]),
      _c("option", { attrs: { value: "Europe/Gibraltar" } }, [
        _vm._v("Gibraltar")
      ]),
      _c("option", { attrs: { value: "Europe/Guernsey" } }, [
        _vm._v("Guernsey")
      ]),
      _c("option", { attrs: { value: "Europe/Helsinki" } }, [
        _vm._v("Helsinki")
      ]),
      _c("option", { attrs: { value: "Europe/Isle_of_Man" } }, [
        _vm._v("Isle of Man")
      ]),
      _c("option", { attrs: { value: "Europe/Istanbul" } }, [
        _vm._v("Istanbul")
      ]),
      _c("option", { attrs: { value: "Europe/Jersey" } }, [_vm._v("Jersey")]),
      _c("option", { attrs: { value: "Europe/Kaliningrad" } }, [
        _vm._v("Kaliningrad")
      ]),
      _c("option", { attrs: { value: "Europe/Kiev" } }, [_vm._v("Kiev")]),
      _c("option", { attrs: { value: "Europe/Lisbon" } }, [_vm._v("Lisbon")]),
      _c("option", { attrs: { value: "Europe/Ljubljana" } }, [
        _vm._v("Ljubljana")
      ]),
      _c("option", { attrs: { value: "Europe/London" } }, [_vm._v("London")]),
      _c("option", { attrs: { value: "Europe/Luxembourg" } }, [
        _vm._v("Luxembourg")
      ]),
      _c("option", { attrs: { value: "Europe/Madrid" } }, [_vm._v("Madrid")]),
      _c("option", { attrs: { value: "Europe/Malta" } }, [_vm._v("Malta")]),
      _c("option", { attrs: { value: "Europe/Mariehamn" } }, [
        _vm._v("Mariehamn")
      ]),
      _c("option", { attrs: { value: "Europe/Minsk" } }, [_vm._v("Minsk")]),
      _c("option", { attrs: { value: "Europe/Monaco" } }, [_vm._v("Monaco")]),
      _c("option", { attrs: { value: "Europe/Moscow" } }, [_vm._v("Moscow")]),
      _c("option", { attrs: { value: "Europe/Nicosia" } }, [_vm._v("Nicosia")]),
      _c("option", { attrs: { value: "Europe/Oslo" } }, [_vm._v("Oslo")]),
      _c("option", { attrs: { value: "Europe/Paris" } }, [_vm._v("Paris")]),
      _c("option", { attrs: { value: "Europe/Podgorica" } }, [
        _vm._v("Podgorica")
      ]),
      _c("option", { attrs: { value: "Europe/Prague" } }, [_vm._v("Prague")]),
      _c("option", { attrs: { value: "Europe/Riga" } }, [_vm._v("Riga")]),
      _c("option", { attrs: { value: "Europe/Rome" } }, [_vm._v("Rome")]),
      _c("option", { attrs: { value: "Europe/Samara" } }, [_vm._v("Samara")]),
      _c("option", { attrs: { value: "Europe/San_Marino" } }, [
        _vm._v("San Marino")
      ]),
      _c("option", { attrs: { value: "Europe/Sarajevo" } }, [
        _vm._v("Sarajevo")
      ]),
      _c("option", { attrs: { value: "Europe/Simferopol" } }, [
        _vm._v("Simferopol")
      ]),
      _c("option", { attrs: { value: "Europe/Skopje" } }, [_vm._v("Skopje")]),
      _c("option", { attrs: { value: "Europe/Sofia" } }, [_vm._v("Sofia")]),
      _c("option", { attrs: { value: "Europe/Stockholm" } }, [
        _vm._v("Stockholm")
      ]),
      _c("option", { attrs: { value: "Europe/Tallinn" } }, [_vm._v("Tallinn")]),
      _c("option", { attrs: { value: "Europe/Tirane" } }, [_vm._v("Tirane")]),
      _c("option", { attrs: { value: "Europe/Tiraspol" } }, [
        _vm._v("Tiraspol")
      ]),
      _c("option", { attrs: { value: "Europe/Uzhgorod" } }, [
        _vm._v("Uzhgorod")
      ]),
      _c("option", { attrs: { value: "Europe/Vaduz" } }, [_vm._v("Vaduz")]),
      _c("option", { attrs: { value: "Europe/Vatican" } }, [_vm._v("Vatican")]),
      _c("option", { attrs: { value: "Europe/Vienna" } }, [_vm._v("Vienna")]),
      _c("option", { attrs: { value: "Europe/Vilnius" } }, [_vm._v("Vilnius")]),
      _c("option", { attrs: { value: "Europe/Volgograd" } }, [
        _vm._v("Volgograd")
      ]),
      _c("option", { attrs: { value: "Europe/Warsaw" } }, [_vm._v("Warsaw")]),
      _c("option", { attrs: { value: "Europe/Zagreb" } }, [_vm._v("Zagreb")]),
      _c("option", { attrs: { value: "Europe/Zaporozhye" } }, [
        _vm._v("Zaporozhye")
      ]),
      _c("option", { attrs: { value: "Europe/Zurich" } }, [_vm._v("Zurich")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Asia" } }, [
      _c("option", { attrs: { value: "Asia/Aden" } }, [_vm._v("Aden")]),
      _c("option", { attrs: { value: "Asia/Almaty" } }, [_vm._v("Almaty")]),
      _c("option", { attrs: { value: "Asia/Amman" } }, [_vm._v("Amman")]),
      _c("option", { attrs: { value: "Asia/Anadyr" } }, [_vm._v("Anadyr")]),
      _c("option", { attrs: { value: "Asia/Aqtau" } }, [_vm._v("Aqtau")]),
      _c("option", { attrs: { value: "Asia/Aqtobe" } }, [_vm._v("Aqtobe")]),
      _c("option", { attrs: { value: "Asia/Ashgabat" } }, [_vm._v("Ashgabat")]),
      _c("option", { attrs: { value: "Asia/Ashkhabad" } }, [
        _vm._v("Ashkhabad")
      ]),
      _c("option", { attrs: { value: "Asia/Baghdad" } }, [_vm._v("Baghdad")]),
      _c("option", { attrs: { value: "Asia/Bahrain" } }, [_vm._v("Bahrain")]),
      _c("option", { attrs: { value: "Asia/Baku" } }, [_vm._v("Baku")]),
      _c("option", { attrs: { value: "Asia/Bangkok" } }, [_vm._v("Bangkok")]),
      _c("option", { attrs: { value: "Asia/Beirut" } }, [_vm._v("Beirut")]),
      _c("option", { attrs: { value: "Asia/Bishkek" } }, [_vm._v("Bishkek")]),
      _c("option", { attrs: { value: "Asia/Brunei" } }, [_vm._v("Brunei")]),
      _c("option", { attrs: { value: "Asia/Calcutta" } }, [_vm._v("Calcutta")]),
      _c("option", { attrs: { value: "Asia/Choibalsan" } }, [
        _vm._v("Choibalsan")
      ]),
      _c("option", { attrs: { value: "Asia/Chongqing" } }, [
        _vm._v("Chongqing")
      ]),
      _c("option", { attrs: { value: "Asia/Chungking" } }, [
        _vm._v("Chungking")
      ]),
      _c("option", { attrs: { value: "Asia/Colombo" } }, [_vm._v("Colombo")]),
      _c("option", { attrs: { value: "Asia/Dacca" } }, [_vm._v("Dacca")]),
      _c("option", { attrs: { value: "Asia/Damascus" } }, [_vm._v("Damascus")]),
      _c("option", { attrs: { value: "Asia/Dhaka" } }, [_vm._v("Dhaka")]),
      _c("option", { attrs: { value: "Asia/Dili" } }, [_vm._v("Dili")]),
      _c("option", { attrs: { value: "Asia/Dubai" } }, [_vm._v("Dubai")]),
      _c("option", { attrs: { value: "Asia/Dushanbe" } }, [_vm._v("Dushanbe")]),
      _c("option", { attrs: { value: "Asia/Gaza" } }, [_vm._v("Gaza")]),
      _c("option", { attrs: { value: "Asia/Harbin" } }, [_vm._v("Harbin")]),
      _c("option", { attrs: { value: "Asia/Ho_Chi_Minh" } }, [
        _vm._v("Ho Chi Minh")
      ]),
      _c("option", { attrs: { value: "Asia/Hong_Kong" } }, [
        _vm._v("Hong Kong")
      ]),
      _c("option", { attrs: { value: "Asia/Hovd" } }, [_vm._v("Hovd")]),
      _c("option", { attrs: { value: "Asia/Irkutsk" } }, [_vm._v("Irkutsk")]),
      _c("option", { attrs: { value: "Asia/Istanbul" } }, [_vm._v("Istanbul")]),
      _c("option", { attrs: { value: "Asia/Jakarta" } }, [_vm._v("Jakarta")]),
      _c("option", { attrs: { value: "Asia/Jayapura" } }, [_vm._v("Jayapura")]),
      _c("option", { attrs: { value: "Asia/Jerusalem" } }, [
        _vm._v("Jerusalem")
      ]),
      _c("option", { attrs: { value: "Asia/Kabul" } }, [_vm._v("Kabul")]),
      _c("option", { attrs: { value: "Asia/Kamchatka" } }, [
        _vm._v("Kamchatka")
      ]),
      _c("option", { attrs: { value: "Asia/Karachi" } }, [_vm._v("Karachi")]),
      _c("option", { attrs: { value: "Asia/Kashgar" } }, [_vm._v("Kashgar")]),
      _c("option", { attrs: { value: "Asia/Kathmandu" } }, [
        _vm._v("Kathmandu")
      ]),
      _c("option", { attrs: { value: "Asia/Katmandu" } }, [_vm._v("Katmandu")]),
      _c("option", { attrs: { value: "Asia/Kolkata" } }, [_vm._v("Kolkata")]),
      _c("option", { attrs: { value: "Asia/Krasnoyarsk" } }, [
        _vm._v("Krasnoyarsk")
      ]),
      _c("option", { attrs: { value: "Asia/Kuala_Lumpur" } }, [
        _vm._v("Kuala Lumpur")
      ]),
      _c("option", { attrs: { value: "Asia/Kuching" } }, [_vm._v("Kuching")]),
      _c("option", { attrs: { value: "Asia/Kuwait" } }, [_vm._v("Kuwait")]),
      _c("option", { attrs: { value: "Asia/Macao" } }, [_vm._v("Macao")]),
      _c("option", { attrs: { value: "Asia/Macau" } }, [_vm._v("Macau")]),
      _c("option", { attrs: { value: "Asia/Magadan" } }, [_vm._v("Magadan")]),
      _c("option", { attrs: { value: "Asia/Makassar" } }, [_vm._v("Makassar")]),
      _c("option", { attrs: { value: "Asia/Manila" } }, [_vm._v("Manila")]),
      _c("option", { attrs: { value: "Asia/Muscat" } }, [_vm._v("Muscat")]),
      _c("option", { attrs: { value: "Asia/Nicosia" } }, [_vm._v("Nicosia")]),
      _c("option", { attrs: { value: "Asia/Novokuznetsk" } }, [
        _vm._v("Novokuznetsk")
      ]),
      _c("option", { attrs: { value: "Asia/Novosibirsk" } }, [
        _vm._v("Novosibirsk")
      ]),
      _c("option", { attrs: { value: "Asia/Omsk" } }, [_vm._v("Omsk")]),
      _c("option", { attrs: { value: "Asia/Oral" } }, [_vm._v("Oral")]),
      _c("option", { attrs: { value: "Asia/Phnom_Penh" } }, [
        _vm._v("Phnom Penh")
      ]),
      _c("option", { attrs: { value: "Asia/Pontianak" } }, [
        _vm._v("Pontianak")
      ]),
      _c("option", { attrs: { value: "Asia/Pyongyang" } }, [
        _vm._v("Pyongyang")
      ]),
      _c("option", { attrs: { value: "Asia/Qatar" } }, [_vm._v("Qatar")]),
      _c("option", { attrs: { value: "Asia/Qyzylorda" } }, [
        _vm._v("Qyzylorda")
      ]),
      _c("option", { attrs: { value: "Asia/Rangoon" } }, [_vm._v("Rangoon")]),
      _c("option", { attrs: { value: "Asia/Riyadh" } }, [_vm._v("Riyadh")]),
      _c("option", { attrs: { value: "Asia/Saigon" } }, [_vm._v("Saigon")]),
      _c("option", { attrs: { value: "Asia/Sakhalin" } }, [_vm._v("Sakhalin")]),
      _c("option", { attrs: { value: "Asia/Samarkand" } }, [
        _vm._v("Samarkand")
      ]),
      _c("option", { attrs: { value: "Asia/Seoul" } }, [_vm._v("Seoul")]),
      _c("option", { attrs: { value: "Asia/Shanghai" } }, [_vm._v("Shanghai")]),
      _c("option", { attrs: { value: "Asia/Singapore" } }, [
        _vm._v("Singapore")
      ]),
      _c("option", { attrs: { value: "Asia/Taipei" } }, [_vm._v("Taipei")]),
      _c("option", { attrs: { value: "Asia/Tashkent" } }, [_vm._v("Tashkent")]),
      _c("option", { attrs: { value: "Asia/Tbilisi" } }, [_vm._v("Tbilisi")]),
      _c("option", { attrs: { value: "Asia/Tehran" } }, [_vm._v("Tehran")]),
      _c("option", { attrs: { value: "Asia/Tel_Aviv" } }, [_vm._v("Tel Aviv")]),
      _c("option", { attrs: { value: "Asia/Thimbu" } }, [_vm._v("Thimbu")]),
      _c("option", { attrs: { value: "Asia/Thimphu" } }, [_vm._v("Thimphu")]),
      _c("option", { attrs: { value: "Asia/Tokyo" } }, [_vm._v("Tokyo")]),
      _c("option", { attrs: { value: "Asia/Ujung_Pandang" } }, [
        _vm._v("Ujung Pandang")
      ]),
      _c("option", { attrs: { value: "Asia/Ulaanbaatar" } }, [
        _vm._v("Ulaanbaatar")
      ]),
      _c("option", { attrs: { value: "Asia/Ulan_Bator" } }, [
        _vm._v("Ulan Bator")
      ]),
      _c("option", { attrs: { value: "Asia/Urumqi" } }, [_vm._v("Urumqi")]),
      _c("option", { attrs: { value: "Asia/Vientiane" } }, [
        _vm._v("Vientiane")
      ]),
      _c("option", { attrs: { value: "Asia/Vladivostok" } }, [
        _vm._v("Vladivostok")
      ]),
      _c("option", { attrs: { value: "Asia/Yakutsk" } }, [_vm._v("Yakutsk")]),
      _c("option", { attrs: { value: "Asia/Yekaterinburg" } }, [
        _vm._v("Yekaterinburg")
      ]),
      _c("option", { attrs: { value: "Asia/Yerevan" } }, [_vm._v("Yerevan")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Africa" } }, [
      _c("option", { attrs: { value: "Africa/Abidjan" } }, [_vm._v("Abidjan")]),
      _c("option", { attrs: { value: "Africa/Accra" } }, [_vm._v("Accra")]),
      _c("option", { attrs: { value: "Africa/Addis_Ababa" } }, [
        _vm._v("Addis Ababa")
      ]),
      _c("option", { attrs: { value: "Africa/Algiers" } }, [_vm._v("Algiers")]),
      _c("option", { attrs: { value: "Africa/Asmara" } }, [_vm._v("Asmara")]),
      _c("option", { attrs: { value: "Africa/Asmera" } }, [_vm._v("Asmera")]),
      _c("option", { attrs: { value: "Africa/Bamako" } }, [_vm._v("Bamako")]),
      _c("option", { attrs: { value: "Africa/Bangui" } }, [_vm._v("Bangui")]),
      _c("option", { attrs: { value: "Africa/Banjul" } }, [_vm._v("Banjul")]),
      _c("option", { attrs: { value: "Africa/Bissau" } }, [_vm._v("Bissau")]),
      _c("option", { attrs: { value: "Africa/Blantyre" } }, [
        _vm._v("Blantyre")
      ]),
      _c("option", { attrs: { value: "Africa/Brazzaville" } }, [
        _vm._v("Brazzaville")
      ]),
      _c("option", { attrs: { value: "Africa/Bujumbura" } }, [
        _vm._v("Bujumbura")
      ]),
      _c("option", { attrs: { value: "Africa/Cairo" } }, [_vm._v("Cairo")]),
      _c("option", { attrs: { value: "Africa/Casablanca" } }, [
        _vm._v("Casablanca")
      ]),
      _c("option", { attrs: { value: "Africa/Ceuta" } }, [_vm._v("Ceuta")]),
      _c("option", { attrs: { value: "Africa/Conakry" } }, [_vm._v("Conakry")]),
      _c("option", { attrs: { value: "Africa/Dakar" } }, [_vm._v("Dakar")]),
      _c("option", { attrs: { value: "Africa/Dar_es_Salaam" } }, [
        _vm._v("Dar es Salaam")
      ]),
      _c("option", { attrs: { value: "Africa/Djibouti" } }, [
        _vm._v("Djibouti")
      ]),
      _c("option", { attrs: { value: "Africa/Douala" } }, [_vm._v("Douala")]),
      _c("option", { attrs: { value: "Africa/El_Aaiun" } }, [
        _vm._v("El Aaiun")
      ]),
      _c("option", { attrs: { value: "Africa/Freetown" } }, [
        _vm._v("Freetown")
      ]),
      _c("option", { attrs: { value: "Africa/Gaborone" } }, [
        _vm._v("Gaborone")
      ]),
      _c("option", { attrs: { value: "Africa/Harare" } }, [_vm._v("Harare")]),
      _c("option", { attrs: { value: "Africa/Johannesburg" } }, [
        _vm._v("Johannesburg")
      ]),
      _c("option", { attrs: { value: "Africa/Kampala" } }, [_vm._v("Kampala")]),
      _c("option", { attrs: { value: "Africa/Khartoum" } }, [
        _vm._v("Khartoum")
      ]),
      _c("option", { attrs: { value: "Africa/Kigali" } }, [_vm._v("Kigali")]),
      _c("option", { attrs: { value: "Africa/Kinshasa" } }, [
        _vm._v("Kinshasa")
      ]),
      _c("option", { attrs: { value: "Africa/Lagos" } }, [_vm._v("Lagos")]),
      _c("option", { attrs: { value: "Africa/Libreville" } }, [
        _vm._v("Libreville")
      ]),
      _c("option", { attrs: { value: "Africa/Lome" } }, [_vm._v("Lome")]),
      _c("option", { attrs: { value: "Africa/Luanda" } }, [_vm._v("Luanda")]),
      _c("option", { attrs: { value: "Africa/Lubumbashi" } }, [
        _vm._v("Lubumbashi")
      ]),
      _c("option", { attrs: { value: "Africa/Lusaka" } }, [_vm._v("Lusaka")]),
      _c("option", { attrs: { value: "Africa/Malabo" } }, [_vm._v("Malabo")]),
      _c("option", { attrs: { value: "Africa/Maputo" } }, [_vm._v("Maputo")]),
      _c("option", { attrs: { value: "Africa/Maseru" } }, [_vm._v("Maseru")]),
      _c("option", { attrs: { value: "Africa/Mbabane" } }, [_vm._v("Mbabane")]),
      _c("option", { attrs: { value: "Africa/Mogadishu" } }, [
        _vm._v("Mogadishu")
      ]),
      _c("option", { attrs: { value: "Africa/Monrovia" } }, [
        _vm._v("Monrovia")
      ]),
      _c("option", { attrs: { value: "Africa/Nairobi" } }, [_vm._v("Nairobi")]),
      _c("option", { attrs: { value: "Africa/Ndjamena" } }, [
        _vm._v("Ndjamena")
      ]),
      _c("option", { attrs: { value: "Africa/Niamey" } }, [_vm._v("Niamey")]),
      _c("option", { attrs: { value: "Africa/Nouakchott" } }, [
        _vm._v("Nouakchott")
      ]),
      _c("option", { attrs: { value: "Africa/Ouagadougou" } }, [
        _vm._v("Ouagadougou")
      ]),
      _c("option", { attrs: { value: "Africa/Porto-Novo" } }, [
        _vm._v("Porto-Novo")
      ]),
      _c("option", { attrs: { value: "Africa/Sao_Tome" } }, [
        _vm._v("Sao Tome")
      ]),
      _c("option", { attrs: { value: "Africa/Timbuktu" } }, [
        _vm._v("Timbuktu")
      ]),
      _c("option", { attrs: { value: "Africa/Tripoli" } }, [_vm._v("Tripoli")]),
      _c("option", { attrs: { value: "Africa/Tunis" } }, [_vm._v("Tunis")]),
      _c("option", { attrs: { value: "Africa/Windhoek" } }, [
        _vm._v("Windhoek")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Australia" } }, [
      _c("option", { attrs: { value: "Australia/ACT" } }, [_vm._v("ACT")]),
      _c("option", { attrs: { value: "Australia/Adelaide" } }, [
        _vm._v("Adelaide")
      ]),
      _c("option", { attrs: { value: "Australia/Brisbane" } }, [
        _vm._v("Brisbane")
      ]),
      _c("option", { attrs: { value: "Australia/Broken_Hill" } }, [
        _vm._v("Broken Hill")
      ]),
      _c("option", { attrs: { value: "Australia/Canberra" } }, [
        _vm._v("Canberra")
      ]),
      _c("option", { attrs: { value: "Australia/Currie" } }, [
        _vm._v("Currie")
      ]),
      _c("option", { attrs: { value: "Australia/Darwin" } }, [
        _vm._v("Darwin")
      ]),
      _c("option", { attrs: { value: "Australia/Eucla" } }, [_vm._v("Eucla")]),
      _c("option", { attrs: { value: "Australia/Hobart" } }, [
        _vm._v("Hobart")
      ]),
      _c("option", { attrs: { value: "Australia/LHI" } }, [_vm._v("LHI")]),
      _c("option", { attrs: { value: "Australia/Lindeman" } }, [
        _vm._v("Lindeman")
      ]),
      _c("option", { attrs: { value: "Australia/Lord_Howe" } }, [
        _vm._v("Lord Howe")
      ]),
      _c("option", { attrs: { value: "Australia/Melbourne" } }, [
        _vm._v("Melbourne")
      ]),
      _c("option", { attrs: { value: "Australia/North" } }, [_vm._v("North")]),
      _c("option", { attrs: { value: "Australia/NSW" } }, [_vm._v("NSW")]),
      _c("option", { attrs: { value: "Australia/Perth" } }, [_vm._v("Perth")]),
      _c("option", { attrs: { value: "Australia/Queensland" } }, [
        _vm._v("Queensland")
      ]),
      _c("option", { attrs: { value: "Australia/South" } }, [_vm._v("South")]),
      _c("option", { attrs: { value: "Australia/Sydney" } }, [
        _vm._v("Sydney")
      ]),
      _c("option", { attrs: { value: "Australia/Tasmania" } }, [
        _vm._v("Tasmania")
      ]),
      _c("option", { attrs: { value: "Australia/Victoria" } }, [
        _vm._v("Victoria")
      ]),
      _c("option", { attrs: { value: "Australia/West" } }, [_vm._v("West")]),
      _c("option", { attrs: { value: "Australia/Yancowinna" } }, [
        _vm._v("Yancowinna")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Indian" } }, [
      _c("option", { attrs: { value: "Indian/Antananarivo" } }, [
        _vm._v("Antananarivo")
      ]),
      _c("option", { attrs: { value: "Indian/Chagos" } }, [_vm._v("Chagos")]),
      _c("option", { attrs: { value: "Indian/Christmas" } }, [
        _vm._v("Christmas")
      ]),
      _c("option", { attrs: { value: "Indian/Cocos" } }, [_vm._v("Cocos")]),
      _c("option", { attrs: { value: "Indian/Comoro" } }, [_vm._v("Comoro")]),
      _c("option", { attrs: { value: "Indian/Kerguelen" } }, [
        _vm._v("Kerguelen")
      ]),
      _c("option", { attrs: { value: "Indian/Mahe" } }, [_vm._v("Mahe")]),
      _c("option", { attrs: { value: "Indian/Maldives" } }, [
        _vm._v("Maldives")
      ]),
      _c("option", { attrs: { value: "Indian/Mauritius" } }, [
        _vm._v("Mauritius")
      ]),
      _c("option", { attrs: { value: "Indian/Mayotte" } }, [_vm._v("Mayotte")]),
      _c("option", { attrs: { value: "Indian/Reunion" } }, [_vm._v("Reunion")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Atlantic" } }, [
      _c("option", { attrs: { value: "Atlantic/Azores" } }, [_vm._v("Azores")]),
      _c("option", { attrs: { value: "Atlantic/Bermuda" } }, [
        _vm._v("Bermuda")
      ]),
      _c("option", { attrs: { value: "Atlantic/Canary" } }, [_vm._v("Canary")]),
      _c("option", { attrs: { value: "Atlantic/Cape_Verde" } }, [
        _vm._v("Cape Verde")
      ]),
      _c("option", { attrs: { value: "Atlantic/Faeroe" } }, [_vm._v("Faeroe")]),
      _c("option", { attrs: { value: "Atlantic/Faroe" } }, [_vm._v("Faroe")]),
      _c("option", { attrs: { value: "Atlantic/Jan_Mayen" } }, [
        _vm._v("Jan Mayen")
      ]),
      _c("option", { attrs: { value: "Atlantic/Madeira" } }, [
        _vm._v("Madeira")
      ]),
      _c("option", { attrs: { value: "Atlantic/Reykjavik" } }, [
        _vm._v("Reykjavik")
      ]),
      _c("option", { attrs: { value: "Atlantic/South_Georgia" } }, [
        _vm._v("South Georgia")
      ]),
      _c("option", { attrs: { value: "Atlantic/Stanley" } }, [
        _vm._v("Stanley")
      ]),
      _c("option", { attrs: { value: "Atlantic/St_Helena" } }, [
        _vm._v("St Helena")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Pacific" } }, [
      _c("option", { attrs: { value: "Pacific/Apia" } }, [_vm._v("Apia")]),
      _c("option", { attrs: { value: "Pacific/Auckland" } }, [
        _vm._v("Auckland")
      ]),
      _c("option", { attrs: { value: "Pacific/Chatham" } }, [
        _vm._v("Chatham")
      ]),
      _c("option", { attrs: { value: "Pacific/Easter" } }, [_vm._v("Easter")]),
      _c("option", { attrs: { value: "Pacific/Efate" } }, [_vm._v("Efate")]),
      _c("option", { attrs: { value: "Pacific/Enderbury" } }, [
        _vm._v("Enderbury")
      ]),
      _c("option", { attrs: { value: "Pacific/Fakaofo" } }, [
        _vm._v("Fakaofo")
      ]),
      _c("option", { attrs: { value: "Pacific/Fiji" } }, [_vm._v("Fiji")]),
      _c("option", { attrs: { value: "Pacific/Funafuti" } }, [
        _vm._v("Funafuti")
      ]),
      _c("option", { attrs: { value: "Pacific/Galapagos" } }, [
        _vm._v("Galapagos")
      ]),
      _c("option", { attrs: { value: "Pacific/Gambier" } }, [
        _vm._v("Gambier")
      ]),
      _c("option", { attrs: { value: "Pacific/Guadalcanal" } }, [
        _vm._v("Guadalcanal")
      ]),
      _c("option", { attrs: { value: "Pacific/Guam" } }, [_vm._v("Guam")]),
      _c("option", { attrs: { value: "Pacific/Honolulu" } }, [
        _vm._v("Honolulu")
      ]),
      _c("option", { attrs: { value: "Pacific/Johnston" } }, [
        _vm._v("Johnston")
      ]),
      _c("option", { attrs: { value: "Pacific/Kiritimati" } }, [
        _vm._v("Kiritimati")
      ]),
      _c("option", { attrs: { value: "Pacific/Kosrae" } }, [_vm._v("Kosrae")]),
      _c("option", { attrs: { value: "Pacific/Kwajalein" } }, [
        _vm._v("Kwajalein")
      ]),
      _c("option", { attrs: { value: "Pacific/Majuro" } }, [_vm._v("Majuro")]),
      _c("option", { attrs: { value: "Pacific/Marquesas" } }, [
        _vm._v("Marquesas")
      ]),
      _c("option", { attrs: { value: "Pacific/Midway" } }, [_vm._v("Midway")]),
      _c("option", { attrs: { value: "Pacific/Nauru" } }, [_vm._v("Nauru")]),
      _c("option", { attrs: { value: "Pacific/Niue" } }, [_vm._v("Niue")]),
      _c("option", { attrs: { value: "Pacific/Norfolk" } }, [
        _vm._v("Norfolk")
      ]),
      _c("option", { attrs: { value: "Pacific/Noumea" } }, [_vm._v("Noumea")]),
      _c("option", { attrs: { value: "Pacific/Pago_Pago" } }, [
        _vm._v("Pago Pago")
      ]),
      _c("option", { attrs: { value: "Pacific/Palau" } }, [_vm._v("Palau")]),
      _c("option", { attrs: { value: "Pacific/Pitcairn" } }, [
        _vm._v("Pitcairn")
      ]),
      _c("option", { attrs: { value: "Pacific/Ponape" } }, [_vm._v("Ponape")]),
      _c("option", { attrs: { value: "Pacific/Port_Moresby" } }, [
        _vm._v("Port Moresby")
      ]),
      _c("option", { attrs: { value: "Pacific/Rarotonga" } }, [
        _vm._v("Rarotonga")
      ]),
      _c("option", { attrs: { value: "Pacific/Saipan" } }, [_vm._v("Saipan")]),
      _c("option", { attrs: { value: "Pacific/Samoa" } }, [_vm._v("Samoa")]),
      _c("option", { attrs: { value: "Pacific/Tahiti" } }, [_vm._v("Tahiti")]),
      _c("option", { attrs: { value: "Pacific/Tarawa" } }, [_vm._v("Tarawa")]),
      _c("option", { attrs: { value: "Pacific/Tongatapu" } }, [
        _vm._v("Tongatapu")
      ]),
      _c("option", { attrs: { value: "Pacific/Truk" } }, [_vm._v("Truk")]),
      _c("option", { attrs: { value: "Pacific/Wake" } }, [_vm._v("Wake")]),
      _c("option", { attrs: { value: "Pacific/Wallis" } }, [_vm._v("Wallis")]),
      _c("option", { attrs: { value: "Pacific/Yap" } }, [_vm._v("Yap")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Antarctica" } }, [
      _c("option", { attrs: { value: "Antarctica/Casey" } }, [_vm._v("Casey")]),
      _c("option", { attrs: { value: "Antarctica/Davis" } }, [_vm._v("Davis")]),
      _c("option", { attrs: { value: "Antarctica/DumontDUrville" } }, [
        _vm._v("DumontDUrville")
      ]),
      _c("option", { attrs: { value: "Antarctica/Macquarie" } }, [
        _vm._v("Macquarie")
      ]),
      _c("option", { attrs: { value: "Antarctica/Mawson" } }, [
        _vm._v("Mawson")
      ]),
      _c("option", { attrs: { value: "Antarctica/McMurdo" } }, [
        _vm._v("McMurdo")
      ]),
      _c("option", { attrs: { value: "Antarctica/Palmer" } }, [
        _vm._v("Palmer")
      ]),
      _c("option", { attrs: { value: "Antarctica/Rothera" } }, [
        _vm._v("Rothera")
      ]),
      _c("option", { attrs: { value: "Antarctica/South_Pole" } }, [
        _vm._v("South Pole")
      ]),
      _c("option", { attrs: { value: "Antarctica/Syowa" } }, [_vm._v("Syowa")]),
      _c("option", { attrs: { value: "Antarctica/Vostok" } }, [
        _vm._v("Vostok")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Arctic" } }, [
      _c("option", { attrs: { value: "Arctic/Longyearbyen" } }, [
        _vm._v("Longyearbyen")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }