<!--
Emitted events:
- close(submitted: boolean)
-->
<template>
  <AppPanel :opened="opened" @close="$emit('close', false)" class="new-appointment-panel">
    <SectionTitle>Proposer un rendez-vous</SectionTitle>
    <NewAppointmentForm :date="startDate" @success="$emit('close', true)" />
  </AppPanel>
</template>

<script>
  import AppPanel from './AppPanel';
  import NewAppointmentForm from './Dashboard/NewAppointmentForm';
  import SectionTitle from './SectionTitle';

  export default {
    name: 'NewAppointmentPanel',
    components: { SectionTitle, NewAppointmentForm, AppPanel },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
      startDate: {
        type: String,
        required: false,
      }
    },
  };
</script>
