var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agenda-calendar-appointment-group",
      style: { top: _vm.top, height: _vm.height }
    },
    _vm._l(_vm.appointments, function(appointment, index) {
      return _c("AgendaCalendarAppointment", {
        key: appointment.id,
        attrs: {
          appointment: appointment,
          "group-start-at": _vm.startAt,
          offset: index / _vm.appointments.length
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }