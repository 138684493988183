var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prescription-module" },
    [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.add($event)
            }
          }
        },
        [_vm._v("Ajouter une ordonnance")]
      ),
      _c("PrescriptionPanel", {
        attrs: {
          opened: _vm.isEditorVisible,
          "birth-date": _vm.birthDate,
          "prescription-id": _vm.editedPrescriptionId
        },
        on: { close: _vm.handlePanelClose }
      }),
      _c("AppConfirmation", {
        ref: "deletePrescription",
        attrs: {
          title: "Êtes-vous sûr de vouloir supprimer cette ordonnance ?"
        },
        on: { confirm: _vm.remove }
      }),
      _vm._l(_vm.prescriptions, function(prescription, index) {
        return _c(
          "div",
          { staticClass: "line" },
          [
            _c("FontAwesomeIcon", {
              staticClass: "icon",
              attrs: { icon: _vm.$icons.prescription }
            }),
            _c("div", { staticClass: "label" }, [
              _vm._v("Ordonnance n°" + _vm._s(index + 1))
            ]),
            _c("div", { staticClass: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "action",
                  class: { inactive: !_vm.isEditable(prescription) },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.edit(prescription)
                    }
                  }
                },
                [_vm._v("\n        Modifier\n      ")]
              ),
              _vm._v("\n      |\n      "),
              _c(
                "a",
                {
                  staticClass: "action",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.view(prescription)
                    }
                  }
                },
                [_vm._v("Visualiser")]
              ),
              _vm._v("\n      |\n      "),
              _c(
                "a",
                {
                  staticClass: "action",
                  class: { inactive: !_vm.isRemovable(prescription) },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$confirm(
                        _vm.$refs.deletePrescription,
                        prescription
                      )
                    }
                  }
                },
                [_vm._v("\n        Supprimer\n      ")]
              )
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }