var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agenda-calendar-week" }, [
    _c(
      "header",
      [
        _c(
          "div",
          { staticClass: "zoom" },
          [
            _c("FontAwesomeIcon", {
              class: { disabled: !_vm.canDecreaseSize },
              attrs: { icon: _vm.$icons.zoomOut },
              on: {
                click: function($event) {
                  return _vm.changeSize(false)
                }
              }
            }),
            _c("FontAwesomeIcon", {
              class: { disabled: !_vm.canIncreaseSize },
              attrs: { icon: _vm.$icons.zoomIn },
              on: {
                click: function($event) {
                  return _vm.changeSize(true)
                }
              }
            })
          ],
          1
        ),
        _vm._l(_vm.days, function(day) {
          return _c("AgendaCalendarDayColumnHeader", {
            key: day.unix(),
            attrs: { day: day }
          })
        })
      ],
      2
    ),
    _c("div", { ref: "calendar", staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "calendar" },
        [
          _c("AgendaCalendarHoursColumn"),
          _vm._l(_vm.days, function(day, index) {
            return _c("AgendaCalendarDayColumn", {
              key: day.format(),
              attrs: { appointments: _vm.appointmentsList[index], day: day },
              on: {
                click: function($event) {
                  return _vm.openNewAppointmentPanel($event)
                }
              }
            })
          }),
          _c("div", {
            staticClass: "hours-indicator",
            style: "background-size: 100% " + _vm.oneHourHeight
          }),
          _c("div", {
            staticClass: "current-time-indicator",
            style: { top: _vm.currentTimeIndicatorTop }
          }),
          _c("NewAppointmentPanel", {
            attrs: {
              opened: _vm.isNewAppointmentPanelOpened,
              "start-date": _vm.newAppointmentStartAt
            },
            on: { close: _vm.handleNewAppointmentPanelClose }
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }