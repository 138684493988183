var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "div",
      { staticClass: "document-signed" },
      [
        _c("SectionTitle", [_vm._v("Signature de document")]),
        _vm.signature.image
          ? _c("div", [
              _c("p", [
                _vm._v(
                  "Faites glisser la signature sur le document à l'endroit souhaité."
                )
              ]),
              _c("br"),
              _c(
                "div",
                { staticClass: "canvas-container" },
                [
                  _c("canvas", { attrs: { id: "mask" } }),
                  _c("br"),
                  _c(
                    "ButtonGreen",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.sign($event)
                        }
                      }
                    },
                    [_vm._v("\n          Signer\n        ")]
                  ),
                  !this.fileSigned
                    ? _c("img", {
                        staticClass: "draggable img-signature",
                        attrs: {
                          width: "100px",
                          src: "data:image/png;base64," + _vm.signature.image,
                          alt: "signature",
                          id: "signature"
                        }
                      })
                    : _c("span", [_vm._v("\n          Loading...\n          ")])
                ],
                1
              )
            ])
          : _c(
              "div",
              [
                _c("p", [
                  _vm._v("Pour pouvoir signer un document vous devez d'abord "),
                  _c("strong", [_vm._v("ajouter une signature")]),
                  _vm._v(" dans "),
                  _c("strong", [_vm._v("l'édition de votre profil.")])
                ]),
                _c("br"),
                _c(
                  "router-link",
                  { attrs: { to: { name: "profile-edit" } } },
                  [_c("ButtonGreen", [_vm._v("Editer mon profil")])],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }