import api from './';
import {normalizeMedicalesObservations} from "@/helpers/normalizers";

export default {
    update: (data, id) => api.put(`patients/${id}`, {json: data}).json().then(normalizeMedicalesObservations)
/*        json: {
            measure :{
                tension_arterielle: data.tensionArterielle,
                priseDePouls: data.priseDePouls,
                frequencesRespiratoires: data.frequencesRespiratoires,
                poulsPeripheriques: data.poulsPeripheriques,
                temperatureCentrale: data.temperatureCentrale,
                saturationOxygene: data.saturationOxygene,
                glycemieCapillaire: data.glycemieCapillaire,
                examenGorge: data.examenGorge,
                examenCutanee: data.examenCutanee,
                bandeletteUrinaire: data.bandeletteUrinaire,
                oscultationPoumons: data.oscultationPoumons,
                caracterisationToux: data.caracterisationToux,
            }

        }*/
};