<template>
  <header class="app-header" :class="{ 'with-navigation' : withNavigation }">
    <div>
      {{ profile.civility | civility(true) }} {{ fullName }}
    </div>
    <div class="current-date-time">
      {{ currentDateTime }}
    </div>
  </header>
</template>

<script>
  import { getFullName } from '@/helpers/format';

  export default {
    name: 'AppHeader',
    props: {
      withNavigation: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      profile() {
        return this.$store.state.practitioner.profile;
      },
      currentDateTime() {
        return this.$store.state.currentTime.format('dddd DD MMMM YYYY - HH:mm');
      },
      fullName() {
        return getFullName(this.profile.firstName, this.profile.lastName);
      },
    },
  };
</script>

<style scoped lang="scss">
  .app-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #D8D8D8;
    font-size: 1.4rem;
    font-weight: 300;
    position: sticky;
    height:35px;
    top: 7rem;
    background-color: white;
    z-index: 90;

    &:not(.with-navigation) {
      top: 0;
    }

    @include onDesktop {
      top: 0;
    }
  }

  .current-date-time {
    text-transform: capitalize;
  }
</style>
