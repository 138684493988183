import moment from 'moment';


const firstDateOfMonth = (date) => {
    if (!date) date = moment(new Date);
    return moment(date).startOf('month').toDate();
};

const lastDateOfMonth = (date) => {
    if (!date) date = moment(new Date);
    return moment(date).endOf('month').toDate();
};

const startOfWeek = (date, firstDay = 0) => {
    firstDay = (firstDay < 0 || firstDay > 6) ? 0 : firstDay;
    let day = moment(date).get('day');
    let calendarStart = date;

    let diff = moment(calendarStart).get('day') - day + ((day === 0 ? -7 : 0) + firstDay);
    calendarStart = moment(calendarStart).set('day', diff);

    return calendarStart > moment(date) ? moment(calendarStart).date(calendarStart.get('date') - 7) : calendarStart;
};

const shiftMonth = (date, shift) => {
    return moment(date).add(shift, 'month');
};

const buildCalendar = (date, firstDay) => {
    let calendar = [];
    let today = moment(new Date).startOf('day');

    let calendarDate = startOfWeek(date, firstDay);
    let nbDay = moment(date).daysInMonth();
    let diff = moment(date).diff(calendarDate, 'day');
    nbDay += diff;

    let n = 0;
    while (n <= nbDay) {
        let week = [];
        for (let day = 0; day < 7; day++) {
            week.push({
                weekDay: day,
                date: calendarDate,
                isSunday: day === 0,
                isSaturday: day === 6,
                isWeekend: day === 0 || day === 6,
                monthDay: calendarDate.get('date'),
                isToday: calendarDate.valueOf() === today.valueOf(),
                isCurrentMonth: calendarDate.get('month') === moment(date).get('month')
            });
            calendarDate = moment(calendarDate).add(1, 'day');
            n++;
        }
        calendar.push(week);
    }

    return calendar;
};

const filterEventsForDate = (date, events) => {
    let data = {};
    events.forEach(function (event) {

        const start = moment(event.date);
        const dateStart = moment(date).startOf('day');
        const dateEnd = moment(date).endOf('day');

        if (start.valueOf() >= dateStart.valueOf() && start.valueOf() <= dateEnd.valueOf()) {
            if(event.slots) {
                data = event;
            }
        }
    });

    return data ? data : false;
};

const dateOccursIn = (date, daysObj) => {
    if (daysObj.dates) {
        return daysObj.dates.some(d => date.toDateString() === d.toDateString());
    }

    if (daysObj.before && date < daysObj.before) return true;
    if (daysObj.after && date > daysObj.after) return true;
    if (daysObj.days && daysObj.days.indexOf(date.getDay) !== -1) return true;
    if (daysObj.start && daysObj.end && date <= daysObj.end && date >= daysObj.start) return true;
};

export default {
    shiftMonth,
    dateOccursIn,
    buildCalendar,
    firstDateOfMonth,
    lastDateOfMonth,
    filterEventsForDate
};