<template>
  <div class="vue-calendar">
    <CalendarHeader
      :month-start="monthStart"
      @month-changed="monthChanged"
    />

    <CalendarBody
      :events="events"
      :calendar="calendar"
      :month-start="monthStart"
    />
  </div>
</template>

<script>
  import CalendarHeader from './CalendarHeader';
  import CalendarBody from './CalendarBody';
  import calendarJs from '../../helpers/calendar';
  import moment from 'moment';

  export default {
    name: 'Calendar',
    components: { CalendarHeader, CalendarBody },
    props: {
      monthStart: {
        type: Date,
        default: function () {
          return calendarJs.firstDateOfMonth();
        },
      },
    },
    data() {
      return {
        calendar: [],
        events: [],
        start: this.monthStart,
      };
    },
    beforeMount() {
      this.calendar = calendarJs.buildCalendar(this.start, 1);
      this.loadAvailabilities(this.start);
    },
    methods: {
      init() {
        this.events = this.getEvents;
      },
      monthChanged(date) {
        this.start = date;
        this.calendar = calendarJs.buildCalendar(this.start, 1);
        this.loadAvailabilities(this.start);
      },
      async loadAvailabilities(date) {
        this.$store.dispatch('appLoaderShow');
        try {
          let startCalendar = calendarJs.firstDateOfMonth(date);
          let endCalendar = calendarJs.lastDateOfMonth(date);
          await this.$store.dispatch('practitionerFetchAvailabilities', {
            start: moment(startCalendar).format('YYYY-MM-DD'),
            end: moment(endCalendar).format('YYYY-MM-DD'),
          });
          await this.init();
        } finally {
          this.$store.dispatch('appLoaderHide');
        }
      },
    },
    computed: {
      getEvents() {
        if (this.$store.state.availability.availabilities) {
          return this.$store.state.availability.availabilities;
        }
      },
    },
    watch: {
      height(newHeight) {
        this.height = newHeight;
      },
      $route(to) {
        if (to.name === 'availabilities') {
          this.loadAvailabilities(this.start);
        }
      },
    },
  };
</script>

<style lang="scss">
  .vue-calendar {
    font-size: 1.5rem;
    font-weight: 300;
    background: #fff;
    margin: 0 auto;
  }
</style>
