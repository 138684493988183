import api from './';
import { normalizePatientSearchResult, normalizePatientSearchResults } from '@/helpers/normalizers';

export default {
  search: (query, signal) => {
    return api.get('patients', {
      searchParams: new URLSearchParams({ q: query }), signal,
    }).json().then(response => normalizePatientSearchResults(response.data));
  },
  fetchOne: (id) => api.get(`patients/${id}`).json().then(normalizePatientSearchResult),
  fetchAll: () => api.get('patients').json().then(response => normalizePatientSearchResults(response.data)),
  update: (data, id) => api.put(`patients/${id}`, {
    json: {
      id: id,
      ssn: data.ssn,
      mutualName: data.mutualName,
      mutualNumber: data.mutualNumber,
      birth_date: data.birthDate,
      blood_group: data.bloodGroup,
      height: data.height,
      weight: data.weight,
      gender: data.gender,
      measure: data.measure
    }
  }).json().then(normalizePatientSearchResult)
};
