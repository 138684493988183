import { toLower, toUpper, words } from 'lodash-es';
import moment from 'moment';

export function capitalize(value) {
  return toLower(value).replace(/(^|[ '-])[^ '-]/g, (x) => x.toLocaleUpperCase());
}

export function getFullName(firstName, lastName) {
  return `${capitalize(firstName)} ${toUpper(lastName)}`
}

export function formatCivility(civility, short = false) {
  switch (civility) {
    case 'dr':
      return short ? 'Dr.' : 'Docteur';
    case 'dre':
      return short ? 'Dre.' : 'Docteure';
    case 'pr':
      return short ? 'Pr.' :  'Professeur';
    case 'pre':
      return short ? 'Pre.' :  'Professeure';
    case 'm':
      return short ? 'M.' :  'Monsieur';
    case 'mme':
      return short ? 'Mme' :  'Madame';
    default:
      return '';
  }
}

export function age(birthDate) {
  const years = moment().diff(birthDate, 'years');
  const months = moment().diff(birthDate, 'months');
  const days = moment().subtract(months, 'months').diff(birthDate, 'days');

  if (months >= 6 && months <=36){
    return `${months} mois`;
  }
  if (months >=1 && months < 6) {
    return `${months} mois ${days} jours`;
  }
  if (months < 1){
    return `${days} jours`;
  }
  if (months > 36) {
    return `${years} ans`;
  }
  return '0 jour'
}

export function getInitials(fullName) {
  return words(fullName)
    .map(word => word.charAt(0).toUpperCase())
    .join('');
}

export const convertDateToDash = (date) => {
  if (date) {
    return date
      .split('/')
      .reverse()
      .join('-');
  }

  return null;
};

export const convertDateToSlash = (date) => {
  if (date) {
    return date
      .split('-')
      .reverse()
      .join('/');
  }

  return null;
};


export const appointmentStatusClass = (status, canBeStarted) => {
  switch (status) {
    case 'CONFIRMED':
      return canBeStarted ? 'available' : 'confirmed';
    case 'CLOSED':
      return 'closed';
    case 'TERMINATED':
      return 'terminated';
    case 'NEW_BY_PATIENT':
    case 'NEW_BY_PRACTITIONER':
      return 'pending';
    case 'CANCELLED_BY_PATIENT':
    case 'CANCELLED_BY_PRACTITIONER':
      return 'cancelled';
    default:
      return null;
  }
}
