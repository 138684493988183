var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment
    ? _c(
        "div",
        { staticClass: "appointment-panel-details" },
        [
          _vm.isCancelledByPatient
            ? _c("div", { staticClass: "cancelled" }, [
                _vm._v("\n    Rendez-vous annulé par le patient\n  ")
              ])
            : _vm.isCancelledByPractitioner
            ? _c("div", { staticClass: "cancelled" }, [
                _vm._v("\n    Vous avez annulé ce RDV\n  ")
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.calendar, "fixed-width": "" }
              }),
              _c("strong", [_vm._v(_vm._s(_vm.appointmentDate))])
            ],
            1
          ),
          !_vm.isProductReplacementFormVisible
            ? [
                _c(
                  "div",
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.clock, "fixed-width": "" }
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.appointment.time))]),
                    _c("span", { staticClass: "duration" }, [
                      _vm._v("\n        Durée de l'acte : "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.appointment.payload.product.duration) +
                            " min"
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.eye, "fixed-width": "" }
                    }),
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("channel")(_vm.product.channel)))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.stethoscope, "fixed-width": "" }
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.productName))]),
                    _vm.canReplaceProduct
                      ? _c("span", { staticClass: "replace-product" }, [
                          _vm._v("\n        ( "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.isProductReplacementFormVisible = true
                                }
                              }
                            },
                            [_vm._v("Modifier")]
                          ),
                          _vm._v(" )\n      ")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _c(
                "form",
                {
                  staticClass: "product-replacement-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.replaceProduct($event)
                    }
                  }
                },
                [
                  _c(
                    "FormWidget",
                    [
                      _c(
                        "label",
                        { attrs: { for: "product-replacement-id" } },
                        [_vm._v("Type d'acte")]
                      ),
                      _c("SelectWrapper", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productReplacementId,
                                expression: "productReplacementId"
                              }
                            ],
                            attrs: { id: "product-replacement-id" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.productReplacementId = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.products, function(product) {
                            return _c(
                              "option",
                              { domProps: { value: product.id } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(product.name) +
                                    " (" +
                                    _vm._s(_vm._f("channel")(product.channel)) +
                                    ")\n          "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "product-replacement-actions" },
                    [
                      _c(
                        "ButtonGreen",
                        {
                          attrs: {
                            busy: _vm.isReplacingProduct,
                            "no-border": "",
                            outlined: "",
                            small: "",
                            type: "submit"
                          }
                        },
                        [_vm._v("\n        Valider\n      ")]
                      ),
                      _c(
                        "ButtonRed",
                        {
                          attrs: {
                            busy: _vm.isReplacingProduct,
                            "no-border": "",
                            outlined: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isProductReplacementFormVisible = false
                            }
                          }
                        },
                        [_vm._v("\n        Annuler\n      ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "div",
            [
              _c("AppointmentStatusIcon", { attrs: { status: _vm.status } }),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("appointmentStatus")(_vm.status, _vm.canBeStarted)
                  )
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.wallet, "fixed-width": "" }
              }),
              _vm.isPaid
                ? _c("strong", [_vm._v("Paiement effectué")])
                : _vm.isAuthorized
                ? _c("strong", [_vm._v("Pré-autorisation effectuée")])
                : _c("strong", [_vm._v("Aucun paiement")])
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }