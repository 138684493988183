<template>
  <ButtonGrey :busy="isBusy" :icon="$icons.goBack" icon-to-left outlined @click="close">
    <slot>Annuler</slot>
  </ButtonGrey>
</template>

<script>
  import ButtonGrey from '@/components/Button/ButtonGrey.vue';

  export default {
    name: 'CancelButton',
    components: { ButtonGrey },
    inject: ['appConfirmationClose', 'appConfirmationStatus'],
    computed: {
      isBusy() {
        return this.appConfirmationStatus.isBusy;
      },
    },
    methods: {
      close() {
        this.appConfirmationClose();
      },
    },
  };
</script>
