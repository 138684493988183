var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "profile-act-page" },
      [
        _c("ProfileTabBar"),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col _12 information" }, [
                _vm._v(
                  "\n          Définissez ici vos tarifs en fonction de vos types de consultations\n        "
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col _12" },
                [
                  _c(
                    "ButtonGreen",
                    {
                      staticClass: "add-act",
                      attrs: { outlined: "", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.newProduct($event)
                        }
                      }
                    },
                    [_vm._v("\n            Ajouter un tarif\n          ")]
                  )
                ],
                1
              )
            ]),
            _c("hr"),
            _c("AppConfirmation", {
              ref: "deleteProduct",
              attrs: { title: "Êtes-vous sûr de vouloir supprimer ce tarif ?" },
              on: { confirm: _vm.deleteProduct }
            }),
            _c(
              "SlickList",
              {
                staticClass: "products-list",
                class: { "is-reordering": _vm.isReordering },
                attrs: {
                  "should-cancel-start": _vm.shouldCancelOrdering,
                  "lock-axis": "y",
                  "use-drag-handle": ""
                },
                on: { "sort-end": _vm.handleSortEnd },
                model: {
                  value: _vm.products,
                  callback: function($$v) {
                    _vm.products = $$v
                  },
                  expression: "products"
                }
              },
              _vm._l(_vm.products, function(product, index) {
                return _c(
                  "SlickItem",
                  {
                    key: product.id,
                    staticClass: "row act",
                    attrs: { index: index }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [{ name: "handle", rawName: "v-handle" }],
                        staticClass: "col _10 handle"
                      },
                      [
                        _c("h2", [
                          _vm._v(_vm._s(product.name) + " "),
                          _c("small", { staticClass: "channel" }, [
                            _vm._v(
                              "- " + _vm._s(_vm._f("channel")(product.channel))
                            )
                          ])
                        ]),
                        _c("p", { staticClass: "details" }, [
                          _c("span", { staticClass: "price" }, [
                            _vm._v(_vm._s(_vm._f("money")(product.unitPrice)))
                          ]),
                          _c("span", { staticClass: "duration" }, [
                            _vm._v(_vm._s(product.duration) + " min")
                          ]),
                          !product.visible
                            ? _c(
                                "span",
                                { staticClass: "hidden" },
                                [
                                  _c("FontAwesomeIcon", {
                                    attrs: { icon: _vm.$icons.hidden }
                                  }),
                                  _vm._v(
                                    "\n                Non visible par le patient\n              "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col _2 actions" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "icon",
                          attrs: { icon: _vm.$icons.edit },
                          on: {
                            click: function($event) {
                              return _vm.editProduct(product)
                            }
                          }
                        }),
                        _c("FontAwesomeIcon", {
                          staticClass: "icon",
                          attrs: { icon: _vm.$icons.removeProduct },
                          on: {
                            click: function($event) {
                              return _vm.$confirm(
                                _vm.$refs.deleteProduct,
                                product.id
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("router-view", {
          attrs: { "selected-product": _vm.selectedProduct }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }