var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("main", [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("PatientTabBar", {
            attrs: { "patient-id": _vm.$route.params.id }
          }),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col _7" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.clock } }, [
                  _vm._v("Historique des rendez-vous")
                ]),
                _vm.$route.params.id
                  ? _c("AppointmentList", {
                      attrs: { "patient-id": _vm.$route.params.id }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "col _1" }),
            _c(
              "div",
              { staticClass: "col _4" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.calendar } }, [
                  _vm._v("Nouveau rendez-vous")
                ]),
                !_vm.isLoadingPatient
                  ? _c("NewAppointmentForm", {
                      attrs: { "forced-patient": _vm.patient }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }