import Conventions from '../../api/conventions';

export default {
  state: {
    all: [],
    isFetchingAll: false,
  },
  mutations: {
    CONVENTIONS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    CONVENTIONS_FETCH_ALL_SUCCESS(state, { conventions }) {
      state.isFetchingAll = false;
      state.all = conventions;
    },
    CONVENTIONS_FETCH_ALL_FAIL(state) {
      state.isFetchingAll = false;
    },
  },
  actions: {
    async conventionsFetchAll({ commit }) {
      commit('CONVENTIONS_FETCH_ALL');
      try {
        const conventions = await Conventions.fetchAll();
        commit('CONVENTIONS_FETCH_ALL_SUCCESS', { conventions });
      } catch (e) {
        commit('CONVENTIONS_FETCH_ALL_FAIL');
        throw e;
      }
    },
  },
};
