import { getLocalStorage, setLocalStorage } from '../../helpers/tools';

const MINIMUM_MINUTE_TO_PIXELS_RATIO = 160;
const MAXIMUM_MINUTE_TO_PIXELS_RATIO = 520;
const MINUTE_TO_PIXELS_RATIO_STEP = 120;

export default {
  state: {
    minuteToPixelsRatio: MINIMUM_MINUTE_TO_PIXELS_RATIO,
  },
  mutations: {
    AGENDA_SET_MINUTE_TO_PIXELS_RATIO(state, { ratio }) {
      state.minuteToPixelsRatio = ratio;
    },
  },
  actions: {
    agendaSetMinuteToPixelsRatio({ commit }, { ratio }) {
      if (typeof ratio !== 'number') {
        throw 'Change minuteToPixelsRatio error: Not a number';
      }

      if (ratio < MINIMUM_MINUTE_TO_PIXELS_RATIO || ratio > MAXIMUM_MINUTE_TO_PIXELS_RATIO) {
        throw 'Change minuteToPixelsRatio error: Not in range';
      }

      setLocalStorage('hc-m2px', ratio);

      commit('AGENDA_SET_MINUTE_TO_PIXELS_RATIO', { ratio });
    },
    agendaLoadMinuteToPixelsRatioFromLocalStorage({ dispatch }) {
      const ratio = getLocalStorage('hc-m2px');

      if (ratio) {
        dispatch('agendaSetMinuteToPixelsRatio', { ratio });
      }
    },
    agendaIncreaseMinuteToPixelsRatio({ dispatch, state }) {
      dispatch('agendaSetMinuteToPixelsRatio', { ratio: state.minuteToPixelsRatio + MINUTE_TO_PIXELS_RATIO_STEP });
    },
    agendaDecreaseMinuteToPixelsRatio({ dispatch, state }) {
      dispatch('agendaSetMinuteToPixelsRatio', { ratio: state.minuteToPixelsRatio - MINUTE_TO_PIXELS_RATIO_STEP });
    },
  },
  getters: {
    agendaPixelsSinceMidnight(state, getters) {
      return getters.minutesSinceMidnight * state.minuteToPixelsRatio / 100;
    },
    agendaCanDecreaseMinuteToPixelsRatio(state) {
      return (state.minuteToPixelsRatio - MINUTE_TO_PIXELS_RATIO_STEP) >= MINIMUM_MINUTE_TO_PIXELS_RATIO;
    },
    agendaCanIncreaseMinuteToPixelsRatio(state) {
      return (state.minuteToPixelsRatio + MINUTE_TO_PIXELS_RATIO_STEP) <= MAXIMUM_MINUTE_TO_PIXELS_RATIO;
    },
  },
};
