export default {
  state: {
    isVisible: 0
  },
  mutations: {
    'APP_LOADER_SET_VISIBLE'(state, { visible }) {
      state.isVisible += visible;
    },
  },
  actions: {
    appLoaderShow({ commit }) {
      commit('APP_LOADER_SET_VISIBLE', { visible: 1 });
    },
    appLoaderHide({ commit }) {
      commit('APP_LOADER_SET_VISIBLE', { visible: -1 });
    },
  }
};
