var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "form-radio" },
    [
      _vm._t("default"),
      _c("input", {
        attrs: { type: "radio" },
        domProps: { checked: _vm.checked, value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("change", _vm.value)
          }
        }
      }),
      _vm._m(0)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "radio" }, [
      _c("span", { staticClass: "radio-mark" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }