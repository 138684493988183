var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signature-pad" },
    [
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "Signez dans l’encart ci-dessous à l’aide de la souris ou d’un appareil tactile."
        )
      ]),
      _c("VueSignaturePad", {
        ref: "pad",
        staticClass: "pad",
        attrs: { height: "20rem", options: { onEnd: _vm.handleDrawingEnd } }
      }),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _vm.hasDrawing
            ? _c(
                "ButtonRed",
                {
                  attrs: { outlined: "", small: "" },
                  on: { click: _vm.clear }
                },
                [_vm._v("Effacer")]
              )
            : _c(
                "ButtonRed",
                {
                  attrs: { outlined: "", small: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Annuler")]
              ),
          _vm.hasDrawing
            ? _c(
                "ButtonGreen",
                { attrs: { small: "" }, on: { click: _vm.save } },
                [_vm._v("Valider")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "text" }, [
        _vm._v("\n    Vous ne réussissez pas à tracer votre signature ?"),
        _c("br"),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.uploadFile($event)
              }
            }
          },
          [_vm._v("Envoyez une photo")]
        ),
        _vm._v(" ou utilisez notre application mobile sur\n    "),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://play.google.com/store/apps/details?id=com.hellocare.connect.pro",
              target: "_blank"
            }
          },
          [_vm._v("Android")]
        ),
        _vm._v(" ou\n    "),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://apps.apple.com/fr/app/hellocare-pro-télémédecine/id1489699546",
              target: "_blank"
            }
          },
          [_vm._v("iOS")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }