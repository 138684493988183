var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenNotification",
    {
      attrs: {
        icon: _vm.$icons.thumbsUp,
        title: "Demande de rendez-vous refusée"
      },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function() {
            return [
              _c(
                "ButtonGreen",
                { attrs: { "link-to": { name: "dashboard" } } },
                [_vm._v("Continuer")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", [
        _vm._v("Vous avez refusé le rendez-vous demandé par votre patient.")
      ]),
      _c("p", [_vm._v("Un mail lui sera envoyé.")]),
      _c("p", [
        _vm._v(
          "\n    Pour fixer un nouveau rendez-vous, nous vous invitons à faire votre demande depuis votre cabinet virtuel"
        ),
        _c("br"),
        _vm._v("\n    ou à prendre contact directement avec votre patient.\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }