import Patients from '../../api/patients';

export default {
  state: {
    current: null,
    all: [],
    isFetchingOne: false,
    isFetchingAll: false,
    isUpdatingPatient: false
  },
  mutations: {
    PATIENTS_FETCH_ONE(state) {
      state.isFetchingOne = true;
    },
    PATIENTS_FETCH_ONE_SUCCESS(state, { patient }) {
      state.isFetchingOne = false;
      state.current = patient;
    },
    PATIENTS_FETCH_ONE_FAIL(state) {
      state.isFetchingOne = false;
    },
    PATIENTS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    PATIENTS_FETCH_ALL_SUCCESS(state, { patients }) {
      state.isFetchingAll = false;
      state.all = patients;
    },
    PATIENTS_FETCH_ALL_FAIL(state) {
      state.isFetchingAll = false;
    },
    PATIENTS_EDIT_PROFILE(state) {
      state.isUpdatingPatient = true;
    },
    PATIENTS_EDIT_PROFILE_SUCCESS(state) {
      state.isUpdatingPatient = false;
    },
    PATIENTS_EDIT_PROFILE_FAIL(state) {
      state.isUpdatingPatient = false;
    },
  },
  actions: {
    async patientsFetchOne({ commit }, { id }) {
      commit('PATIENTS_FETCH_ONE');
      try {
        const patient = await Patients.fetchOne(id);
        commit('PATIENTS_FETCH_ONE_SUCCESS', { patient });
      } catch (e) {
        commit('PATIENTS_FETCH_ONE_FAIL');
        throw e;
      }
    },
    async patientsFetchAll({ commit }) {
      commit('PATIENTS_FETCH_ALL');
      try {
        const patients = await Patients.fetchAll();
        commit('PATIENTS_FETCH_ALL_SUCCESS', { patients });
      } catch (e) {
        commit('PATIENTS_FETCH_ALL_FAIL');
        throw e;
      }
    },
    async patientEditProfile({ commit }, {data, id}) {
      commit('PATIENTS_EDIT_PROFILE');
      try {
        const patient = await Patients.update(data, id);
        commit('PATIENTS_EDIT_PROFILE_SUCCESS');
        commit('PATIENTS_FETCH_ONE_SUCCESS', { patient })
      } catch (e) {
        commit('PATIENTS_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
  },
};
