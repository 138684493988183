import Plans from '../../api/plans';

export default {
  state: {
    all: [],
    isFetchingAll: false,
  },
  mutations: {
    PLANS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    PLANS_FETCH_ALL_SUCCESS(state, { plans }) {
      state.isFetchingAll = false;
      state.all = plans;
    },
    PLANS_FETCH_ALL_FAIL(state) {
      state.isFetchingAll = false;
    },
  },
  actions: {
    async plansFetchAll({ commit }) {
      commit('PLANS_FETCH_ALL');
      try {
        const plans = await Plans.fetchAll();
        commit('PLANS_FETCH_ALL_SUCCESS', { plans });
      } catch (e) {
        commit('PLANS_FETCH_ALL_FAIL');
        throw e;
      }
    },
  },
};
