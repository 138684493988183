<template>
  <AppLayout :header="false" :footer="false">
    <div class="close-button">
      <router-link :to="{ name: 'agenda', query: { 'appointment-detail': appointmentId } }">
        Fermer <FontAwesomeIcon :icon="$icons.closePanel" />
      </router-link>
    </div>
    <main class="consultation-report" v-if="!isLoading">
      <SectionTitle center>Rapport de consultation</SectionTitle>
      <EditProfil :patient="patient" :picture="false" />
      <hr/>
      <Observations :patient="patient"/>
        <hr/>
      <form @submit.prevent novalidate>
        <FormWidget>
          <label for="motives">Motif de consultation *</label>
          <AutoHeightTextarea
              id="motives"
              v-model.trim="form.motives"
              @blur="$v.form.motives.$touch()"
          />
          <template v-slot:error v-if="$v.form.motives.$error">
          <span v-if="!$v.form.motives.required">
            Veuillez renseigner le motif de consultation
          </span>
          </template>
        </FormWidget>
        <FormWidget>
          <label for="medical-observation">Observation médicale *</label>
          <AutoHeightTextarea
              id="medical-observation"
              v-model.trim="form.medicalObservation"
              @blur="$v.form.medicalObservation.$touch()"
          />
          <template v-slot:help>
            Ce champ est uniquement visible par les professionnels de santé.
            Le patient n'aura pas accès à cette information.
          </template>
          <template v-slot:error v-if="$v.form.medicalObservation.$error">
            <span v-if="!$v.form.medicalObservation.required">Veuillez renseigner l'observation médicale</span>
          </template>
        </FormWidget>
        <FormWidget>
          <label for="patient-advice">Conseils au patient</label>
          <AutoHeightTextarea id="patient-advice" v-model.trim="form.patientAdvice" />
        </FormWidget>
        <hr>
        <PrescriptionModule
            v-if="encounter && appointment && !isNurse"
            :birth-date="this.patient.birthDate"
        />
        <hr>
        <div class="claim-form-action" v-if="claimFormEnabled && !isNurse">
          <span @click.prevent="showClaimForm">
            <FontAwesomeIcon
                :icon="claimFormReloading ? $icons.spinner : $icons.claimForm"
                fixed-width
                :spin="claimFormReloading"
            />
            <span v-if="claimFormReloading">Vérification de la feuille de soins en cours…</span>
            <span v-else-if="hasClaim">Modifier la feuille de soins</span>
            <span v-else>Ajouter une feuille de soins</span>
          </span>
        </div>
        <div v-if="!isNurse">
          <ButtonBlue :link-to="olaqinLink">Feuille de soins Stellair*</ButtonBlue><br/>
          <span class="mb-5">*Cette fonctionnalité est en version bêta et nécessite un compte Olaqin Stellair.</span>
        </div>
        <div class="total-amount" v-if="isPaymentActivated">
          <FormWidget>
            <label for="amount">Montant total de l'acte (€)</label>
            <input
                id="amount"
                :disabled="isOrderPaid"
                :value="price"
                @input="price = $inputToPrice($event.target.value)"
            >
          </FormWidget>
        </div>
        <div class="actions" v-if="!this.isOrderPaid && isPaymentActivated">
          <ButtonGreen
              :busy="isSendingReport"
              @click="handleSubmit({ createOrder: true, charge: true })"
              :icon="$icons.encounterReportSendAndBill"
              icon-to-left
              v-if="price > 0"
          >
            Envoyer et facturer
          </ButtonGreen>
          <ButtonGreen
              :busy="isSendingReport"
              @click="handleSubmit({ createOrder: true, charge: false })"
              :outlined="price > 0"
              :no-border="price > 0"
          >
            Envoyer sans facturer
          </ButtonGreen>
        </div>
        <div class="actions" v-else>
          <ButtonGreen
              :busy="isSendingReport"
              @click="handleSubmit({ createOrder: false })"
          >
            Envoyer les documents
          </ButtonGreen>
        </div>
      </form>
      <div class="claim-form-container" v-if="claimFormEnabled && claimFormVisible && !isNurse">
        <SectionTitle center>Feuille de soin électronique</SectionTitle>
        <ClaimForm
            @close="claimFormVisible = false"
            @success="handleClaimFormSuccess"
            :encounter-id="encounter.id"
            :edited-claim="editedClaim"
            :patient="patient"
            :appointment-date="appointment.startAt"
        />
      </div>
    </main>
  </AppLayout>
</template>

<script>
import EditProfil from '@/components/EditProfil.vue';
import SectionTitle from '@/components/SectionTitle';
import FormWidget from '@/components/Form/FormWidget';
import ButtonGreen from '@/components/Button/ButtonGreen.vue';
import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
import { required } from '@byscripts/vuelidate/lib/validators';
import ClaimForm from '@/components/Consultation/ClaimForm';
import AppLayout from '@/components/AppLayout';
import * as Sentry from '@sentry/browser';
import { getSetting } from '@/helpers/tools';
import PrescriptionModule from '@/components/PrescriptionModule';
import Observations from "@/components/Consultation/CallingInterface/Observations.vue";
import ButtonBlue from "@/components/Button/ButtonBlue.vue";
import BuildUrl from "@/Builder/BuildUrl";
import {v4 as uuidv4} from 'uuid';

export default {
  name: 'ConsultationReportPage',
  components: {
      EditProfil,
      PrescriptionModule,
      AppLayout,
      AutoHeightTextarea,
      ClaimForm,
      ButtonGreen,
      ButtonBlue,
      FormWidget,
      SectionTitle,
      Observations
  },
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      form: {
        beneficiaryId: null,
        motives: null,
        medicalObservation: null,
        patientAdvice: null
      },
      claimFormVisible: false,
      claimFormReloading: false,
      editedClaim: {},
      price: 0,
    };
  },
  validations: {
    form: {
      motives: { required },
      medicalObservation: { required },
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    isNurse() {
      return this.$store.state.practitioner.profile.type === 'nurse';
    },
    isSendingReport() {
      return this.$store.state.encounters.isSendingReport || this.$store.state.appointments.isClosing;
    },
    patient() {
      return this.appointment.patient;
    },
    practitioner() {
      return this.$store.state.practitioner.profile;
    },
    encounter() {
      return this.$store.state.encounters.current;
    },
    appointment() {
      return this.$store.state.appointments.current;
    },
    order() {
      return this.appointment.order;
    },
    isOrderPaid() {
      return this.order?.paymentStatus === 'paid';
    },
    isPaymentActivated() {
      return this.practitioner.cardPaymentActivated && this.encounter?.isAuthorized;
    },
    claimFormEnabled() {
      return this.practitioner.uploadedSignature && this.practitioner.claimFormEnabled;
    },
    hasClaim() {
      return this.appointment.hasClaim;
    },
    localStorageReportKey() {
      return this.$isPrescriptionHelperEnabled ? `report-ph-${this.appointmentId}` : `report-${this.appointmentId}`;
    },
    olaqinLink() {
        const birthDateFormatted = this.formattedDate(this.patient.birthDate);
        const url = "https://fse.demo.stellair.fr/fse/";
        const olaqinId = uuidv4();
        const params = {
            'ticket_id': olaqinId,
            'prenom': this.patient.firstName,
            'nom': this.patient.lastName,
            'nir': this.patient.ssn,
            'dateNaissance': birthDateFormatted,
            'lireVitale': 'false',
            'sso': 'cognito',
        };

        this.updateEncounter(olaqinId);

        return BuildUrl(url, params);
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('appLoaderShow');
      try {
        this.isLoading = true;
        await this.$store.dispatch('appointmentsFetchOne', { id: this.appointmentId });

        const amount = this.isOrderPaid ? this.order.total : this.appointment.product.unitPrice;

        this.price = amount / 100;

        if (this.$hasLocalStorage(this.localStorageReportKey)) {
          const {
            beneficiaryId,
            medicalObservation,
            patientAdvice,
          } = this.$getLocalStorage(this.localStorageReportKey);

          this.form.beneficiaryId = beneficiaryId;
          this.form.medicalObservation = medicalObservation;
          this.form.patientAdvice = patientAdvice;
        }

        this.form.motives = this.appointment.description;

        const encounter = this.appointment.encounters?.[0];

        if (!encounter) {
          return;
        }

        await this.$store.dispatch('encountersFetchOne', { id: encounter.id });
        const { claims, motives, medicalObservation, patientAdvice } = this.encounter;
        this.editedClaim = claims[0] || {};

        if (motives) {
          this.form.motives = motives;
        }

        if (medicalObservation) {
          this.form.medicalObservation = medicalObservation;
        }

        if (patientAdvice) {
          this.form.patientAdvice = patientAdvice;
        }

        if (getSetting('SENTRY_ENABLED')) {
          Sentry.configureScope(scope => scope.setTags({
            appointment_id: this.appointment.id,
            patient_id: this.appointment.patient.id,
            product_id: this.appointment.product.id,
            encounter_id: this.encounter.id,
          }));
        }
      } catch (e) {
        this.$addError('Une erreur s\'est produite lors du chargement de la consultation.');
        throw e;
      } finally {
        await this.$store.dispatch('appLoaderHide');
        this.isLoading = false;
      }
    },
    showClaimForm() {
      if (!this.claimFormReloading) {
        this.claimFormVisible = true;
      }
    },
    async handleClaimFormSuccess() {
      this.claimFormVisible = false;
      this.claimFormReloading = true;
      try {
        await this.$store.dispatch('appointmentsFetchOne', { id: this.appointmentId });
        const encounter = this.appointment.encounters[0];
        await this.$store.dispatch('encountersFetchOne', { id: encounter.id });
        this.editedClaim = this.encounter.claims[0] || {};
      } catch (e) {
        throw e;
      } finally {
        this.claimFormReloading = false;
      }
    },
    async handleSubmit({ createOrder, charge }) {
      this.$v.$touch();

      if (this.$v.$anyError) {
        await this.$addError('Le formulaire contient des erreurs');
        return false;
      }

      await this.$store.dispatch('encountersSendReport', {
        appointmentId: this.appointmentId,
        encounterId: this.encounter.id,
        productId: this.appointment.product.id,
        productPrice: Math.round(this.price * 100),
        beneficiaryId: this.form.beneficiaryId,
        motives: this.form.motives,
        medicalObservation: this.form.medicalObservation,
        patientAdvice: this.form.patientAdvice || null,
        createOrder,
        charge,
      });

      if (!['CLOSED', 'PAYMENT_REQUIRED'].includes(this.appointment.status)) {
        await this.$store.dispatch('appointmentsClose', { id: this.appointmentId });
      }

      this.$removeLocalStorage(this.localStorageReportKey);

      this.form = {
        motives: null,
        medicalObservation: null,
        patientAdvice: null,
      };

      await this.$router.push({
        name: 'consultation-report-confirmed',
        query: {
          to: this.$route.query.to,
          appointmentId: this.appointmentId,
        }
      });
    },
    formattedDate(date) {
        const originalDate = new Date(date);
        return `${('0' + (originalDate.getDate() + 1)).slice(-2)}/${
            ('0' + originalDate.getMonth()).slice(-2)}/${
            originalDate.getFullYear()}`;
    },
    async updateEncounter(olaqinId) {
        await this.$store.dispatch(
            'encounterUpdate',
            {
                id: this.encounter.id,
                payload: { olaqinId }
            }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-report {
  max-width: 80rem;
  margin: 0 auto;
}

.actions {
  text-align: center;

  button {
    margin: 0 1rem;
  }
}

.claim-form-action {
  font-size: 1.5rem;
  padding: 0 0 2.3rem;
  color: $submitButtonColor;

  span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.claim-form-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: #FFFFFF;
  z-index: 1000;
  padding-top: 3rem;
}

.claim-form {
  max-width: 100rem;
  margin: 0 auto;
}

.total-amount {
  max-width: 20rem;
  text-align: center;
  margin: 2rem auto 0;

  ::v-deep input {
    text-align: center;
  }
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.6rem;

  a {
    color: $textColor;
    text-decoration: none;
  }
}
.pharmacy-checkbox{
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
