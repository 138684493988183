import Availabilities from '../../api-platform/availabilities';

export default {
  state: {
    isFetching: false,
    isCreating: false,
    availabilities: [],
  },
  mutations: {
    PRACTITIONER_FETCH_AVAILABILITIES(state) {
      state.isFetching = true;
    },
    PRACTITIONER_FETCH_AVAILABILITIES_SUCCESS(state, { availabilities }) {
      state.availabilities = availabilities;
      state.isFetching = false;
    },
    PRACTITIONER_FETCH_AVAILABILITIES_FAIL(state) {
      state.availabilities = [];
      state.isFetching = false;
    },
    PRACTITIONER_CREATE_AVAILABILITIES(state) {
      state.isCreating = true;
    }
    ,
    PRACTITIONER_CREATE_AVAILABILITIES_SUCCESS(state) {
    }
    ,
    PRACTITIONER_CREATE_AVAILABILITIES_FAIL(state) {
      state.isCreating = false;
    },
    PRACTITIONER_DETAILS_AVAILABILITIES(state) {
      state.isFetching = true;
    },
    PRACTITIONER_DETAILS_AVAILABILITIES_SUCCESS(state, { availabilities }) {
      state.availabilities = availabilities;
      state.isFetching = false;
    },
    PRACTITIONER_DETAILS_AVAILABILITIES_FAIL(state) {
      state.availabilities = [];
      state.isFetching = false;
    },
    PRACTITIONER_DELETE_AVAILABILITIES(state) {
      state.isCreating = true;
    }
    ,
    PRACTITIONER_DELETE_AVAILABILITIES_SUCCESS(state ) {
      state.isFetching = false;
    }
    ,
    PRACTITIONER_DELETE_AVAILABILITIES_FAIL(state) {
      state.isFetching = false;
    },
    PRACTITIONER_UPDATE_AVAILABILITIES(state) {
      state.isCreating = true;
    }
    ,
    PRACTITIONER_UPDATE_AVAILABILITIES_SUCCESS(state ) {
      state.isFetching = false;
    }
    ,
    PRACTITIONER_UPDATE_AVAILABILITIES_FAIL(state) {
      state.isFetching = false;
    }
  },
  actions: {
    async practitionerFetchAvailabilities({ commit, rootState }, { start, end, smart }) {
      commit('PRACTITIONER_FETCH_AVAILABILITIES');
      try {
        const availabilities = await Availabilities.list(rootState.practitioner.profile.id, start, end, smart);
        commit('PRACTITIONER_FETCH_AVAILABILITIES_SUCCESS', { availabilities });
      } catch (e) {
        commit('PRACTITIONER_FETCH_AVAILABILITIES_FAIL');
        throw e;
      }
    },
    async practitionerCreateAvailabilities({ commit, rootState }, data) {
      commit('PRACTITIONER_CREATE_AVAILABILITIES');
      try {
        await Availabilities.create(rootState.practitioner.profile.id, data);
        commit('PRACTITIONER_CREATE_AVAILABILITIES_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_CREATE_AVAILABILITIES_FAIL');
        throw e;
      }
    },
    async practitionerDetailsAvailabilities({ commit, rootState }, {date, type = null}) {
      commit('PRACTITIONER_DETAILS_AVAILABILITIES');
      try {
        let availabilities = await Availabilities.details(rootState.practitioner.profile.id, date, type);
        commit('PRACTITIONER_DETAILS_AVAILABILITIES_SUCCESS', { availabilities });
      } catch (e) {
        commit('PRACTITIONER_DETAILS_AVAILABILITIES_FAIL');
        throw e;
      }
    },
    async practitionerDeleteAvailabilities({ commit, rootState }, data) {
      commit('PRACTITIONER_DELETE_AVAILABILITIES');
      try {
        await Availabilities.delete(rootState.practitioner.profile.id, data);
        commit('PRACTITIONER_DELETE_AVAILABILITIES_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_DELETE_AVAILABILITIES_FAIL');
        throw e;
      }
    },
    async practitionerUpdateAvailabilities({ commit, rootState }, data) {
      commit('PRACTITIONER_UPDATE_AVAILABILITIES');
      try {
        await Availabilities.update(rootState.practitioner.profile.id, data);
        commit('PRACTITIONER_UPDATE_AVAILABILITIES_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_UPDATE_AVAILABILITIES_FAIL');
        throw e;
      }
    },
  },
};
