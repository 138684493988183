<template>
  <div v-if="patient">
    <form @submit.prevent="handleSubmit">
      <GroupTitle :icon="$icons.myPatients">Informations personnelles</GroupTitle>
      <div>
        <div class="edit_profil_form">
          <div v-if="picture" class="d-flex">
            <UserAvatar :src="patient.picture" size="7rem" />
          </div>
          <div class="d-flex edit_profil_form__inputs">
            <FormWidget>
              <label for="civility">Civilité</label>
              <SelectWrapper>
                <select id="civility" v-model="form.gender">
                  <option :value="null" v-if="!form.gender" />
                  <option value="m">Monsieur</option>
                  <option value="mme">Madame</option>
                </select>
              </SelectWrapper>
            </FormWidget>
            <FormText :value="patient.email" label="Email" disabled />
          </div>
          <div class="d-flex edit_profil_form__inputs">
            <FormText :value="patient.firstName" label="Prénom" disabled />
            <FormText :value="patient.lastName"  label="Nom" disabled />
          </div>
          <div class="d-flex edit_profil_form__inputs">
            <FormText :value="patient.phone | phone"  label="Téléphone" disabled />
            <FormWidget>
              <label for="birth-date">Date de naissance</label>
              <input
                  id="birth-date"
                  type="text"
                  v-mask="'##/##/####'"
                  v-model="form.birthDate"
                  placeholder="jj/mm/aaaa"
              >
              <template v-slot:error v-if="$v.form.birthDate.$error">
                <span v-if="$v.form.birthDate.$error">
                  La date est incorrecte
                </span>
              </template>
            </FormWidget>
          </div>

        </div>
        <div class="edit_profil_form">
          <GroupTitle :icon="$icons.myPatients">Sécurité sociale et mutuelle</GroupTitle>
          <div class="d-flex edit_profil_form__inputs">
            <FormWidget>
              <label for="ssn">N° de sécurité sociale</label>
              <input @input="form.ssn" v-model="form.ssn" type="text" id="ssn" />
              <template v-slot:error v-if="$v.form.ssn.$error">
                <span v-if="$v.form.ssn.$error">
                  Veuillez respecter le format du numéro de sécurité sociale
                </span>
              </template>
            </FormWidget>
          </div>
          <div class="d-flex edit_profil_form__inputs">
            <FormWidget>
              <label for="mutualName">Nom de la mutuelle</label>
              <input @input="form.mutualName" v-model="form.mutualName" type="text" id="mutualName" />
              <template v-slot:error v-if="$v.form.mutualName.$error">
                <span v-if="$v.form.mutualName.$error">
                  Nom de la mutuelle invalide
                </span>
              </template>
            </FormWidget>
            <FormWidget>
              <label for="mutualName">Numéro de mutuelle</label>
              <input @input="form.mutualNumber" v-model="form.mutualNumber" type="text" id="mutualName" />
              <template v-slot:error v-if="$v.form.mutualNumber.$error">
                <span v-if="$v.form.mutualNumber.$error">
                  Numéro de mutuelle invalide
                </span>
              </template>
            </FormWidget>
          </div>
        </div>
        <div class="edit_profil_form">
          <GroupTitle :icon="$icons.myPatients">Autres informations</GroupTitle>
          <div class="d-flex edit_profil_form__inputs">
              <FormWidget>
                <label for="height">Taille (cm)</label>
                <input id="height" type="text" v-model="form.height" @input="form.height = $inputToHeight($event)" />
                <template v-slot:error v-if="$v.form.height.$error">
                <span v-if="$v.form.height.$error">
                  La valeur est incorrecte
                </span>
                </template>
              </FormWidget>
              <FormWidget>
                <label for="weight">Poids (kg)</label>
                <input id="weight" type="text" v-model="form.weight" @input="form.weight = $inputToWeight($event)" />
                <template v-slot:error v-if="$v.form.weight.$error">
                <span v-if="$v.form.weight.$error">
                  La valeur est incorrecte
                </span>
                </template>
              </FormWidget>
          </div>
          <div class="d-flex edit_profil_form__inputs">
            <FormWidget>
              <label for="bloodGroup">Groupe sanguin</label>
              <input id="bloodGroup" type="text" v-model="form.bloodGroup">
            </FormWidget>
          </div>
        </div>
      </div>
      <ButtonGreen
          outlined
          small
          type="submit"
          class="edit"
      >
        Enregistrer
      </ButtonGreen>
    </form>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader';
import AppPanel from '@/components/AppPanel.vue';
import FormInput from '@/components/Form/FormInput.vue';
import FormText from '@/components/Form/FormText.vue';
import FormWidget from '@/components/Form/FormWidget';
import SelectWrapper from '@/components/Form/SelectWrapper.vue';
import GroupTitle from '@/components/GroupTitle.vue';
import AppLayout from '../components/AppLayout';
import HorizontalDivider from '../components/HorizontalDivider';
import SectionTitle from '../components/SectionTitle';
import UserAvatar from '../components/UserAvatar';
import moment from "moment";
import {age} from "@/helpers/format";
import ButtonGreen from "./Button/ButtonGreen.vue";
import { convertDateToDash } from '@/helpers/format';
import {checkDate, checkSsn, isNumeric} from "@/helpers/validation";

export default {
  name: 'PatientsPage',
  components: {
    ButtonGreen,
    FormText,
    FormInput,
    SelectWrapper,
    GroupTitle,
    AppPanel,
    FormWidget,
    AppLoader,
    UserAvatar,
    HorizontalDivider,
    SectionTitle,
    AppLayout,
  },
  props: {
    patient: {
      required: true,
      default:null,
    },
    picture: {
      default: true
    }
  },
  data() {
    return {
      filter: '',
      form: {
        ssn: null,
        mutualName: null,
        mutualNumber: null,
        birthDate: null,
        bloodGroup: null,
        height: null,
        weight: null,
        gender: null,
      },
      submitErrors: null
    };
  },
  validations: {
    form: {
      mutualName: {
      },
      mutualNumber: {
      },
      ssn: {
        format: checkSsn,
      },
      height: {
        isNumeric
      },
      weight: {
        isNumeric
      },
      birthDate: {
        checkDate
      },
    }
  },
  async beforeMount() {
    this.form = {
      mutualName: this.patient.mutualName,
      mutualNumber: this.patient.mutualNumber,
      ssn: this.patient.ssn,
      birthDate: this.patient.birthDate ? moment(this.patient.birthDate).format('DD/MM/YYYY') : null,
      bloodGroup: this.patient.bloodGroup,
      height: this.patient.height,
      weight: this.patient.weight,
      gender: this.patient.gender,
    }
  },
  computed: {
    isLoadingPatient() {
      return this.$store.state.patients.isFetchingOne;
    },
    birthDate() {
      return `${moment(this.patient.birthDate).format('DD/MM/YYYY')} (${age(this.patient.birthDate)})`;
    }
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch();
      let errors = [];

      if (this.$v.$anyError) {
        await this.$addError('Le formulaire pour modifier le patient contient des erreurs');
        return false;
      }

      const data = {
        ssn: this.form.ssn,
        mutualName: this.form.mutualName,
        mutualNumber: this.form.mutualNumber,
        birthDate: convertDateToDash(this.form.birthDate),
        bloodGroup: this.form.bloodGroup,
        height: parseInt(this.form.height),
        weight: parseFloat(this.form.weight),
        gender: this.form.gender,
      }
      const id =  this.patient.id;
      try {
        await this.$store.dispatch('patientEditProfile', {data,id});
        if (errors.length) {
          this.$addSuccess('Le reste du profil du patient a été mis à jour.');
        } else {
          this.$addSuccess('Le profil du patient a été mis à jour.');
        }
      } catch (e) {
        errors = ['Une erreur est survenue lors de la mise à jour du profil patient'];
        if (e.response) {
          this.submitErrors = await e.response.json();
        }
      }
      errors.forEach(error => this.$addError(error));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/grid";

.row {
  margin-bottom: 2rem;
}
.d-flex{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  @media (max-width: 991px) {
    margin: 0;
  }
  *{
    margin: 0;
  }
}
.patient-tab-bar {
  margin-bottom: 4rem;
}
.edit_profil_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  .edit_profil_form__inputs {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 1rem;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 1rem;
    }
    .form-widget,.form-text {
      width: 50%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
</style>
