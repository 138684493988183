<template>
  <div class="fullscreen-notification">
    <FontAwesomeIcon class="icon" size="5x" :icon="icon || $icons.info" />
    <SectionTitle light center>
      {{ title }}
    </SectionTitle>
    <div class="content">
      <slot />
    </div>
    <div class="buttons">
      <slot name="buttons" />
    </div>
    <div class="links">
      <slot name="links"/>
    </div>
  </div>
</template>

<script>
  import ButtonGrey from './Button/ButtonGrey.vue';
  import ButtonGreen from './Button/ButtonGreen.vue';
  import SectionTitle from './SectionTitle';

  export default {
    name: 'FullscreenNotification',
    components: { SectionTitle, ButtonGreen, ButtonGrey },
    props: {
      icon: {
        type: Object,
        required: false,
      },
      title: {
        type: String,
        required: true,
      },
      busy: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fullscreen-notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: $gradientBackground;
    text-align: center;
    z-index: 2100;

    hr {
      width: 14rem;
      border: none;
    }
  }

  .section-title::after {
    background-color: $lightTextColor;
  }

  .icon {
    color: $lightTextColor;
    margin-bottom: 4rem;
  }

  .content {
    color: $lightTextColor;
    margin-bottom: 3.5rem;
  }

  .buttons {
    display: flex;
    width: 40rem;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 3.5em;
    .base-button {
      width: 19rem;

      &:only-child {
        margin: 0 auto;
      }
    }
  }

  .links  {
    margin-right: 0;
    a {
      font-size: 1.2em;
      color: #fff !important;
      text-decoration: underline;
    }
  }
</style>
