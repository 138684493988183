import { groupBy, orderBy } from 'lodash-es';
import moment from 'moment';
import Appointments from '@/api/appointments';
import Prescriptions from '@/api-platform/prescriptions';
import { getFullName } from '@/helpers/format';

const withMeta = (appointment, state) => {
  if (!appointment) {
    return null;
  }

  const startAt = moment(appointment.startAt);
  const endAt = moment(appointment.endAt);

  return {
    payload: appointment,
    dayOfWeekShort: startAt.format('ddd'),
    dayOfWeek: startAt.format('dddd'),
    dateShort: startAt.format('DD/MM'),
    day: startAt.format('DD'),
    month: startAt.format('MM'),
    monthShort: startAt.format('MMM'),
    monthLong: startAt.format('MMMM'),
    year: startAt.format('YYYY'),
    time: startAt.format('HH:mm'),
    expired: endAt.isBefore(state.currentTime),
    duration: endAt.diff(startAt, 'minutes'),
  };
};

export default {
  state: {
    isFetchingOne: false,
    isFetchingAll: false,
    isFetchingPage: false,
    isCancelling: false,
    isAccepting: false,
    isCreating: false,
    isClosing: false,
    isTerminating: false,
    isUpdatingPrivateNote: false,
    isReplacingProduct: false,
    isReplacingPatient: false,
    isCreatingPrescription: false,
    isUpdatingPrescription: false,
    removingPrescriptionIds: {},
    current: null,
    all: [],
    found: [],
    page: 1,
    pages: 1,
  },
  mutations: {
    APPOINTMENTS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    APPOINTMENTS_FETCH_ALL_SUCCESS(state, { appointments }) {
      state.all = appointments;
      state.isFetchingAll = false;
    },
    APPOINTMENTS_FETCH_ALL_FAIL(state) {
      state.all = [];
      state.isFetchingAll = false;
    },
    APPOINTMENTS_FIND(state) {
      state.isFindingAll = true;
    },
    APPOINTMENTS_FIND_SUCCESS(state, { appointments }) {
      state.found = appointments;
      state.isFindingAll = false;
    },
    APPOINTMENTS_FIND_FAIL(state) {
      state.found = [];
      state.isFindingAll = false;
    },
    APPOINTMENTS_FETCH_ONE(state) {
      state.isFetchingOne = true;
    },
    APPOINTMENTS_FETCH_ONE_SUCCESS(state, { appointment }) {
      state.current = appointment;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_FETCH_ONE_FAIL(state) {
      state.current = null;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_FETCH_ONE_CONNECT(state) {
      state.isFetchingOne = true;
    },
    APPOINTMENTS_FETCH_ONE_CONNECT_SUCCESS(state, { appointment }) {
      state.current = appointment;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_FETCH_ONE_CONNECT_FAIL(state) {
      state.current = null;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_FETCH_PAGE(state) {
      state.isFetchingPage = true;
    },
    APPOINTMENTS_FETCH_PAGE_SUCCESS(state, { appointments, page, pages }) {
      state.all = appointments;
      state.page = page;
      state.pages = pages;
      state.isFetchingPage = false;
    },
    APPOINTMENTS_FETCH_PAGE_FAIL(state) {
      state.all = [];
      state.page = 1;
      state.pages = 1;
      state.isFetchingPage = false;
    },
    APPOINTMENTS_CANCEL(state) {
      state.isCancelling = true;
    },
    APPOINTMENTS_CANCEL_SUCCESS(state) {
      state.isCancelling = false;
    },
    APPOINTMENTS_CANCEL_FAIL(state) {
      state.isCancelling = false;
    },
    APPOINTMENTS_ACCEPT(state) {
      state.isAccepting = true;
    },
    APPOINTMENTS_ACCEPT_SUCCESS(state) {
      state.isAccepting = false;
    },
    APPOINTMENTS_ACCEPT_FAIL(state) {
      state.isAccepting = false;
    },
    APPOINTMENTS_CLEAR_CURRENT(state) {
      state.current = null;
    },
    APPOINTMENTS_CLOSE(state) {
      state.isClosing = true;
    },
    APPOINTMENTS_CLOSE_SUCCESS(state) {
      state.isClosing = false;
    },
    APPOINTMENTS_CLOSE_FAIL(state) {
      state.isClosing = false;
    },
    APPOINTMENTS_TERMINATE(state) {
      state.isTerminating = true;
    },
    APPOINTMENTS_TERMINATE_SUCCESS(state) {
      state.isTerminating = false;
    },
    APPOINTMENTS_TERMINATE_FAIL(state) {
      state.isTerminating = false;
    },
    APPOINTMENTS_UPDATE_PRIVATE_NOTE(state) {
      state.isUpdatingPrivateNote = true;
    },
    APPOINTMENTS_UPDATE_PRIVATE_NOTE_SUCCESS(state, { id, privateNote }) {
      if (state.current.id === id) {
        state.current.privateNote = privateNote;
      }

      state.isUpdatingPrivateNote = false;
    },
    APPOINTMENTS_UPDATE_PRIVATE_NOTE_FAIL(state) {
      state.isUpdatingPrivateNote = false;
    },
    APPOINTMENTS_REPLACE_PRODUCT(state) {
      state.isReplacingProduct = true;
    },
    APPOINTMENTS_REPLACE_PRODUCT_SUCCESS(state) {
      state.isReplacingProduct = false;
    },
    APPOINTMENTS_REPLACE_PRODUCT_FAIL(state) {
      state.isReplacingProduct = false;
    },
    APPOINTMENTS_REPLACE_PATIENT(state) {
      state.isReplacingPatient = true;
    },
    APPOINTMENTS_REPLACE_PATIENT_SUCCESS(state, { appointment }) {
      state.current = appointment;
      state.isReplacingPatient = false;
    },
    APPOINTMENTS_REPLACE_PATIENT_FAIL(state) {
      state.isReplacingPatient = false;
    },
    APPOINTMENTS_CREATE(state) {
      state.isCreating = true;
    },
    APPOINTMENTS_CREATE_SUCCESS(state) {
      state.isCreating = false;
    },
    APPOINTMENTS_CREATE_FAIL(state) {
      state.isCreating = false;
    },
    APPOINTMENTS_PRESCRIPTIONS_CREATE(state) {
      state.isCreatingPrescription = true;
    },
    APPOINTMENTS_PRESCRIPTIONS_CREATE_SUCCESS(state, { prescription }) {
      state.current.prescriptions.push(prescription);
      state.isCreatingPrescription = false;
    },
    APPOINTMENTS_PRESCRIPTIONS_CREATE_FAIL(state) {
      state.isCreatingPrescription = true;
    },
    APPOINTMENTS_PRESCRIPTIONS_UPDATE(state) {
      state.isUpdatingPrescription = true;
    },
    APPOINTMENTS_PRESCRIPTIONS_UPDATE_SUCCESS(state, { prescription }) {
      const index = state.current.prescriptions.findIndex(p => p.id === prescription.id);
      state.current.prescriptions[index] = prescription;
      state.isUpdatingPrescription = false;
    },
    APPOINTMENTS_PRESCRIPTIONS_UPDATE_FAIL(state) {
      state.isUpdatingPrescription = false;
    },
    APPOINTMENTS_PRESCRIPTIONS_REMOVE(state, { id }) {
      state.removingPrescriptionIds[id] = true;
    },
    APPOINTMENTS_PRESCRIPTIONS_REMOVE_SUCCESS(state, { id }) {
      const index = state.current.prescriptions.findIndex(p => p.id === id);
      state.current.prescriptions.splice(index, 1);
      state.removingPrescriptionIds[id] = undefined;
    },
    APPOINTMENTS_PRESCRIPTIONS_REMOVE_FAIL(state, { id }) {
      state.removingPrescriptionIds[id] = undefined;
    },
  },
  actions: {
    async appointmentsFetchPage({ commit }, { page = 1, patientId = undefined, byTransaction = null }) {
      commit('APPOINTMENTS_FETCH_PAGE');
      try {
        const result = await Appointments.getPage(page, patientId, byTransaction);
        commit('APPOINTMENTS_FETCH_PAGE_SUCCESS', { appointments: result.data, page: result.page, pages: result.pages });
      } catch (e) {
        commit('APPOINTMENTS_FETCH_PAGE_FAIL');
        throw e;
      }
    },
    /**
     * @param commit
     * @param {Moment} startDate
     * @param {Moment} endDate
     * @param {boolean} byTransaction
     * @returns {Promise<void>}
     */
    async appointmentsFind({ commit }, { startDate, endDate, byTransaction }) {
      commit('APPOINTMENTS_FIND');
      try {
        const appointments = await Appointments.find(startDate, endDate, byTransaction);
        commit('APPOINTMENTS_FIND_SUCCESS', { appointments });
      } catch (e) {
        commit('APPOINTMENTS_FIND_FAIL');
        throw e;
      }
    },
    async appointmentsFetchOne({ commit }, { id, abortSignal }) {
      commit('APPOINTMENTS_FETCH_ONE');
      try {
        const appointment = await Appointments.getOne(id, abortSignal);
        commit('APPOINTMENTS_FETCH_ONE_SUCCESS', { appointment });
      } catch (e) {
        commit('APPOINTMENTS_FETCH_ONE_FAIL');
        throw e;
      }
    },
    async appointmentsFetchOneConnect({ commit }, { id, token, abortSignal }) {
      commit('APPOINTMENTS_FETCH_ONE_CONNECT');
      try {
        const appointment = await Appointments.getOne(id, abortSignal, token);
        commit('APPOINTMENTS_FETCH_ONE_CONNECT_SUCCESS', { appointment });
      } catch (e) {
        commit('APPOINTMENTS_FETCH_ONE_CONNECT_FAIL');
        throw e;
      }
    },
    async appointmentsCancel({ commit }, { id, token, reason }) {
      commit('APPOINTMENTS_CANCEL');
      try {
        await Appointments.cancel(id, token, reason);
        commit('APPOINTMENTS_CANCEL_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_CANCEL_FAIL');
        throw e;
      }
    },
    async appointmentsAccept({ commit }, { id, token }) {
      commit('APPOINTMENTS_ACCEPT');
      try {
        await Appointments.accept(id, token);
        commit('APPOINTMENTS_ACCEPT_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_ACCEPT_FAIL');
        throw e;
      }
    },
    appointmentsClearCurrent({ commit }) {
      commit('APPOINTMENTS_CLEAR_CURRENT');
    },
    async appointmentsClose({ commit }, { id }) {
      commit('APPOINTMENTS_CLOSE');
      try {
        await Appointments.close(id);
        commit('APPOINTMENTS_CLOSE_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_CLOSE_FAIL');
        throw e;
      }
    },
    async appointmentsTerminate({ commit }, { id }) {
      commit('APPOINTMENTS_TERMINATE');
      try {
        await Appointments.terminate(id);
        commit('APPOINTMENTS_TERMINATE_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_TERMINATE_FAIL');
        throw e;
      }
    },
    async appointmentsUpdatePrivateNote({ commit }, { id, privateNote }) {
      commit('APPOINTMENTS_UPDATE_PRIVATE_NOTE');
      try {
        await Appointments.updatePrivateNote(id, privateNote);
        commit('APPOINTMENTS_UPDATE_PRIVATE_NOTE_SUCCESS', { id, privateNote });
      } catch (e) {
        commit('APPOINTMENTS_UPDATE_PRIVATE_NOTE_FAIL');
        throw e;
      }
    },
    async appointmentsReplaceProduct({ commit, dispatch }, { id, productId }) {
      commit('APPOINTMENTS_REPLACE_PRODUCT');
      try {
        await Appointments.replaceProduct(id, productId);
        await dispatch('appointmentsFetchOne', { id });
        commit('APPOINTMENTS_REPLACE_PRODUCT_SUCCESS', { id });
      } catch (e) {
        commit('APPOINTMENTS_REPLACE_PRODUCT_FAIL');
        throw e;
      }
    },
    async appointmentsReplacePatient({ commit }, { appointmentId, patientId }) {
      commit('APPOINTMENTS_REPLACE_PATIENT');
      try {
        const appointment = await Appointments.replacePatient(appointmentId, patientId);
        commit('APPOINTMENTS_REPLACE_PATIENT_SUCCESS', { appointment });
      } catch (e) {
        commit('APPOINTMENTS_REPLACE_PATIENT_FAIL');
        throw e;
      }
    },
    /**
     * @param {function}  commit
     * @param {string}    startAt      The ATOM start time of the appointment
     * @param {string}    endAt        The ATOM end time of the appointment
     * @param {string}    message      An arbitrary message for this appointment
     * @param {string}    privateNote  The private comment
     * @param {string}    email        The email of the patient
     * @param {string}    productId    The product id
     * @param patientId
     * @param practitionerId
     * @returns {Promise}
     */
    async appointmentsCreate({ commit }, { startAt, endAt, message, privateNote, email, productId, patientId, practitionerId=null }) {
      commit('APPOINTMENTS_CREATE');
      try {
        await Appointments.create(startAt, endAt, message, privateNote, email, productId, patientId, practitionerId);
        commit('APPOINTMENTS_CREATE_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_CREATE_FAIL');
        throw e;
      }
    },
    async appointmentsPrescriptionsCreate({ commit }, { appointmentId, content, metadata }) {
      commit('APPOINTMENTS_PRESCRIPTIONS_CREATE');
      try {
        const prescription = await Prescriptions.create(appointmentId, content, metadata);
        commit('APPOINTMENTS_PRESCRIPTIONS_CREATE_SUCCESS', { prescription });
      } catch(e) {
        commit('APPOINTMENTS_PRESCRIPTIONS_CREATE_FAIL');
        throw e;
      }
    },
    async appointmentsPrescriptionsUpdate({ commit }, { id, content, metadata }) {
      commit('APPOINTMENTS_PRESCRIPTIONS_UPDATE');
      try {
        const prescription = await Prescriptions.update(id, content, metadata);
        commit('APPOINTMENTS_PRESCRIPTIONS_UPDATE_SUCCESS', { prescription });
      } catch(e) {
        commit('APPOINTMENTS_PRESCRIPTIONS_UPDATE_FAIL');
        throw e;
      }
    },
    async appointmentsPrescriptionsRemove({ commit }, { id }) {
      commit('APPOINTMENTS_PRESCRIPTIONS_REMOVE', { id });
      try {
        await Prescriptions.delete(id);
        commit('APPOINTMENTS_PRESCRIPTIONS_REMOVE_SUCCESS', { id });
      } catch (e) {
        commit('APPOINTMENTS_PRESCRIPTIONS_REMOVE_FAIL', { id });
        throw e;
      }
    },
  },
  getters: {
    currentAppointmentWithMeta(state, _, rootState) {
      return withMeta(state.current, rootState);
    },

    orderedAppointments(state) {
      return orderBy(state.all, ['startAt'], ['desc']);
    },

    orderedFoundAppointments(state) {
      return orderBy(state.found, ['startAt'], ['asc']);
    },

    appointmentsWithMeta(state, getters, rootState) {
      return getters.orderedAppointments.reduce((accumulator, appointment) => {
        accumulator.push(withMeta(appointment, rootState));

        return accumulator;
      }, []);
    },

    foundAppointmentsWithMeta(state, getters, rootState) {
      return getters.orderedFoundAppointments.reduce((accumulator, appointment) => {
        accumulator.push(withMeta(appointment, rootState));

        return accumulator;
      }, []);
    },

    appointmentsForPlanning(state, getters) {
      return groupBy(getters.appointmentsWithMeta, (withMeta) => {
        return `${withMeta.year} ${withMeta.month} ${withMeta.day}`;
      });
    },

    currentAppointmentPatientFullName(state) {
      if (!state.current) {
        return '';
      }

      const { firstName, lastName } = state.current.patient;
      return getFullName(firstName, lastName);
    },

    currentAppointmentPractitionerFullName(state) {
      if (!state.current) {
        return '';
      }

      const { firstName, lastName } = state.current.practitioner;
      return getFullName(firstName, lastName);
    },
  },
};
