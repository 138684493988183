<template>
  <div class="agenda-calendar-appointment-group" :style="{ top, height }">
    <AgendaCalendarAppointment
      v-for="(appointment, index) in appointments"
      :key="appointment.id"
      :appointment="appointment"
      :group-start-at="startAt"
      :offset="index / appointments.length"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import AgendaCalendarAppointment from './AgendaCalendarAppointment';

  export default {
    name: 'AgendaCalendarAppointmentGroup',
    components: { AgendaCalendarAppointment },
    props: {
      appointments: {
        type: Array,
        required: true,
      },
      startAt: {
        type: moment,
        required: true,
      },
      endAt: {
        type: moment,
        required: true
      }
    },
    computed: {
      day() {
        return moment(this.startAt).startOf('day');
      },
      top() {
        return this.$minutesToPixels(this.startAt.diff(this.day, 'minutes'));
      },
      height() {
        return this.$minutesToPixels(moment(this.endAt).diff(this.startAt, 'minutes'));
      },
    },
  };
</script>

<style scoped lang="scss">
  .agenda-calendar-appointment-group {
    position: absolute;
    width: 100%;
  }
</style>
