<template>
  <AppPanel :opened="opened" class="appointment-panel" @close="$emit('close')">
    <AppLoader v-if="isLoadingAppointment || isLoadingPatient">Chargement du rendez-vous</AppLoader>
    <template v-else-if="appointment">
      <SectionTitle>Votre rendez-vous en détail</SectionTitle>
      <div class="summary">
        <AppointmentPanelCloseLink v-if="canBeClosed" class="section" @close="loadAppointment" />

        <AppointmentPanelPatient class="section" />

        <AppointmentPanelDetails v-if="opened" :can-be-started="canBeStarted" class="section" />

        <p class="section description">{{ appointment.payload.description }}</p>

        <div v-if="isCancelled" class="section cancel-reason">
          <h4>Motif de l'annulation</h4>
          {{ appointment.payload.cancelReason || 'Aucun motif renseigné' }}
        </div>
        <template v-else>

        <div class="doc2u_container">
          <div v-if="this.$store.state.observations.isFetching" class="container-spinner">
            <Spinner />
          </div>
          <div v-else>
            <div v-if="searchLength>0">
              <LastObservations :search-length="searchLength" :observations="this.$store.state.observations.search"/>
            </div>
            <div v-else>
              <p>Aucune mesure enregistrée.</p>
            </div>
          </div>
        </div>

          <AppointmentPanelPrivateNote class="section" />

          <template v-if="isClosed">
            <p v-if="isLoadingEncounter" class="section">
              <FontAwesomeIcon :icon="$icons.spinner" spin />
              Vérification des documents disponibles…
            </p>
            <template v-else-if="encounter">
              <div v-if="encounter.medicalObservation" class="section medical-observation-container">
                <h4>Observation médicale</h4>
                <div class="medical-observation">{{ encounter.medicalObservation }}</div>
              </div>
              <AppointmentPanelPrescriptions class="section" v-if="!isNurse" />
              <AppointmentPanelPatientAdvice class="section" />
              <AppointmentPanelClaims class="section" v-if="!isNurse" />
            </template>
          </template>

          <SharedFiles
            :appointment-id="appointmentId"
            :patient-id="patient.id"
            class="section"
          />
        </template>
      </div>

      <AppointmentPanelActions
        v-if="!isClosed && !isTerminated && !isCancelled"
        :can-be-started="canBeStarted"
        @accept="reloadAppointments"
        @cancel="reloadAppointments"
      />

    </template>
  </AppPanel>
</template>

<script>
  import AppLoader from '@/components/AppLoader';
  import AppointmentPanelActions from '@/components/AppointmentPanel/AppointmentPanelActions';
  import AppointmentPanelClaims from '@/components/AppointmentPanel/AppointmentPanelClaims';
  import AppointmentPanelCloseLink from '@/components/AppointmentPanel/AppointmentPanelCloseLink';
  import AppointmentPanelDetails from '@/components/AppointmentPanel/AppointmentPanelDetails';
  import AppointmentPanelPatient from '@/components/AppointmentPanel/AppointmentPanelPatient';
  import AppointmentPanelPatientAdvice from '@/components/AppointmentPanel/AppointmentPanelPatientAdvice';
  import AppointmentPanelPrescriptions from '@/components/AppointmentPanel/AppointmentPanelPrescriptions';
  import AppointmentPanelPrivateNote from '@/components/AppointmentPanel/AppointmentPanelPrivateNote';
  import AppointmentStatusIcon from '@/components/AppointmentStatusIcon';
  import AppPanel from '@/components/AppPanel';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonRed from '@/components/Button/ButtonRed.vue';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import FormWidget from '@/components/Form/FormWidget';
  import SelectWrapper from '@/components/Form/SelectWrapper';
  import SectionTitle from '@/components/SectionTitle';
  import SharedFiles from '@/components/SharedFiles';
  import { AbortMixin } from '@/helpers/mixins';
  import moment from 'moment';
  import Spinner from "./Spinner";
  import LastObservations from "./LastObservations";

  export default {
    name: 'AppointmentPanel',
    components: {
      AppointmentPanelActions,
      AppointmentPanelClaims,
      AppointmentPanelPatientAdvice,
      AppointmentPanelPrescriptions,
      AppLoader,
      AppPanel,
      AppointmentPanelCloseLink,
      AppointmentPanelDetails,
      AppointmentPanelPatient,
      AppointmentPanelPrivateNote,
      AppointmentStatusIcon,
      AutoHeightTextarea,
      ButtonGreen,
      ButtonRed,
      FormWidget,
      SectionTitle,
      SelectWrapper,
      SharedFiles,
      Spinner,
      LastObservations
    },
    mixins: [AbortMixin],
    props: {
      appointmentId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        searchLength: 0
      }
    },
    mounted() {
      this.$store.dispatch('appointmentsClearCurrent');
      this.$store.dispatch('encountersClearCurrent');
      if (this.appointmentId) {
        this.loadAppointment();
      }
      this.getLastObservations()
    },
    computed: {
      opened() {
        return !!this.appointmentId;
      },
      isLoadingAppointment() {
        return this.$store.state.appointments.isFetchingOne;
      },
      isLoadingPatient() {
        return this.$store.state.patients.isFetchingOne;
      },
      isLoadingEncounter() {
        return this.$store.state.encounters.isFetchingOne;
      },
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      encounter() {
        return this.$store.state.encounters.current;
      },
      status() {
        return this.appointment.payload.status;
      },
      patient() {
        return this.$store.state.patients.current;
      },
      practitioner() {
        return this.appointment.payload.practitioner;
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
      canBeStarted() {
        if (!this.isConfirmed || !this.isRemote || this.isAccountRestricted) {
          return false;
        }

        return this.$store.state.currentTime.isAfter(this.appointmentMinimumStartTime);
      },
      appointmentMinimumStartTime() {
        return moment(this.appointment.payload.startAt).subtract(15, 'minutes');
      },
      isTerminated() {
        return this.status === 'TERMINATED';
      },
      isClosed() {
        return this.status === 'CLOSED';
      },
      isConfirmed() {
        return this.status === 'CONFIRMED';
      },
      hasEncounter() {
        return !!this.appointment.payload.encounters?.[0];
      },
      isRemote() {
        return this.appointment.payload.product.channel === 'remote';
      },
      canBeClosed() {
        if (this.isRemote) {
          // TODO : Encounter has no status on Connect API
          if (this.hasEncounter && this.appointment.payload.encounters[0].status === 'finished') {
            return true;
          }

          return this.status === 'TERMINATED' || this.isClosed;
        }

        return this.$store.state.currentTime.isAfter(moment(this.appointment.payload.startAt));
      },
      isCancelled() {
        return this.status === 'CANCELLED_BY_PATIENT' || this.status === 'CANCELLED_BY_PRACTITIONER';
      },
      isNurse() {
        return this.$store.state.practitioner.profile.type === 'nurse';
      },
    },
    methods: {
      async getLastObservations() {
        await this.$store.dispatch('observationsFetch', {
          patient: this.patient ? this.patient.id : null ,
          lastObs: true,
          code: null
        })
        if(this.$store.state.observations && this.$store.state.observations.search) {
          this.searchLength = this.$store.state.observations.search.length;
        }
      },
      async loadAppointment() {
        try {
          await this.$store.dispatch('appointmentsFetchOne', {
            id: this.appointmentId,
            abortSignal: this.abortSignal,
          });

          await this.$store.dispatch('patientsFetchOne', { id: this.appointment.payload.patient.id });

          if (this.isClosed && this.isRemote && this.hasEncounter) {
            const encounter = this.appointment.payload.encounters?.[0];
            await this.$store.dispatch('encountersFetchOne', {
              id: encounter.id,
              abortSignal: this.abortSignal,
            });
          }
        } catch (e) {
          if (!this.isAbortError(e)) {
            this.$addError('Erreur lors du chargement du rendez-vous');
            throw e;
          }
        }
      },
      async reloadAppointments() {
        this.loadAppointment();
        await this.$store.dispatch('appointmentsFetchPage', { page: this.$store.state.appointments.page });
      },
    },
    watch: {
      appointmentId(appointmentId) {
        if (appointmentId) {
          this.loadAppointment();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel {
    font-size: 1.4rem;
    font-weight: 200;
  }

  .description {
    line-height: 2.4rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 2rem;
  }

  .cancel-reason {
    font-family: $fontRoboto;
    font-weight: 300;
    white-space: break-spaces;

    h4 {
      font-family: $fontGotham;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .medical-observation-container h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .medical-observation {
    white-space: pre-line;
  }

  .summary {
    font-family: $titleFont;
    font-size: 1.5rem;
  }

  .section {
    margin: 0;
    padding: 2rem 0;
    border-bottom: 1px solid $hrColor;
    &:first-child {
      padding-top: 0;
    }
  }

  .description {
    max-height: 5rem;
    margin: 0.5rem 0;
    font-size: 1.4rem;
    white-space: break-spaces;
  }


  .shared-files::v-deep .files-group:first-child {
    padding-top: 0;
  }

  .doc2u_container{
    padding: 2rem 0;
  }
</style>
