var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingPatients
    ? _c("AppLoader", [_vm._v("\n  Chargement des patients en cours...\n")])
    : _c("AppLayout", [
        _c(
          "main",
          [
            _c("SectionTitle", [_vm._v("Mes patients")]),
            _c(
              "div",
              { staticClass: "letters" },
              _vm._l(_vm.letters, function(letter) {
                return _c("span", { staticClass: "letter" }, [
                  _c("a", { attrs: { href: "#letter-" + letter } }, [
                    _vm._v(_vm._s(letter))
                  ])
                ])
              }),
              0
            ),
            _vm._l(_vm.secretariesOf, function(secretaryOf, index) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "practitioner-filter-entry" },
                  [
                    _c(
                      "FormCheckbox",
                      {
                        model: {
                          value: _vm.secretariesChecked[index].isChecked,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.secretariesChecked[index],
                              "isChecked",
                              $$v
                            )
                          },
                          expression: "secretariesChecked[index].isChecked"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getFullName(
                              secretaryOf.first_name,
                              secretaryOf.last_name
                            )
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            }),
            _c("FormWidget", { attrs: { transparent: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter,
                    expression: "filter"
                  }
                ],
                attrs: {
                  id: "patient-filter",
                  placeholder:
                    "Filtrer par nom, email et/ou numéro de téléphone"
                },
                domProps: { value: _vm.filter },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.filter = $event.target.value
                  }
                }
              })
            ]),
            !_vm.patients.length
              ? _c("div", { staticClass: "no-results" }, [
                  _vm._v("Aucun patient trouvé")
                ])
              : _vm._e(),
            _vm._l(_vm.patientsByFirstLetter, function(patients, letter) {
              return [
                _c(
                  "HorizontalDivider",
                  {
                    staticClass: "patients-divider",
                    attrs: { id: "letter-" + letter, left: "" }
                  },
                  [_vm._v("\n        " + _vm._s(letter) + "\n      ")]
                ),
                _c(
                  "div",
                  { staticClass: "patient-items" },
                  _vm._l(patients, function(patient) {
                    return _c(
                      "router-link",
                      {
                        staticClass: "patient-list-item",
                        attrs: {
                          to: { name: "patient", params: { id: patient.id } }
                        }
                      },
                      [
                        _c("UserAvatar", {
                          attrs: {
                            src: patient.picture,
                            "user-name": _vm.toFullName(patient),
                            size: "6rem"
                          }
                        }),
                        _c("div", { staticClass: "content" }, [
                          _c("span", { staticClass: "patient-name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.toFullName(patient)) +
                                "\n            "
                            )
                          ]),
                          _c("p", { staticClass: "birth-date" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("date")(
                                    patient.birthDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                " (" +
                                _vm._s(_vm._f("age")(patient.birthDate)) +
                                ")\n            "
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "icon-container" },
                          [
                            _c("FontAwesomeIcon", {
                              attrs: { icon: _vm.$icons.viewPatient }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ]
            })
          ],
          2
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }