<!--
Emitted events:
- upload
-->
<template>
  <div class="shared-files">
    <div class="files-group">
      <label>Vos documents partagés</label>
      <ModuleLoader v-if="isFetching" />
      <div v-else class="files">
        <File v-for="file in practitionerFiles" :path='`/document-sign/${file.id}`' :appointmentId="appointmentId" :id="file.id" :key="file.id" removable @removed="reloadFiles"
          @click.native="showFile(file.id)">           
          {{ file.name }}
        </File>
        <File v-if="isAttaching" id="">
          <FontAwesomeIcon :icon="$icons.spinner" spin />
        </File>
        <p v-else-if="practitionerFiles.length === 0" class="no-share">Vous n'avez pas encore partagé de document</p>
      </div>
    </div>
    <div class="files-group">
      <label>Documents partagés par le patient</label>
      <ModuleLoader v-if="isFetching" />
      <div v-else class="files">
        <File v-for="file in patientFiles" :id="file.id" :key="file.id">
          {{ file.name }}
        </File>
        <p v-if="patientFiles.length === 0" class="no-share">Votre patient n'a pas encore partagé de document</p>
      </div>
    </div>
    <div class="files-group">
      <label>Partager un nouveau document</label>
      <FileUploadButton label="Vous souhaitez partager un document avec votre patient, cliquez ici."
        @change="handleFileUpload" />
      <a class="reload-files" href="#" @click.prevent="reloadFiles">
        <FontAwesomeIcon :icon="$icons.reload" />
        Recharger la liste des documents
      </a>
    </div>
  </div>
</template>

<script>
import ModuleLoader from './ModuleLoader';
import File from './Consultation/CallingInterface/File';
import FileUploadButton from './Consultation/CallingInterface/FileUploadButton';
import HorizontalDivider from '../components/HorizontalDivider';

export default {
  name: 'SharedFiles',
  components: { FileUploadButton, File, ModuleLoader ,HorizontalDivider},
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
    };
  },
  mounted() {
    this.reloadFiles();
  },
  computed: {
    isFetching() {
      return this.$store.state.sharedFiles.isFetchingAll;
    },
    isAttaching() {
      return this.$store.state.sharedFiles.isAttaching;
    },
    isShowing() {
      return this.$store.state.sharedFiles.isShowing;
    },
    patientFiles() {
      return this.$store.state.sharedFiles.patientFiles;
    },
    practitionerFiles() {
      return this.$store.state.sharedFiles.practitionerFiles;
    },
  },
  methods: {
    async reloadFiles() {
      try {
        await this.$store.dispatch('sharedFilesFetchAllByAppointment', { appointmentId: this.appointmentId });
      } catch (e) {
        await this.$addError('Une erreur est survenue lors du chargement des fichiers');
        throw e;
      }
    },
    /**
     * @param {Blob}   file
     * @param {string} fileName
     */
    async handleFileUpload(file, fileName) {
      try {
        await this.$store.dispatch('sharedFilesUploadAndAttach', {
          file,
          fileName,
          appointmentId: this.appointmentId,
          patientId: this.patientId,
        });

        await this.$addSuccess('Le fichier a été partagé avec succès.');
        await this.reloadFiles();

        this.$emit('upload');
      } catch (e) {
        await this.$addError('Une erreur s\'est produite lors de l\'envoi du fichier');
        throw e;
      }
    },
    async showFile(fileId) {
      await this.$store.dispatch('sharedFileShown', { fileId })
      this.fileShownImage = this.$store.state.sharedFiles.fileShown;
    },
  },
};
</script>

<style lang="scss" scoped>
.files-panel {
  font-size: 1.4rem;
  z-index: 1;
  flex: 3;
  padding: 8rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.files-group {
  padding: 2rem 0;
  border-bottom: 1px solid $lightTextColor;

  &:last-child {
    border: none;
  }

  label {
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    margin-bottom: 2rem;
  }
}

.files {
  display: flex;
  flex-wrap: wrap;
}

.upload-file-container {
  display: flex;
  align-items: center;

  p {
    margin: 0 2rem 0 0;
  }
}

.upload-file-button {
  font-size: 2rem;
  float: right;
  padding: 1.5rem 6rem;
  color: $lightTextColor;
  border: none;
  border-radius: 4rem;
  background-color: $altColor;
}

.reload-files {
  font-size: 1.4rem;
  display: block;
  text-align: center;
  color: $mainColor;

  :first-child {
    margin-right: 1rem;
  }
}

.no-share {
  margin: 0;
}
</style>
