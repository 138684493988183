import Vue from 'vue';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { toUpper } from 'lodash-es';
import { formatCivility, capitalize, age, getFullName } from '@/helpers/format';

Vue.filter('capitalize', capitalize)
Vue.filter('uppercase', toUpper);
Vue.filter('age', age)

Vue.filter('phone', (value) => {
  if (!value) {
    return '';
  }
  const phoneNumberParsed = parsePhoneNumberFromString(value);
  return phoneNumberParsed && phoneNumberParsed.formatInternational();
});

Vue.filter('ssn', (ssn) => {
  if (!ssn) {
    return '';
  }

  ssn = ssn.replace(/[^0-9AB]/g, '');

  let overseaRegex = /(\d)(\d{2})(\d{2})(9[78]\d)(\d{2})(\d{3})(\d{2})/;
  let defaultRegex = /(\d)(\d{2})(\d{2})(2[AB]|\d{2})(\d{3})(\d{3})(\d{2})/;

  let regex = ssn.match(overseaRegex) ? overseaRegex : defaultRegex;

  return ssn.replace(regex, '$1 $2 $3 $4 $5 $6 $7');
});

const MoneyFormatter = new Intl.NumberFormat(
  'fr-FR',
  { style: 'currency', currency: 'EUR' },
);

Vue.filter('money', function (value) {
  return MoneyFormatter.format(value / 100);
});

Vue.filter('date', function(value, format) {
  return moment(value).format(format);
});

Vue.filter('channel', value => {
  switch (value) {
    case 'remote':
      return 'Téléconsultation';
    case 'medical_office':
      return 'En cabinet';
    case 'home':
      return 'À domicile';
    default:
      return null;
  }
});

Vue.filter('appointmentStatus', (status, canBeStarted) => {
  switch (status) {
    case 'CONFIRMED':
      return canBeStarted ? 'Accessible' : 'Confirmé';
    case 'TERMINATED':
      return 'En attente de rapport';
    case 'CLOSED':
      return 'Terminé';
    case 'CANCELLED_BY_PATIENT':
    case 'CANCELLED_BY_PRACTITIONER':
      return 'Annulé';
    case 'NEW_BY_PATIENT':
    case 'NEW_BY_PRACTITIONER':
      return 'En attente de validation';
    default:
      return null;
  }
});

Vue.filter('civility', (value, short = false) => {
  return formatCivility(value, short);
});

Vue.filter('territorialOrganization', (type) => {
  switch (type) {
    case 'cpts':
    case 'esp':
    case 'msp':
    case 'cds':
      return type.toLocaleUpperCase();
    case 'other':
      return 'Autre';
    default:
      return 'Aucune';
  }
});

Vue.filter('fullName', (user) => getFullName(user.firstName, user.lastName));
