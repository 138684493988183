var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "new-appointment-form",
      class: { restricted: _vm.isAccountRestricted },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _vm.isSecretary
        ? _c(
            "div",
            { staticStyle: { "margin-top": "2rem", "margin-bottom": "2rem" } },
            [
              _c("p", [
                _vm._v("Pour quel praticien prenez-vous un rendez-vous ?")
              ]),
              _vm._l(_vm.secretariesOf, function(secretaryOf) {
                return _c("div", {}, [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "1rem" } },
                    [
                      _c(
                        "FormRadio",
                        {
                          attrs: { value: secretaryOf.id },
                          on: { change: _vm.onPractitionerChange },
                          model: {
                            value: _vm.form.practitioner.id,
                            callback: function($$v) {
                              _vm.$set(_vm.form.practitioner, "id", $$v)
                            },
                            expression: "form.practitioner.id"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getFullName(
                                  secretaryOf.first_name,
                                  secretaryOf.last_name
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "FormWidget",
        {
          scopedSlots: _vm._u(
            [
              _vm.$v.form.startAt.$error
                ? {
                    key: "error",
                    fn: function() {
                      return [
                        !_vm.$v.form.startAt.required
                          ? _c("span", [
                              _vm._v(
                                "\n          Veuillez renseigner la date du rendez-vous\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c("label", { attrs: { for: "new-appointment-start-at" } }, [
            _vm._v("Date et heure du rendez-vous")
          ]),
          _c("DateTimePicker", {
            attrs: {
              "input-id": "new-appointment-start-at",
              type: "datetime",
              "minute-step": 5,
              "min-datetime": _vm.$store.state.currentTime.format(),
              phrases: { cancel: "Annuler", ok: "OK" },
              auto: ""
            },
            model: {
              value: _vm.form.startAt,
              callback: function($$v) {
                _vm.$set(_vm.form, "startAt", $$v)
              },
              expression: "form.startAt"
            }
          })
        ],
        1
      ),
      _c(
        "FormWidget",
        {
          scopedSlots: _vm._u(
            [
              _vm.$v.form.product.$error
                ? {
                    key: "error",
                    fn: function() {
                      return [
                        !_vm.$v.form.product.required
                          ? _c("span", [
                              _vm._v(
                                "\n          Veuillez choisir un type d'acte\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null,
              _vm.form.product && _vm.form.product.unitPrice === 0
                ? {
                    key: "help",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n            Les frais de service de la platforme s'appliquent même sur les actes gratuits\n        "
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c("label", { attrs: { for: "new-appointment-product" } }, [
            _vm._v("Type d'acte")
          ]),
          _c("SelectWrapper", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.product,
                    expression: "form.product"
                  }
                ],
                attrs: { id: "new-appointment-product" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "product",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _vm.products.length > 1 && !_vm.form.product
                  ? _c("option", { domProps: { value: null } })
                  : _vm._e(),
                _vm._l(_vm.products, function(product) {
                  return _c("option", { domProps: { value: product } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(product.name) +
                        " (" +
                        _vm._s(_vm._f("channel")(product.channel)) +
                        ")\n        "
                    )
                  ])
                })
              ],
              2
            )
          ])
        ],
        1
      ),
      _c(
        "FormWidget",
        {
          scopedSlots: _vm._u(
            [
              _vm.$v.form.patient.email.$error
                ? {
                    key: "error",
                    fn: function() {
                      return [
                        !_vm.$v.form.patient.email.required
                          ? _c("span", [
                              _vm._v(
                                "\n          Veuillez renseigner l'adresse email du patient\n        "
                              )
                            ])
                          : !_vm.$v.form.patient.email.email
                          ? _c("span", [
                              _vm._v(
                                "\n          L'adresse email est incorrecte\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null,
              {
                key: "help",
                fn: function() {
                  return [
                    _vm.isSearchingPatient
                      ? [
                          _c("FontAwesomeIcon", {
                            attrs: { spin: "", icon: _vm.$icons.spinner }
                          }),
                          _vm._v("\n        Recherche en cours...\n      ")
                        ]
                      : [
                          _vm._v(
                            "\n        * email obligatoire pour le 1er RDV avec un nouveau patient\n      "
                          )
                        ]
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c("label", { attrs: { for: "new-appointment-patient-email" } }, [
            _vm._v("Votre patient (email ou nom)")
          ]),
          _c("AutoCompletedInput", {
            attrs: {
              value: _vm.form.patient,
              id: "new-appointment-patient-email",
              "search-method": _vm.searchPatient,
              searching: _vm.isSearchingPatient,
              disabled: !!_vm.forcedPatient,
              "typed-text": _vm.form.typedText
            },
            on: {
              "update:searching": function($event) {
                _vm.isSearchingPatient = $event
              },
              "update:typedText": _vm.handleTypedText,
              change: _vm.handleSelectedPatient
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var result = ref.result
                  return [
                    _c("p", { staticClass: "patient-search-name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getFullName(result.firstName, result.lastName)
                        )
                      )
                    ]),
                    _c("p", { staticClass: "patient-search-phone" }, [
                      _vm._v(_vm._s(_vm._f("phone")(result.phone)))
                    ]),
                    _c("p", { staticClass: "patient-search-email" }, [
                      _vm._v(_vm._s(result.email))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "advanced-fields" },
        [
          _c(
            "div",
            {
              staticClass: "divider",
              on: {
                click: function($event) {
                  _vm.areAdvancedFieldsVisible = !_vm.areAdvancedFieldsVisible
                }
              }
            },
            [
              _c("FontAwesomeIcon", {
                attrs: {
                  icon: _vm.areAdvancedFieldsVisible
                    ? _vm.$icons.shrink
                    : _vm.$icons.expand
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.areAdvancedFieldsVisible ? "Masquer" : "Afficher"
                  ) +
                  " les options avancées\n      "
              ),
              _c("FontAwesomeIcon", {
                attrs: {
                  icon: _vm.areAdvancedFieldsVisible
                    ? _vm.$icons.shrink
                    : _vm.$icons.expand
                }
              })
            ],
            1
          ),
          _vm.areAdvancedFieldsVisible
            ? [
                _c(
                  "FormWidget",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n          Ces informations seront partagées avec le patient\n        "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      27439570
                    )
                  },
                  [
                    _c("label", { attrs: { for: "new-appointment-message" } }, [
                      _vm._v("Raison du rendez-vous")
                    ]),
                    _c("AutoHeightTextarea", {
                      attrs: {
                        id: "new-appointment-message",
                        placeholder: _vm.defaultMessage
                      },
                      model: {
                        value: _vm.form.message,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "message", $$v)
                        },
                        expression: "form.message"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "FormWidget",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n          Cette note est strictement confidentielle et n’est pas partagée avec le patient.\n        "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2825012032
                    )
                  },
                  [
                    _c(
                      "label",
                      { attrs: { for: "new-appointment-private-note" } },
                      [_vm._v("Note privée")]
                    ),
                    _c("AutoHeightTextarea", {
                      attrs: { id: "new-appointment-private-note" },
                      model: {
                        value: _vm.form.privateNote,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "privateNote", $$v)
                        },
                        expression: "form.privateNote"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "ButtonRed",
        {
          staticClass: "new-appointment-submit",
          attrs: {
            big: "",
            type: "submit",
            icon: _vm.$icons.send,
            busy: _vm.isSubmitting
          }
        },
        [_vm._v("\n    Envoyer le rendez-vous\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }