<template>
  <div class="prescription-module">
    <a href="#" @click.prevent="add">Ajouter une ordonnance</a>
    <PrescriptionPanel
      :opened="isEditorVisible"
      :birth-date="birthDate"
      :prescription-id="editedPrescriptionId"
      @close="handlePanelClose"
    />

    <AppConfirmation
      ref="deletePrescription"
      title="Êtes-vous sûr de vouloir supprimer cette ordonnance ?"
      @confirm="remove"
    />

    <div v-for="(prescription, index) in prescriptions" class="line">
      <FontAwesomeIcon :icon="$icons.prescription" class="icon" />
      <div class="label">Ordonnance n°{{ index + 1 }}</div>
      <div class="actions">
        <a :class="{ inactive: !isEditable(prescription) }" class="action" href="#" @click.prevent="edit(prescription)">
          Modifier
        </a>
        |
        <a class="action" href="#" @click.prevent="view(prescription)">Visualiser</a>
        |
        <a
          :class="{ inactive: !isRemovable(prescription) }"
          class="action"
          href="#"
          @click.prevent="$confirm($refs.deletePrescription, prescription)"
        >
          Supprimer
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Prescriptions from '@/api-platform/prescriptions';
  import PrescriptionPanel from '@/components/PrescriptionPanel';
  import AppConfirmation from '@/components/AppConfirmation';

  export default {
    name: 'PrescriptionModule',
    components: { AppConfirmation, PrescriptionPanel },
    props: {
      birthDate: {
        required: true,
      },
    },
    data() {
      return {
        isEditorVisible: false,
        editedPrescriptionId: null,
      };
    },
    computed: {
      encounter() {
        return this.$store.state.encounters.current;
      },
      appointment() {
        return this.$store.state.appointments.current;
      },
      prescriptions() {
        return this.appointment.prescriptions.filter(p => p.status !== 'deleted');
      },
    },
    methods: {
      handlePanelClose(newPrescriptionId) {
        if (newPrescriptionId) {
          this.$store.dispatch('appointmentsPrescriptionsCreate', { id: newPrescriptionId });
        }

        this.editedPrescriptionId = null;
        this.isEditorVisible = false;
      },
      isEditable(prescription) {
        return !prescription.id.includes('legacy');
      },
      isRemovable(prescription) {
        return !prescription.id.includes('legacy');
      },
      add() {
        this.editedPrescriptionId = null;
        this.isEditorVisible = true;
      },
      edit(prescription) {
        if (!this.isEditable(prescription)) {
          return;
        }

        this.editedPrescriptionId = prescription.id;
        this.isEditorVisible = true;
      },
      async view(prescription) {
        const pdf = await Prescriptions.download(prescription.id);
        let objectURL = window.URL.createObjectURL(pdf);
        window.open(objectURL);
      },
      async remove(prescription) {
        if (!this.isRemovable(prescription)) {
          return;
        }

        try {
          await this.$store.dispatch('appointmentsPrescriptionsRemove', { id: prescription.id });
          await this.$addSuccess('La prescription a été supprimée');
        } catch (e) {
          await this.$addError('Une erreur a eu lieu lors de la tentative de suppression de la prescription');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .prescription-module {
    font-size: 1.5rem;
  }

  .line {
    display: flex;
    margin-top: 1rem;

    .icon {
      margin-right: 1rem;
    }
  }

  .actions {
    margin-left: 2rem;

    .inactive {
      color: $grey;
      text-decoration: none;
      font-style: italic;
      cursor: default;
    }
  }
</style>
