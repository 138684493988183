import ky from 'ky';

import { getPlatformJWT, refreshPlatformJWT } from '../helpers/auth';
import * as Sentry from '@sentry/browser';
import { getSetting } from '../helpers/tools.js';

const api = ky.create({
  prefixUrl: getSetting('URL_API_PLATFORM'),
  retry: {
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRetry: [
      async ({ request }) => {
        const jwt = await refreshPlatformJWT();
        request.headers.set('Authorization', `Bearer ${jwt}`);
      }
    ],
    beforeRequest: [
      async (request) => {
        let platformJWT = getPlatformJWT();

        if (!platformJWT) {
          platformJWT = await refreshPlatformJWT();
        }

        request.headers.set('X-Api-Key', getSetting('PLATFORM_API_PUBLIC_KEY'));
        request.headers.set('Authorization', `Bearer ${platformJWT}`);
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status >= 400 && getSetting('SENTRY_ENABLED')) {
          Sentry.configureScope(scope => scope.setTags({
            api_url: request.url,
            api_method: request.method,
          }));

          Sentry.setExtra('response', await response.clone().json());
        }

        return response;
      },
    ],
  },
});

export default api;
