<!--
Emitted events:
- @change(year, month)
-->
<template>
  <div class="agenda-date-picker-month">
    <div class="button" @click="goToPreviousMonth()">
      <FontAwesomeIcon :icon="$icons.chevronLeft" />
    </div>
    <div>{{ monthName }} {{ year }}</div>
    <div class="button" @click="goToNextMonth()">
      <FontAwesomeIcon :icon="$icons.chevronRight" />
    </div>
  </div>
</template>

<script>
  const monthsName = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  export default {
    name: 'AgendaDatePickerMonth',
    props: {
      year: {
        type: Number,
        required: true,
      },
      month: {
        type: Number,
        required: true,
      },
    },
    computed: {
      monthName() {
        return monthsName[this.month];
      },
    },
    methods: {
      shiftMonth(backward) {
        let year = this.year;
        let month = backward ? this.month - 1 : this.month + 1;

        switch (month) {
          case -1:
            month = 11;
            year -= 1;
            break;
          case 12:
            month = 0;
            year += 1;
            break;
        }

        this.$emit('change', year, month);
      },
      goToNextMonth() {
        this.shiftMonth(false);
      },
      goToPreviousMonth() {
        this.shiftMonth(true);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .agenda-date-picker-month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    font-family: $fontGotham;
  }

  .button {
    cursor: pointer;
    padding: 4px 10px;
  }
</style>
