import Credentials from '../api/credentials';
import { getSetting, setLocalStorage, removeLocalStorage } from './tools';

export function extractAccessTokenFromHash(hash) {
  const tokenMatch = hash.match(/^#access_token=([a-zA-Z0-9]+)/);
  if (tokenMatch) {
    clearPlatformJWT();
    localStorage.setItem('accessToken', tokenMatch[1]);
    return true;
  }
  return false;
}

export function clearPlatformJWT() {
  localStorage.removeItem('platformJWT');
}

export function setPlatformJWT(jwt) {
  localStorage.setItem('platformJWT', jwt);
}

export function getPlatformJWT() {
  return localStorage.getItem('platformJWT');
}

export async function refreshPlatformJWT() {
  clearPlatformJWT();
  const jwt = await Credentials.getPlatformJWT();
  setPlatformJWT(jwt);
  return jwt;
}

export function clearSynapseJWT() {
  localStorage.removeItem('synapseJWT');
}

export function setSynapseJWT(jwt) {
  localStorage.setItem('synapseJWT', jwt);
}

export function getSynapseJWT() {
  return localStorage.getItem('synapseJWT');
}

export async function refreshSynapseJWT() {
  clearSynapseJWT();
  const jwt = await Credentials.getSynapseJWT();
  setSynapseJWT(jwt);
  return jwt;
}

export function clearAccessToken() {
  localStorage.removeItem('accessToken');
}

export function logout() {
  clearAccessToken();
  clearPlatformJWT();
}

export function hasAccessToken() {
  return null !== localStorage.getItem('accessToken');
}

export function redirectToAuth(returnToCurrentPageAfterLogin = true) {
  if (returnToCurrentPageAfterLogin) {
    setLocalStorage('postAuthUrl', window.location.href);
  } else {
    removeLocalStorage('postAuthUrl');
  }

  window.location = getSetting('URL_SSO_FRONT');
}
