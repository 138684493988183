var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ButtonGreen",
    {
      attrs: { busy: _vm.isBusy, icon: _vm.$icons.accept, outlined: "" },
      on: { click: _vm.handleClick }
    },
    [_vm._t("default", [_vm._v("Valider")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }