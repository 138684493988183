import api from './';

let startPath = process.env.ENVIRONMENT === "local" ? "practitioners" : "Practitioner"

export default {
  getAccountLink: (redirectPath) => {
    const url = window.location.origin + redirectPath;

    return api.post(`${startPath}/me/account_link`, {
      json: {
        success_url: url,
        failure_url: url,
      },
    }).json();
  },
};
