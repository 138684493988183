var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { staticClass: "profile-access-page" }, [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "information bordered" }, [
            _vm._v(
              "\n          Veuillez activer les options souhaitées pour personnaliser votre solution Hellocare.\n        "
            )
          ]),
          _c("div", { staticClass: "settings" }, [
            _c(
              "div",
              { staticClass: "settings-left" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.settings } }, [
                  _vm._v(
                    "\n              Gérez les accès externes\n            "
                  )
                ]),
                !_vm.isSecretary
                  ? _c("ButtonGreen", { on: { click: _vm.open } }, [
                      _vm._v(" Ajouter une secrétaire ")
                    ])
                  : _vm._l(_vm.accessTokens, function(accessToken) {
                      return _c("div", { staticClass: "setting" }, [
                        _c("img", {
                          staticClass: "setting-icon",
                          attrs: { src: accessToken.partner.logo, alt: "" }
                        }),
                        _c("div", { staticClass: "settings-content" }, [
                          _c("div", { staticClass: "setting-label" }, [
                            _c("label", { attrs: { for: accessToken.id } }, [
                              _vm._v(
                                "Active la synchronisation avec " +
                                  _vm._s(accessToken.partner.name)
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "setting-switch" },
                              [
                                _c("FormSwitch", {
                                  attrs: {
                                    id: accessToken.id,
                                    busy: _vm.updating[accessToken.id],
                                    checked: accessToken.active
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeAccessTokenStatus(
                                        accessToken.id,
                                        !accessToken.active
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("p", { staticClass: "setting-description" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(accessToken.partner.description) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    })
              ],
              2
            )
          ])
        ]),
        _vm.isOpen
          ? _c("div", { staticClass: "secretary" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", [
                  _vm._v(
                    "\n            Pour permettre à votre secrétaire d'acceder à votre agenda, veuillez-lui transmettre le lien suivant :\n          "
                  )
                ]),
                _c("div", { staticClass: "secretary-link hc-border" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.getSecretaryUrl) +
                        "\n            "
                    )
                  ]),
                  _c(
                    "span",
                    { staticClass: "secretary-button" },
                    [
                      _c("ButtonGreen", { on: { click: _vm.copyLink } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.isCopied ? "Copié !" : "Copier") +
                            "\n              "
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.isSecretary
          ? _c("div", { staticClass: "secretary" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("SectionTitle", [_vm._v("Mes praticiens connectés")]),
                  _vm._l(_vm.secretariesOf, function(secretaryOf) {
                    return _c(
                      "div",
                      [
                        _c("p", { staticClass: "setting-description" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getFullName(
                                  secretaryOf.first_name,
                                  secretaryOf.last_name
                                )
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "ButtonGreen",
                          {
                            on: {
                              click: function($event) {
                                return _vm.disconnectPractitioner(
                                  secretaryOf.id
                                )
                              }
                            }
                          },
                          [_vm._v("Déconnecter")]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        !_vm.isSecretary
          ? _c("div", { staticClass: "secretary" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("SectionTitle", [_vm._v("Mes secrétaires")]),
                  _vm._l(_vm.secretaries, function(secretary) {
                    return _c(
                      "div",
                      [
                        _c("p", { staticClass: "setting-description" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.getFullName(
                                  secretary.first_name,
                                  secretary.last_name
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _c(
                          "ButtonGreen",
                          {
                            on: {
                              click: function($event) {
                                return _vm.disconnectSecretary(secretary.id)
                              }
                            }
                          },
                          [_vm._v("Déconnecter")]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }