var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "agenda-calendar-appointment",
      class: _vm.classes,
      style: { top: _vm.top, left: _vm.left, height: _vm.height },
      attrs: { to: _vm.$linkToAppointment(_vm.appointment.id) }
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }