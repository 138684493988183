var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { attrs: { header: false, footer: false } }, [
    _c(
      "main",
      { staticClass: "consultation-report-confirmed fullscreen" },
      [
        _c("FontAwesomeIcon", {
          staticClass: "icon",
          attrs: { icon: _vm.$icons.thumbsUp, size: "4x" }
        }),
        _c("SectionTitle", { attrs: { center: "", light: "" } }, [
          _vm._v("\n      Compte-rendu enregistré\n    ")
        ]),
        _c("p", { staticClass: "text" }, [
          _vm._v(
            "\n      Votre compte-rendu a bien été enregistré, vous pouvez le consulter depuis la fiche rendez-vous liée à celui-ci.\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "actions single" },
          [
            _c(
              "ButtonGreen",
              { staticClass: "action", attrs: { "link-to": _vm.linkTo } },
              [_vm._v("\n        Continuer\n      ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }