import api from './';

import { normalizeObservation, normalizeObservations } from '@/helpers/normalizers';

export default {
    create: (patient, data, encounter) => api.post('observations', {
        json: {
            patient: patient,
            data: data,
            encounter: encounter ? encounter : null
        },
    }).json().then(normalizeObservation),
    getOne: (id) => api.get(`observations/${id}`).json().then(normalizeObservation),
    search: (code, patient, lastObs) => {
        const searchParams = new URLSearchParams();
        if (patient) {
            searchParams.append('patient', patient);
        }
        if (code) {
            searchParams.append('code', code);
        }
        if(lastObs) {
            searchParams.append('lastObs', lastObs);
        }
        return api.get('observations', { searchParams }).json().then(response => {
            if(response.data.length>0) {
                return normalizeObservations(response.data)
            }
        });
    },
    delete: (id) => api.delete(`observations/${id}`)
};
