<template>
  <router-link
    class="agenda-calendar-appointment"
    :class="classes"
    :style="{ top, left, height }"
    :to="$linkToAppointment(appointment.id)"
  >
    {{ label }}
  </router-link>
</template>

<script>
  import moment from 'moment';
  import { getFullName } from '@/helpers/format';

  export default {
    name: 'AgendaCalendarAppointment',
    props: {
      appointment: {
        type: Object,
        required: true,
      },
      offset: {
        type: Number,
        required: true,
      },
      groupStartAt: {
        type: moment,
        required: true,
      }
    },
    computed: {
      left() {
        return `${this.offset * 100}%`;
      },
      top() {
        return this.$minutesToPixels(moment(this.appointment.startAt).diff(this.groupStartAt, 'minutes'));
      },
      height() {
        return this.$minutesToPixels(moment(this.appointment.endAt).diff(moment(this.appointment.startAt), 'minutes'));
      },
      label() {
        const shorten = this.offset > 0;
        let { firstName, lastName } = this.appointment.patient;

        if (!firstName) {
          return shorten ? 'Nouv. Pat.' : 'Nouveau Patient';
        }

        if (shorten) {
          lastName = `${lastName.substr(0, 1)}.`;
        }

        return getFullName(firstName, lastName);
      },
      classes() {
        return [
          `channel-${this.appointment.channel}`,
          `status-${this.appointment.status}`,
          {
            'has-offset': this.offset > 0,
            'is-old': moment(this.appointment.startAt).isBefore(this.$store.state.currentTime),
          },
        ];
      },
    },
  };
</script>

<style scoped lang="scss">
  .agenda-calendar-appointment {
    position: absolute;
    right: 0.1rem;
    display: flex;
    align-items: center;
    border-left: 0.5rem solid transparent;
    padding-left: 0.5em;
    background-color: white;
    white-space: nowrap;
    color: $textColor;
    text-decoration: none;

    &:hover {
      font-weight: bold;
      background-color: #DFF9F3;
    }
  }

  .has-offset {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .channel-remote {
    border-left-color: #00eaa7;
  }

  .channel-medical_office {
    border-left-color: #004f6c;
  }

  .channel-home {
    border-left-color: #00b9e3;
  }

  .status-pending {
    border-left-color: #ff315c;
  }

  .is-old {
    border-left-color: #9b9b9b;
  }
</style>
