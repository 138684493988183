<template>
  <AppLayout>
    <main class="">
      <div class="container">
        <div class="row">
          <div class="col _12 information" v-if="connectedPractitioner">
            Vous êtes maintenant connecté au praticien {{getFullName(connectedPractitioner.firstName, connectedPractitioner.lastName)}}
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout';
  import {getFullName} from "../../helpers/format";

  export default {
    name: 'ProfileLinkPage',
    components: {
      AppLayout,
    },
    data() {
      return {
        connectedPractitioner:null
      };
    },
    async mounted() {
      await this.$store.dispatch('appLoaderShow');
      if(this.$route.params.id !== this.$store.state.practitioner.profile.id && this.$store.state.practitioner.profile.type === 'secretary') {
        await this.$store.dispatch('practitionerGetOne', this.$route.params.id);
        const connectedPractitioner = this.$store.state.practitioner.connectedPractitioner
        this.connectedPractitioner = connectedPractitioner
        const secretary = {id:this.$store.state.practitioner.profile.id, secretariesOf:[connectedPractitioner.id]}
        await this.$store.dispatch('practitionerEditProfile', secretary);
        await this.$store.dispatch('appLoaderHide');
        this.redirect()
      } else{
        await this.$store.dispatch('appLoaderHide');
        this.$router.push({name: 'profile-access'})
      }
    },
    methods: {
      getFullName,
      redirect() {
        setTimeout( () =>
          this.$router.push({name: 'profile-access'})
        , 10000);
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/grid";

  .container {
    max-width: 60rem;
    padding: 0 1rem;

    @include onDesktop {
      padding: 0;
    }
  }


  h2 {
    font-family: $fontRoboto;
    font-size: 2rem;
    font-weight: normal;
  }

  .information {
    font-size: 2rem;
    text-align: center;
  }
</style>
