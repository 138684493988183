<template>
  <div :class="{ disabled: isDisabled }" class="profile-setting">
    <div class="setting-label">
      <label :for="id">
        <slot name="default" />
      </label>
      <div class="setting-switch">
        <FormSwitch
          :id="id"
          ref="switch"
          :busy="busy"
          :checked="checked"
          :disabled="isDisabled"
          @change="handleChange"
        />
      </div>
    </div>
    <div v-if="$slots.description" class="description">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
  import FormSwitch from '@/components/Form/FormSwitch';

  export default {
    name: 'ProfileSetting',
    components: { FormSwitch },
    props: {
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      property: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        busy: false,
      };
    },
    computed: {
      id() {
        return `profile-setting-${this.property}`;
      },
      profile() {
        return this.$store.state.practitioner.profile;
      },
      checked() {
        return !!this.$store.state.practitioner.profile[this.property];
      },
      isDisabled() {
        return this.disabled || this.$store.state.practitioner.profile.isRestricted;
      },
    },
    methods: {
      async handleChange(checked) {
        if (checked === this.checked) { return; }

        this.busy = true;

        try {
          await this.$store.dispatch('practitionerEditSettings', { [this.property]: checked });
        } catch (e) {
          await this.$addError(`Une erreur est survenue lors de ${checked ? 'l\'activation' : 'la désactivation'} de l'option`);
          throw e;
        } finally {
          this.busy = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .profile-setting {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  .setting-label {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      cursor: pointer;
    }

    .disabled & {
      opacity: 0.5;
    }
  }

  .price {
    font-weight: 300;
    margin-right: 6rem;
    margin-left: auto;
  }

  .description {
    font-weight: 300;
    font-style: italic;
    margin-top: 1rem;
    text-align: justify;
  }
</style>
