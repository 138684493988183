import api from './';
import { normalizeProduct, normalizeProducts } from '../helpers/normalizers';

export default {
  fetchAll: () => api.get('products?_count=9999').json().then(response => normalizeProducts(response.data)),
  create: (data) => api.post(`products`, { json: data }).json().then(normalizeProduct),
  update: (id, data) => api.put(`products/${id}`, { json: data }).json().then(normalizeProduct),
  updateRank: (id, rank) => api.put(`products/${id}`, { json: { rank } }).json().then(normalizeProduct),
  delete: (id) => api.delete(`products/${id}`),
}
