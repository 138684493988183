var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "form-input",
      class: {
        disabled: _vm.disabled,
        focused: _vm.focused || _vm.autoFilled,
        "has-value": !!_vm.value,
        "has-error": !!_vm.$slots.error
      },
      on: {
        focusin: function($event) {
          _vm.focused = true
        },
        focusout: function($event) {
          _vm.focused = false
        }
      }
    },
    [
      _vm.type === "password"
        ? _c("FontAwesomeIcon", {
            staticClass: "icon",
            attrs: {
              icon: _vm.showPassword
                ? _vm.$icons.passwordVisible
                : _vm.$icons.passwordHidden
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("toggle-password-visibility")
              }
            }
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "label",
          class: { "has-tel-input": _vm.type === "tel" },
          attrs: { id: _vm.id }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.label)),
          _vm.required ? [_vm._v(" *")] : _vm._e()
        ],
        2
      ),
      _vm._t("input", [
        _c(
          _vm.componentName,
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask"
                  }
                ],
                tag: "component",
                staticClass: "input-component autofill-workaround",
                class: { vti_hack: _vm.vti_hack > 0 },
                attrs: {
                  "aria-disabled": _vm.disabled,
                  "aria-labelledby": _vm.id,
                  disabled: _vm.disabled,
                  mode: _vm.type === "tel" ? "international" : undefined,
                  placeholder: _vm.focused ? _vm.placeholder : "",
                  type: _vm.overriddenType,
                  value: _vm.value
                },
                on: {
                  animationstart: _vm.checkAutoFill,
                  close: function($event) {
                    _vm.vti_hack -= 1
                  },
                  open: function($event) {
                    !_vm.vti_hack && (_vm.vti_hack = 2)
                  },
                  "country-changed": function($event) {
                    return _vm.$emit("country-changed", $event)
                  }
                }
              },
              "component",
              _vm.$attrs,
              false
            ),
            Object.assign({}, _vm.$listeners, { input: _vm.handleInput })
          )
        )
      ]),
      _vm.$slots.help && !_vm.$slots.error
        ? _c("span", { staticClass: "help" }, [_vm._t("help")], 2)
        : _vm._e(),
      _vm.$slots.error
        ? _c("span", { staticClass: "error" }, [_vm._t("error")], 2)
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }