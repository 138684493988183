<template>
  <div class="appointment-list">
    <br/>
    <div class="sort-appointments">
      <FormWidget>
        <label for="select-input">Type de rendez-vous</label>
        <SelectWrapper>
          <select id="select-input" v-model="selectedOption" @change="toggleAppointments">
            <option v-for="option in options" :value="option.value">{{ option.text }}</option>
          </select>
        </SelectWrapper>
      </FormWidget>

      <div class="practitioner-filter" v-if="isSecretary">
        <SectionTitle>Agendas à afficher</SectionTitle>
        <div v-for="(secretaryOf, index) in secretariesOf" class="">
          <div class="practitioner-filter-entry">
            <FormCheckbox v-model="secretariesChecked[index].isChecked">{{getFullName(secretaryOf.first_name, secretaryOf.last_name)}}</FormCheckbox>
          </div>
        </div>
      </div>
    </div>
    <template v-for="appointments in appointmentsForPlanning">
      <template v-if="appointments[0]">
        <HorizontalDivider class="appointment-divider" left>
          {{ appointments[0].day }}
          {{ appointments[0].monthLong }}
          {{ appointments[0].year }}
        </HorizontalDivider>
        <div class="appointment-items">
          <AppointmentListItem
            v-for="appointment in appointments"
            :key="appointment.payload.id"
            :appointment-id="appointment.payload.id"
            :can-be-started="canBeStarted(appointment.payload.startAt)"
            :channel="appointment.payload.product.channel"
            :date="appointment.dateShort"
            :day-of-week="appointment.dayOfWeekShort"
            :description="appointment.payload.description"
            :expired="appointment.expired"
            :patient="appointment.payload.patient"
            :status="appointment.payload.status"
            :time="appointment.time"
          />
        </div>
      </template>
    </template>
    <div class="no-result" v-if="!hasAppointments">
      Aucun rendez-vous
    </div>
    <hr>
    <Paginator
      v-if="totalPages"
      v-model="page"
      :class="{ loading: isLoading }"
      :click-handler="loadAppointments"
      :no-li-surround="true"
      :page-count="totalPages"
      class="paginator"
      next-text="Page suivante"
      prev-text="Page précédente"
    />
  </div>
</template>

<script>
import AppointmentListItem from '@/components/AppointmentListItem.vue';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import moment from 'moment';
import Paginator from 'vuejs-paginate';
import FormWidget from "./Form/FormWidget.vue";
import SelectWrapper from "./Form/SelectWrapper.vue";
import FormCheckbox from "@/components/Form/FormCheckbox.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import {getFullName} from "@/helpers/format";

export default {
    name: 'AppointmentList',
    components: {
      SectionTitle,
      FormCheckbox, SelectWrapper, FormWidget, AppointmentListItem, HorizontalDivider, Paginator },
    props: {
      patientId: {
        type: String,
        required: false,
      },
    },
    data() {
      const secretariesChecked = this.$store.state.practitioner.profile.secretariesOf.map(s => ({
        id: s.id,
        isChecked: true
      }))
      secretariesChecked.push({id:this.$store.state.practitioner.profile.id, isChecked:true})
      return {
        secretariesChecked,
        page: 1,
        byTransaction: false,
        toggleLoader: false,
        selectedOption: 'all',
        options: [
          { text: 'Tous les rendez-vous', value: 'all' },
          { text: 'Les rendez-vous avec une empreinte bancaire', value: 'captured' },
        ]
      };
    },
    mounted() {
      this.handleMounted();
    },
    computed: {
      secretariesOf() {
        return this.$store.state.practitioner.profile.secretariesOf;
      },
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      isLoading() {
        return this.$store.state.appointments.isFetchingPage;
      },
      appointmentsForPlanning() {
        const displayedPractitionersIds = this.secretariesChecked.filter(s => s.isChecked).map(s => s.id)
        const appointmentsForPlanning = this.$store.getters.appointmentsForPlanning
        const appointmentsForPlanningKeys = Object.keys(appointmentsForPlanning)
        let filteredAppointmentsForPlanning = {}
        appointmentsForPlanningKeys.forEach(a => {
          if(appointmentsForPlanning[a])
            filteredAppointmentsForPlanning[a] = appointmentsForPlanning[a].filter(b => {
              return displayedPractitionersIds.includes(b.payload.practitioner.id)
            })
        });
        return filteredAppointmentsForPlanning
      },
      totalPages() {
        return this.$store.state.appointments.pages;
      },
      hasAppointments() {
        return this.$store.state.appointments.all.length > 0;
      }
    },
    methods: {
      getFullName,
      async handleMounted() {
        await this.$store.dispatch('appLoaderShow');
        await this.loadAppointments();
        await this.$store.dispatch('appLoaderHide');
        this.$setInterval(this.loadAppointments, 60000);
      },
      toggleAppointments() {
        if(this.selectedOption==='all') {
          this.byTransaction = false
        }
        if(this.selectedOption==='captured') {
          this.byTransaction = true
        }
        this.toggleLoader = true;
        this.loadAppointments()
        this.toggleLoader = false;
      },
      async loadAppointments() {
        try {
          await this.$store.dispatch('appointmentsFetchPage', {
            page: this.page,
            patientId: this.patientId,
            byTransaction: this.byTransaction
          });
        } catch (e) {
          this.$addError('Erreur lors du chargement des rendez-vous.', false);
          throw e;
        }
      },
      canBeStarted(startAt) {
        const minimumStartTime = moment(startAt).subtract(15, 'm');
        return moment().isAfter(minimumStartTime);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/assets/paginator";

  .practitioner-filter {
    font-size: 2rem;
    margin-top: 4rem;

  }
  .practitioner-filter-entry {
    margin-bottom: 2rem;
  }

  .appointment-items {
    display: flex;
    flex-wrap: wrap;
  }

  .appointment-divider {
    padding: 2rem 1rem;
  }
  .sort-appointments {
    max-width: 350px;
    margin-top: 2rem;
  }
  .appointment-list {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  .paginator.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  .no-result {
    font-style: italic;
    text-align: center;
    color: $grey;
  }
</style>
