var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.appLoaderVisible
        ? _c("AppLoader", [_vm._v("\n    CHARGEMENT\n  ")])
        : _vm._e(),
      !_vm.appLoaderVisible ? _c("AppNotification") : _vm._e(),
      _c("router-view"),
      _c("portal-target", { attrs: { name: "app-panel", multiple: "" } }),
      _c("portal-target", { attrs: { name: "app-pop-in" } }),
      _c("portal-target", { attrs: { name: "app-confirmation" } }),
      _c("AppGuide")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }