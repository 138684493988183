<template>
  <AppLayout>
    <div class="document-signed">
      <SectionTitle>Signature de document</SectionTitle>
      <div v-if="signature.image">
        <p>Faites glisser la signature sur le document à l'endroit souhaité.</p>
        <br/>
        <div class="canvas-container">
          <canvas id="mask"></canvas>
          <br/>
          <ButtonGreen @click.native="sign">
            Signer
          </ButtonGreen>
            <img class="draggable img-signature" v-if="!this.fileSigned" width="100px" :src="`data:image/png;base64,` + signature.image" alt="signature" id="signature" />
            <span v-else>
            Loading...
            </span>
          </div>
        </div>
      <div v-else>
        <p>Pour pouvoir signer un document vous devez d'abord <strong>ajouter une signature</strong> dans <strong>l'édition de votre profil.</strong></p>
        <br/>
        <router-link :to="{ name:'profile-edit' }">
          <ButtonGreen>Editer mon profil</ButtonGreen>
        </router-link>
      </div></div>
  </AppLayout>
</template>
<script>
import AppLayout from '../components/AppLayout';
import Draggabilly from 'draggabilly'
import SharedFiles from "../components/SharedFiles";
import ButtonGreen from '../components/Button/ButtonGreen.vue';
import SectionTitle from "../components/SectionTitle.vue";

export default {
  name: 'DocumentSign',
  components: {
    SectionTitle,
    SharedFiles,
    AppLayout,
    ButtonGreen,
  },
  data() {
    return {
      fileId: null,
      context: null,
      signatureImg: null,
      maskImg: null,
      signature: {"image":null,"location":null},
      fileShownImage: null,
      documentStyle:null,
      fileSigned: null,
      draggies:[]
    };
  },
  async mounted() {
    await this.getSignature();
    if(this.signature.image) {
      //trick to bypass vue closure
      window.draggies = [];
      this.fileId = this.$route.params.id;
      let draggableElems = document.querySelectorAll('.draggable');
      for (let draggableElem of draggableElems) {
        let draggie = new Draggabilly(draggableElem, {
          containment: ".canvas-container",
          cursorAt: {left: 0, top: 0}
        });
        let id = draggableElem.id;
        let parentHeight = draggie.element.parentElement.clientHeight;
        let parentWidth = draggie.element.parentElement.clientWidth;

        draggie.setPosition(parentWidth / 2, -parentHeight / 2);
        window.draggies.push({"type": id, "elt": draggie});
      }
      await this.showFile(this.fileId)
    }
  },
  methods: {

    loadImage(src, onload) {
      let img = new Image();
      img.onload = onload;
      img.src = src;
      return img;
    },
    maskOnLoad()
    {
      let canvas = document.getElementById("mask");
      let ctx = canvas.getContext('2d');
      canvas.width = this.maskImg.width;
      canvas.height=this.maskImg.height;
      // Draw the maskImg
      let containerRatio = this.maskImg.width/ this.maskImg.height;
      let width = this.maskImg.naturalWidth;
      let height = this.maskImg.naturalHeight;
      let imgRatio = height / width;

      if (imgRatio > containerRatio) { // maskImg's height too big
        height = width * containerRatio;
      } else { // this.maskImg's width too big
        width = height / containerRatio;
      }
      ctx.drawImage(this.maskImg, 0, 0);
    },
    async sign(){
      let fileId = this.fileId;
      let signature = window.draggies.find(e => e.type === 'signature');
      if (typeof(signature) == 'undefined')
        return;
      let signX = signature.elt.position.x;
      let signY = -signature.elt.position.y;
      await this.$store.dispatch("sharedFileSign",{fileId,signX,signY});
      this.fileSigned = this.$store.state.sharedFiles.fileSigned;
      this.$router.push({ name: 'appointments', query: { 'appointment-detail': this.$route.params.appointmentid } });
    },
    async getSignature() {
      await this.$store.dispatch('practitionerGetSignature')
      this.signature.image = this.$store.state.practitioner.signature;
    },      
    async showFile(fileId) {
        // debugger;        
        await this.$store.dispatch('sharedFileShown', {fileId})
        this.fileShownImage = this.$store.state.sharedFiles.fileShown;
        this.fill();
    },
    async fill() {
      this.signatureImg = this.loadImage(`data:image/png;base64,${this.signature.image}`,this.signatureOnLoad)
      this.maskImg = this.loadImage(`data:image/png;base64,${this.fileShownImage}`,this.maskOnLoad);
    }
  }
};
</script>

<style lang="scss" scoped>
.document-signed {
   @include onDesktop {
     padding: 8rem;
   }
  @include onMobile {
    padding: 4rem;
  }
}
.canvas-container {
  width: 596px;
  height: 842px;
  canvas {
    border: solid 1px black;
  }
  #mask {
  }
}

.draggable {
  cursor: pointer;
  width: 100px;
}

.draggable.is-pointer-down {
  background: #09F;
  z-index: 2;
  border: dotted 1px black;
}

.draggable.is-dragging {
  opacity: 0.7;
}

#mask img {
  border: 5px solid green;
}

.img-signature {
  border: 1px dashed gray;
  padding: 3px;
}

</style>
