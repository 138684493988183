<template>
  <div class="rootPage">
    <router-view />
    <AppointmentPanel v-if="appointmentId" @close="handleClosePanel" :appointment-id="appointmentId" />
  </div>
</template>

<script>
  import AppointmentPanel from '../components/AppointmentPanel';
  import AppPanel from '../components/AppPanel';

  export default {
    name: 'RootPage',
    components: { AppPanel, AppointmentPanel },
    props: {
      appointmentId: {
        type: String,
        required: false,
      },
    },
    methods: {
      handleClosePanel() {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            'appointment-detail': undefined,
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .rootPage {
    height: inherit;
  @include onMobile {
    min-height: calc(100vh - 70px);
  }
  }
</style>