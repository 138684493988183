var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-layout",
      class: {
        "has-navigation": _vm.navigation,
        "has-footer": _vm.footer,
        fullscreen: _vm.fullscreen
      }
    },
    [
      _vm.navigation
        ? [
            _vm.$media.desktop
              ? _c("AppNavigation", { key: "desktop", attrs: { desktop: "" } })
              : _c("AppNavigation", { key: "mobile" })
          ]
        : _vm._e(),
      _vm.header
        ? _c("AppHeader", { attrs: { withNavigation: _vm.navigation } })
        : _vm._e(),
      _vm.globalWarningMessage
        ? _c(
            "div",
            { staticClass: "global-warning-message" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "icon",
                attrs: { icon: _vm.$icons.inputError }
              }),
              _vm._v("\n    " + _vm._s(_vm.globalWarningMessage) + "\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm.isBrowserUnsupported
        ? _c(
            "div",
            { staticClass: "global-warning-message" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "icon",
                attrs: { icon: _vm.$icons.inputError }
              }),
              _vm._v(
                "\n    Votre navigateur peut ne pas être complètement compatible avec la solution Hellocare. Nous vous conseillons de\n    télécharger un navigateur récent tel que\n    "
              ),
              _vm._m(0)
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { "fullscreen-content": _vm.fullscreen }
        },
        [_vm._t("default")],
        2
      ),
      _vm.footer
        ? _c(
            "footer",
            { staticClass: "main-footer" },
            [
              _c(
                "router-link",
                { staticClass: "logo", attrs: { to: "/dashboard" } },
                [
                  _c("AppLogo", {
                    attrs: { text: _vm.$media.desktop, beta: _vm.$isBeta }
                  })
                ],
                1
              ),
              _vm._m(1)
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "white-space": "nowrap" } }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.mozilla.org/fr/firefox/new/"
          }
        },
        [_vm._v("Mozilla Firefox")]
      ),
      _vm._v(",\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.google.com/intl/fr_fr/chrome/"
          }
        },
        [_vm._v("Google Chrome")]
      ),
      _vm._v(" ou\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.microsoft.com/fr-fr/edge"
          }
        },
        [_vm._v("Microsoft Edge")]
      ),
      _vm._v(".\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "links" }, [
      _c("li", { attrs: { id: "changelog" } }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://headwayapp.co/hellocare-changelog",
              target: "_blank"
            }
          },
          [_vm._v("Correctifs & Nouveautés  ")]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "https://hellocare.com/mentions-legales/",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("Mentions légales")])]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "https://hellocare.com/confidentialite",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("Confidentialité")])]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://medecin.hellocare.pro/hubfs/Elements%20a%20telecharger/CGV-Hellocare-Connect.pdf",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("CGU")])]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf",
              target: "_blank"
            }
          },
          [_c("span", [_vm._v("Tarifs pour les patients")])]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }