import api from './';
import {
  normalizeAppointment,
  normalizeAppointments
} from '@/helpers/normalizers';

export default {
  getNearest: () => api.get('appointments/nearest').json(),
  /**
   * @param {string}  startAt     The ATOM start time of the appointment
   * @param {string}  endAt       The ATOM end time of the appointment
   * @param {string}  message     An arbitrary message for this appointment
   * @param {string}  privateNote The private comment
   * @param {string}  email       The email of the patient
   * @param {string}  productId   The product id
   * @param {string}  patientId   The patient id
   * @param {string|null}  practitionerId        The practitioner in case of it is a secretary that create the appointment
   * @returns {Promise}
   */
  create: (startAt, endAt, message, privateNote, email, productId, patientId, practitionerId=null) => api.post('appointments', {
    json: {
      start_at: startAt,
      end_at: endAt,
      message,
      practitioner: practitionerId,
      private_note: privateNote,
      email: email?.trim().toLocaleLowerCase(),
      product: productId,
      patient: patientId,
    },
  }).json().then(normalizeAppointment),
  close: (id) => api.post(`appointments/${id}/close`).json(),
  terminate: (id) => api.post(`appointments/${id}/terminate`).json(),
  getOne: (id, abortSignal, token) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
    }
    return api.get(`appointments/${id}`, { signal: abortSignal, searchParams }).json().then(normalizeAppointment);
  },
  accept: (id, token) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
      searchParams.set('type', 'practitioner');
    }

    return api.post(`appointments/${id}/confirm`, { searchParams }).json();
  },
  cancel: (id, token, reason) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
      searchParams.set('type', 'practitioner');
    }
    return api.post(`appointments/${id}/decline`, { searchParams, json: { cancelReason: reason } }).json();
  },
  updatePrivateNote: (id, privateNote) => api.patch(`appointments/${id}`, { json: { private_note: privateNote } }),
  replaceProduct: (id, productId) => api.patch(`appointments/${id}`, { json: { product: productId } }),
  /**
   * @param {Moment} startDate
   * @param {Moment} endDate
   * @param {boolean|null} byTransaction
   * @returns {Promise<unknown>}
   */
  find: (startDate, endDate, byTransaction = null) => {
    let searchParams = new URLSearchParams({
      start: startDate.format(),
      end: endDate.format(),
      _count: 9999,
      byTransaction: byTransaction
    });

    return api.get('appointments', { searchParams }).json().then(response => normalizeAppointments(response.data));
  },
  /**
   * @param {int} page
   * @param {string|undefined} patientId
   * @param {boolean|null} byTransaction
   * @return {Promise<{pages: number, page: number, data: *}>}
   */
  getPage: (page, patientId, byTransaction = null) => {
    const resultsByPage = 20;

    const searchParams = new URLSearchParams({
      _count: resultsByPage.toString(),
      _page: page.toString(),
      byTransaction: byTransaction
    });

    if (patientId) {
      searchParams.set('patient', patientId);
    }

    return api.get('appointments', { searchParams }).json().then(response => ({
      page: response.page,
      pages: Math.ceil(response.total / resultsByPage),
      data: normalizeAppointments(response.data),
    }));
  },
  replacePatient: (id, newPatientId) => {
    return api.patch(`appointments/${id}`, {
      json: {
        patient: newPatientId,
      },
    }).json().then(normalizeAppointment);
  },
};
