<template>
  <AppLayout :header="false" :footer="false">
    <main class="not-found-page">
      <div>
        <img src="../assets/images/logo.png" alt="Hellocare">
      </div>
      Cette page n'existe pas
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../components/AppLayout';

  export default {
    name: 'NotFoundPage',
    components: { AppLayout },
  };
</script>

<style lang="scss" scoped>
  .not-found-page {
    font-size: 3rem;
    color: #444444;
    text-align: center;

    img {
      width: 100px;
      margin-bottom: 2rem;
    }
  }
</style>
