var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "dashboard-page" },
      [
        _c(
          "div",
          { staticClass: "appointments" },
          [
            _c("SectionTitle", [_vm._v("Vos rendez-vous du jour")]),
            _c(
              "FormWidget",
              [
                _c("label", { attrs: { for: "select-input" } }, [
                  _vm._v("Type de rendez-vous")
                ]),
                _c("SelectWrapper", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedOption,
                          expression: "selectedOption"
                        }
                      ],
                      attrs: { id: "select-input" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedOption = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.toggleAppointments
                        ]
                      }
                    },
                    _vm._l(_vm.options, function(option) {
                      return _c(
                        "option",
                        { domProps: { value: option.value } },
                        [_vm._v(_vm._s(option.text))]
                      )
                    }),
                    0
                  )
                ])
              ],
              1
            ),
            _c("hr"),
            _vm.isFetchingAppointments
              ? _c("ModuleLoader")
              : _vm.hasAppointments
              ? _c(
                  "div",
                  { staticClass: "active-appointments" },
                  [
                    _vm._l(
                      [
                        _vm.appointmentsByExpired[false],
                        _vm.appointmentsByExpired[true]
                      ],
                      function(appointments, index) {
                        return [
                          _vm._l(appointments, function(appointment) {
                            return _c("AppointmentListItem", {
                              key: appointment.payload.id,
                              attrs: {
                                "appointment-id": appointment.payload.id,
                                "can-be-started": _vm.canBeStarted(
                                  appointment.payload.startAt
                                ),
                                channel: appointment.payload.product.channel,
                                date: appointment.dateShort,
                                "day-of-week": appointment.dayOfWeekShort,
                                description: appointment.payload.description,
                                expired: appointment.expired,
                                patient: appointment.payload.patient,
                                status: appointment.payload.status,
                                time: appointment.time
                              }
                            })
                          }),
                          index === 0 ? _c("hr") : _vm._e()
                        ]
                      }
                    )
                  ],
                  2
                )
              : _c("p", [_vm._v("Vous n’avez pas de rendez-vous à venir.")])
          ],
          1
        ),
        _c("hr", { staticClass: "vertical separator" }),
        _c(
          "div",
          { staticClass: "new-appointment" },
          [
            _c("SectionTitle", [_vm._v("Proposer un rendez-vous")]),
            _vm.shouldDisplayReminder()
              ? _c("p", { staticClass: "reminder grey-reminder" }, [
                  _vm._v(
                    "\n            Rappel : selon les récentes MAJ de nos\n            "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "underline grey-reminder",
                      attrs: {
                        href:
                          "https://hellocare.com/blog/conditions-generales-dutilisation-cgu",
                        target: "_blank"
                      }
                    },
                    [_vm._v("\n                CGUV Patient\n            ")]
                  ),
                  _vm._v(
                    ",\n            à compter\n            du 20/12/23, chaque téléconsultation entraînera des frais\n            de service de 3€ à la charge du patient.\n        "
                  )
                ])
              : _vm._e(),
            _c("NewAppointmentForm", {
              ref: "newAppointmentForm",
              on: { success: _vm.loadAppointments }
            })
          ],
          1
        ),
        _c("transition", { attrs: { duration: 600 } }, [_c("router-view")], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }