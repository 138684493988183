import api from './';
import { some } from 'lodash-es';

export default {
  /**
   * @param {string} appointmentId Appointment ID
   * @returns {Promise<unknown>}
   */
  getAllByAppointment: (appointmentId) => {
    let searchParams = new URLSearchParams();

    searchParams.set('appointment', appointmentId);

    return api.get(`files`, { searchParams }).json().then(response => response.data);
  },
  /**
   * @param {Blob} file
   * @param {string} fileName Optional. Original file name if not set
   * @returns {Promise<object>}
   */
  upload: (file, fileName) => {
    const body = new FormData();
    body.append('file', file, fileName);
    return api.post('files', { body, timeout: 60000 }).json();
  },
  /**
   * @param {string}      id             The file to attach
   * @param {string|null} appointmentId  The appointment to attach the file to
   * @param {string|null} patientId      The patient to attach the file to
   * @returns {Promise}
   */
  attach: (id, { appointmentId = null, patientId = null }) => {
    if (!some([appointmentId, patientId])) {
      return Promise.reject(new Error('Provide at least one element to attach the file to'));
    }

    const body = new FormData();

    if (appointmentId) {
      body.append('appointments[]', appointmentId);
    }

    if (patientId) {
      body.append('patients[]', patientId);
    }

    return api.post(`files/${id}/share`, { body }).json();
  },
  download: (id) => {
    return api.get(`files/${id}/download`).blob();
  },
  remove: (id) => {
    return api.delete(`files/${id}`);
  },
  get: (id) => {
    return api.get(`files/${id}`).json();
  },
  asBase64: (id) => {
    return api.get(`files/${id}/base64`).then((response) => {
      return response.text()
    }).then((response) => {
      return response;
    });
  },
  update: (id,x,y) => {
    return api.get(`files/${id}/${x}/${y}/update`).then((response) => {
      return response.text()
    }).then((response) => {
      return response;
    });
  }
};
