<template>
  <div class="app-layout" :class="{ 'has-navigation': navigation, 'has-footer': footer, 'fullscreen': fullscreen }">
    <template v-if="navigation">
      <AppNavigation v-if="$media.desktop" desktop key="desktop" />
      <AppNavigation v-else key="mobile" />
    </template>
    <AppHeader v-if="header" :withNavigation="navigation" />
    <div v-if="globalWarningMessage" class="global-warning-message">
      <FontAwesomeIcon class="icon" :icon="$icons.inputError" />
      {{ globalWarningMessage }}
    </div>
    <div v-if="isBrowserUnsupported" class="global-warning-message">
      <FontAwesomeIcon class="icon" :icon="$icons.inputError" />
      Votre navigateur peut ne pas être complètement compatible avec la solution Hellocare. Nous vous conseillons de
      télécharger un navigateur récent tel que
      <span style="white-space: nowrap">
        <a target="_blank" href="https://www.mozilla.org/fr/firefox/new/">Mozilla Firefox</a>,
        <a target="_blank" href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou
        <a target="_blank" href="https://www.microsoft.com/fr-fr/edge">Microsoft Edge</a>.
      </span>
    </div>
    <div class="main-container" :class="{'fullscreen-content': fullscreen}">
      <slot />
    </div>
    <footer class="main-footer" v-if="footer">
      <router-link to="/dashboard" class="logo">
        <AppLogo :text="$media.desktop" :beta="$isBeta" />
      </router-link>
      <ul class="links">
        <li id="changelog">
          <a href="https://headwayapp.co/hellocare-changelog" target="_blank">Correctifs &amp; Nouveautés &nbsp;</a>
        </li>
        <li>
          <a href="https://hellocare.com/mentions-legales/" target="_blank"><span>Mentions légales</span></a>
        </li>
        <li>
          <a href="https://hellocare.com/confidentialite" target="_blank"><span>Confidentialité</span></a>
        </li>
        <li>
          <a href="https://medecin.hellocare.pro/hubfs/Elements%20a%20telecharger/CGV-Hellocare-Connect.pdf" target="_blank"><span>CGU</span></a>
        </li>
        <li>
          <a href="https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf" target="_blank"><span>Tarifs pour les patients</span></a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import AppNavigation from './AppNavigation';
import BaseButton from './Button/BaseButton.vue';
import AppLogo from './AppLogo';
import AppHeader from './AppHeader';

export default {
  name: 'AppLayout',
  components: { AppHeader, AppLogo, BaseButton, AppNavigation },
  props: {
    navigation: {
      type: Boolean,
      required: false,
      default: true,
    },
    header: {
      type: Boolean,
      required: false,
      default: true,
    },
    footer: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    if ('Headway' in window) {
      window.Headway.init({
        selector: "#changelog",
        trigger: '#changelog > a',
        account:  "7qwwWy"
      });
    }
  },
  computed: {
    isBrowserUnsupported() {
      const userAgent = window.navigator.userAgent.toLowerCase();

      // User agent contains "edge" for old MS Edge (Edge Chromium contains "edg")
      return userAgent.indexOf('edge') !== -1 || userAgent.indexOf('trident') !== -1;
    },
    globalWarningMessage() {
      return this.$store.state.settings.GLOBAL_WARNING_MESSAGE;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-layout {
  @include onMobile {
    min-height: calc(100vh - 70px);
    flex: 100%;
    display: flex;
    flex-direction: column;
  }
}
  .has-navigation {
    padding-top: 0;
  @include onDesktop {
    padding-left: 7.4rem;
  }
}

.fullscreen {
  height: 100vh;
}

.fullscreen-content {
  height: 100%;
}

.has-footer {
  padding-bottom: 6rem;
  @include onMobile {
    padding-bottom: 0;
  }
}

.main-container {
  @include onMobile {
    flex: 100%;
  }
  main {
    padding: 1rem;

    @include onDesktop {
      padding: 8rem;
    }

    &.fullscreen {
      min-height: calc(100vh - 7rem);

      @include onDesktop {
        min-height: 100vh;
      }
    }
    top:30px;
  }
}

.main-footer {
  display: flex;
  height: 6rem;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: #FFFFFF;
  border-top: 1px solid #F6F6F6;
  padding: 0 2rem;
  @include onMobile {
    position: initial;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .links {
    margin-left: auto;
    display: flex;
    list-style: none;
    font-size: 1.5rem;

    @include onMobile {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      font-size: 1rem;
      text-align: center;
    }

    li {
      display: inline-flex;
      align-items: center;

      &:not(:first-child) {
        border-left: 1px solid #1F1F1F;
      }

      &:first-child a {
        padding-right: 0;
      }
    }

    a {
      color: #1F1F1F;
      padding: 0 1rem;
      text-decoration: none;
    }
  }
}

.global-warning-message {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 1rem;
  color: $lightTextColor;
  background-color: $errorColor;
  text-align: center;

  .icon {
    margin-right: 0.4rem;
  }

    a {
      color: white;
    }
  }
.logo {
  @include onMobile {
    display: none;
  }
}
</style>
