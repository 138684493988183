var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "appointment-list-item",
      class: _vm.statusClass,
      attrs: { to: _vm.$linkToAppointment(_vm.appointmentId) }
    },
    [
      _c("div", { staticClass: "date-time" }, [
        _c("div", { staticClass: "day-of-week" }, [
          _vm._v(_vm._s(_vm.dayOfWeek))
        ]),
        _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
        _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))])
      ]),
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "patient-name" }, [
          _vm._v(_vm._s(_vm.patientName))
        ]),
        _c("p", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.description))
        ]),
        _c("p", { staticClass: "info" }, [
          _c("span", { staticClass: "channel" }, [
            _vm._v(_vm._s(_vm._f("channel")(_vm.channel)))
          ]),
          _vm._v("\n      |\n      "),
          _c(
            "span",
            { staticClass: "status" },
            [
              _c("AppointmentStatusIcon", { attrs: { status: _vm.status } }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("appointmentStatus")(_vm.status, _vm.canBeStarted)
                  ) +
                  "\n      "
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }