<template>
  <textarea
    class="auto-height-textarea"
    v-on="{ ...$listeners, input: handleInput }"
    :value="value"
  />
</template>

<script>
  export default {
    name: 'AutoHeightTextarea',
    props: {
      value: {
        type: String,
      },
      maxLength: {
        type: Number,
        default: 0,
      }
    },
    mounted() {
      this.resize();
    },
    updated() {
      this.resize();
    },
    methods: {
      handleInput($event) {
        if (this.maxLength === 0 || $event.target.value.length <= this.maxLength) {
          this.$emit('input', $event.target.value);
        } else {
          this.$forceUpdate();
        }
        this.resize();
      },
      resize() {
        this.$el.style.height = 'auto';
        this.$el.style.height = (this.$el.scrollHeight) + 'px';
      },
    },
  };
</script>

<style scoped>
  .auto-height-textarea {
    resize: none;
    min-height: 5rem;
  }
</style>
