<template>
  <div v-if="appointment" class="appointment-panel-details">
    <div v-if="isCancelledByPatient" class="cancelled">
      Rendez-vous annulé par le patient
    </div>
    <div v-else-if="isCancelledByPractitioner" class="cancelled">
      Vous avez annulé ce RDV
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.calendar" fixed-width />
      <strong>{{ appointmentDate }}</strong>
    </div>
    <template v-if="!isProductReplacementFormVisible">
      <div>
        <FontAwesomeIcon :icon="$icons.clock" fixed-width />
        <strong>{{ appointment.time }}</strong>
        <span class="duration">
          Durée de l'acte&nbsp;: <strong>{{ appointment.payload.product.duration }} min</strong>
        </span>
      </div>
      <div>
        <FontAwesomeIcon :icon="$icons.eye" fixed-width />
        <strong>{{ product.channel | channel }}</strong>
      </div>
      <div>
        <FontAwesomeIcon :icon="$icons.stethoscope" fixed-width />
        <strong>{{ productName }}</strong>
        <span v-if="canReplaceProduct" class="replace-product">
          ( <a href="#" @click.prevent="isProductReplacementFormVisible = true">Modifier</a> )
        </span>
      </div>
    </template>
    <form v-else class="product-replacement-form" @submit.prevent="replaceProduct">
      <FormWidget>
        <label for="product-replacement-id">Type d'acte</label>
        <SelectWrapper>
          <select id="product-replacement-id" v-model="productReplacementId">
            <option v-for="product in products" :value="product.id">
              {{ product.name }} ({{ product.channel | channel }})
            </option>
          </select>
        </SelectWrapper>
      </FormWidget>
      <div class="product-replacement-actions">
        <ButtonGreen
          :busy="isReplacingProduct"
          no-border
          outlined
          small
          type="submit"
        >
          Valider
        </ButtonGreen>
        <ButtonRed
          :busy="isReplacingProduct"
          no-border
          outlined
          small
          @click="isProductReplacementFormVisible = false"
        >
          Annuler
        </ButtonRed>
      </div>
    </form>
    <div>
      <AppointmentStatusIcon :status="status" />
      <strong>{{ status | appointmentStatus(canBeStarted) }}</strong>
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.wallet" fixed-width />
      <strong v-if="isPaid">Paiement effectué</strong>
      <strong v-else-if="isAuthorized">Pré-autorisation effectuée</strong>
      <strong v-else>Aucun paiement</strong>
    </div>
  </div>
</template>

<script>
  import AppointmentStatusIcon from '@/components/AppointmentStatusIcon';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonRed from '@/components/Button/ButtonRed.vue';
  import FormWidget from '@/components/Form/FormWidget';
  import SelectWrapper from '@/components/Form/SelectWrapper';
  import moment from 'moment';

  export default {
    name: 'AppointmentPanelDetails',
    components: { AppointmentStatusIcon, ButtonRed, ButtonGreen, SelectWrapper, FormWidget },
    props: {
      canBeStarted: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isProductReplacementFormVisible: false,
        productReplacementId: null,
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      appointmentId() {
        return this.appointment.payload.id;
      },
      appointmentDate() {
        return moment(this.appointment.payload.startAt).format('dddd DD MMMM YYYY');
      },
      product() {
        return this.appointment.payload.product;
      },
      products() {
        return this.$store.state.practitioner.profile.products;
      },
      productName() {
        return this.product ? this.product.name : 'Téléconsultation générale';
      },
      order() {
        return this.appointment.payload.order;
      },
      isPaid() {
        return this.order?.paymentStatus === 'paid';
      },
      isAuthorized() {
        return !!this.order?.transactions.find(transaction => transaction.paymentStatus === 'not_captured');
      },
      status() {
        return this.appointment.payload.status;
      },
      canReplaceProduct() {
        return !this.hasEncounter && !this.isAccountRestricted;
      },
      isReplacingProduct() {
        return this.$store.state.appointments.isReplacingProduct;
      },
      hasEncounter() {
        return !!this.appointment.payload.encounters?.[0];
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
      isCancelledByPatient() {
        return this.status === 'CANCELLED_BY_PATIENT';
      },
      isCancelledByPractitioner() {
        return this.status === 'CANCELLED_BY_PRACTITIONER';
      },
    },
    methods: {
      async replaceProduct() {
        try {
          await this.$store.dispatch('appointmentsReplaceProduct', {
            id: this.appointmentId,
            productId: this.productReplacementId,
          });
        } catch (e) {
          this.$addError('Le délai imparti pour modifier le type d\'acte a été dépassé.');
          throw e;
        } finally {
          this.isProductReplacementFormVisible = false;
        }
      },
    },
    watch: {
      isProductReplacementFormVisible(isVisible) {
        this.productReplacementId = isVisible
          ? this.product.id
          : null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-details {
    color: $textColor;

    svg {
      margin-right: 1rem;
    }

    .duration {
      margin-left: 2rem;
    }

    div {
      margin-bottom: 1rem;

      &.cancelled {
        font-family: $fontRoboto;
        font-weight: 500;
        margin-bottom: 3rem;
        padding: 1.2rem 2rem;
        color: $errorColor;
        border: 1px solid $errorColor;
        border-radius: 0.8rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-replacement-form {
    display: flex;

    .form-widget {
      flex: 1;
    }

    .base-button {
      padding: 0.5rem 1.8rem;
    }
  }

  .replace-product {
    margin-left: 1rem;
    color: $grey;

    a {
      text-decoration: none;
    }
  }

  .product-replacement-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
  }
</style>
