<template>
  <label class="form-checkbox" :class="{ disabled }">
    <span><slot /></span>
    <input
      type="checkbox"
      class="form-checkbox-input"
      :checked="checked"
      :disabled="disabled"
      :aria-checked="checked"
      :aria-disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    >
    <span class="checkbox">
      <span class="checkbox-mark" />
    </span>
  </label>
</template>

<script>
  export default {
    name: 'FormCheckbox',
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      checked: Boolean,
      disabled: Boolean,
    },
  };
</script>

<style scoped lang="scss">
  .form-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-family: $checkboxFont;
    font-size: 1.6rem;
    color: $checkboxLabelColor;
    font-weight: 400;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .form-checkbox-input {
    position: absolute;
    opacity: 0;
  }

  .checkbox {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    border: 1px solid $checkboxBorderColor;
    border-radius: 4px;
  }

  .checkbox-mark {
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
  }

  .form-checkbox:hover .checkbox-mark {
    background-color: $checkboxHoverColor;
  }

  .form-checkbox-input:checked ~ .checkbox .checkbox-mark {
    background-color: $checkboxCheckedColor;
  }

  .form-checkbox-input:focus ~ .checkbox {
    @include onFocus;
  }
</style>
