<!--
Emitted events:
- @change(<moment>)
-->
<template>
  <div class="agenda-date-picker-day">
    <div class="initials">
      <div class="initial" v-for="initial in ['L', 'M', 'M', 'J', 'V', 'S', 'D']">{{ initial }}</div>
    </div>
    <div class="week" :class="{ selected: isWeekSelected(week) }" v-for="week in weeks">
      <div
        v-for="day in week"
        class="day"
        :class="{fade: shouldFade(day), today: isToday(day)}"
        @click="selectDay(day)"
      >
        {{ day.date() }}
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { extendMoment } from 'moment-range';
  import { splitArray } from '../../helpers/tools';

  export default {
    name: 'AgendaDatePickerDay',
    props: {
      year: {
        type: Number,
        required: true,
      },
      month: {
        type: Number,
        required: true,
      },
      day: {
        type: moment,
        required: true,
      },
    },
    computed: {
      weeks() {
        const firstDay = moment({ year: this.year, month: this.month }).startOf('month').startOf('isoWeek');
        const lastDay = moment({ year: this.year, month: this.month }).endOf('month').endOf('isoWeek');
        const range = extendMoment(moment).range(firstDay, lastDay);

        const days = range.by('days');

        return splitArray(Array.from(days), 7);
      },
    },
    methods: {
      isToday(day) {
        return day.isSame(this.$store.state.currentDay, 'day');
      },
      shouldFade(day) {
        if (day.isBefore(this.$store.state.currentDay, 'day')) {
          return true;
        }

        if (day.month() !== this.month) {
          return true;
        }
      },
      selectDay(day) {
        this.$emit('change', day);
      },
      isWeekSelected(week) {
        return week.some(day => day.isSame(this.day, 'day'));
      },
    },
  };
</script>

<style lang="scss" scoped>

  .initials, .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    > div {
      display: flex;
      width: 3rem;
      height: 3rem;
      justify-content: center;
      align-items: center;
    }
  }

  .week {
    cursor: pointer;

    &:hover, &.selected {
      border-radius: 2rem;
      background-color: #F3F3F3;
    }
  }

  .initial {
    font-size: 1.4rem;
    color: #AAAAAA;
  }

  .fade {
    color: #DDDDDD;
  }

  .today {
    background-color: $successColor;
    color: white;
    border-radius: 50%;
  }
</style>
