import Languages from "../../api/languages";

export default {
    state: {
        languages: [],
        isFetchingActive: false,
    },
    mutations: {
        LANGUAGES_FETCH_ACTIVE(state) {
            state.isFetchingActive = true;
        },
        LANGUAGES_FETCH_ACTIVE_SUCCESS(state, { languages }) {
            state.isFetchingActive = false;
            state.languages = languages;
        },
        LANGUAGES_FETCH_ACTIVE_FAIL(state) {
            state.isFetchingActive = false;
        }
    },
    actions: {
        async languagesFetchActive({commit}) {
            commit('LANGUAGES_FETCH_ACTIVE');
            try{
                const languages = await Languages.fetchActive();
                commit('LANGUAGES_FETCH_ACTIVE_SUCCESS', {languages});
            }catch (e) {
                commit('LANGUAGES_FETCH_ACTIVE_FAIL');
                throw (e);
            }
        }
    }

}