import api from './';
import { normalizeTransaction, normalizeTransactions } from '../helpers/normalizers';

export default {
  getSlice: (offset, count) => {
    const searchParams = new URLSearchParams();
    searchParams.append('_offset', offset);
    searchParams.append('_count', count);
    for (const status of ['paid', 'not_captured', 'confirmed', 'refunded']) {
      searchParams.append('paymentStatus[]', status);
    }
    return api.get('transactions', { searchParams }).json().then(response => normalizeTransactions(response.data));
  },
  refund: (id, amount) => {
    return api.post(`transactions/${id}/refund`, { json: { amount } }).json().then(normalizeTransaction);
  },
};
