var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fullscreen-notification" },
    [
      _c("FontAwesomeIcon", {
        staticClass: "icon",
        attrs: { size: "5x", icon: _vm.icon || _vm.$icons.info }
      }),
      _c("SectionTitle", { attrs: { light: "", center: "" } }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
      _c("div", { staticClass: "buttons" }, [_vm._t("buttons")], 2),
      _c("div", { staticClass: "links" }, [_vm._t("links")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }