<template>
  <div class="calendar-body">
    <div class="days-header">
      <div class="day-label" v-for="day in 7" :key="day">
        {{ printDay(day) }}
      </div>
    </div>

    <div class="days-body">
      <div class="week-row" v-for="(week, key) in calendar" :key="key">
        <template v-for="day in week">
          <CalendarDay
            :day="day"
            :events="events"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import CalendarDay from './CalendarDay';

  export default {
    name: 'CalendarBody',
    components: { CalendarDay },
    props: {
      events: {
        type: Array,
        required: true,
      },
      calendar: {
        type: Array,
      },
    },
    computed: {
      dayNameShort() {
        return ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
      },
    },
    methods: {
      printDay(day) {
        let dayIndex = parseInt(day) % 7;
        return this.dayNameShort[dayIndex];
      },
    },
  };
</script>

<style lang="scss">
  .calendar-body {
    display: block;
  }

  .days-header {
    color: #9B9B9B;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
    border: 1px solid #D8D8D8;
  }

  .day-number {
    padding: 10px 0 0 10px;
    text-align: left;
  }

  .day-label {
    text-align: center;
  }

  .week-row {
    display: flex;
    grid-row-gap: 5px;
    border-left: 1px solid #D8D8D8;
  }

  .week-day {
    position: relative;
    height: 10em;
    width: 100%;
    padding: 4px;
    border-right: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
  }

  .week-day.disabled {
    background-color: #f5f5f5;
  }

  .not-current,
  .not-current:hover {
    background: $lighterGrey !important;
  }

  .week-day.not-current > .day-number {
    color: #1F1F1F;
    opacity: .3;
  }

  .week-day.today > .day-number {
    color: transparent;
    font-weight: 700;

    &:before {
      color: $mainColor;
      content: 'Aujourd\'hui';
      text-transform: uppercase;
    }
  }
</style>

