
function BuildUrl(url, params) {
    const urlObject = new URL(url);

    Object.entries(params).map(([key, value]) => {
       urlObject.searchParams.append(key, value);
    });

    return urlObject.toString();
}

export default BuildUrl;