<template>
  <portal to="app-pop-in">
    <transition :duration="600">
      <div class="app-pop-in" v-if="opened">
        <div class="backdrop" :class="{ listened: hasCloseListener }" @click="$emit('close')" />
        <div class="container" :style="{ '--maxWidth': maxWidth }">
          <div class="content">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
  export default {
    name: 'AppPopIn',
    props: {
      opened: {
        type: Boolean,
        required: false,
        default: true,
      },
      maxWidth: {
        type: String,
        required: false,
        default: '60rem',
      },
    },
    computed: {
      hasCloseListener() {
        return !!this.$listeners.close;
      },
    },
  };
</script>


<style lang="scss" scoped>
  .app-pop-in {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2020;
    font-size: 1.4rem;
    font-weight: 200;
  }

  .backdrop {
    position: fixed;
    background: $gradientBackground;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    transition: opacity 0.6s;

    &.listened {
      cursor: pointer;
    }

    .v-enter &,
    .v-leave-to & {
      opacity: 0;
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: auto;
    background-color: #FFFFFF;
    width: 100%;
    max-width: var(--maxWidth);
    transform: translate(-50%, -50%);
    transition: opacity 0.6s;
    box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.3);

    .v-enter &,
    .v-leave-to & {
      opacity: 0;
    }
  }

  .content {
    padding: 2rem;
  }
</style>
