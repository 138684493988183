<template>
  <div v-if="appointment">
    <div v-if="!editingPrivateNote" :class="{ empty: !hasPrivateNote }" class="private-note">
      <FontAwesomeIcon :icon="$icons.edit" class="private-note-icon" @click.prevent="editingPrivateNote = true" />
      <div class="private-note-text">{{ hasPrivateNote ? appointment.payload.privateNote : 'Aucune note privée' }}</div>
      <div v-if="hasPrivateNote" class="private-note-comment">
        Cette note privée n'est pas visible par le patient
      </div>
    </div>

    <form v-else class="private-note-form" @submit.prevent="handlePrivateNoteSubmit">
      <FormWidget>
        <label for="private-note">Note privée (non visible par le patient)</label>
        <AutoHeightTextarea id="private-note" v-model.trim="privateNote" :disabled="isUpdatingPrivateNote" />
      </FormWidget>
      <ButtonGreen :busy="isUpdatingPrivateNote" small type="submit">Enregistrer</ButtonGreen>
      &nbsp;
      <ButtonRed :busy="isUpdatingPrivateNote" outlined small @click="editingPrivateNote = false">Annuler</ButtonRed>
    </form>
  </div>
</template>

<script>
  import FormWidget from '@/components/Form/FormWidget';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonRed from '@/components/Button/ButtonRed.vue';

  export default {
    name: 'AppointmentPanelPrivateNote',
    components: { ButtonRed, ButtonGreen, AutoHeightTextarea, FormWidget },
    data() {
      return {
        editingPrivateNote: false,
        privateNote: '',
      };
    },
    computed: {
      hasPrivateNote() {
        return !!this.appointment.payload.privateNote;
      },
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      isUpdatingPrivateNote() {
        return this.$store.state.appointments.isUpdatingPrivateNote;
      },
    },
    methods: {
      async handlePrivateNoteSubmit() {
        await this.$store.dispatch('appointmentsUpdatePrivateNote', {
          id: this.appointment.payload.id,
          privateNote: this.privateNote,
        });

        this.editingPrivateNote = false;
      },
    },
    watch: {
      editingPrivateNote(editingPrivateNote) {
        this.privateNote = editingPrivateNote ? this.appointment.payload.privateNote : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .private-note-form {
    font-family: $fontRoboto;
    font-size: 1.2rem;
  }

  .private-note.empty,
  .private-note-comment {
    font-style: italic;
    color: $grey;
  }

  .private-note-comment {
    margin-top: 1rem;
  }

  .private-note-text {
    white-space: pre-line;
  }

  .private-note-icon {
    float: right;
    cursor: pointer;
  }
</style>
