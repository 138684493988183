<template>
    <div class="calendar-header">
        <p class="go-today" @click.stop="goToToday"> Aujourd'hui </p>

        <p class="prev-month" @click.stop="goPrev">
            <FontAwesomeIcon class="nav-item-label-icon" :icon="$icons.chevronLeft"/>
        </p>
        <p class="next-month" @click.stop="goNext">
            <FontAwesomeIcon class="nav-item-label-icon" :icon="$icons.chevronRight"/>
        </p>

        <p class="title"> {{ month }} {{ year }} </p>
    </div>
</template>

<script>
    import calendarJs from '../../helpers/calendar';
    import moment from 'moment';

    export default {
        name: 'CalendarHeader',
        props: {
            monthStart: {
                type: Date
            }
        },
        data: function () {
            return {
                monthStartDays: this.monthStart
            }
        },
        computed: {
            year() {
                return moment(this.monthStartDays).get('year');
            },
            month() {
                return this.printMonth(moment(this.monthStartDays).get('month'));
            },
            monthNameLong() {
                return ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
            }
        },
        methods: {
            goPrev() {
                this.monthStartDays = calendarJs.shiftMonth(this.monthStartDays, -1);
            },
            goNext() {
                this.monthStartDays = calendarJs.shiftMonth(this.monthStartDays, 1);
            },
            goToToday() {
                this.monthStartDays = calendarJs.firstDateOfMonth();
            },
            printMonth(month) {
                return this.monthNameLong[month];
            }
        },
        watch: {
            monthStart(days) {
                this.monthStartDays = days;
            },
            monthStartDays(days) {
                this.$emit('month-changed', days);
            }
        }
    }
</script>

<style lang="scss">
    .calendar-header {
        display: block;

        p {
            display: inline-block;
        }

        .prev-month, .next-month, .go-today {
            cursor: pointer;
            user-select: none
        }

        .prev-month, .next-month, .title {
            font-weight: bold;
        }

        .go-today {
            cursor: pointer;
            color: #D8D8D8;
            border: 1px solid #D8D8D8;
            border-radius: 4px;
            padding: 10px 40px;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .prev-month, .next-month {
            padding: 5px;
            margin-right: 10px;
        }
    }

</style>
