<template>
  <label
    :class="{ disabled, focused: focused || autoFilled, 'has-value': !!value, 'has-error': !!$slots.error }"
    class="form-input"
    @focusin="focused = true"
    @focusout="focused = false"
  >
    <FontAwesomeIcon
      v-if="type === 'password'"
      :icon="showPassword ? $icons.passwordVisible : $icons.passwordHidden"
      class="icon"
      @click.stop="$emit('toggle-password-visibility')"
    />
    <span :id="id" :class="{ 'has-tel-input': type === 'tel' }" class="label">
      {{ label }}<template v-if="required"> *</template>
    </span>
    <slot name="input">
      <component
        :is="componentName"
        v-bind="$attrs"
        v-mask="mask"
        v-on="{ ...$listeners, input: handleInput }"
        :aria-disabled="disabled"
        :aria-labelledby="id"
        :class="{ 'vti_hack': vti_hack > 0 }"
        :disabled="disabled"
        :mode="type === 'tel' ? 'international' : undefined"
        :placeholder="focused ? placeholder : ''"
        :type="overriddenType"
        :value="value"
        class="input-component autofill-workaround"
        @animationstart="checkAutoFill"
        @close="vti_hack -= 1"
        @open="!vti_hack && (vti_hack = 2)"
        @country-changed="$emit('country-changed', $event)"
      />
    </slot>
    <span v-if="$slots.help && !$slots.error" class="help"><slot name="help" /></span>
    <span v-if="$slots.error" class="error"><slot name="error" /></span>
  </label>
</template>

<script>
  import { VueTelInput } from 'vue-tel-input';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import AutoFillWorkaround from '@/helpers/AutoFillWorkaround';

  export default {
    name: 'FormInput',
    inheritAttrs: false,
    components: { VueTelInput, AutoHeightTextarea },
    mixins: [AutoFillWorkaround],
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
        default: 'Entrer une valeur...',
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        required: false,
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      showPassword: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      mask: {
        required: false,
        default: false,
      },
    },
    data() {
      return {
        focused: false,
        vti_hack: 0,
      };
    },
    computed: {
      componentName() {
        switch (this.type) {
          case 'tel':
            return 'VueTelInput';
          case 'textarea':
            return 'AutoHeightTextarea';
          default:
            return 'input';
        }
      },
      overriddenType() {
        if (this.type === 'password') {
          return this.showPassword ? 'text' : 'password';
        }

        return this.type;
      },
    },
    methods: {
      handleInput($event, telValidation) {
        if ('tel' !== this.type) {
          return this.$emit('input', this.type === 'textarea' ? $event : $event.target.value);
        }

        if (telValidation.valid) {
          return this.$emit('input', telValidation.number.international);
        }

        return this.$emit('input', $event);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-input {
    font-family: $inputFont;
    font-weight: 300;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.3rem 0.5rem;
    border-bottom: 1px solid $inputBorderColor;
    background-color: $inputBackgroundColor;
    will-change: border-bottom-color;

    &.disabled {
      opacity: 0.5;
    }

    &.focused {
      border-bottom-color: $inputLabelFocusedColor;
    }

    &.has-error {
      border-bottom-color: $inputErrorColor;
    }
  }

  .label {
    font-size: $inputLabelFontSize;
    font-weight: 200;
    width: fit-content;
    padding: 0.8rem 0.5rem 0.5rem;
    transition: transform 150ms linear, color 150ms linear;
    transform: translateY(1.1rem) scale(1.2);
    transform-origin: left top;
    pointer-events: none;
    color: $inputLabelColor;
    will-change: transform, color;

    &.has-tel-input {
      transform: translateY(1.9rem) translateX(5.8rem) scale(1.333);
    }

    .has-value &, .focused & {
      transform: translateY(0) scale(1);
    }

    .has-value & {
      color: $inputLabelFilledColor;
    }

    .focused & {
      color: $inputLabelFocusedColor;
    }

    .has-error & {
      color: $inputErrorColor;
    }
  }

  input, textarea {
    font-size: 1.5rem;
    padding: 0.4rem 0.5rem 0.5rem;
    transition: border-color 150ms linear;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
  }

  .help, .error {
    font-size: 1.4rem;
    padding-top: 0.5rem;
  }

  .help {
    color: $inputHelpColor;
  }

  .error {
    font-weight: bold;
    color: $inputErrorColor;
  }

  .icon {
    position: absolute;
    top: 1.9rem;
    right: 1rem;
    cursor: pointer;
  }

  .vue-tel-input {
    border: none;
    border-radius: 0;

    &:focus-within {
      border-color: $inputBorderColor;
      box-shadow: none;
    }

    ::v-deep .vti__input {
      background-color: transparent;
      appearance: none;
    }

    &.vti_hack::v-deep .vti__dropdown-list {
      display: block !important;
    }
  }
</style>
