var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "credit-card-display-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("FormWidget", { staticClass: "col _12" }, [
            _c("label", { attrs: { for: "cardNumber" } }, [
              _vm._v("Numéro de carte")
            ]),
            _c("input", {
              attrs: { id: "cardNumber", readonly: "" },
              domProps: { value: _vm.cardNumber }
            })
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("FormWidget", { staticClass: "col _6" }, [
            _c("label", { attrs: { for: "cardExpiry" } }, [
              _vm._v("Date d'expiration")
            ]),
            _c("input", {
              attrs: { id: "cardExpiry", readonly: "" },
              domProps: { value: _vm.cardExpiry }
            })
          ]),
          _c("FormWidget", { staticClass: "col _6" }, [
            _c("label", { attrs: { for: "cardCvc" } }, [
              _vm._v("Cryptogramme")
            ]),
            _c("input", {
              attrs: { id: "cardCvc", value: "***", readonly: "" }
            })
          ])
        ],
        1
      ),
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("replace")
            }
          }
        },
        [_vm._v("\n    Remplacer ma carte\n  ")]
      ),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "security" }, [
      _c("img", {
        attrs: { alt: "", src: require("@/assets/images/picto-payment.svg") }
      }),
      _c("p", [
        _vm._v(
          "\n      Votre moyen de paiement est traité et sécurisé par Stripe, acteur certifié et accrédité par les autorités\n      bancaires européennes. Conformément à la directive européenne DSP2 et à l’authentification forte du client,\n      Stripe pourra être amené à vous authentifier à l'aide d'un code reçu par SMS.\n    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }