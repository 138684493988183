var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-item" },
    [
      _c(
        _vm.external ? "ExternalLink" : "router-link",
        {
          tag: "component",
          staticClass: "nav-item-label",
          class: { expanded: _vm.expanded },
          attrs: { to: _vm.to },
          nativeOn: {
            click: function($event) {
              return _vm.toggleSubMenu($event)
            }
          }
        },
        [
          _c("span", { staticClass: "nav-item-label-text" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _c(_vm.icon ? "FontAwesomeIcon" : "span", {
            tag: "component",
            staticClass: "nav-item-label-icon",
            attrs: { icon: _vm.icon }
          })
        ],
        1
      ),
      _vm.hasChildren
        ? _c(
            "div",
            { staticClass: "nav-item-children" },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }