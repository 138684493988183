var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppTabBar",
    { staticClass: "profile-tab-bar" },
    [
      _c("router-link", { attrs: { to: { name: "profile-edit" } } }, [
        _vm._v("Mon Profil")
      ]),
      !_vm.isSecretary
        ? _c("router-link", { attrs: { to: { name: "profile-act" } } }, [
            _vm._v("Mes Tarifs")
          ])
        : _vm._e(),
      !_vm.isSecretary
        ? _c("router-link", { attrs: { to: { name: "profile-settings" } } }, [
            _vm._v("Mes paramètres")
          ])
        : _vm._e(),
      !_vm.isSecretary
        ? _c(
            "router-link",
            { attrs: { to: { name: "profile-subscription" } } },
            [_vm._v("Mon abonnement")]
          )
        : _vm._e(),
      _c("router-link", { attrs: { to: { name: "profile-access" } } }, [
        _vm._v("Mes accès")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }