<template>
  <div class="full-panel">
    <div class="close">
      <router-link :to="{ name:'profile-act' }">
        <FontAwesomeIcon :icon="$icons.closePanel" />
        Fermer
      </router-link>
    </div>
    <div class="container">
      <div class="container-header">
        <div v-if="selectedProduct.id" class="title">Modifer un tarif de téléconsultation</div>
        <div v-else class="title">Ajouter un tarif</div>
        <div class="separator"></div>
      </div>

      <form class="container-body" @submit.prevent="handleSubmit">
        <FormWidget class="span4">
          <label for="name">Nom de l'acte</label>
          <input id="name" v-model="form.name" type="text">
          <template v-if="$v.form.name.$error" v-slot:error>
            <span>Champ obligatoire (65 caractères maximum)</span>
          </template>
        </FormWidget>

        <FormWidget class="form-control span2">
          <label for="price">Tarif</label>
          <input
            id="price"
            :value="form.unitPrice"
            type="text"
            @input="form.unitPrice = $inputToPrice($event.target.value)"
          >
          <div class="icon">€</div>
          <template v-if="$v.form.unitPrice.$error" v-slot:error>
            <span v-if="!$v.form.unitPrice.required">Champ obligatoire</span>
            <span v-if="!$v.form.unitPrice.decimal">Valeur incorrecte</span>
            <span v-if="!$v.form.unitPrice.minValue">Le tarif doit être supérieur ou égal à 0,50€ (ou gratuit)</span>
          </template>
        </FormWidget>
        <FormWidget class="span2">
          <label for="duration">Durée</label>
          <input id="duration" v-model="form.duration" type="text">
          <div class="icon">min</div>
          <template v-if="$v.form.duration.$error" v-slot:error>
            <span>Champ obligatoire (5 minutes minimum)</span>
          </template>
        </FormWidget>

        <FormWidget class="span4">
          <label for="channel">Canal de consultation</label>
          <SelectWrapper>
            <select id="channel" v-model="form.channel">
              <option value="remote">Téléconsultation</option>
              <option value="medical_office">En cabinet</option>
              <option value="home">À domicile</option>
            </select>
          </SelectWrapper>
          <template v-if="$v.form.channel.$error" v-slot:error>
            <span>Champ obligatoire</span>
          </template>
        </FormWidget>

        <FormWidget class="span4">
          <label for="information"> Informations particulières à destination du patient</label>
          <AutoHeightTextarea id="information" v-model="form.information"/>
          <template #help>Indiquez ici les informations nécessaires à la réalisation du rendez-vous pour le patient. Exemple : partager son ordonnance, son attestation de droits...</template>
        </FormWidget>

        <FormCheckbox class="span4 test" v-model="form.visible">
          Masquer cet acte auprès des patients
        </FormCheckbox>

        <hr class="separator span4">

        <div class="actions span4">
          <ButtonGreen :busy="isSaving" :icon="$icons.saveOutlined" outlined small type="submit">
            Enregistrer
          </ButtonGreen>

          <p class="btn-control cancel" @click="cancel">
            Annuler
          </p>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
  import FormWidget from '../../components/Form/FormWidget';
  import ButtonGreen from '../../components/Button/ButtonGreen.vue';
  import SelectWrapper from '../../components/Form/SelectWrapper';
  import { decimal, maxLength, minValue, numeric, or, required } from 'vuelidate/lib/validators';
  import FormCheckbox from '@/components/Form/FormCheckbox';
  import AutoHeightTextarea from "@/components/Form/AutoHeightTextarea";

  export default {
    name: 'profile-add-act-page',
    components: {AutoHeightTextarea, FormCheckbox, SelectWrapper, ButtonGreen, FormWidget },
    props: {
      selectedProduct: Object,
    },
    data() {
      return {
        form: {
          name: null,
          unitPrice: null,
          duration: null,
          channel: null,
          visible: true,
          information: null,
        },
      };
    },
    mounted() {
      this.form = {
        name: this.selectedProduct.name,
        unitPrice: null === this.selectedProduct.unitPrice ? null : (this.selectedProduct.unitPrice / 100).toString(),
        duration: this.selectedProduct.duration,
        channel: this.selectedProduct.channel,
        visible: !this.selectedProduct.visible,
        information: this.selectedProduct.information,
      };
    },
    validations: {
      form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        unitPrice: {
          required,
          decimal,
          minValue: or(minValue(0.5), (value) => value === '0'),
        },
        duration: {
          required,
          numeric,
          minValue: minValue(5),
        },
        channel: {
          required,
        },
      },
    },
    computed: {
      isSaving() {
        return this.$store.state.products.isCreating || this.$store.state.products.isUpdating;
      },
    },
    methods: {
      async handleSubmit() {
        this.$v.$touch();

        if (this.$v.$anyError) {
          this.$addError('Le formulaire contient des erreurs');
          return false;
        }

        let data = {
          unitPrice: Math.round(this.form.unitPrice * 100),
          name: this.form.name,
          duration: this.form.duration,
          channel: this.form.channel,
          visible: !this.form.visible,
          rules: this.form.information
        };

        try {
          if (this.selectedProduct.id) {
            await this.$store.dispatch('productsUpdate', { id: this.selectedProduct.id, data });
          } else {
            await this.$store.dispatch('productsCreate', data);
          }

          await this.$router.push({ name: 'profile-act' });
        } catch (e) {
          this.$addError('Une erreur s\'est produite.');
          throw e;
        }
      },
      cancel() {
        this.$router.push({ name: 'profile-act' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .full-panel {
    position: fixed;
    top: 10rem;
    right: 0;
    bottom: 0;
    overflow: auto;
    width: 100vw;
    transition: transform 0.6s;
    transform: translate3d(0, 0, 0);
    background-color: #FFFFFF;
    padding-top: 3rem;

    @include onDesktop {
      top: 0;
      padding-left: 8rem;
    }
  }

  .close {
    font-size: 1.5em;
    position: absolute;
    cursor: pointer;
    text-align: right;
    top: 1.5rem;
    right: 2rem;

    @include onDesktop {
      top: 7rem;
      right: 7rem;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .container {
    @include onDesktop {
      padding: 8rem;
    }
  }


  .container-header {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .title {
      font-family: $titleFont;
      font-size: 2em;
      font-weight: bold;
      padding: 0.6rem 1.1em;
      color: #1F1F1F;
    }

    .separator {
      position: absolute;
      width: 125px;
      border-top: 4px solid #27D7AB;
    }
  }

  .container-body {
    display: grid;
    max-width: 600px;
    margin: 5rem auto;
    grid-template-columns: 1fr 1fr;
    padding: 0 1rem;

    div {
      font-size: 1.2em;
    }

    .separator {
      margin: 2rem 0;
    }
  }

  .form-control {
    margin-right: 15px
  }

  .separator {
    margin: 40px 0;
    border: 0;
    border-top: 1px solid #D8D8D8;
  }

  .cancel {
    font-family: $buttonFont;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    color: #9B9B9B;
  }

  .icon {
    font-size: 1.5em !important;;
    font-weight: 400;
    position: absolute;
    right: 1.5rem;
    top: 3.7rem;
  }

  .form-widget {
    position: relative;
  }

  .actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
  }


  .span1 {
    grid-column-end: span 1;
  }

  .span2 {
    grid-column-end: span 2;
  }

  .span3 {
    grid-column-end: span 3;
  }

  .span4 {
    grid-column-end: span 4;
  }

  .grid1 {
    grid-column: 1;
  }

  .grid3 {
    grid-column: 3;
  }

</style>
