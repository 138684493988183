<template>
  <AppLayout>
    <main class="edit-profile-page">
      <ProfileTabBar ref="profileTabBar" />
      <form @submit.prevent="handleSubmit">
        <div class="container">
          <div class="row">
            <FormWidget class="col _3">
              <label for="civility">Civilité</label>
              <SelectWrapper>
                <select id="civility" v-model="form.civility">
                  <option :value="null" v-if="!form.civility" />
                  <option value="dr">Docteur</option>
                  <option value="dre">Docteure</option>
                  <option value="pr">Professeur</option>
                  <option value="pre">Professeure</option>
                  <option value="m">Monsieur</option>
                  <option value="mme">Madame</option>
                </select>
              </SelectWrapper>
            </FormWidget>
            <FormWidget class="col _3">
              <label for="first-name">Prénom *</label>
              <input id="first-name" type="text" v-model="form.firstName">
              <template #error v-if="$v.form.firstName.$error">
                Champ obligatoire
              </template>
            </FormWidget>
            <FormWidget class="col _3">
              <label for="last-name">Nom *</label>
              <input id="last-name" type="text" v-model="form.lastName">
              <template #error v-if="$v.form.lastName.$error">
                Champ obligatoire
              </template>
            </FormWidget>
            <FormWidget class="col _3">
              <label for="birth-date">Date de naissance *</label>
              <input
                id="birth-date"
                type="text"
                v-mask="'##/##/####'"
                v-model="form.birthDate"
                placeholder="jj/mm/aaaa"
              >
              <template #error v-if="$v.form.birthDate.$error">
                Date de naissance invalide
              </template>
            </FormWidget>
          </div>

          <div class="row" v-if="!isSecretary">
            <FormWidget class="col _5">
              <label for="convention">Quelle convention ? *</label>
              <SelectWrapper>
                <select id="convention" v-model="form.convention">
                  <option :value="null" v-if="!form.convention" />
                  <option :value="convention" v-for="convention in conventions">{{ convention.name }}</option>
                </select>
              </SelectWrapper>
              <template #error v-if="$v.form.convention.$error">
                Champ obligatoire
              </template>
            </FormWidget>
            <FormWidget class="col _4">
              <label for="rpps">Numéro RPPS</label>
              <input id="rpps" type="text" v-model="form.rpps" maxlength="11" minlength="11">
              <template #error v-if="$v.form.rpps.$error">
                <span v-if="!$v.form.rpps.unique">Ce numéro RPPS est déjà utilisé</span>
                <span v-else-if="!$v.form.rpps.length">Doit contenir 11 caractères</span>
              </template>
            </FormWidget>
            <FormWidget class="col _3">
              <label for="adeli">Numéro ADELI / AM</label>
              <input id="adeli" type="text" v-model="form.adeli" maxlength="9" minlength="9">
              <template #error v-if="$v.form.adeli.$error">
                <span v-if="!$v.form.adeli.unique">Ce numéro ADELI est déjà utilisé</span>
                <span v-else-if="!$v.form.adeli.length">Doit contenir 9 caractères</span>
              </template>
            </FormWidget>
          </div>

          <div class="row" v-if="!isSecretary">
            <FormWidget class="col _6" :class="{ highlighted: $route.query.hl === 'mainSpecialty' }" ref="mainSpecialty">
              <label for="specialty">Votre spécialité *</label>
              <SelectWrapper>
                <select id="specialty" v-model="form.mainSpecialty">
                  <option :value="null" v-if="!form.mainSpecialty" />
                  <option :value="specialty" v-for="specialty in specialties">
                    {{ specialty.name }}
                  </option>
                </select>
              </SelectWrapper>
              <template #error v-if="$v.form.mainSpecialty.$error">
                Champ obligatoire
              </template>
            </FormWidget>
            <FormWidget class="col _6">
              <label for="specialties">Autres spécialités</label>
              <MultiSelect
                v-model="form.specialties"
                :close-on-select="false"
                :multiple="true"
                :options="availableSecondarySpecialties"
                :searchable="true"
                :show-labels="false"
                id="specialties"
                placeholder="Sélectionnez..."
                track-by="id"
                label="name"
              >
              </MultiSelect>
            </FormWidget>
          </div>

          <div class="row">
            <FormWidget class="col _12">
              <label for="languages">Langues parlées</label>
              <MultiSelect
                  v-model="form.languages"
                  :close-on-select="false"
                  :multiple="true"
                  :searchable="true"
                  :options="languages"
                  :show-labels="false"
                  id="languages"
                  placeholder="Sélectionnez..."
                  track-by="id"
                  label="name"
              >
              </MultiSelect>
            </FormWidget>
          </div>

          <div class="row">
            <div class="group-form file-document col _4" v-if="!isSecretary">
              <FormWidget>
                <label for="signature">Votre signature numérisée</label>
                <input
                  ref="signature"
                  @click="handleSignatureClick"
                  @change="handleSignatureFileChange"
                  accept=".jpg, .png"
                  class="file-input"
                  id="signature"
                  type="file"
                />
                <div class="file-name" v-if="signatureFile">
                  <FontAwesomeIcon :icon="$icons.signature" size="1x" />
                  {{ signatureFile && signatureFile.name }}
                </div>
                <FontAwesomeIcon :icon="$icons.upload" class="file-icon" size="1x" />
                <template #help>
                  jpg ou png - 2 Mo maximum
                  <span class="file-sent" v-if="profile.uploadedSignature">
                    Signature déjà envoyée <FontAwesomeIcon :icon="$icons.accept" size="1x" />
                  </span>
                </template>
              </FormWidget>
              <AppPopIn :opened="isSignaturePadVisible">
                <SignaturePad
                  @select-file="handleSignaturePadSelectFile"
                  @save="handleSignaturePadSave"
                  @cancel="isSignaturePadVisible = false"
                />
              </AppPopIn>
            </div>
            <div class="group-form file-document col _4" v-if="isSecretary">
              <FormWidget>
                <label for="id-card">Votre attestation d'emploi</label>
                <input
                    @change="handleEmploymentAttestationFileChange"
                    accept=".jpg, .png"
                    class="file-input"
                    id="employment-attestation"
                    type="file"
                />
                <div class="file-name" v-if="employmentAttestationFile">
                  <FontAwesomeIcon :icon="$icons.idCard" size="1x" />
                  {{ employmentAttestationFile && employmentAttestationFile.name }}
                </div>
                <FontAwesomeIcon :icon="$icons.upload" class="file-icon" size="1x" />
                <template #help>
                  jpg ou png - 2 Mo maximum
                  <span class="file-sent" v-if="profile.uploadedEmploymentAttestation">
                    Attestation employeur déjà envoyée <FontAwesomeIcon :icon="$icons.accept" size="1x" />
                  </span>
                </template>
              </FormWidget>
            </div>

            <div class="group-form file-document col _4">
              <FormWidget>
                <label for="id-card">Votre pièce d’identité numérisée</label>
                <input
                  @change="handleIdCardFileChange"
                  accept=".jpg, .png"
                  class="file-input"
                  id="id-card"
                  type="file"
                />
                <div class="file-name" v-if="idCardFile">
                  <FontAwesomeIcon :icon="$icons.idCard" size="1x" />
                  {{ idCardFile && idCardFile.name }}
                </div>
                <FontAwesomeIcon :icon="$icons.upload" class="file-icon" size="1x" />
                <template #help>
                  jpg ou png - 2 Mo maximum
                  <span class="file-sent" v-if="profile.uploadedLegalDocument">
                    Pièce d'identité déjà envoyée <FontAwesomeIcon :icon="$icons.accept" size="1x" />
                  </span>
                </template>
              </FormWidget>
            </div>

            <div class="group-form col _4">
              <FormWidget>
                <label for="timezone">Fuseau horaire *</label>
                <SelectWrapper>
                  <TimezoneSelector id="timezone" v-model="form.timezone" />
                </SelectWrapper>
                <template #help>&nbsp;</template>
                <template #error v-if="$v.form.timezone.$error">
                  <span>
                    Champ obligatoire
                  </span>
                </template>
              </FormWidget>
            </div>
          </div>

          <div class="row" v-if="!isSecretary">
            <div class="group-form col _12">
              <FormWidget class="textarea">
                <label for="bio">Présentez vous en quelques lignes</label>
                <AutoHeightTextarea
                  id="bio"
                  v-model="form.description"
                  placeholder="Spécialités, années d'expériences, diplômes, langues parlées, remplaçants, infos sur votre façon de pratiquer, site web..."
                />
              </FormWidget>
            </div>
          </div>
          <template v-if="!isSecretary">
            <SectionTitle class="territorial-organization-title">Mon organisation territoriale</SectionTitle>
            <div class="row">
              <FormWidget class="col _6">
                <label for="to-type">Sélectionnez le type d'organisation</label>
                <SelectWrapper>
                  <select id="to-type" v-model="form.territorialOrganizationType">
                    <option :value="null">Je ne fais pas partie d'une organisation territoriale</option>
                    <option value="cpts">CPTS</option>
                    <option value="esp">ESP</option>
                    <option value="msp">MSP</option>
                    <option value="cds">CDS</option>
                    <option value="other">Autre</option>
                  </select>
                </SelectWrapper>
                <template
                  v-if="$v.form.territorialOrganizationType.$dirty && !$v.form.territorialOrganizationType.valid"
                  #error
                >
                  Veuillez sélectionner un type d'organisation
                </template>
                <template #help>
                  Si vous faites partie de plusieurs organisations, merci de choisir celle avec la territorialité la plus
                  étendue
                </template>
              </FormWidget>
              <FormWidget v-if="form.territorialOrganizationType" class="col _6">
                <label for="to-name">Nom de votre organisation territoriale</label>
                <input id="to-name" v-model="form.territorialOrganizationName" maxlength="128" />
                <template
                  v-if="$v.form.territorialOrganizationName.$dirty && !$v.form.territorialOrganizationName.valid"
                  #error
                >
                  <template v-if="!$v.form.territorialOrganizationName.length">128 caractères maximum</template>
                </template>
              </FormWidget>
            </div>
          </template>
          <SectionTitle class="address-title" ref="practiceAddress">Mon adresse d'exercice</SectionTitle>
          <div class="row">
            <FormWidget class="col _4" :class="{ highlighted: $route.query.hl === 'practiceAddress' }">
              <label for="address">Adresse du cabinet *</label>
              <input id="address" type="text" v-model="form.practiceAddress">
            </FormWidget>

            <FormWidget class="col _2" :class="{ highlighted: $route.query.hl === 'practiceAddress' }">
              <label for="cp">Code Postal *</label>
              <input id="cp" type="text" v-model="form.practiceZipCode">
            </FormWidget>

            <FormWidget class="col _3" :class="{ highlighted: $route.query.hl === 'practiceAddress' }">
              <label for="ville">Ville *</label>
              <input id="ville" type="text" v-model="form.practiceCity">
            </FormWidget>

            <FormWidget class="col _3">
              <label for="practiceCountry">Pays *</label>
              <MultiSelect
                  v-model="form.practiceCountry"
                  :options="countries"
                  :show-labels="false"
                  id="practiceCountry"
                  placeholder="Sélectionnez..."
                  track-by="code"
                  label="country"
              >
              </MultiSelect>
              <template #error v-if="$v.form.practiceCountry.$error">
                Champ obligatoire
              </template>
            </FormWidget>
          </div>
          <div class="row">
            <FormWidget class="col _6">
              <label for="telephone">Téléphone du cabinet</label>
              <vue-tel-input default-country="FR" id="telephone" v-model="form.practicePhone" mode="international" placeholder=""></vue-tel-input>
            </FormWidget>

            <FormWidget class="col _6">
              <label for="mail-protected">Votre adresse email sécurisée</label>
              <input id="mail-protected" type="text" v-model="form.securedEmail">
              <template #error v-if="$v.form.securedEmail.$error">
                <span v-if="!$v.form.securedEmail.email">Email incorrect</span>
              </template>
            </FormWidget>
          </div>
          <template v-if="!isSecretary">
            <SectionTitle class="billing-title">Facturation</SectionTitle>
            <div class="row">
              <FormWidget class="col _12">
                <label for="iban">IBAN</label>
                <input id="iban" type="text" v-model="form.iban">
                <template #error v-if="$v.form.iban.$error && !$v.form.iban.isValid">Cet IBAN est invalide</template>
              </FormWidget>
            </div>
          </template>
          <SectionTitle class="account-title">Mon compte</SectionTitle>
          <div class="row">
            <FormWidget class="col _6">
              <label for="mail">Votre adresse email</label>
              <input id="mail" type="text" :value="form.email" disabled>
              <template #error v-if="$v.form.email.$error">
                <span v-if="!$v.form.email.required">Champ obligatoire</span>
                <span v-else-if="!$v.form.email.email">Email incorrect</span>
              </template>
            </FormWidget>
            <FormWidget class="col _6">
              <label for="mobile">Téléphone mobile</label>
              <vue-tel-input id="mobile" :value="form.phone" disabled mode="international" placeholder="" />
            </FormWidget>
          </div>

          <div v-if="!this.profile.deletedAt">
            <a
                href="#"
                @click.prevent
                @click="$confirm($refs.deleteProfile)"
                class="delete"
            >
              Supprimer mon compte
            </a>
          </div>
          <AppConfirmation
              class="test1"
              ref="deleteProfile"
              @confirm="deleteAccount"
              title="Supprimer mon compte"
          >
            <template v-slot:description="scoped"> En confirmant la suppression de votre compte, vos données personnelles seront effacées sous 15 jours. Si des consultations ont été effectuées, les données médicales liées à ces consultations seront conservées pendant 10 ans (durée légale obligatoire). Pour plus d'informations, veuillez contacter confidentialite@hellocare.com<br> Vous allez être déconnecté de votre compte. </template>

          </AppConfirmation>
        </div>

        <footer>
          <ButtonGreen
            :busy="submitting"
            class="edit"
            outlined
            small
            type="submit"
            :icon="$icons.chevronRight"
          >
            Enregistrer
          </ButtonGreen>
        </footer>
      </form>
    </main>
  </AppLayout>
</template>

<script>
  import { some } from 'lodash-es';
  import moment from 'moment';
  import MoveTo from 'moveto';
  import MultiSelect from 'vue-multiselect';
  import { VueTelInput } from 'vue-tel-input';
  import {and, email, maxLength, minLength, required, requiredIf} from 'vuelidate/lib/validators';
  import AppConfirmation from "@/components/AppConfirmation";
  import AppLayout from '@/components/AppLayout';
  import AppPopIn from '@/components/AppPopIn';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import FormCheckbox from '@/components/Form/FormCheckbox';
  import FormWidget from '@/components/Form/FormWidget';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import SectionTitle from '@/components/SectionTitle';
  import SelectWrapper from '@/components/Form/SelectWrapper';
  import SignaturePad from '@/components/SignaturePad';
  import TimezoneSelector from '@/components/TimezoneSelector';
  import { convertDateToDash } from '@/helpers/format';
  import { checkBirthDate, checkIBAN } from '@/helpers/validation';
  import { countries } from 'countries-list';

  export default {
    name: 'ProfileEditPage',
    components: {
      SignaturePad,
      AppPopIn,
      FormCheckbox,
      ProfileTabBar,
      TimezoneSelector,
      AutoHeightTextarea,
      SelectWrapper,
      AppLayout,
      SectionTitle,
      FormWidget,
      ButtonGreen,
      MultiSelect,
      AppConfirmation,
      VueTelInput,
    },
    created() {
      this.loadData();
    },
    mounted() {
      this.scrollToHighlight();
      this.setupGuide();
    },
    data() {
      return {
        form: {
          convention: null,
          firstName: null,
          lastName: null,
          civility: null,
          birthDate: null,
          email: null,
          phone: null,
          country: null,
          rpps: null,
          adeli: null,
          healthInsuranceNumber: null,
          description: null,
          practiceAddress: null,
          practiceZipCode: null,
          practiceCountry: null,
          practiceCity: null,
          practicePhone: null,
          securedEmail: null,
          timezone: null,
          mainSpecialty: null,
          specialties: [],
          languages: [],
          iban: '',
          territorialOrganizationType: null,
          territorialOrganizationName: null,
        },
        isSignaturePadEnabled: true,
        isSignaturePadVisible: false,
        idCardFile: null,
        signatureFile: null,
        employmentAttestationFile: null,
        submitErrors: null,
        allCountries: [],
      };
    },
    validations: {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        convention: {
          required: requiredIf(function () { return !this.isSecretary; }),
        },
        timezone: {
          required,
        },
        mainSpecialty: {
          required: requiredIf(function () { return !this.isSecretary; }),
        },
        birthDate: {
          required: requiredIf(function () { return !this.isSecretary; }),
          isValid: checkBirthDate
        },
        email: {
          required,
          email,
        },
        securedEmail: {
          email,
        },
        practiceCountry: {
          required: requiredIf(function () { return !this.isSecretary; }),
        },
        rpps: {
          length: and(minLength(11), maxLength(11)),
          unique: function (rpps) {
            if (!this.submitErrors) {
              return true;
            }
            return !some(this.submitErrors.details, detail => detail.field === 'rpps' && detail.message === 'Already used' && rpps === detail.value);
          },
        },
        adeli: {
          length: and(minLength(9), maxLength(9)),
          unique: function (adeli) {
            if (!this.submitErrors) {
              return true;
            }
            return !some(this.submitErrors.details, detail => detail.field === 'adeli' && detail.message === 'Already used' && adeli === detail.value);
          },
        },
        iban: {
          isValid: checkIBAN,
        },
        territorialOrganizationType: {
          valid: (type) => [null, 'cpts', 'esp', 'msp', 'cds', 'other'].includes(type),
        },
        territorialOrganizationName: {
          length: maxLength(128),
        },
      },
    },
    methods: {
      getAllCountries() {
        return Object.entries(countries).map(country => {
            return {
                code: country[0],
                country: country[1].name
            };
        });
      },
      getPracticeCountryData(code) {
        return this.getAllCountries().find(country => country.code === code);
      },
      async deleteAccount() {
        try {
          await this.$store.dispatch('patientDeleteAccount');
          await this.$router.push('/logout');
        } catch (e) {
          await this.$addError('Une erreur s\'est produite lors de la suppression du compte.');
          throw e;
        }
      },
      async setupGuide() {
        await this.$store.dispatch('vueGuideAddStep', {
          id: 'profile-tab-bar',
          target: () => this.$refs.profileTabBar.$el,
          content: 'N\'oubliez pas de compléter tous les onglets du profil.',
          offset: { x: 0, y: 10 },
        });
      },
      scrollToHighlight() {
        const highlight = this.$route.query.hl;

        if (!highlight) {
          return;
        }

        const ref = this.$refs[highlight];

        if (!ref || !ref.$el) {
          return;
        }

        new MoveTo({ tolerance: 110 }).move(ref.$el);
      },
      async loadData() {
        await this.$store.dispatch('appLoaderShow');

        try {
          await Promise.all([
            this.$store.dispatch('specialtiesFetchAll'),
            this.$store.dispatch('conventionsFetchAll'),
            this.$store.dispatch('languagesFetchActive'),
          ]);
        } catch (e) {
          await this.$addError('Une erreur s\'est produite lors du chargement de votre profil.');
          throw e;
        } finally {
          this.form =  {
            civility: this.profile.civility,
            firstName: this.profile.firstName,
            lastName: this.profile.lastName,
            birthDate: this.profile.birthDate ? moment(this.profile.birthDate).format('DD/MM/YYYY') : '',
            convention: this.profile.convention,
            rpps: this.profile.rpps,
            adeli: this.profile.adeli,
            healthInsuranceNumber: this.profile.healthInsuranceNumber,
            mainSpecialty: this.profile.mainSpecialty,
            specialties: this.profile.specialties,
            languages: this.profile.languages,
            country: this.profile.country,
            timezone: this.profile.timezone,
            description: this.profile.description,
            practiceAddress: this.profile.practiceAddress,
            practiceZipCode: this.profile.practiceZipCode,
            practiceCity: this.profile.practiceCity,
            practiceCountry: this.getPracticeCountryData(this.profile.practiceCountry),
            email: this.profile.email,
            securedEmail: this.profile.securedEmail,
            phone: this.profile.phone,
            practicePhone: this.profile.practicePhone,
            iban: this.profile.iban,
            territorialOrganizationType: this.profile.territorialOrganizationType,
            territorialOrganizationName: this.profile.territorialOrganizationName,
          };

          await this.$store.dispatch('appLoaderHide');
        }
      },
      handleSignatureClick(event) {
        if (this.isSignaturePadEnabled) {
          event.preventDefault();
          this.isSignaturePadVisible = true;
        }
      },
      handleSignaturePadSelectFile() {
        this.isSignaturePadEnabled = false;
        this.isSignaturePadVisible = false;
        this.$nextTick(() => {
          this.$refs.signature.click();
          this.isSignaturePadEnabled = true;
        })
      },
      handleSignaturePadSave({ file }) {
        this.signatureFile = file;
        this.isSignaturePadVisible = false;
      },
      handleSignatureFileChange(event) {
        return this.signatureFile = event.target.files[0];
      },
      handleIdCardFileChange(event) {
        return this.idCardFile = event.target.files[0];
      },
      handleEmploymentAttestationFileChange(event) {
        return this.employmentAttestationFile = event.target.files[0];
      },
      async handleSubmit() {
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.$addError('Le formulaire contient une ou plusieurs erreurs');
          return false;
        }

        const data = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          civility: this.form.civility,
          birthDate: convertDateToDash(this.form.birthDate),
          email: this.form.email,
          phone: this.form.phone,
          country: this.form.country,
          rpps: this.form.rpps,
          adeli: this.form.adeli,
          healthInsuranceNumber: this.form.adeli,
          description: this.form.description,
          practiceAddress: this.form.practiceAddress,
          practiceZipCode: this.form.practiceZipCode,
          practiceCity: this.form.practiceCity,
          practicePhone: this.form.practicePhone,
          securedEmail: this.form.securedEmail,
          timezone: this.form.timezone,
          convention: this.form.convention?.id,
          mainSpecialty: this.form.mainSpecialty?.id,
          specialties: this.form.specialties.map(specialty => specialty.id),
          languages: this.form.languages.map(language => language.id),
          practiceCountry: this.form.practiceCountry?.code,
          iban: this.form.iban,
          territorialOrganizationType: this.form.territorialOrganizationType,
          territorialOrganizationName: this.form.territorialOrganizationType ? this.form.territorialOrganizationName : null,
        };

        let errors = [];
        let signaturePromise = Promise.resolve();
        let idCardPromise = Promise.resolve();
        let employmentAttestationPromise = Promise.resolve();
        const profilePromise = this.$store.dispatch('practitionerEditProfile', data);

        if (this.signatureFile) {
          signaturePromise = this.$store.dispatch('practitionerUploadSignature', { file: this.signatureFile });
        }

        if (this.idCardFile) {
          idCardPromise = this.$store.dispatch('practitionerUploadIdCard', { file: this.idCardFile });
        }

        if (this.employmentAttestationFile) {
          employmentAttestationPromise = this.$store.dispatch('practitionerUploadEmploymentAttestation', { file: this.employmentAttestationFile });
        }

        try {
          await signaturePromise;
        } catch (e) {
          errors.push('Une erreur est survenue lors de l\'envoi de votre signature');
        }

        try {
          await idCardPromise;
        } catch (e) {
          errors.push('Une erreur est survenue lors de l\'envoi de votre carte d\'identité');
        }

        try {
          await employmentAttestationPromise;
        } catch (e) {
          errors.push('Une erreur est survenue lors de l\'envoi de votre attestation d\'emploi');
        }

        try {
          await profilePromise;

          if (errors.length) {
            this.$addSuccess('Le reste de votre profil a été mis à jour.');
          } else {
            this.$addSuccess('Votre profil a été mis à jour.');
          }
        } catch (e) {
          errors = ['Une erreur est survenue lors de la mise à jour de votre profil'];
          if (e.response) {
            this.submitErrors = await e.response.json();
          }
        }

        errors.forEach(error => this.$addError(error));
      },
    },
    computed: {
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      countries() {
        return this.getAllCountries();
      },
      profile() {
        return this.$store.state.practitioner.profile;
      },
      conventions() {
        return this.$store.state.conventions.all;
      },
      specialties() {
        return this.$store.state.specialties.all;
      },
      languages() {
        return this.$store.state.languages.languages
      },
      submitting() {
        return this.$store.state.practitioner.isUploadingSignature
          || this.$store.state.practitioner.isUploadingCardId
          || this.$store.state.practitioner.isUpdatingProfile;
      },
      availableSecondarySpecialties() {
        if (!this.form.mainSpecialty) {
          return this.specialties;
        }
        return this.specialties.filter(specialty => specialty.id !== this.form.mainSpecialty.id);
      },
    },
    watch: {
      'form.mainSpecialty': function (mainSpecialty) {
        this.form.specialties = this.form.specialties.filter(specialty => specialty.id === mainSpecialty.id);
      },
    },
  };
</script>

<style lang="scss" src='pretty-checkbox/src/pretty-checkbox.scss'></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
  @import "../../assets/grid";

  .edit-profile-page form {
    padding: 0 1rem;
  }

  .address-title,
  .billing-title,
  .account-title,
  .territorial-organization-title {
    margin-top: 3rem;
  }

  .vue-tel-input {
    border: 0;

    &:focus-within {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }

  .multiselect::v-deep {
    min-height: 33px;

    .multiselect__placeholder {
      color: #1F1F1F;
      font-size: 1.1em;
      font-weight: normal;
    }

    .multiselect__content {
      font-weight: normal;
    }

    .multiselect__tags {
      border: none;
      height: 33px;
      overflow: hidden;
      min-height: 33px;
      padding: 3px 40px 0 8px;
      background: transparent;
    }

    .multiselect__option--highlight,
    .multiselect__tag {
      background: $successColor;
    }

    .multiselect__select {
      height: 28px;
    }

    .multiselect__option {
      padding: 9px 12px;
      font-size: 1em;
      min-height: 0;
    }

    .multiselect__single {
      font-weight: normal;
    }

    .multiselect__tag-icon:hover {
      background: #F46868;
      border-radius: 0;
    }

    input {
      padding: 0;
    }
  }

  .file-document {
    position: relative;

    .file-input {
      z-index: 2;
      opacity: 0;
    }

    .file-name {
      position: absolute;
      top: 38px;
      padding-left: 1rem;
      font-size: 1em !important;

      svg {
        margin-right: 0.8rem;
      }
    }

    .file-icon {
      position: absolute;
      bottom: 40px;
      right: 0;
      margin: 20px 10px;
      z-index: 1;
    }
  }

  .file-sent {
    color: $successColor;
    font-weight: bold;
  }

  footer {
    margin-bottom: 30px;
    text-align: center;

    button {
      span {
        font-size: 1.5em;
      }
    }
  }

  .group-form {
    font-size: 1em !important;

    .textarea {
      height: calc(100% - .9em);

      textarea {
        resize: none;
        height: 100%;
      }
    }
  }

  .checkbox {
    margin-bottom: 2rem;
  }

  .form-widget.highlighted {
    box-shadow: 0 0 1rem 0.2rem $successColor;
  }
  .delete {
    color: $errorColor;
    text-decoration: underline;
  }
   .buttons {
    flex-wrap: wrap;
  }
  ::v-deep .multiselect__single {
    background: initial;
  }
</style>