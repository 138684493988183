<template>
  <AppLoader v-if="isLoadingPatients">
    Chargement des patients en cours...
  </AppLoader>
  <AppLayout v-else>
    <main>
      <SectionTitle>Mes patients</SectionTitle>

      <div class="letters">
        <span v-for="letter in letters" class="letter">
          <a :href="`#letter-${letter}`">{{ letter }}</a>
        </span>
      </div>
      <div v-for="(secretaryOf, index) in secretariesOf" class="">
        <div class="practitioner-filter-entry">
          <FormCheckbox v-model="secretariesChecked[index].isChecked">{{getFullName(secretaryOf.first_name, secretaryOf.last_name)}}</FormCheckbox>
        </div>
      </div>

      <FormWidget transparent>
        <input id="patient-filter" v-model="filter" placeholder="Filtrer par nom, email et/ou numéro de téléphone" />
      </FormWidget>

      <div v-if="!patients.length" class="no-results">Aucun patient trouvé</div>

      <template v-for="(patients, letter) in patientsByFirstLetter">
        <HorizontalDivider :id="`letter-${letter}`" class="patients-divider" left>
          {{ letter }}
        </HorizontalDivider>
        <div class="patient-items">
          <router-link
            v-for="patient in patients"
            :to="{ name: 'patient', params: { id: patient.id } }"
            class="patient-list-item"
          >
            <UserAvatar :src="patient.picture" :user-name="toFullName(patient)" size="6rem" />
            <div class="content">
              <span class="patient-name">
                {{ toFullName(patient) }}
              </span>
              <p class="birth-date">
                {{ patient.birthDate | date('DD/MM/YYYY') }} ({{ patient.birthDate | age }})
              </p>
            </div>
            <div class="icon-container">
              <FontAwesomeIcon :icon="$icons.viewPatient" />
            </div>
          </router-link>
        </div>
      </template>
    </main>
  </AppLayout>
</template>

<script>
  import AppLoader from '@/components/AppLoader';
  import AppPanel from '@/components/AppPanel.vue';
  import NewAppointmentForm from '@/components/Dashboard/NewAppointmentForm.vue';
  import FormWidget from '@/components/Form/FormWidget';
  import { getFullName } from '@/helpers/format';
  import { groupBy, sortBy } from 'lodash-es';
  import AppLayout from '../components/AppLayout';
  import HorizontalDivider from '../components/HorizontalDivider';
  import SectionTitle from '../components/SectionTitle';
  import UserAvatar from '../components/UserAvatar';
  import FormCheckbox from "@/components/Form/FormCheckbox.vue";

  export default {
    name: 'PatientsPage',
    components: {
      FormCheckbox,
      NewAppointmentForm,
      AppPanel,
      FormWidget,
      AppLoader,
      UserAvatar,
      HorizontalDivider,
      SectionTitle,
      AppLayout,
    },
    data() {
      const secretariesChecked = this.$store.state.practitioner.profile.secretariesOf.map(s => ({
        id:s.id,
        isChecked:true
      }))
      secretariesChecked.push({id:this.$store.state.practitioner.profile.id, isChecked:true})
      return {
        secretariesChecked,
        filter: '',
        appointmentFormPanel: {
          isOpen: false,
          patient: null,
        },
      };
    },
    async beforeMount() {
      await this.$store.dispatch('patientsFetchAll');
    },
    computed: {
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      secretariesOf() {
        return this.$store.state.practitioner.profile.secretariesOf;
      },
      isLoadingPatients() {
        return this.$store.state.patients.isFetchingAll;
      },
      letters() {
        return Object.keys(this.patientsByFirstLetter).sort();
      },
      patients() {
        let filter = this.filter;

        // Replace successive spaces
        filter = filter.replace(/\s+/, ' ');

        // Remove spaces in phone numbers
        filter = filter.replace(/(\d) (\d)/g, '$1$2');

        // Remove 0 or +33 begin phone number
        filter = filter.replace(/(?:0|\+33)(\d+)/g, '$1');

        const terms = filter.split(' ');

        // Extract patient data to array
        const patientToArray = (patient) => [patient.firstName, patient.lastName, patient.email, patient.phone];

        // Check if `term` exists in `data`
        const match = (data, term) => data.toLocaleLowerCase().replace(/\s+/, '').match(term);

        // Look if `term` exist in any patient data
        const isTermInPatient = (term, patient) => patientToArray(patient).some(data => match(data, term));

        // Ensure that every typed terms is found in patient data
        const patientFilter = patient => terms.every(term => isTermInPatient(term, patient));
        const displayedPractitionersIds = this.secretariesChecked.filter(s => s.isChecked).map(s => s.id)
        return this.$store.state.patients.all.filter(patientFilter).filter(p => {
          return displayedPractitionersIds.find(displayedPractitionersId => {
            return (p.appointments.find(patientAppointment => {
              return patientAppointment.practitioner.id === displayedPractitionersId
            }))
          })
        });
      },
      patientsByFirstLetter() {
        return groupBy(sortBy(this.patients, (patient) => patient.lastName), (patient) => patient.lastName.charAt(0).toLocaleUpperCase());
      },
    },
    methods: {
      getFullName,
      toFullName(patient) {
        return getFullName(patient.firstName, patient.lastName);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .letters {
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;

    .letter {
      color: #9C9C9C;

      &:after {
        padding: 0 1rem;
        content: '-';
      }

      &:last-child:after {
        content: none;
      }
    }

    a {
      color: #9C9C9C;
    }
  }

  .practitioner-filter-entry {
    margin-bottom: 2rem;
  }

  .patient-items {
    display: flex;
    flex-wrap: wrap;
  }

  .patients-divider {
    padding: 2rem 1rem;
    text-transform: uppercase;
  }

  .patient-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30rem;
    height: 8rem;
    margin: 1rem;
    padding-left: 1rem;
    text-decoration: none;
    color: inherit;
    border: 1px solid #D8D8D8;
    border-radius: 0.3rem;
  }

  .patient-name {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }

  .birth-date {
    font-size: 1.2rem;
    overflow: hidden;
    max-height: 5rem;
    margin: 0;
  }

  .icon-container {
    font-size: 1.4rem;
    flex-basis: 32px;
    flex-shrink: 0;
    text-align: center;
  }

  .content {
    font-size: 1.4rem;
    flex: 1;
    padding: 0 1rem;
  }

  .form-widget {
    max-width: 50rem;
    border-bottom: 1px solid $lightTextColor;
  }

  .no-results {
    font-size: 2rem;
    margin-top: 4rem;
    text-align: center;
  }
</style>
