var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { duration: 600 },
      on: {
        "after-leave": function($event) {
          _vm.canBeClosed = true
        }
      }
    },
    [
      _vm.opened
        ? _c("div", { staticClass: "app-panel" }, [
            _c("div", {
              staticClass: "backdrop",
              class: { transparent: _vm.transparent },
              on: { click: _vm.close }
            }),
            _c(
              "div",
              { staticClass: "panel", style: { "--maxWidth": _vm.maxWidth } },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("FontAwesomeIcon", {
                      staticClass: "close-panel-icon",
                      attrs: { icon: _vm.$icons.closePanel },
                      on: { click: _vm.close }
                    }),
                    _vm._t("default")
                  ],
                  2
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }