<template>
  <footer class="app-footer">
    <slot />
  </footer>
</template>

<script>
  export default {
    name: 'AppFooter',
  };
</script>

<style lang="scss" scoped>
  .app-footer {
    background: $altColor;
    display: grid;
    color: $lightTextColor;
    grid-column-gap: 0;
    grid-row-gap: 0;
    padding: 50px 40px 10px;

    @include onDesktop {
      padding: 30px 14rem;
    }
  }
</style>
