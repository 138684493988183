<!--
Emitted events:
- refund
-->
<template>
  <div class="transaction-item">
    <div class="patient-name">
      <router-link :to="$linkToAppointment(transaction.appointment.id)">
        {{ patientFullName }}
      </router-link>
    </div>
    <div :title="dateTime" class="date">{{ date }}</div>

    <!-- STATUS -->
    <template v-if="statusRefunded">
      <div class="amount"><p class="line-through">{{ transaction.amount | money }}</p> <p>{{ amountAfterRefunded | money }}</p></div>
      <div class="status status-refunded">
        Remboursé
      </div>
      <div class="status-icon-captured">
        <FontAwesomeIcon :icon="$icons.transactionStatusCaptured" />
      </div>
    </template>
    <template v-else-if="statusPartiallyRefunded">
      <div class="partially-refunded"><p class="line-through">{{ transaction.amount | money }}</p> <p>{{ amountAfterRefunded | money }}</p></div>
      <div class="status status-partially-refunded">
        Partiellement remboursé
      </div>
      <div class="status-icon-captured">
        <FontAwesomeIcon :icon="$icons.transactionStatusCaptured" />
      </div>
    </template>
    <template v-else-if="statusCaptured">
      <div class="amount">{{ transaction.amount | money }}</div>
      <div class="status status-captured">
        Payé
      </div>
      <div class="status-icon-captured">
        <FontAwesomeIcon :icon="$icons.transactionStatusCaptured" />
      </div>
    </template>
    <template v-else>
      <div class="amount">{{ transaction.amount | money }}</div>
      <div class="status status-pending">
        Pré-autorisé
      </div>
      <div class="status-icon">
        <FontAwesomeIcon :icon="$icons.transactionStatusPending" />
      </div>
    </template>
    <!-- END STATUS -->
    <div>
      <OptionsMenu v-if="!statusRefunded">
        <OptionsMenuItem  :icon="$icons.refund" @click="$emit('refund')">Rembourser</OptionsMenuItem>
      </OptionsMenu>
      <OptionsMenu v-else>
        <OptionsMenuItem class="noRefundable" :icon="$icons.refund" @click.stop>Rembourser</OptionsMenuItem>
      </OptionsMenu>
    </div>
  </div>
</template>

<script>
  import OptionsMenu from '@/components/OptionsMenu.vue';
  import OptionsMenuItem from '@/components/OptionsMenuItem.vue';
  import moment from 'moment';
  import { getFullName } from '@/helpers/format';

  export default {
    name: 'TransactionItem',
    components: { OptionsMenuItem, OptionsMenu },
    props: {
      transaction: {
        type: Object,
        required: true,
      },
    },
    computed: {
      patientFullName() {
        const { firstName, lastName } = this.transaction.patient;
        return getFullName(firstName, lastName);
      },
      date() {
        return moment(this.transaction.createdAt).format('DD MMM YYYY');
      },
      dateTime() {
        return moment(this.transaction.createdAt).format('DD/MM/YYYY HH:mm:ss');
      },
      amountAfterRefunded(){
        return this.transaction.amount - this.transaction.amountRefunded;
      },
      statusCaptured() {
        return this.transaction.paymentStatus === 'paid';
      },
      statusPartiallyRefunded() {
        return this.transaction.amountRefunded > 0 && this.transaction.amount > this.transaction.amountRefunded;
      },
      statusRefunded() {
        return this.transaction.amount === this.transaction.amountRefunded;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .transaction-item {
    font-size: 1.5rem;
    display: flex;
    padding: 1rem 0;
    border-bottom: 1px solid $borderColor;
    margin: 0 auto;
    align-items: center;
  }

  .patient-name {
    width: 30rem;
  }
  .patient-name a {
    color: black;
  }

  .date, .amount, .status, .status-icon {
    text-align: center;
  }

  .date {
    color: #9B9B9B;
    width: 20rem;
    padding: 0 1rem;
  }

  .amount {
    width: 15rem;
    padding: 0 1rem;
  }

  .status {
    width: 15rem;
    padding: 0 1rem;
  }

  .status-icon {
    width: 10rem;
    color: #F46868;
  }
  .status-icon-captured{
    color: $successColor;
    padding: 0 1rem;
    width: 10rem;
    text-align: center;
  }

  .status-captured, .status-partially-refunded, .status-refunded{
    color: $successColor;
  }

  .status-pending {
    color: #9B9B9B;
  }

  .partially-refunded{
    width: 15rem;
    padding: 0 1rem;
    text-align: center;
  }

  .noRefundable{
    text-decoration: line-through;
    color: #9B9B9B;
  }

  .line-through{
    text-decoration: line-through;
    color: #9B9B9B;
  }
  p{
    margin: 0;
  }


</style>
