var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "agenda-availabilities-page" },
      [
        _c("SectionTitle", [_vm._v("Mes disponibilités")]),
        _c("Calendar"),
        _c("div", { staticClass: "agenda-description" }, [
          _c("div", { staticClass: "item-description" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/picto-flexible.svg"),
                alt: ""
              }
            }),
            _c("h3", [_vm._v("FLEXIBLE")]),
            _c("p", [
              _vm._v(
                "\n          Un imprévu s’est glissé dans votre semaine ?\n          Vous pouvez à tout moment modifier votre planning\n          de disponibilité et ainsi ne jamais être bloqué.\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "item-description" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/picto-sur-mesure.svg"),
                alt: ""
              }
            }),
            _c("h3", [_vm._v("SUR MESURE")]),
            _c("p", [
              _vm._v(
                "\n          Votres emploi du temps est compliqué et vous\n          n’avez jamais les mêmes disponibilités ?\n          Paramétrez vos disponibilités à votre guise.\n        "
              )
            ]),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://kb.hellocare.pro/support/renseigner-mes-disponibilites"
                }
              },
              [_vm._v("Comment ça marche\n          ?")]
            )
          ]),
          _c("div", { staticClass: "item-description" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/picto-pratique.svg"),
                alt: ""
              }
            }),
            _c("h3", [_vm._v("PRATIQUE")]),
            _c("p", [
              _vm._v(
                "\n          En combinant votre planning et le temps de vos consultations,\n          vos disponibilités sont immédiatement visibles par vos patients.\n        "
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }