<template>
  <form class="credit-card-add-form" @submit.prevent="handleSubmit">
    <div class="row">
      <FormInput id="iban" ref="iban" :value="iban" class="col _12" label="">
        <template #input>
          <div id="iban" ref="ibanContainer" />
        </template>
        <template v-if="ibanError" #error>{{ ibanError }}</template>
      </FormInput>
    </div>
    <div class="buttons">
      <ButtonTransparent
          v-if="previousIbanId"
          :busy="submitting"
          :icon="$icons.chevronLeft"
          icon-to-left
          rounded
          @click="$emit('close')"
      >
        <slot name="cancel-button">Annuler</slot>
      </ButtonTransparent>
      <ButtonGreen
          :busy="submitting"
          center
          class="submit-button"
          rounded
          type="submit"
      >
        <slot name="submit-button">Valider</slot>
      </ButtonGreen>
    </div>
    <div v-if="!required" class="ignore-button">
      <ButtonTransparent
          :busy="submitting"
          :icon="$icons.chevronRight"
          rounded
          @click="$emit('ignore')"
      >
        <slot name="ignore-button">Ignorer</slot>
      </ButtonTransparent>
    </div>
    <div class="security">
      <img alt="" src="@/assets/images/picto-payment.svg" />
      <p>
        Votre moyen de paiement est traité et sécurisé par Stripe, acteur certifié et accrédité par les autorités
        bancaires européennes. Conformément à la directive européenne DSP2 et à l’authentification forte du client,
        Stripe pourra être amené à vous authentifier à l'aide d'un code reçu par SMS.
      </p>
    </div>
  </form>
</template>

<script>
import ButtonGreen from '@/components/Button/ButtonGreen.vue';
import ButtonTransparent from '@/components/Button/ButtonTransparent.vue';
import FormInput from '@/components/Form/FormInput';
import { iban, stripe } from '@/helpers/stripe';
import SetupIntents from '@/api/setup_intents';

export default {
  name: 'CreditCardReplaceForm',
  components: { FormInput, ButtonTransparent, ButtonGreen },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
    previousIbanId: {
      required: false
    }
  },
  data() {
    return {
      submitting: false,
      iban:'',
      ibanError: null,
    };
  },
  mounted() {
    for (let element of [iban]) {
      element.addEventListener('change', this.handleChange);
      element.addEventListener('focus', this.handleFocus);
      element.addEventListener('blur', this.handleBlur);
      element.mount(this.$refs[`${element._componentName}Container`]);
    }
  },
  beforeDestroy() {
    for (let element of [iban]) {
      element.removeEventListener('change', this.handleChange);
      element.removeEventListener('focus', this.handleFocus);
      element.removeEventListener('blur', this.handleBlur);
      element.unmount();
    }
  },
  methods: {
    handleChange({ elementType, empty, error }) {
      switch (elementType) {
        case 'iban':
          this.ibanError = error?.message;
          return this.iban = empty ? '' : 'fake-value';
      }
    },
    profile() {
      return this.$store.state.practitioner.profile;
    },
    handleFocus({ elementType }) {
      this.$refs[elementType].focused = true;
    },
    handleBlur({ elementType }) {
      this.$refs[elementType].focused = false;
      switch (elementType) {
        case 'iban':
          return iban.update({ placeholder: '' });
      }
    },
    async removePreviousIban() {
      await this.$store.dispatch('paymentMethodsDelete', { id: this.previousIbanId });
    },
    async handleSubmit() {
      this.submitting = true;

      try {
        if (this.previousIbanId) {
          await this.removePreviousIban();
        }
        const { clientSecret } = await SetupIntents.create({
          type: 'sepa'
        });
        const { error } = await stripe.confirmSepaDebitSetup(clientSecret, {
          payment_method: {
            sepa_debit: iban,
            billing_details: {
              name: this.profile().lastName,
              email: this.profile().email,
            }
          }
        });

        if (error) {
          await this.$addError(error.message);
        } else {
          await this.$emit('replace');
        }
      } catch (e) {
        await this.$addError('Une erreur est survenue');
        throw e;
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/grid";

.row {
  margin-bottom: 2rem;
}

.security {
  display: flex;
  align-items: flex-start;
  margin-top: 3.5rem;
  padding: 2.5rem 1.5rem 1.5rem;
  border-top: 1px solid $lightGrey;

  p {
    margin: 0 0 0 2rem;
  }
}

.StripeElement {
  width: 100%;
  padding: 0.5rem 1rem 1rem;
  color: #1F1F1F;
}

.ElementsApp {
  height: 100%;

  &::v-deep input, &::v-deep select, &::v-deep textarea {
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 1rem 1rem;
    color: #1F1F1F;
    border: none;
    outline: none;
    background-color: transparent;

    &[disabled], &.disabled {
      font-style: italic;
      color: #7C7C7C;
    }
  }

  &::v-deep div {
    outline: none;
  }
}

.details-card {
  .expire, .cvc {
    display: inline-block;
  }

  .expire {
    width: calc(50% - 15px);
    margin-right: 15px;
  }

  .cvc {
    width: 50%;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  text-align: center;
}

.ignore-button {
  margin-top: 1rem;
  text-align: center;
}
</style>
