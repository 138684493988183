import api from './';

let startPath = process.env.ENVIRONMENT === "local" ? "prescriptions" : "Prescription"

export default {
  create: (appointmentId, content, metadata) => api.post(startPath, {
    json: {
      appointment: appointmentId,
      content,
      metadata,
    },
  }).json(),
  update: (prescriptionId, content, metadata) => api.put(`${startPath}/${prescriptionId}`, {
    json: {
      content,
      metadata,
    },
  }).json(),
  download: (id) => api.get(`${startPath}/${id}/download`).blob(),
  getOne: (id) => api.get(`${startPath}/${id}`).json(),
  complete: (id) => api.post(`${startPath}/${id}/complete`),
  delete: (id) => api.delete(`${startPath}/${id}`).json(),
};
