<template>
    <div class="slots">
        <div class="channel-slot" v-if="channelsAvailable" v-for="channel in channelsAvailable">
            {{ channel | channel }}
            <div class="slot" v-for="slot in eventsDay.slots[channel]">
                {{ slot.start }} - {{ slot.end }}
            </div>
        </div>
        <div class="limit" v-if="isLimited">
            ({{ availabilitiesCount - 4 }} de plus)
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CalendarEvent',
        props: {
            eventsDay: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                displayIsLimited: false,
            }
        },
        computed: {
            channelsAvailable() {
                if(this.eventsDay) {
                    if (this.eventsDay.slots) {
                        if (!Array.isArray(this.eventsDay.slots)) {
                            let channels = Object.getOwnPropertyNames(this.eventsDay.slots);
                            if (channels.includes('__ob__')) {
                                const index = channels.indexOf('__ob__');
                                channels.splice(index, 1);
                            }
                            return channels;
                        }
                    }
                }
            },
            isLimited() {
                return this.availabilitiesCount > 4;
            },
            availabilitiesCount() {
                if (!this.eventsDay.slots) {
                    return 0;
                }

                return Object.keys(this.eventsDay.slots).reduce((total, channel) => this.eventsDay.slots[channel].length + total, 0);
            },
        },
        watch: {
            eventsDay(events) {
                this.eventsDay = events;
            }
        }
    }
</script>

<style lang="scss">
    .slots {
        padding: 0 8px;
        display: block;
        justify-content: center;
        .channel-slot {
            font-weight: 400;
            .slot {
                font-weight: 300;
                text-align: center;
                height: 18px;
                margin: 0;
            }
        }
        .limit {
            position: absolute;
            bottom: 0;
            background: #fff;
            width: 100%;
            text-align: center;
            padding: 4px 0;
            line-height: 1em;
            font-weight: bold;
            margin-left: -12px;
        }
    }
</style>
