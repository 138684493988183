var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-body" }, [
    _c(
      "div",
      { staticClass: "days-header" },
      _vm._l(7, function(day) {
        return _c("div", { key: day, staticClass: "day-label" }, [
          _vm._v("\n      " + _vm._s(_vm.printDay(day)) + "\n    ")
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "days-body" },
      _vm._l(_vm.calendar, function(week, key) {
        return _c(
          "div",
          { key: key, staticClass: "week-row" },
          [
            _vm._l(week, function(day) {
              return [
                _c("CalendarDay", { attrs: { day: day, events: _vm.events } })
              ]
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }