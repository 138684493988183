var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "beneficiary-list" }, [
    _c(
      "div",
      { staticClass: "beneficiaries" },
      _vm._l(_vm.beneficiaries, function(beneficiary) {
        return _c(
          "div",
          {
            staticClass: "beneficiary",
            class: { selected: _vm.selected.id === beneficiary.id },
            on: {
              click: function($event) {
                return _vm.$emit("change", beneficiary)
              }
            }
          },
          [
            _c("UserAvatar", {
              attrs: { src: beneficiary.picture, size: "5.5rem" }
            }),
            _c("div", [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm._f("fullName")(beneficiary)))
              ])
            ])
          ],
          1
        )
      }),
      0
    ),
    _vm.cancelable
      ? _c("div", { staticClass: "cancel" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("Annuler")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }