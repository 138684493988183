import accessTokens from './access_tokens';
import agenda from './agenda';
import appNavigation from './app_navigation';
import appointments from './appointments';
import encounters from './encounters';
import appLoader from './app_loader';
import appNotification from './app_notification';
import chat from './chat';
import patients from './patients';
import paymentMethods from './payment_methods';
import practitioner from './practitioner';
import transactions from './transactions';
import availability from './availability';
import tesly from './tesly';
import sharedFiles from './shared_files';
import products from './products';
import specialties from './specialties';
import conventions from './conventions';
import plans from './plans';
import languages from './languages'
import observations from "./observations";
import medicaleObservations from "./medicale_observations"

export default {
  accessTokens,
  agenda,
  appNotification,
  appLoader,
  appNavigation,
  appointments,
  chat,
  encounters,
  patients,
  paymentMethods,
  plans,
  practitioner,
  transactions,
  availability,
  tesly,
  sharedFiles,
  products,
  specialties,
  conventions,
  languages,
  observations,
  medicaleObservations
};
