<template>
  <router-link :class="statusClass" :to="$linkToAppointment(appointmentId)" class="appointment-list-item">
    <div class="date-time">
      <div class="day-of-week">{{ dayOfWeek }}</div>
      <div class="date">{{ date }}</div>
      <div class="time">{{ time }}</div>
    </div>
    <div class="content">
      <span class="patient-name">{{ patientName }}</span>
      <p class="description">{{ description }}</p>
      <p class="info">
        <span class="channel">{{ channel | channel }}</span>
        |
        <span class="status">
          <AppointmentStatusIcon :status="status" />
          {{ status | appointmentStatus(canBeStarted) }}
        </span>
      </p>
    </div>
  </router-link>
</template>

<script>
  import AppointmentStatusIcon from './AppointmentStatusIcon';
  import { appointmentStatusClass, getFullName } from '@/helpers/format';

  export default {
    name: 'AppointmentListItem',
    components: { AppointmentStatusIcon },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      dayOfWeek: {
        type: String,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
      time: {
        type: String,
        required: true,
      },
      expired: {
        type: Boolean,
        default: false,
      },
      patient: {
        type: Object,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
      },
      channel: {
        type: String,
        required: true,
      },
      canBeStarted: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      statusClass() {
        return appointmentStatusClass(this.status, this.canBeStarted);
      },
      patientName() {
        if (!this.patient?.firstName) {
          return 'Patient non-inscrit';
        }

        return getFullName(this.patient.firstName, this.patient.lastName);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-list-item {
    display: flex;
    width: 31rem;
    height: 8rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    color: inherit;
    border: 1px solid #D8D8D8;
    border-radius: 0.3rem;
    align-items: center;
    text-decoration: none;
  }

  .date-time {
    display: flex;
    flex-basis: 80px;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-self: stretch;
    color: #1F1F1F;
    background-color: #FAFAFA;

    .pending &, .terminated &, .waiting-payment & {
      color: white;
      background-color: #F46868;
    }

    .available & {
      color: white;
      background-color: $successColor;
    }
  }

  .day-of-week {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 300;
  }

  .date {
    font-size: 2rem;
    padding: 0.3rem 0;
  }

  .time {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;
  }

  .icon-container {
    flex-basis: 32px;
    flex-shrink: 0;
    text-align: center;
    font-size: 1.4rem;
  }

  .patient-name {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .description {
    max-height: 5rem;
    overflow: hidden;
    margin: 0.5rem 0;
    font-size: 1.4rem;
    white-space: break-spaces;
  }

  .info {
    margin: 0;
    font-size: 1rem;
  }

  .channel {
    margin-right: 0.2rem;
  }

  .status {
    display: inline-block;
    margin-left: 0.2rem;

    .confirmed & {
      color: $successColor;
    }

    .available & {
      font-weight: bold;
      animation: shake 10s infinite cubic-bezier(.36, .07, .19, .97) both;
      color: $successColor;
    }

    .pending & {
      font-weight: bold;
      color: $grey;
    }

    .terminated &, .closed & {
      font-style: italic;
      color: $grey;
    }

    .cancelled & {
      font-style: italic;
      color: $errorColor;
    }
  }
</style>
