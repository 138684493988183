<template>
  <div class="claim-form">
    <form @submit.prevent="handleSubmit">
      <Panel v-model="currentPanel" :has-error="$v.panel1.$anyError" :number="1">
        <template v-slot:title>Personne recevant les soins et assuré(e)</template>
        <b>Personne recevant les soins</b>

        <div class="row">
          <FormWidget class="col _5">
            <label for="patient-firstname">Prénom *</label>
            <input
              id="patient-firstname"
              v-model="form.patient.first_name"
              @blur="$v.form.patient.first_name.$touch()"
            >
            <template v-if="$v.form.patient.first_name.$error" v-slot:error>
              <span v-if="!$v.form.patient.first_name.required">Le prénom du patient est obligatoire</span>
              <span v-if="!$v.form.patient.first_name.minLength">Le prénom doit faire 2 caractères au minimum</span>
            </template>
          </FormWidget>
          <FormWidget class="col _5">
            <label for="patient-lastname">Nom *</label>
            <input
              id="patient-lastname"
              v-model="form.patient.last_name"
              @blur="$v.form.patient.last_name.$touch()"
            >
            <template v-if="$v.form.patient.last_name.$error" v-slot:error>
              <span v-if="!$v.form.patient.last_name.required">Le nom du patient est obligatoire</span>
              <span v-if="!$v.form.patient.last_name.minLength">Le nom doit faire 2 caractères au minimum</span>
            </template>
          </FormWidget>
          <FormWidget class="col _2">
            <label for="patient-birth-date">Date de naissance</label>
            <input
              id="patient-birth-date"
              v-model="form.patient.birth_date"
              v-mask="'##/##/####'"
              placeholder="jj/mm/aaaa"
              @blur="$v.form.patient.birth_date.$touch()"
            >
            <template v-if="$v.form.patient.birth_date.$error" v-slot:error>
              <span v-if="!$v.form.patient.birth_date.date">La date de naissance est incorrecte</span>
            </template>
          </FormWidget>
        </div>

        <div class="row">
          <FormWidget class="col _8">
            <label for="patient-ssn">Numéro d'immatriculation</label>
            <input
              id="patient-ssn"
              v-mask="patientSSNMask"
              :value="form.patient.ssn"
              @blur="$v.form.patient.ssn.$touch()"
              @input="form.patient.ssn = $event.target.value.replace(/\s/g, '')"
            >
            <template v-if="$v.form.patient.ssn.$error" v-slot:error>
              <span v-if="!$v.form.patient.ssn.format">Le numéro d'immatriculation est incorrect</span>
            </template>
          </FormWidget>
          <FormWidget class="col _4">
            <label for="patient-attachment-organization-code">Code de l'organisme de rattachement</label>
            <input
              id="patient-attachment-organization-code"
              v-mask="'## ### ####'"
              :value="form.patient.attachment_organization_code"
              @blur="$v.form.patient.attachment_organization_code.$touch()"
              @input="form.patient.attachment_organization_code = $event.target.value.replace(/\s/g, '')"
            >
            <template v-slot:help>En cas de dispense d'avance des frais (9 chiffres)</template>
            <template v-if="$v.form.patient.attachment_organization_code.$error" v-slot:error>
              <span v-if="!$v.form.patient.attachment_organization_code.format">Le code de l'organisme de rattachement est incorrect</span>
            </template>
          </FormWidget>
        </div>

        <hr>

        <b>Identité de l'assuré(e)</b>

        <div class="row">
          <FormWidget class="col _4">
            <label for="ph-firstname">Prénom</label>
            <input
              id="ph-firstname"
              v-model="form.policy_holder.first_name"
              @blur="$v.form.policy_holder.first_name.$touch()"
            >
            <template v-slot:help>À remplir si la personne recevant les soins n'est pas l'assuré(e)</template>
            <template v-if="$v.form.policy_holder.first_name.$error" v-slot:error>
              <span
                v-if="!$v.form.policy_holder.first_name.minLength">Le prénom doit faire 2 caractères au minimum</span>
            </template>
          </FormWidget>
          <FormWidget class="col _4">
            <label for="ph-lastname">Nom</label>
            <input
              id="ph-lastname"
              v-model="form.policy_holder.last_name"
              @blur="$v.form.policy_holder.last_name.$touch()"
            >
            <template v-if="$v.form.policy_holder.last_name.$error" v-slot:error>
              <span v-if="!$v.form.policy_holder.last_name.minLength">Le nom doit faire 2 caractères au minimum</span>
            </template>
          </FormWidget>
          <FormWidget class="col _4">
            <label for="ph-social-security-number">Numéro d'immatriculation</label>
            <input
              id="ph-social-security-number"
              v-mask="patientSSNMask"
              :value="form.policy_holder.ssn"
              @blur="$v.form.policy_holder.ssn.$touch()"
              @input="form.policy_holder.ssn = $event.target.value.replace(/\s/g, '')"
            >
            <template v-if="$v.form.policy_holder.ssn.$error" v-slot:error>
              <span v-if="!$v.form.policy_holder.ssn.format">Le numéro d'immatriculation est incorrect</span>
            </template>
          </FormWidget>
        </div>

      </Panel>

      <Panel v-model="currentPanel" :has-error="$v.panel2.$anyError" :number="2">
        <template v-slot:title>Conditions de prise en charge des soins</template>
        <div class="row">
          <div class="col _8">
            <div class="row">
              <div class="col _12">
                <FormWidget transparent>
                  <div class="row">
                    <FormRadio v-model="form.ui.disease_type" class="col _4" value="disease">Maladie</FormRadio>
                    <FormRadio v-model="form.ui.disease_type" class="col _4" value="maternity">Maternité</FormRadio>
                    <FormRadio v-model="form.ui.disease_type" class="col _4" value="professional_disease">AT/MP
                    </FormRadio>
                  </div>
                  <template v-if="$v.form.ui.disease_type.$error" v-slot:error>
                    <span v-if="!$v.form.ui.disease_type.required">Veuillez faire un choix</span>
                  </template>
                </FormWidget>
              </div>
            </div>
            <template v-if="form.ui.disease_type === 'disease'">
              <FormWidget transparent>
                <div class="row">
                  <FormCheckbox v-model="form.disease.is_ald" class="col _4">
                    Acte conforme au protocole ALD
                  </FormCheckbox>
                  <FormCheckbox v-model="form.disease.is_prevention_action" class="col _4">
                    Acte de prévention
                  </FormCheckbox>
                  <FormCheckbox v-model="form.disease.is_other" class="col _4">
                    Autre
                  </FormCheckbox>
                </div>
              </FormWidget>

              <div>
                <label><b>Accident causé par un tiers</b></label>
                <div class="row">
                  <FormWidget class="col _7" transparent>
                    <div class="row">
                      <FormRadio v-model="form.ui.is_caused_by_third_party" :value="false" class="col _4">
                        Non
                      </FormRadio>
                      <FormRadio v-model="form.ui.is_caused_by_third_party" :value="true" class="col _4">
                        Oui
                      </FormRadio>
                    </div>
                    <template v-if="$v.form.ui.is_caused_by_third_party.$error" v-slot:error>
                      <span v-if="!$v.form.ui.is_caused_by_third_party.required">Veuillez faire un choix</span>
                    </template>
                  </FormWidget>

                  <FormWidget v-if="form.ui.is_caused_by_third_party" class="col _5">
                    <label for="accident_date">Date de l'accident</label>
                    <input
                      id="accident_date"
                      v-model="form.disease.caused_by_third_party_at"
                      v-mask="'##/##/####'"
                      placeholder="jj/mm/aaaa"
                      @blur="$v.form.disease.caused_by_third_party_at.$touch()"
                    >
                    <template v-if="$v.form.disease.caused_by_third_party_at.$error" v-slot:error>
                      <span v-if="!$v.form.disease.caused_by_third_party_at.required">
                        Veuillez renseigner la date de l'accident
                      </span>
                      <span v-if="!$v.form.disease.caused_by_third_party_at.date">
                        La date de l'accident est incorrecte
                      </span>
                    </template>
                  </FormWidget>
                </div>
              </div>
            </template>
            <template v-else-if="form.ui.disease_type === 'maternity'">
              <p><b>Date présumée de début de grossesse ou date d'accouchement</b></p>
              <div class="row">
                <FormWidget class="col _4">
                  <label for="maternity-since">Date</label>
                  <input
                    id="maternity-since"
                    v-model="form.maternity_since"
                    v-mask="'##/##/####'"
                    placeholder="jj/mm/aaaa"
                    @blur="$v.form.maternity_since.$touch()"
                  >
                  <template v-if="$v.form.maternity_since.$error" v-slot:error>
                    <span v-if="!$v.form.maternity_since.required">Veuillez renseigner la date</span>
                    <span v-if="!$v.form.maternity_since.date">La date est incorrecte</span>
                  </template>
                </FormWidget>
              </div>
            </template>
            <div v-else-if="form.ui.disease_type === 'professional_disease'" class="row">
              <div class="col _5">
                <FormWidget>
                  <label for="professional-disease-number">Numéro</label>
                  <input
                    id="professional-disease-number"
                    v-model="form.professional_disease_number"
                    @blur="$v.form.professional_disease_number.$touch()"
                  >
                  <template v-if="$v.form.professional_disease_number.$error" v-slot:error>
                    <span v-if="!$v.form.professional_disease_number.required">
                      Veuillez renseigner le numéro ou la date
                    </span>
                  </template>
                </FormWidget>
              </div>
              <div class="col _2 professional-disease-or">
                ou
              </div>
              <div class="col _5">
                <FormWidget>
                  <label for="professional-disease-date">Date</label>
                  <input
                    id="professional-disease-date"
                    v-model="form.professional_disease_since"
                    v-mask="'##/##/####'"
                    placeholder="jj/mm/aaaa"
                    @blur="$v.form.professional_disease_since.$touch()"
                  >
                  <template v-if="$v.form.professional_disease_since.$error" v-slot:error>
                    <span v-if="!$v.form.professional_disease_since.date">La date est incorrecte</span>
                  </template>
                </FormWidget>
              </div>
            </div>
          </div>
          <FormWidget v-if="form.ui.disease_type === 'disease'" class="col _4" transparent>
            <FormCheckbox v-model="form.disease.is_war_wounded">
              Soins dispensés au titre de l'art. L.212-1
            </FormCheckbox>
          </FormWidget>
        </div>
        <hr>
        <div class="row">
          <FormWidget class="col _6">
            <label for="care_type">Prise en charge</label>
            <SelectWrapper>
              <select id="care_type" v-model="form.ui.care_type" @change="$v.form.ui.care_type.$touch()">
                <option />
                <option value="doctor">Médecin traitant</option>
                <option value="sent_by_doctor">Envoyé par le médecin traitant</option>
                <option value="is_direct_access">Accès direct spécifique</option>
                <option value="is_emergency">Urgence</option>
                <option value="is_away_from_home">Hors résidence habituelle</option>
                <option value="is_substitute_doctor">Médecin traitant remplacé</option>
                <option value="is_non_coordinating">Accès hors-coordination</option>
              </select>
            </SelectWrapper>
            <template v-if="$v.form.ui.care_type.$error" v-slot:error>
              <span v-if="!$v.form.ui.care_type.required">Veuillez choisir le type de prise en charge</span>
            </template>
          </FormWidget>
          <FormWidget v-if="form.ui.care_type === 'sent_by_doctor'" class="col _6">
            <label for="sent_by_doctor">Prénom et nom du médecin traitant</label>
            <input
              id="sent_by_doctor"
              v-model="form.sent_by_doctor"
              @blur="$v.form.sent_by_doctor.$touch()"
            >
            <template v-if="$v.form.sent_by_doctor.$error" v-slot:error>
              <span v-if="!$v.form.sent_by_doctor.minLength">Veuillez vérifier le nom du médecin traitant</span>
            </template>
          </FormWidget>
        </div>
      </Panel>

      <Panel v-model="currentPanel" :has-error="$v.panel3.$anyError" :number="3">
        <template v-slot:title>Actes effectués</template>
        <div class="row">
          <p class="col _5">
            Si les actes sont soumis à la formalité de l'accord préalable, indiquez la date de la demande&nbsp;:
          </p>
          <div class="col _4">
            <FormWidget>
              <label for="request_agreement_at">Date</label>
              <input
                id="request_agreement_at"
                v-model="form.request_agreement_at"
                v-mask="'##/##/####'"
                placeholder="jj/mm/aaaa"
                @blur="$v.form.request_agreement_at.$touch()"
              >
              <template v-if="$v.form.request_agreement_at.$error" v-slot:error>
                <span v-if="!$v.form.request_agreement_at.date">La date de l'accident est incorrecte</span>
              </template>
            </FormWidget>
          </div>
        </div>
        <hr>
        <div class="quotations">
          <div class="row quotations-header">
            <div class="col _2">Date</div>
            <div class="col _6">Cotation(s)</div>
            <div class="col _1_5 quotation-overpaid">Dépass.</div>
            <div class="col _2">Montant</div>
            <div class="col _0_5 quotation-remove" />
          </div>
          <div
            v-for="(v, index) in $v.form.quotations.$each.$iter"
            :class="{ 'to-be-removed': quotationToRemove === index }"
            class="row quotation"
          >
            <div class="col _2">
              <FormWidget>
                <label :for="`quotation-date-${index}`">Date</label>
                <input
                  :id="`quotation-date-${index}`"
                  v-model="form.quotations[index].date"
                  v-mask="'##/##/####'"
                  placeholder="jj/mm/aaaa"
                  @blur="v.date.$touch()"
                >
                <template v-if="v.date.$error" v-slot:error>
                  <span v-if="!v.date.required">Date obligatoire</span>
                  <span v-if="!v.date.isDate">Date incorrecte</span>
                </template>
              </FormWidget>
            </div>
            <div class="col _6">
              <FormWidget>
                <label :for="`quotation-cotation-${index}`">Cotation</label>
                <SelectWrapper>
                  <select
                    :id="`quotation-cotation-${index}`"
                    v-model="form.quotations[index].sid"
                    @change="($event) => { v.sid.$touch(); updateQuotationPrice($event, index) }"
                  >
                    <option />
                    <option v-for="quotation in quotations" :value="quotation.sid">
                      {{ quotation.key }} - {{ quotation.name }}
                    </option>
                  </select>
                </SelectWrapper>
                <template v-if="v.sid.$error" v-slot:error>
                  <span v-if="!v.sid.required">Cotation obligatoire</span>
                </template>
              </FormWidget>
            </div>
            <div class="col _1_5 quotation-overpaid">
              <FormWidget>
                <label :for="`quotation-overpaid-code-${index}`">Dépassement</label>
                <SelectWrapper>
                  <select
                    :id="`quotation-overpaid-code-${index}`"
                    v-model="form.quotations[index].overpaid_code"
                    @change="v.overpaid_code.$touch()"
                  >
                    <option />
                    <option value="DE">DE</option>
                    <option value="DA">DA</option>
                  </select>
                </SelectWrapper>
              </FormWidget>
            </div>
            <div class="col _2">
              <FormWidget>
                <label :for="`quotation-price-${index}`">Montant</label>
                <input
                  :id="`quotation-price-${index}`"
                  :value="form.quotations[index].price"
                  @blur="v.price.$touch()"
                  @input="form.quotations[index].price = $inputToPrice($event.target.value)"
                >
                <template v-if="v.price.$error" v-slot:error>
                  <span v-if="!v.price.required">Montant requis</span>
                  <span v-if="!v.price.decimal">Montant incorrect</span>
                </template>
              </FormWidget>
            </div>
            <div class="col _0_5 quotation-remove">
              <div class="quotation-remove-icons">
                <FontAwesomeIcon
                  v-if="quotationToRemove === null && form.quotations.length > 1"
                  :icon="$icons.removeQuotation"
                  size="2x" @click="quotationToRemove = index"
                />
                <FontAwesomeIcon
                  v-if="quotationToRemove === index"
                  :icon="$icons.removeQuotationConfirm"
                  class="quotation-remove-icon-confirm"
                  size="2x" @click="removeQuotation(index)"
                />
                <FontAwesomeIcon
                  v-if="quotationToRemove === index"
                  :icon="$icons.removeQuotationCancel"
                  size="2x" @click="quotationToRemove = null"
                />
              </div>
            </div>
          </div>
          <div v-show="form.quotations.length < 4" class="row">
            <div class="col _12 add-quotation">
              <ButtonGreen outlined small @click="addQuotation">
                Ajouter une ligne
              </ButtonGreen>
            </div>
          </div>
        </div>
      </Panel>

      <Panel v-model="currentPanel" :has-error="$v.panel4.$anyError" :number="4">
        <template v-slot:title>Paiement</template>
        <p>Total: {{ totalPrice }}&nbsp;€</p>
        <hr>
        <p><b>L'assuré n'a pas payé</b></p>
        <div class="row">
          <FormWidget class="col _3" transparent>
            <FormCheckbox v-model="form.is_free_of_mandatory_part">La part obligatoire</FormCheckbox>
          </FormWidget>
          <FormWidget class="col _3" transparent>
            <FormCheckbox v-model="form.is_free_of_complementary_part">La part complémentaire</FormCheckbox>
          </FormWidget>
        </div>
      </Panel>

      <div class="actions">
        <ButtonGreen :busy="isSubmitting" :icon="$icons.accept" type="submit">
          Valider
        </ButtonGreen>
        <ButtonRed :busy="isSubmitting" outlined @click="$emit('close')">
          Annuler
        </ButtonRed>
      </div>
    </form>
  </div>
</template>

<script>
  import moment from 'moment';
  import { decimal, minLength, required } from '@byscripts/vuelidate/src/validators';
  import Claims from '@/api/claims';
  import Quotations from '@/api-platform/quotations';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonRed from '@/components/Button/ButtonRed.vue';
  import Panel from '@/components/Consultation/ClaimForm/Panel';
  import FormCheckbox from '@/components/Form/FormCheckbox';
  import FormRadio from '@/components/Form/FormRadio';
  import FormWidget from '@/components/Form/FormWidget';
  import SelectWrapper from '@/components/Form/SelectWrapper';
  import { convertDateToDash, convertDateToSlash } from '@/helpers/format';
  import { guessCareType, guessDiseaseType, guessQuotations } from '@/helpers/tools';
  import { checkDate, hasValue } from '@/helpers/validation';

  function isoDateToFrench(date) {
    if (!date) {
      return '';
    }

    return moment(date).format('DD/MM/YYYY');
  }

  export default {
    name: 'ClaimForm',
    props: {
      encounterId: {
        type: String,
        required: true,
      },
      editedClaim: {
        type: Object,
      },
      patient: {
        type: Object,
      },
      appointmentDate: {
        type: String,
        required: true,
      }
    },
    components: { FormRadio, FormCheckbox, ButtonRed, ButtonGreen, FormWidget, Panel, SelectWrapper },
    created() {
      this.loadQuotations();
    },
    data() {
      let patientBirthDate;
      if (this.editedClaim.patient) {
        patientBirthDate = convertDateToSlash(this.editedClaim.patient.birth_date);
      } else if (this.patient) {
        patientBirthDate = moment(this.patient.birthDate).format('DD/MM/YYYY');
      }

      return {
        isSubmitting: false,
        isLoadingQuotations: false,
        quotations: [],
        currentPanel: 1,
        quotationToRemove: null,
        form: {
          patient: {
            first_name: this.editedClaim.patient?.first_name || this.patient?.firstName,
            last_name: this.editedClaim.patient?.last_name || this.patient?.lastName,
            birth_date: patientBirthDate,
            attachment_organization_code: this.editedClaim.patient?.attachment_organization_code,
            ssn: this.editedClaim.patient?.ssn || this.patient?.ssn?.replace(/ /g, ''),
          },
          policy_holder: {
            first_name: this.editedClaim.policy_holder?.first_name,
            last_name: this.editedClaim.policy_holder?.last_name,
            ssn: this.editedClaim.policy_holder?.ssn,
          },
          ui: {
            care_type: guessCareType(this.editedClaim),
            disease_type: guessDiseaseType(this.editedClaim),
            is_caused_by_third_party: !!this.editedClaim.disease?.caused_by_third_party_at,
          },
          disease: {
            is_ald: !!this.editedClaim.disease?.is_ald,
            is_prevention_action: !!this.editedClaim.disease?.is_prevention_action,
            is_other: !!this.editedClaim.disease?.is_other,
            is_war_wounded: !!this.editedClaim.disease?.is_war_wounded,
            caused_by_third_party_at: convertDateToSlash(this.editedClaim.disease?.caused_by_third_party_at),
          },
          maternity_since: isoDateToFrench(this.editedClaim.maternity_since),
          professional_disease_number: this.editedClaim.professional_disease_number,
          professional_disease_since: isoDateToFrench(this.editedClaim.professional_disease_since),
          sent_by_doctor: this.editedClaim.sent_by_doctor,
          request_agreement_at: isoDateToFrench(this.editedClaim.request_agreement_at),
          quotations: guessQuotations(this.editedClaim, this.appointmentDate),
          is_free_of_mandatory_part: !!this.editedClaim.is_free_of_mandatory_part,
          is_free_of_complementary_part: !!this.editedClaim.is_free_of_complementary_part,
        },
      };
    },

    validations: {
      form: {
        patient: {
          first_name: {
            required,
            minLength: minLength(2),
          },
          last_name: {
            required,
            minLength: minLength(2),
          },
          birth_date: {
            date: checkDate,
          },
          ssn: {
            format: (value) => value ? !!value.match(/^\d{6}[\dAB]\d{8}$/) : true,
          },
          attachment_organization_code: {
            format: (value) => value ? value.length === 9 : true,
          },
        },
        policy_holder: {
          first_name: {
            minLength: minLength(2),
          },
          last_name: {
            minLength: minLength(2),
          },
          ssn: {
            format: (value) => value ? !!value.match(/^\d{6}[\dAB]\d{8}$/) : true,
          },
        },
        ui: {
          disease_type: {
            required,
          },
          is_caused_by_third_party: {
            required: function (value) {
              if (this.form.ui.disease_type === 'disease') {
                return typeof value === 'boolean';
              }

              return true;
            },
          },
          care_type: {
            required,
          },
        },
        disease: {
          is_ald: {},
          is_prevention_action: {},
          is_other: {},
          caused_by_third_party_at: {
            required: function (value) {
              if (this.form.ui.disease_type === 'disease' && this.form.ui.is_caused_by_third_party) {
                return hasValue(value);
              }

              return true;
            },
            date: checkDate,
          },
          is_war_wounded: {},
        },
        maternity_since: {
          required: function (value) {
            if (this.form.ui.disease_type === 'maternity') {
              return hasValue(value);
            }

            return true;
          },
          date: checkDate,
        },
        professional_disease_number: {
          required: function (value) {
            if (this.form.ui.disease_type === 'professional_disease') {
              return hasValue(value) || hasValue(this.form.professional_disease_since);
            }

            return true;
          },
        },
        professional_disease_since: {
          date: checkDate,
        },
        sent_by_doctor: {
          minLength: minLength(5),
        },
        request_agreement_at: {
          date: checkDate,
        },
        quotations: {
          required,
          $each: {
            date: {
              required,
              isDate: checkDate,
            },
            sid: {
              required,
            },
            overpaid_code: {},
            price: {
              required,
              decimal,
            },
          },
        },
        is_free_of_mandatory_part: {},
        is_free_of_complementary_part: {},
      },
      panel1: [
        'form.patient.first_name',
        'form.patient.last_name',
        'form.patient.birth_date',
        'form.patient.ssn',
        'form.patient.attachment_organization_code',
        'form.policy_holder.first_name',
        'form.policy_holder.last_name',
      ],
      panel2: [
        'form.ui.disease_type',
        'form.ui.is_caused_by_third_party',
        'form.ui.care_type',
        'form.disease.is_ald',
        'form.disease.is_prevention_action',
        'form.disease.is_other',
        'form.disease.caused_by_third_party_at',
        'form.disease.is_war_wounded',
        'form.maternity_since',
        'form.professional_disease_number',
        'form.professional_disease_since',
        'form.sent_by_doctor',
      ],
      panel3: [
        'form.request_agreement_at',
        'form.quotations',
      ],
      panel4: [
        'form.is_free_of_mandatory_part',
        'form.is_free_of_complementary_part',
      ],
    },
    computed: {
      patientSSNMask() {
        return {
          mask: '# ## ## #D ### ### ##',
          tokens: {
            '#': {
              pattern: /\d/,
            },
            D: {
              pattern: /[\dabAB]/,
              transform: v => v.toLocaleUpperCase(),
            },
          },
        };
      },
      totalPrice() {
        const reducer = (accumulator, { price }) => {
          return accumulator + parseFloat(String(price || 0).replace(',', '.'));
        };
        return this.form.quotations.reduce(reducer, 0).toFixed(2);
      },
    },
    methods: {
      async loadQuotations() {
        this.isLoadingQuotations = true;
        try {
          const { data } = await Quotations.getAll();
          this.quotations = data.sort((q1, q2) => q1.key.localeCompare(q2.key));
        } catch (e) {
          this.$addError('Erreur lors du chargement des cotations');
          throw e;
        } finally {
          this.isLoadingQuotations = false;
        }
      },
      addQuotation() {
        const lastQuotation = this.form.quotations[this.form.quotations.length - 1];
        this.form.quotations.push({
          date: lastQuotation.date,
          sid: '',
          overpaid_code: '',
          price: '',
        });
      },
      removeQuotation(index) {
        if (this.form.quotations.length > 1) {
          this.$delete(this.form.quotations, index);
          this.quotationToRemove = null;
        }
      },
      updateQuotationPrice(event, index) {
        let price;
        const quotationId = event.target.value;

        if (quotationId) {
          const quotation = this.quotations.find(q => q.sid === event.target.value);
          price = quotation.price / 100;
        }

        this.form.quotations[index].price = price;
      },
      async handleSubmit() {
        if (this.isSubmitting) {
          return;
        }

        this.$v.$touch();

        switch (true) {
          case this.$v.panel1.$anyError:
            return this.currentPanel = 1;
          case this.$v.panel2.$anyError:
            return this.currentPanel = 2;
          case this.$v.panel3.$anyError:
            return this.currentPanel = 3;
          case this.$v.panel4.$anyError:
            return this.currentPanel = 4;
        }

        this.isSubmitting = true;

        const data = {
          encounter: this.encounterId,
          patient: {
            ...this.form.patient,
            birth_date: convertDateToDash(this.form.patient.birth_date) || '',
          },
          policy_holder: {
            ...this.form.policy_holder,
          },
          disease: {
            is_ald: false,
            is_prevention_action: false,
            is_other: false,
            caused_by_third_party_at: '',
            is_war_wounded: false,
          },
          maternity_since: '',
          professional_disease_since: '',
          professional_disease_number: '',
          sent_by_doctor: '',
          request_agreement_at: convertDateToDash(this.form.request_agreement_at) || '',
          quotations: this.form.quotations
            .map((quotation) => {
              if (!quotation.sid) {
                return false;
              }
              return {
                date: convertDateToDash(quotation.date),
                sid: quotation.sid,
                overpaid_code: quotation.overpaid_code || '',
                price: Math.round(String(quotation.price).replace(',', '.') * 100),
              };
            })
            .filter(Boolean),
          is_direct_access: this.form.ui.care_type === 'is_direct_access',
          is_emergency: this.form.ui.care_type === 'is_emergency',
          is_away_from_home: this.form.ui.care_type === 'is_away_from_home',
          is_substitute_doctor: this.form.ui.care_type === 'is_substitute_doctor',
          is_non_coordinating: this.form.ui.care_type === 'is_non_coordinating',
          is_free_of_mandatory_part: this.form.is_free_of_mandatory_part,
          is_free_of_complementary_part: this.form.is_free_of_complementary_part,
        };

        switch (this.form.ui.disease_type) {
          case 'disease':
            data.disease = {
              ...this.form.disease,
              caused_by_third_party_at: '',
            };

            if (this.form.ui.is_caused_by_third_party) {
              data.disease.caused_by_third_party_at = convertDateToDash(this.form.disease.caused_by_third_party_at) || '';
            }
            break;
          case 'maternity':
            data.maternity_since = convertDateToDash(this.form.maternity_since) || '';
            break;
          case 'professional_disease':
            data.professional_disease_since = convertDateToDash(this.form.professional_disease_since) || '';
            data.professional_disease_number = this.form.professional_disease_number;
            break;
        }

        if (this.form.ui.care_type === 'sent_by_doctor') {
          data.sent_by_doctor = this.form.sent_by_doctor;
        }

        try {
          if (this.editedClaim.id) {
            await Claims.update(this.editedClaim.id, data);
            this.$addSuccess('La feuille de soin a été mise à jour');
          } else {
            await Claims.create(data);
            this.$addSuccess('La feuille de soin a été enregistrée');
          }

          this.$emit('success');
        } catch (e) {
          this.$addError('Une erreur s\'est produite lors de l\'enregistrement de la feuille de soins');
          throw e;
        } finally {
          this.isSubmitting = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/grid";

  hr {
    margin: 0.7rem 0 3rem;
    border-width: 1px;
    border-style: solid none none;
    border-color: $borderColor;
  }

  .quotations-header {
    display: none;

    @include onDesktop {
      display: flex;
    }
  }

  .quotation-remove {
    padding-bottom: 2rem;

    @include onDesktop {
      align-self: center;
    }

    .quotation-remove-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @include onDesktop {
        flex-direction: column;
      }

      svg {
        cursor: pointer;
        color: $errorColor;

        &.quotation-remove-icon-confirm {
          color: $successColor;
        }
      }
    }
  }

  .quotation {
    margin-bottom: 2rem;

    @include onDesktop {
      margin-bottom: 0;
    }

    @include onMobile {
      border-bottom: 1rem solid $borderColor;

      &.to-be-removed {
        background-color: lighten($errorColor, 27%);

        .form-widget {
          opacity: 0;
        }
      }
    }
  }

  .add-quotation {
    text-align: center;

    @include onDesktop {
      text-align: left;
    }
  }

  .professional-disease-or {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40rem;
    margin: 2rem auto;
    text-align: center;

    button {
      flex: 1;
      margin: 0 1rem;
    }
  }
</style>
