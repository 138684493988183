var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "agenda-view-page" },
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "side" },
            [
              _c("SectionTitle", [
                _vm._v(_vm._s(_vm.isSecretary ? "Mes agendas" : "Mon agenda"))
              ]),
              _c("AgendaDatePickerMonth", {
                attrs: { year: _vm.year, month: _vm.month },
                on: { change: _vm.handleMonthChange }
              }),
              _c(
                "ButtonGrey",
                {
                  attrs: { small: "", outlined: "", block: "" },
                  on: { click: _vm.goToToday }
                },
                [_vm._v("Aujourd'hui")]
              ),
              _c("AgendaDatePickerDay", {
                attrs: { year: _vm.year, month: _vm.month, day: _vm.day },
                on: { change: _vm.handleDayChange }
              }),
              _c("hr"),
              !_vm.isAccountRestricted
                ? [
                    _c(
                      "ButtonRed",
                      {
                        attrs: {
                          block: "",
                          small: "",
                          icon: _vm.$icons.newAppointment
                        },
                        on: {
                          click: function($event) {
                            _vm.isNewAppointmentPanelOpened = true
                          }
                        }
                      },
                      [_vm._v("\n            Ajouter un RDV\n          ")]
                    ),
                    _vm.profileType === "doctor"
                      ? _c(
                          "ButtonGreen",
                          {
                            staticClass: "replace-ps",
                            attrs: {
                              block: "",
                              small: "",
                              external: "",
                              icon: _vm.$icons.userMd,
                              "link-to":
                                "https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links"
                            }
                          },
                          [
                            _vm._v(
                              "\n            Besoin d'être remplacé ?\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("NewAppointmentPanel", {
                      attrs: { opened: _vm.isNewAppointmentPanelOpened },
                      on: { close: _vm.handleNewAppointmentPanelClose }
                    })
                  ]
                : _vm._e(),
              _vm.isSecretary
                ? _c(
                    "div",
                    { staticClass: "practitioner-filter" },
                    [
                      _c("SectionTitle", [_vm._v("Agendas à afficher")]),
                      _vm._l(_vm.secretariesOf, function(secretaryOf, index) {
                        return _c("div", {}, [
                          _c(
                            "div",
                            { staticClass: "practitioner-filter-entry" },
                            [
                              _c(
                                "FormCheckbox",
                                {
                                  model: {
                                    value:
                                      _vm.secretariesChecked[index].isChecked,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.secretariesChecked[index],
                                        "isChecked",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "secretariesChecked[index].isChecked"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFullName(
                                        secretaryOf.first_name,
                                        secretaryOf.last_name
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "agenda" },
            [
              _c(
                "div",
                { staticClass: "agenda-buttons" },
                [
                  _c(
                    "ButtonGreen",
                    {
                      staticClass: "link-to-appointments",
                      attrs: {
                        small: "",
                        outlined: "",
                        "link-to": "/agenda/appointments",
                        icon: _vm.$icons.planning
                      }
                    },
                    [_vm._v("\n            Afficher le planning\n          ")]
                  )
                ],
                1
              ),
              _c("AgendaCalendarWeek", {
                ref: "agendaCalendarWeek",
                attrs: {
                  day: _vm.day,
                  secretariesChecked: _vm.secretariesChecked
                }
              })
            ],
            1
          )
        ]),
        _c(
          "transition",
          { attrs: { duration: 600 } },
          [_c("router-view", { attrs: { day: _vm.day } })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }