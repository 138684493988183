<template>
  <header class="app-tab-bar">
    <slot />
  </header>
</template>

<script>
  export default {
    name: 'AppTabBar',
  };
</script>

<style lang="scss" scoped>
  .app-tab-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include onDesktop {
      align-items: flex-start;
      flex-wrap: nowrap;
    }

    > a {
      font-family: "Gotham Rounded", sans-serif;
      font-weight: normal;
      font-size: 2rem;
      margin: 0 0.6rem;
      padding: 0.6rem 0;
      text-align: center;
      text-decoration: none;
      color: #1F1F1F;

      @include onDesktop {
        margin: 0 2.4rem;
      }

      &.router-link-exact-active {
        font-weight: bold;
        border-bottom: solid .5rem #27D7AB;
      }
    }
  }
</style>
