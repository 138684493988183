var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file",
      attrs: { title: _vm.label },
      on: { click: _vm.download }
    },
    [
      _c(
        "div",
        { staticClass: "thumbnail" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "file-icon",
            attrs: {
              icon: _vm.removing ? _vm.$icons.spinner : _vm.$icons.file,
              spin: _vm.removing
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.download($event)
              }
            }
          }),
          _vm.removable
            ? _c(
                "div",
                {
                  staticClass: "remove-icon",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.remove($event)
                    }
                  }
                },
                [
                  _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.trashOutlined }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "plus-icon",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.toggleDisplay($event)
                }
              }
            },
            [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.eye } })],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "label" }, [_vm._t("default")], 2),
      _vm.showFile && _vm.base64File
        ? _c(
            "AppPopIn",
            { attrs: { "max-width": "50rem" }, on: { close: _vm.close } },
            [
              _c("img", {
                attrs: {
                  src: "data:image/png;base64," + _vm.base64File,
                  alt: "file preview",
                  id: "file-preview"
                }
              }),
              _c("ButtonRed", { on: { click: _vm.close } }, [_vm._v("Quitter")])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }