import { convertDateToSlash } from './format';
import moment from 'moment';
import { cloneDeep } from 'lodash-es';

export class ObjectGetter {
  constructor(obj) {
    this.obj = obj;
  }

  get(path, defaultValue = null) {
    return path.split('.').reduce((xs, x) => (xs && xs[x] ? xs[x] : null), this.obj) || defaultValue;
  }
}


export const guessDiseaseType = (claim) => {
  if (claim.maternity_since) {
    return 'maternity';
  }

  if (claim.professional_disease_number || claim.professional_disease_since) {
    return 'professional_disease';
  }

  return 'disease';
};

export const guessCareType = (claim) => {
  switch (true) {
    case claim.sent_by_doctor?.length > 1:
      return 'sent_by_doctor';
    case claim.is_away_from_home:
      return 'is_away_from_home';
    case claim.is_direct_access:
      return 'is_direct_access';
    case claim.is_emergency:
      return 'is_emergency';
    case claim.is_substitute_doctor:
      return 'is_substitute_doctor';
    case claim.is_non_coordinating:
      return 'is_non_coordinating';
    default:
      return 'doctor';
  }
};

export const guessQuotations = (claim, defaultDate) => {
  const quotations = [];
  let i = 0;

  do {
    const quotation = claim.quotations?.[i];

    if (!quotation) {
      break;
    }

    quotations.push({
      sid: quotation.sid,
      overpaid_code: quotation.overpaid_code,
      date: convertDateToSlash(quotation.date),
      price: quotation.price / 100,
    });

    i += 1;
  }
  while (true);

  if (quotations.length === 0) {
    return [{ sid: null, overpaid_code: null, date: moment(defaultDate).format('DD/MM/YYYY'), price: null }];
  }

  return quotations;
};

export const splitArray = (array, chunksSize) => {
  let arrays = [];

  for (let i = 0, len = array.length; i < len; i += chunksSize) {
    arrays.push(array.slice(i, i + chunksSize));
  }

  return arrays;
};

export function logError(...args) {
  console.log('%c FRONT PRACTITIONER ERROR ', 'background:#111;color:#FF8070');
  args.forEach(arg => console.dir(arg));
}

export function hasLocalStorage(key) {
  return window.localStorage.getItem(key) !== null;
}

export function getLocalStorage(key, removeAfterGet = false) {
  if (!hasLocalStorage(key)) {
    return null;
  }

  const value = window.localStorage.getItem(key);

  if (removeAfterGet) {
    removeLocalStorage(key);
  }

  return JSON.parse(value);
}

export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorage(key) {
  window.localStorage.removeItem(key);
}

export function buildDelayedFunction(delayFunction, clearDelayFunction) {
  return function (handler, delay, immediate = false, clearOnDestroy = true) {
    if (immediate) {
      handler();
    }

    const id = delayFunction(handler, delay);

    if (clearOnDestroy) {
      this.$once('hook:beforeDestroy', function () {
        clearDelayFunction(id);
      });
    }

    return id;
  };
}

const settings = cloneDeep(window._HC_SETTINGS || {});

export function getSetting(key) {
  return settings[key];
}

export const currentEnvironment = process.env.NODE_ENV;
export const isProduction = currentEnvironment === 'production';
export const isDevelopment = currentEnvironment === 'development';

/**
 * @param {string} selector
 * @param {number} maxAttemptDuration
 * @returns {Promise<HTMLElement>|HTMLElement}
 */
export function pickElement(selector, maxAttemptDuration = 3000) {
  return new Promise((resolve) => {
    const element = document.querySelector(selector);

    if(element) {
      return resolve(element);
    }

    let timeoutId;

    const observer = new MutationObserver((mutations, obs) => {
      const element = document.querySelector(selector);

      if (element) {
        obs.disconnect();
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
        resolve(element);
      }
    });

    if(maxAttemptDuration) {
      timeoutId = window.setTimeout(() => {
        observer.disconnect();
        resolve(null);
      }, maxAttemptDuration);
    }

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  });
}

export function wait(durationInMs) {
  return new Promise((resolve) => window.setTimeout(resolve, durationInMs));
}
