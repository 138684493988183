import { getSetting } from '@/helpers/tools';

export const stripe = Stripe(getSetting('STRIPE_API_KEY'));
const elements = stripe.elements();

export const cardNumber = elements.create('cardNumber', { placeholder: '' });
export const cardExpiry = elements.create('cardExpiry', { placeholder: '' });
export const cardCvc = elements.create('cardCvc', { placeholder: '' });

export const iban = elements.create('iban', {supportedCountries: ['SEPA'], hideIcon: true});

