<template>
  <div class="appointment-panel-close-link" v-if="appointment">
    <p v-if="isRemote">
      <a :class="{'is-terminating': isTerminating}" href="#" @click.prevent="redirectToReport">
        <span>Accéder au formulaire post-consultation</span>
        <FontAwesomeIcon :icon="$icons.arrowCircleRight" />
      </a>
      <br>
      <small>
        Vous pouvez finir de compléter le rapport de consultation.
      </small>
    </p>
    <p v-else>
      <AppConfirmation
        ref="closeAppointment"
        title="Êtes-vous sûr de vouloir clôturer la consultation ?"
        @confirm="closeAppointment"
      />

      <a href="#" @click.prevent="$confirm($refs.closeAppointment)">
        <span>Clôturer la consultation</span>
        <FontAwesomeIcon :icon="$icons.arrowCircleRight" />
      </a>
    </p>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';

  export default {
    name: 'AppointmentPanelCloseLink',
    components: { AppConfirmation },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      appointmentId() {
        return this.appointment.payload.id;
      },
      status() {
        return this.appointment.payload.status;
      },
      hasEncounter() {
        return !!this.appointment.payload.encounters?.[0];
      },
      isConfirmed() {
        return this.status === 'CONFIRMED';
      },
      isRemote() {
        return this.appointment.payload.product.channel === 'remote';
      },
      isTerminating() {
        return this.$store.state.appointments.isTerminating;
      },
      canBeTerminated() {
        return this.hasEncounter &&
          // TODO : Encounter has no status on Connect API
          this.appointment.payload.encounters[0].status === 'finished' &&
          this.isConfirmed;
      },
    },
    methods: {
      async redirectToReport() {
        if (this.isTerminating) {
          return;
        }

        if (this.canBeTerminated) {
          await this.$store.dispatch('appointmentsTerminate', { id: this.appointmentId });
        }

        await this.$router.push({
          name: 'consultation-report',
          params: { appointmentId: this.appointmentId },
          query: { to: this.$route.name },
        });
      },
      async closeAppointment() {
        try {
          await this.$store.dispatch('appointmentsClose', { id: this.appointmentId });
          this.$emit('close');
          await this.$store.dispatch('appointmentsFetchPage', { page: this.$store.state.appointments.page });
        } catch (e) {
          await this.$addError('Une erreur est survenue lors de la fermeture du rendez-vous.');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-close-link {
    p {
      margin: 0;
    }

    a {
      color: $errorColor;

      span {
        margin-right: 0.5rem;
        text-decoration: underline;
      }
    }

    small {
      font-size: 1rem;
      color: $errorColor;
    }
  }
</style>
