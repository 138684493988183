import api from './';

let startPath = process.env.ENVIRONMENT === "local" ? "practitioners" : "Practitioner"

export default {
  list: (practitionerId, start, end, smart) => {
    let url = `${startPath}/${practitionerId}/availability?`;
    let params = new URLSearchParams();
    params.append('start', start);
    params.append('end', end);
    if (smart) {
      params.append('smart', smart);
    }
    url += params.toString();
    return api.get(url).json()
  },
  create: (practitionerId, data) => api.post(`${startPath}/${practitionerId}/availability`, { json: {availability: data} }).json(),
  update: (practitionerId, data) => api.patch(`${startPath}/${practitionerId}/availability`, { json: data }).json(),
  details: (practitionerId, date, type) => {
    let url = `${startPath}/${practitionerId}/availability/details?`;
    let params = new URLSearchParams();
    params.append('date', date);
    if (type) {
      params.append('type', type);
    }
    url += params.toString();
    return api.get(url).json()
  },
  delete: (practitionerId, data) => api.delete(`${startPath}/${practitionerId}/availability`, { json: data }).json(),
};
