import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faAddressBook,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faCalendarAlt,
  faCheckCircle as farCheckCircle,
  faClock as farClock,
  faCreditCard,
  faEdit,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faHourglass,
  faListAlt,
  faObjectGroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle,
  faSave,
  faThumbsDown,
  faThumbsUp,
  faTimesCircle as farTimesCircle,
  faTrashAlt,
  faUser,
  faWindowMinimize,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowRight,
  faBars,
  faBookmark,
  faCamera,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faClipboardList,
  faClock,
  faCog,
  faComment,
  faEllipsisV,
  faEuroSign,
  faExchangeAlt,
  faExclamation,
  faExclamationTriangle,
  faFile,
  faFileMedical,
  faFileSignature,
  faGlobeEurope,
  faIdBadge,
  faIdCardAlt,
  faInfo,
  faLink,
  faList,
  faLock,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faMoneyCheckAlt,
  faPaperPlane,
  faPhone,
  faPhoneSlash,
  faPills,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestion,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faShieldAlt,
  faSitemap,
  faSlidersH,
  faSpinner,
  faStethoscope,
  faTimesCircle,
  faTrash,
  faUndo,
  faUpload,
  faUserLock,
  faUserMd,
  faVideo,
  faVideoSlash,
  faWallet,
  faStore,
  faPen
} from '@fortawesome/free-solid-svg-icons';

import {
  faChrome,
  faFirefox,
  faSafari,
} from '@fortawesome/free-brands-svg-icons';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.mixin({
  computed: {
    $icons: () => ({
      IBAN: faMoneyCheckAlt,
      accept: faCheckCircle,
      acceptOutlined: farCheckCircle,
      addDrug: faPlus,
      addressCard: faAddressCard,
      appointmentStatusCancelled: faTimesCircle,
      appointmentStatusClosed: faCheckCircle,
      appointmentStatusDefault: faPlusCircle,
      appointmentStatusNew: faClock,
      appointmentStatusReport: faEdit,
      arrowCircleRight: faArrowAltCircleRight,
      arrowRight: faArrowRight,
      bookmark: faBookmark,
      calendar: faCalendarAlt,
      camera: faCamera,
      checkbox: faCheck,
      pen: faPen,
      chevronLeft: faChevronLeft,
      chevronRight: faChevronRight,
      chevronUp: faChevronUp,
      chevronDown: faChevronDown,
      consultationsCount: faChartBar,
      chat: faComment,
      chrome: faChrome,
      claimForm: faClipboardList,
      clock: farClock,
      closePanel: farTimesCircle,
      creditCard: faCreditCard,
      dashboard: faObjectGroup,
      edit: faEdit,
      encounterCameraToggle: faVideo,
      encounterCameraToggleDisabled: faVideoSlash,
      encounterMicrophoneToggle: faMicrophone,
      encounterMicrophoneToggleDisabled: faMicrophoneSlash,
      encounterPanelToggle: faFileMedical,
      encounterReportSendAndBill: faEuroSign,
      encounterStart: faVideo,
      encounterStartDisabled: faVideoSlash,
      encounterStop: faPhoneSlash,
      exclamation: faExclamation,
      expand: faAngleDown,
      eye: faEye,
      file: faFile,
      filesPanel: faShareSquare,
      firefox: faFirefox,
      globe: faGlobeEurope,
      goBack: faArrowAltCircleLeft,
      hidden: faEyeSlash,
      idBadge: faIdBadge,
      idCard: faIdCardAlt,
      info: faInfo,
      inputError: faExclamationTriangle,
      link: faLink,
      lock: faLock,
      logout: faPowerOff,
      mail: faPaperPlane,
      mapMarker: faMapMarkerAlt,
      medic: faUserMd,
      microphone: faMicrophone,
      mobile: faMobileAlt,
      myPatients: faAddressBook,
      navigationToggle: faBars,
      navigationToggleOff: faWindowMinimize,
      newAppointment: faPlus,
      options: faSlidersH,
      panelToggleClosed: faAngleRight,
      panelToggleOpened: faAngleDown,
      passwordHidden: faEyeSlash,
      passwordVisible: faEye,
      paymentDisabled: farTimesCircle,
      paymentEnabled: faCheckCircle,
      paymentPartial: faExclamationTriangle,
      pdf: faFilePdf,
      phone: faPhone,
      pills: faPills,
      planning: faListAlt,
      prescription: faFileAlt,
      protectedMail: faShieldAlt,
      question: faQuestion,
      refund: faUndo,
      reload: faRedo,
      removePrescriptionLine: faTrash,
      removeProduct: faTrash,
      removeQuotation: faTrash,
      removeQuotationCancel: farTimesCircle,
      removeQuotationConfirm: farCheckCircle,
      report: faList,
      safari: faSafari,
      saveOutlined: faSave,
      selectIndicator: faAngleDown,
      send: farPaperPlane,
      settings: faCog,
      shrink: faAngleUp,
      signature: faFileSignature,
      spinner: faSpinner,
      stethoscope: faStethoscope,
      subscription: faFileSignature,
      subscriptionActive: farCheckCircle,
      subscriptionActiveCanceled: farTimesCircle,
      subscriptionInactive: faHourglass,
      territorialOrganization: faSitemap,
      thumbsDown: faThumbsDown,
      thumbsUp: faThumbsUp,
      transaction: faExchangeAlt,
      transactionStatusCaptured: farCheckCircle,
      transactionStatusPending: faHourglass,
      transactionStatusRefunded: farTimesCircle,
      transactionStatusUnknown: faQuestionCircle,
      trashOutlined: faTrashAlt,
      upload: faUpload,
      user: faUser,
      userLock: faUserLock,
      userMd: faUserMd,
      verticalDots: faEllipsisV,
      viewPatient: faPlusCircle,
      wallet: faWallet,
      zoomIn: faSearchPlus,
      zoomOut: faSearchMinus,
      store: faStore
    }),
  },
});
