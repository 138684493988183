import Encounters from '@/api/encounters';
import Orders from '@/api/orders';

export default {
  state: {
    isFetchingOne: false,
    isRating: false,
    isSendingReport: false,
    current: null,
  },
  mutations: {
    ENCOUNTERS_FETCH_ONE(state) {
      state.current = null;
      state.isFetchingOne = true;
    },
    ENCOUNTERS_FETCH_ONE_SUCCESS(state, { encounter }) {
      state.isFetchingOne = false;
      state.current = encounter;
    },
    ENCOUNTERS_FETCH_ONE_FAIL(state) {
      state.isFetchingOne = false;
      state.current = null;
    },
    ENCOUNTERS_CREATE() {},
    ENCOUNTERS_CREATE_SUCCESS(state, { encounter }) {
      state.current = encounter;
    },
    ENCOUNTERS_CREATE_FAIL() {},
    ENCOUNTER_UPDATE() {},
    ENCOUNTER_UPDATE_FAIL() {},
    ENCOUNTERS_CLEAR_CURRENT(state) {
      state.current = null;
    },
    ENCOUNTERS_RATE(state) {
      state.isRating = true;
    },
    ENCOUNTERS_RATE_SUCCESS(state) {
      state.isRating = false;
    },
    ENCOUNTERS_RATE_FAIL(state) {
      state.isRating = false;
    },
    ENCOUNTERS_SEND_REPORT(state) {
      state.isSendingReport = true;
    },
    ENCOUNTERS_SEND_REPORT_SUCCESS(state) {
      state.isSendingReport = false;
    },
    ENCOUNTERS_SEND_REPORT_FAIL(state) {
      state.isSendingReport = false;
    },
  },
  actions: {
    async encountersFetchOne({ commit }, { id, abortSignal }) {
      commit('ENCOUNTERS_FETCH_ONE');
      try {
        const encounter = await Encounters.getOne(id, abortSignal);
        commit('ENCOUNTERS_FETCH_ONE_SUCCESS', { encounter });
      } catch (e) {
        commit('ENCOUNTERS_FETCH_ONE_FAIL');
        throw e;
      }
    },
    async encountersCreate({ commit }, { appointmentId, patientId, productId }) {
      commit('ENCOUNTERS_CREATE');
      try {
        const encounter = await Encounters.create(appointmentId, patientId, productId);
        commit('ENCOUNTERS_CREATE_SUCCESS', { encounter });
      } catch (e) {
        commit('ENCOUNTERS_CREATE_FAIL');
        throw e;
      }
    },
    async encounterUpdate({commit}, { id, payload }) {
      commit('ENCOUNTER_UPDATE');
      try {
        await Encounters.update(id, payload);
      } catch (e) {
        commit('ENCOUNTER_UPDATE_FAIL');
        throw e;
      }
    },
    async encountersRate({ commit }, { id, rate, sound_issue, video_issue, comment_issue }) {
      commit('ENCOUNTERS_RATE');
      try {
        await Encounters.rate(id, rate, sound_issue, video_issue, comment_issue);
        commit('ENCOUNTERS_RATE_SUCCESS');
      } catch (e) {
        commit('ENCOUNTERS_RATE_FAIL');
        throw e;
      }
    },
    async encountersClearCurrent({ commit }) {
      commit('ENCOUNTERS_CLEAR_CURRENT');
    },
    /**
     * @param commit
     * @param {{
     *   encounterId: string,
     *   appointmentId: string,
     *   productId: string,
     *   productPrice: number,
     *   beneficiaryId: number,
     *   medicalObservation: string,
     *   patientAdvice: string,
     *   motives: string,
     *   createOrder: boolean,
     *   charge: boolean,
     *   isForPharmacy: boolean
     * }} data
     * @return {Promise<void>}
     */
    async encountersSendReport({ commit }, data) {
      commit('ENCOUNTERS_SEND_REPORT');
      try {
        const promises = [];
        promises.push(Encounters.report(data.encounterId, data.beneficiaryId, data.medicalObservation, data.patientAdvice, data.motives));

        if (data.createOrder) {
          promises.push(Orders.create(data.appointmentId, data.productId, data.charge ? data.productPrice : 0));
        }

        await Promise.all(promises);

        commit('ENCOUNTERS_SEND_REPORT_SUCCESS');
      } catch (e) {
        commit('ENCOUNTERS_SEND_REPORT_FAIL');
        throw e;
      }
    },
  },
};
