import ky from 'ky';
import Vue from 'vue';
import Vuex from 'vuex';
import modules from './store_modules';
import moment from 'moment';
import { isProduction } from '../helpers/tools.js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: !isProduction,
  modules,
  state: {
    apiKey: null,
    apiToken: null,
    currentTime: moment(),
    currentDay: moment().startOf('day'),
    minimumNewAppointmentStartAt: moment(),
    settings: {},
  },
  mutations: {
    API_KEY_FETCH_SUCCESS(state, { apiKey, apiToken }) {
      state.apiKey = apiKey;
      state.apiToken = apiToken;
    },
    SET_CURRENT_TIME(state, { currentTime }) {
      state.currentTime = currentTime;
    },
    SET_CURRENT_DAY(state, { currentDay }) {
      state.currentDay = currentDay;
    },
    SET_MINIMUM_NEW_APPOINTMENT_START_AT(state, { minimumNewAppointmentStartAt }) {
      state.minimumNewAppointmentStartAt = minimumNewAppointmentStartAt;
    },
    UPDATE_SETTINGS() {},
    UPDATE_SETTINGS_SUCCESS(state, { settings }) {
      state.settings = settings;
    },
    UPDATE_SETTINGS_FAILED() {},
  },
  actions: {
    async loadSettings({ commit }) {
      commit('UPDATE_SETTINGS');
      try {
        const settings = await ky.get('/dynamic_settings.json').json();
        commit('UPDATE_SETTINGS_SUCCESS', { settings });
      } catch (e) {
        commit('UPDATE_SETTINGS_FAILED');
      }
    },
    refreshCurrentTime({ commit, state }) {
      const currentTime = moment();

      if (!currentTime.isSame(state.currentTime, 'minutes')) {
        commit('SET_CURRENT_TIME', { currentTime });
      }

      if (!currentTime.isSame(state.currentDay, 'day')) {
        commit('SET_CURRENT_DAY', { currentDay: moment(currentTime).startOf('day') });
      }

      const minimumNewAppointmentStartAt = moment(currentTime).minutes(Math.ceil(currentTime.minutes() / 10) * 10).seconds(0);

      if (!state.minimumNewAppointmentStartAt.isSame(minimumNewAppointmentStartAt, 'minutes')) {
        commit('SET_MINIMUM_NEW_APPOINTMENT_START_AT', { minimumNewAppointmentStartAt });
      }
    },
  },
  getters: {
    minutesSinceMidnight(state) {
      return state.currentTime.diff(state.currentTime.clone().startOf('day'), 'minutes');
    },
  },
});
