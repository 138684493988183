var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment && _vm.appointment.payload.prescriptions.length > 0
    ? _c(
        "div",
        { staticClass: "appointment-panel-prescriptions" },
        _vm._l(_vm.appointment.payload.prescriptions, function(
          prescription,
          index
        ) {
          return _c(
            "div",
            [
              prescription.status === "completed"
                ? _c(
                    "ButtonRed",
                    {
                      attrs: {
                        busy: _vm.downloadingPrescriptionId === prescription.id,
                        icon: _vm.$icons.pdf,
                        small: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.downloadPrescription(prescription.id)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n      Télécharger l'ordonnance n°" +
                          _vm._s(index + 1) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }