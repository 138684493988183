<template>
  <div id="app">
    <AppLoader v-if="appLoaderVisible">
      CHARGEMENT
    </AppLoader>
    <AppNotification v-if="!appLoaderVisible" />
    <router-view />
    <portal-target name="app-panel" multiple/>
    <portal-target name="app-pop-in" />
    <portal-target name="app-confirmation" />
    <AppGuide />
  </div>
</template>

<script>
  import AppGuide from '@/components/AppGuide.vue';
  import { getFullName } from '@/helpers/format.js';
  import AppLoader from './components/AppLoader';
  import AppNotification from './components/AppNotification';
  import Practitioners from '@/api-platform/practitioners';
  import 'smartbanner.js/dist/smartbanner.min.js';
  import moment from "moment";

  export default {
    name: 'App',
    components: { AppGuide, AppLoader, AppNotification },
    mounted() {
      this.$setInterval(() => { this.$store.dispatch('refreshCurrentTime'); }, 1000);
      this.$setInterval(() => { this.$store.dispatch('loadSettings'); }, 5 * 60 * 1000, true);
      window.addEventListener('load', () => {
        if (!window.FrontChat) {
          return;
        }
        window.FrontChat('init', {chatId: '5ca2dbf58706726d56be871c69aa67ab', useDefaultLauncher: true});
        window.FrontChat('identity', {
          email: this.practitioner.email,
          name: getFullName(this.practitioner.firstName, this.practitioner.lastName),
          // TODO: add userHash property to authenticate the user
          // userHash: this.practitioner.frontAppUserHash,
        })
      })
    },
    computed: {
      appLoaderVisible() {
        return this.$store.state.appLoader.isVisible;
      },
      practitioner() {
        return this.$store.state.practitioner.profile;
      },
      isPreDeletedAccount() {
        if(this.practitioner.deletedAt !== null) {
          return this.practitioner.deletedAt;
        }
      },
      hasAcceptTransferError() {
        return this.practitioner.cardPaymentActivated && this.practitioner.acceptPayment && !this.practitioner.acceptTransfer;
      },
      hasAcceptPaymentError() {
        return this.practitioner.cardPaymentActivated && !this.practitioner.acceptPayment;
      },
      hasStripeError() {
        return this.hasAcceptPaymentError || this.hasAcceptTransferError;
      },
      isAccountRestricted() {
        return this.practitioner.isRestricted;
      },
    },
    methods: {
      async addStripeError() {
        const { link } = await Practitioners.getAccountLink(this.$route.fullPath);

        let errorMessage = '';

        if (this.hasAcceptTransferError) {
          errorMessage = `Les virements vers votre compte sont suspendus : <a href="${link}">complétez vos informations</a> pour les réactiver !`;
        } else if (this.hasAcceptPaymentError) {
          errorMessage = `Les paiements sont suspendus sur votre compte : <a href="${link}">complétez vos informations</a> pour réactiver les téléconsultations payantes !`;
        } else {
          return;
        }

        this.$addError(`${errorMessage} Une fois le profil complété, la vérification peut prendre quelques heures.`, false, 0, true);
      },
      addAccountRestrictedError() {
        const errorMessage = `
          Votre compte est actuellement restreint.
          Nous devons procéder à la vérification de vos données.
          En attendant, certaines fonctionnalités sont désactivées.
          Pour plus d'informations, merci de nous contacter par mail à
          <a href="mailto:support@hellocare.com">support@hellocare.com</a>`;

        this.$addError(errorMessage, false, 0, true);
      },
      messageAfterDeleteAccount() {
        const deleteAccount = `
          Votre compte sera supprimé le ${moment(this.isPreDeletedAccount).calendar()}.
          Si vous n'êtes pas a l'origine de cette suppression ou
          si vous voulez garder votre compte, veuillez contacter le support à
          <a href="support@hellocare.com">support@hellocare.com</a>`;

        this.$addError(deleteAccount, false, 7000, true);
      },
    },
    watch: {
      hasStripeError(hasStripeError) {
        if (hasStripeError) {
          this.addStripeError();
        }
      },
      isAccountRestricted(isAccountRestricted) {
        if (isAccountRestricted) {
          this.addAccountRestrictedError();
        }
      },
      isPreDeletedAccount(isPreDeletedAccount) {
        if(isPreDeletedAccount) {
          this.messageAfterDeleteAccount();
        }
      }
    },
  };
</script>

<style lang="scss" src="./assets/style.scss" />

<style lang="scss">
  @import "../node_modules/smartbanner.js/dist/smartbanner.min.css";

  #app {
    @include onMobile {
      min-height: calc(100vh - 70px);
    }
  }

  .smartbanner {
    top: auto;
    bottom: 0;
  }

  .smartbanner.smartbanner--hellocare {
    position: fixed;
    z-index: 2147483002;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
    top: auto;
    bottom: 0;
    .smartbanner__icon {
      left: 1.5rem;
    }

    .smartbanner__info {
      font-family: $fontRoboto;
      right: 4rem;
      left: 9.2rem;
      width: auto;
    }

    .smartbanner__info__title,
    .smartbanner__info__author,
    .smartbanner__info__price {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .smartbanner__info__author,
    .smartbanner__info__price {
      font-weight: 300;
    }

    .smartbanner__button {
      top: 0;
      right: 3rem;
      bottom: 0;
      left: 0;
      padding: 0;
      background-color: transparent;
    }

    .smartbanner__button__label {
      display: none;
    }

    .smartbanner__exit {
      top: 0.5rem;
      right: 0.5rem;
      left: auto;
      padding: 10px;
      border-radius: 50%;
      background-color: black;

      &::before,
      &::after {
        top: 4px;
        left: 9px;
        width: 2px;
        background-color: white;
      }
    }
  }
</style>
