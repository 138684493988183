export const LoadPractitionerMixin = {
  methods: {
    async loadPractitioner(showAppLoader = true) {
      if(showAppLoader) {
        await this.$store.dispatch('appLoaderShow');
      }
      try {
        await this.$store.dispatch('practitionerFetchProfile');
      } catch (e) {
        await this.$addError('Une erreur s\'est produite lors du chargement du profil.');
      } finally {
        if (showAppLoader) {
          await this.$store.dispatch('appLoaderHide');
        }
      }
    },
  },
};
export const AbortMixin = {
  data() {
    return {
      abortController: null,
    };
  },
  computed: {
    abortSignal() {
      return this.abortController.signal;
    },
  },
  methods: {
    isAbortError(error) {
      return error && error.name === 'AbortError';
    },
  },
  created() {
    this.abortController = new AbortController();
  },
  beforeDestroy() {
    this.abortController.abort();
  },
};
