<template>
  <div class="agenda-calendar-hours-column">
    <div
      v-for="hour in hours"
      class="hour-indicator"
      :style="{ height }"
    >
      {{ hour }}:00
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AgendaCalendarHoursColumn',
    computed: {
      hours() {
        return [...Array(24).keys()];
      },
      height() {
        return this.$minutesToPixels(60);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hour-indicator {
    width: 5rem;
    font-size: 1.4rem;
    transform: translateY(-8px);

    &:first-child {
      transform: none;
    }
  }
</style>
