import api from './';
import { normalizePaymentMethods } from '@/helpers/normalizers';
import {normalizePaymentMethodsSEPA} from "../helpers/normalizers";

export default {
  fetchAll: (type) => {
    const searchParams = new URLSearchParams({ type: `${type}` });
    return api.get('payment_methods', {
      searchParams
    }).json().then( (response) => {
    if(type === 'card') return normalizePaymentMethods(response.data)
    if(type === 'sepa')  return normalizePaymentMethodsSEPA(response.data)
  })},
  delete: (id) => api.delete(`payment_methods/${id}`),
};
