<template>
  <div class="agenda-calendar-week">
    <header>
      <div class="zoom">
        <FontAwesomeIcon
          :class="{ disabled: !canDecreaseSize }"
          :icon="$icons.zoomOut"
          @click="changeSize(false)"
        />
        <FontAwesomeIcon
          :class="{ disabled: !canIncreaseSize }"
          :icon="$icons.zoomIn"
          @click="changeSize(true)"
        />
      </div>
      <AgendaCalendarDayColumnHeader
        v-for="day in days"
        :key="day.unix()"
        :day="day"
      />
    </header>
    <div ref="calendar" class="container">
      <div class="calendar">
        <AgendaCalendarHoursColumn />
        <AgendaCalendarDayColumn
          v-for="(day, index) in days"
          :key="day.format()"
          :appointments="appointmentsList[index]"
          :day="day"
          @click="openNewAppointmentPanel($event)"
        />
        <div :style="`background-size: 100% ${oneHourHeight}`" class="hours-indicator" />
        <div :style="{ top: currentTimeIndicatorTop }" class="current-time-indicator"></div>
        <NewAppointmentPanel
          :opened="isNewAppointmentPanelOpened"
          :start-date="newAppointmentStartAt"
          @close="handleNewAppointmentPanelClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { extendMoment } from 'moment-range';
  import AgendaCalendarHoursColumn from './AgendaCalendarHoursColumn';
  import AgendaCalendarDayColumn from './AgendaCalendarDayColumn';
  import AgendaCalendarDayColumnHeader from './AgendaCalendarDayColumnHeader';
  import NewAppointmentPanel from '../NewAppointmentPanel';
  import ButtonGrey from '../Button/ButtonGrey.vue';

  export default {
    name: 'AgendaCalendarWeek',
    components: {
      ButtonGrey,
      NewAppointmentPanel,
      AgendaCalendarDayColumnHeader,
      AgendaCalendarDayColumn,
      AgendaCalendarHoursColumn,
    },
    props: {
      secretariesChecked: {
        required:false
      },
      day: {
        type: moment,
        required: true,
      },
    },
    mounted() {
      this.loadAgendaAppointments();
      this.loadAvailabilities();
      this.$store.dispatch('agendaLoadMinuteToPixelsRatioFromLocalStorage');
      this.$setInterval(this.loadAgendaAppointments, 60000);
      this.$nextTick(() => this.$refs.calendar.scrollTo(0, this.$store.getters.agendaPixelsSinceMidnight - this.$refs.calendar.clientHeight / 2));
    },
    data() {
      return {
        firstDay: moment(this.day).startOf('isoWeek'),
        lastDay: moment(this.day).endOf('isoWeek'),
        isNewAppointmentPanelOpened: false,
        newAppointmentStartAt: null,
      };
    },
    watch: {
      day: function (newDay) {
        this.firstDay = moment(newDay).startOf('isoWeek');
        this.lastDay = moment(newDay).endOf('isoWeek');
        this.loadAgendaAppointments();
        this.loadAvailabilities();
      },
    },
    methods: {
      changeSize(increase) {
        if ((increase && !this.canIncreaseSize) || (!increase && !this.canDecreaseSize)) {
          return;
        }

        const middleInMinutes = this.$pixelsToMinutes(this.$refs.calendar.scrollTop + 250);

        if (increase) {
          this.$store.dispatch('agendaIncreaseMinuteToPixelsRatio');
        } else {
          this.$store.dispatch('agendaDecreaseMinuteToPixelsRatio');
        }

        this.$nextTick(() => this.$refs.calendar.scrollTo(0, this.$minutesToPixels(middleInMinutes, false) - 250));
      },
      async loadAgendaAppointments() {
        await this.$store.dispatch('appointmentsFind', { startDate: this.firstDay, endDate: this.lastDay });
      },
      async loadAvailabilities() {
        await this.$store.dispatch('practitionerFetchAvailabilities', {
          start: this.firstDay.format('YYYY-MM-DD'),
          end: this.lastDay.format('YYYY-MM-DD'),
          smart: 'true',
        });
      },
      openNewAppointmentPanel(startAt) {
        this.isNewAppointmentPanelOpened = true;
        this.newAppointmentStartAt = startAt.format();
      },
      handleNewAppointmentPanelClose(submitted) {
        this.isNewAppointmentPanelOpened = false;
        this.newAppointmentStartAt = null;

        if (submitted) {
          this.loadAgendaAppointments();
        }
      },
    },
    computed: {
      days() {
        const range = extendMoment(moment).range(this.firstDay, this.lastDay);
        return range.by('days');
      },
      foundAppointments() {
        return this.$store.state.appointments.found;
      },
      appointmentsList() {
        const currDate = moment(this.firstDay).startOf('day');
        const endDate = moment(this.lastDay).startOf('day');
        let appointmentList = [];
        const displayedPractitionersIds = this.secretariesChecked.filter(s => s.isChecked).map(s => s.id)
        const filteredFoundAppointments = this.foundAppointments.filter(a => {
          return displayedPractitionersIds.includes(a.practitioner.id)
        })
        do {
          const currEndOfDay = moment(currDate).endOf('day');
          appointmentList.push(filteredFoundAppointments.filter(elt => (moment(elt.startAt) > currDate && moment(elt.startAt) < currEndOfDay) && !elt.status.startsWith('CANCELLED_BY_')));
        } while (currDate.add(1, 'day').diff(endDate) <= 0);
        return appointmentList;
      },
      canDecreaseSize() {
        return this.$store.getters.agendaCanDecreaseMinuteToPixelsRatio;
      },
      canIncreaseSize() {
        return this.$store.getters.agendaCanIncreaseMinuteToPixelsRatio;
      },
      oneHourHeight() {
        return this.$minutesToPixels(60);
      },
      currentTimeIndicatorTop() {
        return `${this.$store.getters.agendaPixelsSinceMidnight}px`;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 28rem);
  }

  .calendar, header {
    display: flex
  }

  .calendar {
    position: relative;
  }

  header {
    display: flex;
  }

  .day {
    padding: 1px;
    background-color: $lighterGrey;

    &.fade {
      color: #CCCCCC;
    }
  }

  .zoom {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 5rem;

    svg {
      opacity: 0.4;

      &.disabled {
        opacity: 0.2;
      }

      &:not(.disabled):hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .hours-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 5rem;
    pointer-events: none;
    background-image: linear-gradient(0deg, $inputBorderColor 0, $inputBorderColor 1px, transparent 1px, transparent 100%);
    background-repeat: repeat;
  }

  .current-time-indicator {
    position: absolute;
    left: 5rem;
    width: 100%;
    height: 1px;
    pointer-events: none;
    background-color: $mainColor;

    &::before {
      display: block;
      width: 0;
      height: 1.1rem;
      content: " ";
      transform: translate(-0.7rem, -0.5rem);
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-left: 0.8rem solid $mainColor;
    }
  }
</style>
