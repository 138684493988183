<template>
  <AppLayout>
    <main class="profile-act-page">
      <ProfileTabBar />

      <div class="container">
        <div class="row">
          <div class="col _12 information">
            Définissez ici vos tarifs en fonction de vos types de consultations
          </div>
        </div>

        <div class="row">
          <div class="col _12">
            <ButtonGreen class="add-act" outlined small @click.stop="newProduct">
              Ajouter un tarif
            </ButtonGreen>
          </div>
        </div>

        <hr>

        <AppConfirmation
          ref="deleteProduct"
          title="Êtes-vous sûr de vouloir supprimer ce tarif ?"
          @confirm="deleteProduct"
        />

        <SlickList
          :class="{ 'is-reordering': isReordering }"
          :should-cancel-start="shouldCancelOrdering"
          v-model="products"
          class="products-list"
          lock-axis="y"
          @sort-end="handleSortEnd"
          use-drag-handle
        >
          <SlickItem v-for="(product, index) in products" :key="product.id" :index="index" class="row act">
            <div class="col _10 handle" v-handle>
              <h2>{{ product.name }} <small class="channel">- {{ product.channel | channel }}</small></h2>
              <p class="details">
                <span class="price">{{ product.unitPrice | money }}</span>
                <span class="duration">{{ product.duration }} min</span>
                <span v-if="!product.visible" class="hidden">
                  <FontAwesomeIcon :icon="$icons.hidden" />
                  Non visible par le patient
                </span>
              </p>
            </div>
            <div class="col _2 actions">
              <FontAwesomeIcon :icon="$icons.edit" class="icon" @click="editProduct(product)" />
              <FontAwesomeIcon
                :icon="$icons.removeProduct"
                class="icon"
                @click="$confirm($refs.deleteProduct, product.id)"
              />
            </div>
          </SlickItem>
        </SlickList>
      </div>
      <router-view :selected-product="selectedProduct" />
    </main>
  </AppLayout>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import AppLayout from '@/components/AppLayout';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import FormWidget from '@/components/Form/FormWidget';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import SectionTitle from '@/components/SectionTitle';
  import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort';

  export default {
    name: 'ProfileActPage',
    components: {
      AppConfirmation,
      AppLayout,
      ButtonGreen,
      FormWidget,
      ProfileTabBar,
      SectionTitle,
      SlickItem,
      SlickList,
    },
    directives: { handle: HandleDirective },
    data() {
      return {
        selectedProduct: {},
      };
    },
    async mounted() {
      await this.$store.dispatch('appLoaderShow');
      await this.$store.dispatch('productsFetchAll');
      await this.$store.dispatch('appLoaderHide');
    },
    computed: {
      practitioner() {
        return this.$store.state.practitioner.profile;
      },
      products: {
        get() {
          return this.$store.state.products.all;
        },
        set(products) {
          this.$store.dispatch('productsReplaceAll', { products })
        }
      },
      isReordering() {
        return this.$store.state.products.isReordering;
      },
    },
    methods: {
      shouldCancelOrdering(e) {
        return e.target.tagName === 'path' || this.isReordering;
      },
      newProduct() {
        this.selectedProduct = {
          name: null,
          unitPrice: null,
          duration: '10',
          channel: 'remote',
          visible: true
        };
        this.$router.push({ name: 'profile-act-add' });
      },
      editProduct(product) {
        this.$router.push({ name: 'profile-act-add' });
        this.selectedProduct = product;
      },
      async deleteProduct(id) {
        try {
          await this.$store.dispatch('productsDelete', { id });
        } catch (e) {
          await this.$addError('Une erreur s\'est produite lors de la suppression du tarif.');
          throw e;
        }
      },
      handleSortEnd({ oldIndex, newIndex }) {
        const productId = this.products[oldIndex].id;
        this.$store.dispatch('productsReorder', { productId, newIndex });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/grid";

  .container {
    max-width: 60rem;
    padding: 0 1rem;

    @include onDesktop {
      padding: 0;
    }
  }

  .add-act {
    width: 100%;
  }

  .act {
    align-items: normal;
    padding: 1rem 0;

    &:nth-child(even) {
      background-color: #FAFAFA;
    }
  }

  h2 {
    font-family: $fontRoboto;
    font-size: 2rem;
    font-weight: normal;
  }

  .channel {
    color: $grey;
  }

  .details {
    font-size: 2rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    text-align: left;
  }

  .price {
    width: 8rem;
    color: #F46868;
  }

  .duration {
    width: 8rem;
    color: $grey;
  }

  .actions {
    font-size: 2rem;
    display: flex;
    justify-content: flex-end;
    color: #9B9B9B;

    @include onDesktop {
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .icon {
    margin: 1rem 2rem;
    cursor: pointer;

    @include onDesktop {
      margin: 0;
    }
  }

  .information {
    font-style: italic;
    margin-bottom: 2rem;
    text-align: center;
    color: $grey;
  }

  .hidden {
    font-size: 1.5rem;
    font-style: italic;
    color: $grey;
  }

  .is-reordering {
    opacity: 0.5;
  }

  .handle {
    cursor: ns-resize;
  }
</style>
