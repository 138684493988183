<template>
  <div class="module-loader">
    <AppLogoAnimated />
    <p>Chargement en cours...</p>
  </div>
</template>

<script>
  import AppLogoAnimated from './AppLogoAnimated';

  export default {
    name: 'ModuleLoader',
    components: { AppLogoAnimated },
  };
</script>

<style lang="scss" scoped>
  .module-loader {
    padding: 2rem;
    text-align: center;
    background-color: #F9FCFA;
    color: $mainColor;

    p {
      margin: 2rem 0 0;
    }
  }

  .app-logo-animated {
    max-width: 10rem;
  }
</style>
