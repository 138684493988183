import Transactions from '../../api/transactions';

export default {
  state: {
    isFetchingSlice: false,
    slice: [],
    isRefunding: false,
  },
  mutations: {
    TRANSACTIONS_FETCH_SLICE(state) {
      state.isFetchingSlice = true;
    },
    TRANSACTIONS_FETCH_SLICE_SUCCESS(state, { transactions }) {
      state.slice = transactions;
      state.isFetchingSlice = false;
    },
    TRANSACTIONS_FETCH_SLICE_FAIL(state) {
      state.slice = [];
      state.isFetchingSlice = false;
    },
    TRANSACTIONS_REFUND(state) {
      state.isRefunding = true;
    },
    TRANSACTIONS_REFUND_SUCCESS(state, { transaction }) {
      state.isRefunding = false;
      state.slice = state.slice.map(t => t.id === transaction.id ? transaction : t);
    },
    TRANSACTIONS_REFUND_FAIL(state) {
      state.isRefunding = false;
    },
  },
  actions: {
    async transactionsFetchSlice({ commit }, { offset, count }) {
      commit('TRANSACTIONS_FETCH_SLICE');
      try {
        const transactions = await Transactions.getSlice(offset, count);
        commit('TRANSACTIONS_FETCH_SLICE_SUCCESS', { transactions });
      } catch (e) {
        commit('TRANSACTIONS_FETCH_SLICE_FAIL');
        throw e;
      }
    },
    async transactionsRefund({ commit }, { id, amount }) {
      commit('TRANSACTIONS_REFUND', { id, amount });
      try {
        const transaction = await Transactions.refund(id, amount);
        commit('TRANSACTIONS_REFUND_SUCCESS', { transaction });
      } catch(e) {
        commit('TRANSACTIONS_REFUND_FAIL', { id });
        throw e;
      }
    },
  },
};
