<template>
  <AppLayout>
    <main class="appointments-page">
      <div class="title-with-buttons">
        <SectionTitle>{{ isSecretary ? "Mes plannings" : "Mon planning" }}</SectionTitle>
        <div class="buttons">
          <ButtonGreen :icon="$icons.calendar" class="link-to-calendar" link-to="/agenda/view" outlined small>
            Afficher l'agenda
          </ButtonGreen>
          <template v-if="!isAccountRestricted">
            <div class="flex-buttons">
              <ButtonRed :icon="$icons.newAppointment" small @click="isNewAppointmentPanelOpened = true">
                Ajouter un RDV
              </ButtonRed>
              <ButtonGreen external class="replace-ps" v-if="profileType === 'doctor'" :icon="$icons.userMd" small link-to="https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links">
                Besoin d'être remplacé ?
              </ButtonGreen>
            </div>
            <NewAppointmentPanel
              :opened="isNewAppointmentPanelOpened"
              @close="handleNewAppointmentPanelClose"
            />
          </template>
        </div>
      </div>
      <AppointmentList ref="appointmentList" />
      <transition :duration="600">
        <router-view />
      </transition>
    </main>
  </AppLayout>
</template>

<script>
  import AppointmentList from '@/components/AppointmentList.vue';
  import AppLayout from '../components/AppLayout';
  import AppPanel from '../components/AppPanel';
  import ButtonGreen from '../components/Button/ButtonGreen.vue';
  import ButtonRed from '../components/Button/ButtonRed.vue';
  import NewAppointmentPanel from '../components/NewAppointmentPanel';
  import SectionTitle from '../components/SectionTitle';

  export default {
    name: 'AppointmentsPage',
    components: {
      AppointmentList,
      NewAppointmentPanel,
      AppPanel,
      AppLayout,
      SectionTitle,
      ButtonRed,
      ButtonGreen,
    },
    beforeRouteUpdate(to, from, next) {
      if (to.name === 'appointments') {
        this.$refs.appointmentList.loadAppointments();
      }
      next();
    },
    data() {
      return {
        isNewAppointmentPanelOpened: false,
      };
    },
    computed: {
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      profileType() {
        return this.$store.state.practitioner.profile.type;
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
    },
    methods: {
      handleNewAppointmentPanelClose(submitted) {
        this.isNewAppointmentPanelOpened = false;

        if (submitted) {
          this.$refs.appointmentList.loadAppointments();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointments-page {
    padding-left: 15rem;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .title-with-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @include onMobile {
      flex-direction: column;
      h3 {
        margin-bottom: 1rem;
      }
    }
  }

  .link-to-calendar {
    border: none;


    &:hover {
      text-decoration: underline;
    }

  }
  .replace-ps {
    margin-left: 1rem;
  }
  .flex-buttons {
    display: flex;
  }

  .link-to-calendar  {
    @include onMobile {
      padding-left: 0!important;
    }
  }
</style>
