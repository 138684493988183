<template>
  <AppPopIn @close="close" max-width="50rem">
    <template v-if="!isLoaded">
      <FontAwesomeIcon :icon="$icons.spinner" class="icon" spin />
      Chargement en cours...
    </template>
    <template v-else>
      <div v-if="!imageSource">
        <SectionTitle center>Photo de profil</SectionTitle>
        <p class="description">
          Cette photo est affichée lors de la recherche de praticiens pour un RDV. Elle aide les patients à vous
          identifier
        </p>
        <FileUploadButton @change="handleFileSelected" label="Cliquer ici pour choisir une photo de profil" />
      </div>
      <div class="crop-container" v-else>
        <img :src="imageSource" @load="initCropper" alt="" class="crop-preview" ref="preview">
      </div>
      <div class="buttons">
        <ButtonRed :busy="isUploading" @click="close" no-border outlined small>Annuler</ButtonRed>
        <ButtonGreen :busy="isUploading" @click="upload" small v-if="imageSource">Valider</ButtonGreen>
      </div>
    </template>
  </AppPopIn>
</template>

<script>
  import AppPopIn from './AppPopIn.vue';
  import ButtonRed from './Button/ButtonRed.vue';
  import ButtonGreen from './Button/ButtonGreen.vue';
  import FileUploadButton from '@/components/Consultation/CallingInterface/FileUploadButton.vue';
  import SectionTitle from '@/components/SectionTitle.vue';

  let croppie;

  export default {
    name: 'AvatarForm',
    components: { SectionTitle, FileUploadButton, ButtonGreen, ButtonRed, AppPopIn },
    data() {
      return {
        isCroppieScriptLoaded: false,
        isCroppieStyleLoaded: false,
        imageSource: null,
      };
    },
    async beforeMount() {
      if (window.Croppie) {
        this.isCroppieScriptLoaded = true;
        this.isCroppieStyleLoaded = true;
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.5/croppie.min.js';
      scriptElement.async = true;
      scriptElement.onload = () => this.isCroppieScriptLoaded = true;
      document.head.appendChild(scriptElement);

      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = 'https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.5/croppie.min.css';
      linkElement.async = true;
      linkElement.onload = () => { this.isCroppieStyleLoaded = true; };
      document.head.appendChild(linkElement);
    },
    beforeDestroy() {
      this.imageSource = null;
      croppie?.destroy();
      croppie = undefined;
    },
    computed: {
      isLoaded() {
        return this.isCroppieScriptLoaded && this.isCroppieStyleLoaded;
      },
      isUploading() {
        return this.$store.state.practitioner.isUploadingAvatar;
      },
    },
    methods: {
      close() {
        this.$store.dispatch('practitionerAvatarCloseForm');
      },
      async upload() {
        if (!croppie) {
          return;
        }

        let dataUrl = croppie.data.url;
        const file = await croppie.result({
          type: 'blob',
          format: dataUrl.substring(dataUrl.indexOf("/")+1, dataUrl.indexOf(";")),
          circle: false,
        });

        try {
          await this.$store.dispatch('practitionerUploadAvatar', { file });

          const fileAsData = await croppie.result({
            circle: false,
          });

          this.$emit('change', fileAsData);

          this.close();
        } catch (e) {
          this.$addError('Une erreur est survenue lors de l\'envoi de votre photo de profil.');
          throw e;
        }
      },
      async handleFileSelected(file) {
        if (!file) {
          return;
        }

        const reader = new FileReader();

        reader.onload = ({ target }) => {
          this.imageSource = target.result;
        };

        reader.readAsDataURL(file);
      },
      initCropper() {
        croppie = new Croppie(this.$refs.preview, {
          viewport: { width: 384, height: 384, type: 'circle' },
        });
      },
    },
  };
</script>

<style scoped>
  .crop-container {
    margin: 0 auto;
    width: 384px;
    height: 384px;
  }

  .crop-preview {
    opacity: 0;
  }

  .buttons {
    text-align: center;
    margin-top: 5rem;
  }

  .icon {
    margin-right: 1rem;
  }

  .description {
    text-align: center;
    margin-bottom: 2.5rem;
    font-style: italic;
  }
</style>
