<template>
  <AppTabBar class="patient-tab-bar">
    <router-link :to="{ name: 'patient', params: { id: patientId } }">Informations</router-link>
    <router-link :to="{ name: 'patient-appointments', params: { id: patientId } }">Historique RDV</router-link>
  </AppTabBar>
</template>

<script>
  import AppTabBar from '@/components/AppTabBar';

  export default {
    name: 'PatientTabBar',
    components: { AppTabBar },
    props: {
      patientId: {
        type: String,
        required: true,
      },
    },
  };
</script>
