var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { staticClass: "profile-settings-page" }, [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "information bordered" }, [
            _vm._v("\n        Personnalisez votre solution Hellocare !"),
            _c("br"),
            _vm._v(
              "\n        Ces paramètres sont inclus dans le prix de votre abonnement et vous permettent d'adapter la solution à votre\n        pratique."
            ),
            _c("br")
          ]),
          _c("div", { staticClass: "settings" }, [
            _c(
              "div",
              { staticClass: "settings-general" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.settings } }, [
                  _vm._v("Gérez vos paramètres")
                ]),
                _c(
                  "ProfileSetting",
                  {
                    attrs: {
                      disabled:
                        (_vm.profile.searchable &&
                          _vm.profile.instantEncountersEnabled) ||
                        !_vm.profile.practiceAddress ||
                        !_vm.profile.mainSpecialty,
                      property: "searchable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function() {
                          return [
                            !_vm.profile.practiceAddress
                              ? _c(
                                  "p",
                                  { staticClass: "setting-error" },
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.$icons.inputError }
                                    }),
                                    _vm._v("\n                Vous devez "),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "profile-edit",
                                            query: { hl: "practiceAddress" }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "renseigner l'adresse de votre cabinet"
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      " pour activer la prise de RDV en ligne\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.profile.mainSpecialty
                              ? _c(
                                  "p",
                                  { staticClass: "setting-error" },
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.$icons.inputError }
                                    }),
                                    _vm._v("\n                Vous devez "),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "profile-edit",
                                            query: { hl: "mainSpecialty" }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "renseigner votre spécialité principale"
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      " pour activer la prise de RDV en ligne\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.profile.instantEncountersEnabled &&
                            _vm.profile.searchable
                              ? _c(
                                  "p",
                                  { staticClass: "setting-error" },
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.$icons.inputError }
                                    }),
                                    _vm._v(
                                      "\n                Désactivez les RDV hors patientèle pour désactiver la prise de RDV en ligne\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n              Permet à vos patients de vous trouver via notre moteur de recherche et de prendre rendez-vous en ligne\n              directement.\n              "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#prise-de-RDV",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                En savoir plus\n              "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(
                      "\n            J’active la prise de RDV en ligne\n            "
                    )
                  ]
                ),
                _c(
                  "ProfileSetting",
                  {
                    attrs: {
                      disabled:
                        !_vm.profile.searchable &&
                        !_vm.profile.instantEncountersEnabled,
                      property: "instantEncountersEnabled"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function() {
                          return [
                            !_vm.profile.searchable
                              ? _c(
                                  "p",
                                  { staticClass: "setting-error" },
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.$icons.inputError }
                                    }),
                                    _vm._v(
                                      "\n                Activez la prise de RDV en ligne pour accepter les RDV hors patientèle\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n              Permet de développer vos revenus en recevant des patients supplémentaires.\n              "
                            ),
                            _vm.profile.newPatientFee
                              ? [
                                  _vm._v(
                                    "\n                Dans le cadre d'une téléconsultation, Hellocare percevra\n                "
                                  ),
                                  _c("strong", { staticClass: "important" }, [
                                    _vm._v(
                                      _vm._s(_vm.profile.newPatientFee) + "%"
                                    )
                                  ]),
                                  _vm._v(
                                    " du montant de celle-ci.\n              "
                                  )
                                ]
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#patient-plus",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("En savoir plus")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(
                      "\n            J’accepte les RDV hors patientèle\n            "
                    )
                  ]
                ),
                _vm.profile.type === "doctor"
                  ? _c(
                      "ProfileSetting",
                      {
                        attrs: {
                          disabled: !_vm.profile.uploadedSignature,
                          property: "claimFormEnabled"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "description",
                              fn: function() {
                                return [
                                  !_vm.profile.uploadedSignature
                                    ? _c(
                                        "p",
                                        { staticClass: "setting-error" },
                                        [
                                          _c("FontAwesomeIcon", {
                                            attrs: {
                                              icon: _vm.$icons.inputError
                                            }
                                          }),
                                          _vm._v(
                                            "\n                Ajoutez une signature à votre profil pour activer l'édition de la feuille de soin en fin de\n                téléconsultation.\n              "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n              Permet d’éditer et mettre à disposition du patient une feuille de soin en fin de téléconsultation.\n            "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4014305379
                        )
                      },
                      [
                        _vm._v(
                          "\n            J’active les feuilles de soins\n            "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "ProfileSetting",
                  {
                    attrs: { property: "smsEnabled" },
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n              Permet de rappeler à vos patients leurs RDV par SMS. Cette option permet de réduire significativement\n              les consultations non honorées.\n              "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://kb.hellocare.pro/support/les-options-payantes-sur-hellocare#rappels-SMS",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("En savoir plus")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(
                      '\n            J’active l\'option "Rappels SMS"\n            '
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "settings-payment" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.creditCard } }, [
                  _vm._v(
                    "\n            Paiement par carte bancaire\n          "
                  )
                ]),
                _c(
                  "ProfileSetting",
                  {
                    attrs: { property: "cardPaymentActivated" },
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(
                                "\n                En acceptant le paiement CB j'atteste avoir pris connaissance des\n                "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://stripe.com/fr/legal#translation",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "conditions générales\n                  d'utilisation"
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n                associées.\n              "
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n                Commission de 1,2% au titre des frais de traitement bancaire en sus.\n              "
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                "\n                Cette option n'est pas disponible pour les praticiens basés en Polynésie Française, Wallis-et-Futuna,\n                Nouvelle-Calédonie, Saint-Barthélemy et Saint-Pierre-et-Miquelon.\n              "
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "payment-status",
                                class: _vm.paymentStatusClass
                              },
                              [
                                _c("FontAwesomeIcon", {
                                  staticClass: "icon",
                                  attrs: { icon: _vm.paymentStatusIcon }
                                }),
                                _c("p", [_vm._v(_vm._s(_vm.paymentStatusText))])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(
                      "\n            Accepter le paiement CB\n            "
                    )
                  ]
                ),
                _c(
                  "ButtonGreen",
                  {
                    staticClass: "payment-informations-button",
                    attrs: { outlined: "", rounded: "" },
                    on: { click: _vm.goToStripeSetup }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.paymentEnabled ? "Modifier" : "Compléter") +
                        " mes informations\n          "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }