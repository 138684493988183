var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "appointments-page" },
      [
        _c(
          "div",
          { staticClass: "title-with-buttons" },
          [
            _c("SectionTitle", [
              _vm._v(_vm._s(_vm.isSecretary ? "Mes plannings" : "Mon planning"))
            ]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "ButtonGreen",
                  {
                    staticClass: "link-to-calendar",
                    attrs: {
                      icon: _vm.$icons.calendar,
                      "link-to": "/agenda/view",
                      outlined: "",
                      small: ""
                    }
                  },
                  [_vm._v("\n          Afficher l'agenda\n        ")]
                ),
                !_vm.isAccountRestricted
                  ? [
                      _c(
                        "div",
                        { staticClass: "flex-buttons" },
                        [
                          _c(
                            "ButtonRed",
                            {
                              attrs: {
                                icon: _vm.$icons.newAppointment,
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.isNewAppointmentPanelOpened = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Ajouter un RDV\n            "
                              )
                            ]
                          ),
                          _vm.profileType === "doctor"
                            ? _c(
                                "ButtonGreen",
                                {
                                  staticClass: "replace-ps",
                                  attrs: {
                                    external: "",
                                    icon: _vm.$icons.userMd,
                                    small: "",
                                    "link-to":
                                      "https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Besoin d'être remplacé ?\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("NewAppointmentPanel", {
                        attrs: { opened: _vm.isNewAppointmentPanelOpened },
                        on: { close: _vm.handleNewAppointmentPanelClose }
                      })
                    ]
                  : _vm._e()
              ],
              2
            )
          ],
          1
        ),
        _c("AppointmentList", { ref: "appointmentList" }),
        _c("transition", { attrs: { duration: 600 } }, [_c("router-view")], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }