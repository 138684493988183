<template>
  <h3 :class="{light, center}" class="section-title">
    <slot />
  </h3>
</template>

<script>
  export default {
    name: 'SectionTitle',
    props: {
      light: {
        type: Boolean,
        default: false,
      },
      center: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .section-title {
    font-family: $titleFont;
    font-size: 2rem;
    font-weight: bold;
    position: relative;
    margin-bottom: 3.5rem;
    padding-bottom: 2rem;
    color: $sectionTitleTextColor;

    &::after {
      position: absolute;
      bottom: 0.2rem;
      left: 0;
      width: 14rem;
      height: 0.5rem;
      content: "";
      background-color: $sectionTitleBarColor;
    }

    &.light {
      color: $sectionTitleLightTextColor;
    }

    &.center {
      text-align: center;

      &::after {
        left: 50%;
        margin-left: -7rem;
      }
    }
  }
</style>
