var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _vm.practitioner.id
      ? _c(
          "div",
          { staticClass: "profile-view" },
          [
            _c("main", { staticClass: "container-body" }, [
              _c(
                "div",
                { staticClass: "profile" },
                [
                  _c("SectionTitle", [_vm._v("Mon profil")]),
                  _c(
                    "div",
                    { staticClass: "userProfile" },
                    [
                      _c("UserAvatar", {
                        staticClass: "avatar",
                        attrs: {
                          size: _vm.$media.desktop ? "13rem" : "8rem",
                          src: _vm.avatarURL,
                          "user-name": _vm.practitionerFullName,
                          editable: ""
                        }
                      }),
                      _c("div", { staticClass: "name" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("capitalize")(_vm.practitioner.firstName)
                            )
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("uppercase")(_vm.practitioner.lastName)
                            )
                          )
                        ]),
                        _vm.practitioner.mainSpecialty
                          ? _c("p", { staticClass: "specialty" }, [
                              _vm._v(
                                _vm._s(_vm.practitioner.mainSpecialty.name)
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "ButtonGreen",
                    {
                      staticClass: "edit",
                      attrs: {
                        bold: "",
                        "link-to": "/profile/edit",
                        long: "",
                        small: ""
                      }
                    },
                    [_vm._v("Gérer mon profil")]
                  )
                ],
                1
              ),
              _vm.practitioner.description
                ? _c(
                    "div",
                    { staticClass: "bio" },
                    [
                      _c("SectionTitle", [_vm._v("A propos de moi")]),
                      _c("p", [_vm._v(_vm._s(_vm.practitioner.description))])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "infos" },
                [
                  _c("SectionTitle", [_vm._v("Informations professionnelles")]),
                  _vm.practitioner.searchable
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.link }
                          }),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.publicURL, target: "_blank" }
                            },
                            [_vm._v("Ma page publique")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "profile-label-text" },
                    [
                      _c("FontAwesomeIcon", {
                        staticClass: "profile-label-icon",
                        attrs: { icon: _vm.$icons.territorialOrganization }
                      }),
                      _c("small", [
                        _c(
                          "abbr",
                          { attrs: { title: "Organisation Territoriale" } },
                          [_vm._v("O.T.")]
                        ),
                        _vm._v(" - ")
                      ]),
                      _vm.practitioner.territorialOrganizationType
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.practitioner.territorialOrganizationName
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n          (" +
                          _vm._s(
                            _vm._f("territorialOrganization")(
                              _vm.practitioner.territorialOrganizationType
                            )
                          ) +
                          ")\n        "
                      )
                    ],
                    1
                  ),
                  _vm.practitioner.rpps
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.idBadge }
                          }),
                          _c("small", [_vm._v(" RPPS -")]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.rpps) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.adeli
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.addressCard }
                          }),
                          _c("small", [_vm._v(" ADELI -")]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.adeli) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.phone
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.mobile }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("phone")(_vm.practitioner.phone)) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.practicePhone
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.phone }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("phone")(_vm.practitioner.practicePhone)
                              ) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.email
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.mail }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.email) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.securedEmail
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.protectedMail }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.securedEmail) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAddressComplete
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.mapMarker }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.practiceAddress) +
                              " - " +
                              _vm._s(_vm.practitioner.practiceZipCode) +
                              " " +
                              _vm._s(_vm.practitioner.practiceCity) +
                              "\n          " +
                              _vm._s(_vm.practitioner.country) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "profile-label-text" },
                    [
                      _c("FontAwesomeIcon", {
                        staticClass: "profile-label-icon",
                        attrs: { icon: _vm.$icons.globe }
                      }),
                      _c("small", [_vm._v("FUSEAU HORAIRE -")]),
                      _vm._v(
                        " " + _vm._s(_vm.practitioner.timezone) + "\n        "
                      )
                    ],
                    1
                  ),
                  _vm.practitioner.convention
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.bookmark }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.practitioner.convention.name) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.practitioner.specialties.length
                    ? _c(
                        "p",
                        { staticClass: "profile-label-text" },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "profile-label-icon",
                            attrs: { icon: _vm.$icons.medic }
                          }),
                          _vm._l(_vm.practitioner.specialties, function(
                            specialty
                          ) {
                            return _c(
                              "span",
                              { staticClass: "profile-tag-specialty" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(specialty.name) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "AppFooter",
              { staticClass: "container-footer" },
              [
                _c(
                  "SectionTitle",
                  { staticClass: "progress", attrs: { light: "" } },
                  [_vm._v("Votre Profil")]
                ),
                _c("div", { staticClass: "gestion" }, [
                  _c(
                    "div",
                    [
                      _c("p", [
                        _vm._v(
                          "\n            Pour accéder à l’ensemble de nos services, nous vous invitons à compléter votre profil.\n          "
                        )
                      ]),
                      _c(
                        "ButtonGreen",
                        {
                          staticClass: "edit",
                          attrs: { "link-to": "/profile/edit", smallbold: "" }
                        },
                        [_vm._v("Gérer mon profil")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "progress-circle" },
                    [
                      _c("ProgressCircle", {
                        attrs: { percentage: _vm.profileCompletionPercentage }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "SectionTitle",
                  { staticClass: "signaletique", attrs: { light: "" } },
                  [_vm._v("Informer mes patients")]
                ),
                _c(
                  "div",
                  { staticClass: "accompagnement" },
                  [
                    _c("p", [
                      _vm._v(
                        "\n          Pour vous aider à indiquer à vos patients que vous faites de la téléconsultation, un kit est à votre\n          disposition.\n        "
                      )
                    ]),
                    _c(
                      "ButtonWhite",
                      {
                        attrs: {
                          "link-to":
                            "https://kb.hellocare.pro/support/informer-mes-patients-pour-la-teleconsultation",
                          outlined: "",
                          smallbold: ""
                        }
                      },
                      [_vm._v("\n          Ça m'intéresse !\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }