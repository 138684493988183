<!--
Emitted events:
- change(beneficiary)
- cancel
-->

<template>
  <div class="beneficiary-list">
    <div class="beneficiaries">
      <div
        v-for="beneficiary in beneficiaries"
        :class="{ selected: selected.id === beneficiary.id }"
        class="beneficiary"
        @click="$emit('change', beneficiary)"
      >
        <UserAvatar :src="beneficiary.picture" size="5.5rem" />
        <div>
          <div class="name">{{ beneficiary | fullName }}</div>
        </div>
      </div>
    </div>
    <div v-if="cancelable" class="cancel">
      <a href="#" @click.prevent="$emit('cancel')">Annuler</a>
    </div>
  </div>
</template>

<script>
  import UserAvatar from '@/components/UserAvatar.vue';

  export default {
    name: 'BeneficiaryList',
    components: { UserAvatar },
    props: {
      selected: {
        type: Object,
        required: false,
      },
      cancelable: {
        type: Boolean,
        required: false,
        default: false,
      },
      patient: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isPanelOpened: false,
      };
    },
    computed: {
      beneficiaries() {
        return [this.patient, ...this.patient.children];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .beneficiary {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0.4rem 2.4rem 0.4rem 0.4rem;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 3.3rem;

    &:hover {
      border-color: #E9E9E9;
      background-color: #F9F9F9;
    }

    &.selected {
      border-color: #27D7AB;
      background-color: #DFF9F3;
    }
  }

  .user-avatar {
    margin-right: 2.3rem;
  }

  .name {
    font-size: 2rem;
    font-weight: 500;
  }

  .add-beneficiary {
    text-decoration: underline;
    color: $successColor;
  }

  .delete-icon {
    font-size: 2rem;
    margin-right: 2rem;
    margin-left: auto;
    color: $grey;

    &:hover {
      color: $errorColor;
    }
  }

  .cancel {
    margin-top: 2rem;

    a {
      color: $errorColor;
    }
  }
</style>
