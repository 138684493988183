<template>
  <AppLayout>
    <main class="agenda-view-page">
      <div class="container">
        <div class="side">
          <SectionTitle>{{ isSecretary ? "Mes agendas" : "Mon agenda" }}</SectionTitle>
          <AgendaDatePickerMonth :year="year" :month="month" @change="handleMonthChange" />
          <ButtonGrey small outlined block @click="goToToday">Aujourd'hui</ButtonGrey>
          <AgendaDatePickerDay @change="handleDayChange" :year="year" :month="month" :day="day" />
          <!-- TODO <div>Canal de consultation</div> -->
          <hr>
          <template v-if="!isAccountRestricted">
            <ButtonRed block small :icon="$icons.newAppointment" @click="isNewAppointmentPanelOpened = true">
              Ajouter un RDV
            </ButtonRed>
            <ButtonGreen block small external class="replace-ps" v-if="profileType === 'doctor'" :icon="$icons.userMd" link-to="https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links">
              Besoin d'être remplacé ?
            </ButtonGreen>
            <NewAppointmentPanel
              :opened="isNewAppointmentPanelOpened"
              @close="handleNewAppointmentPanelClose"
            />
          </template>
          <div class="practitioner-filter" v-if="isSecretary">
            <SectionTitle>Agendas à afficher</SectionTitle>
            <div v-for="(secretaryOf, index) in secretariesOf" class="">
              <div class="practitioner-filter-entry">
                <FormCheckbox v-model="secretariesChecked[index].isChecked">{{getFullName(secretaryOf.first_name, secretaryOf.last_name)}}</FormCheckbox>
              </div>
            </div>
          </div>
        </div>
        <div class="agenda">
          <div class="agenda-buttons">
            <ButtonGreen class="link-to-appointments" small outlined link-to="/agenda/appointments" :icon="$icons.planning">
              Afficher le planning
            </ButtonGreen>
          </div>
          <AgendaCalendarWeek :day="day" :secretariesChecked="secretariesChecked" ref="agendaCalendarWeek" />
        </div>
      </div>
      <transition :duration="600">
        <router-view :day="day" />
      </transition>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../components/AppLayout';
  import moment from 'moment';
  import SectionTitle from '../components/SectionTitle';
  import AgendaDatePickerMonth from '../components/Agenda/AgendaDatePickerMonth';
  import AgendaDatePickerDay from '../components/Agenda/AgendaDatePickerDay';
  import AgendaCalendarWeek from '../components/Agenda/AgendaCalendarWeek';
  import ButtonGrey from '../components/Button/ButtonGrey.vue';
  import ButtonRed from '../components/Button/ButtonRed.vue';
  import ButtonGreen from '../components/Button/ButtonGreen.vue';
  import NewAppointmentPanel from '../components/NewAppointmentPanel';
  import ButtonTransparent from "@/components/Button/ButtonTransparent.vue";
  import ButtonWhite from "@/components/Button/ButtonWhite.vue";
  import FormCheckbox from "@/components/Form/FormCheckbox.vue";
  import {getFullName} from "@/helpers/format";

  export default {
    name: 'AgendaViewPage',
    components: {
      FormCheckbox,
      ButtonWhite,
      ButtonTransparent,
      NewAppointmentPanel,
      ButtonGreen,
      AppLayout,
      ButtonRed,
      AgendaCalendarWeek,
      ButtonGrey,
      AgendaDatePickerMonth,
      AgendaDatePickerDay,
      SectionTitle,
    },
    data() {
      const today = moment();

      const secretariesChecked = this.$store.state.practitioner.profile.secretariesOf.map(s => ({
        id:s.id,
        isChecked:true
      }))
      secretariesChecked.push({id:this.$store.state.practitioner.profile.id, isChecked:true})
      return {
        secretariesChecked,
        isNewAppointmentPanelOpened: false,
        day: today,
        year: today.year(),
        month: today.month(),
      };
    },
    computed: {
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      secretariesOf() {
        return this.$store.state.practitioner.profile.secretariesOf;
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
      profileType() {
        return this.$store.state.practitioner.profile.type;
      },
    },
    methods: {
      getFullName,
      handleMonthChange(year, month) {
        this.year = year;
        this.month = month;
      },
      handleDayChange(day) {
        this.day = day;
        this.handleMonthChange(day.year(), day.month());
      },
      goToToday() {
        this.handleDayChange(moment());
      },
      handleNewAppointmentPanelClose(submitted) {
        this.isNewAppointmentPanelOpened = false;

        if (submitted) {
          this.$refs.agendaCalendarWeek.loadAgendaAppointments();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .container {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .main-container main {
    @media (min-width:769px) AND (max-width: 991px) {
      padding: 8rem 2rem;
    }
  }

  .practitioner-filter {
    font-size: 2rem;
    margin-top: 4rem;

  }
  .practitioner-filter-entry {
    margin-bottom: 2rem;
  }
  .agenda {
    flex: auto;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    flex-direction: column;
  }

  .side {
    margin-top: 1rem;
    margin-right: 2rem;
    flex: 1;
    @media (max-width: 991px) {
      margin-right: 0;
    }
  }

  .agenda-date-picker-month {
    margin-bottom: 1rem;
  }

  .agenda-date-picker-day {
    margin-top: 1rem;
  }

  .agenda-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .link-to-appointments {
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .zoom {
    svg {
      cursor: pointer;
      margin-right: 1rem;
    }
    color: #CCCCCC;
  }
  .replace-ps {
    margin-top: 1rem;
    white-space: nowrap;
    @include onMobile {
      margin: 1rem 0 0 0;
    }
  }

</style>
