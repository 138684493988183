var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-setting", class: { disabled: _vm.isDisabled } },
    [
      _c("div", { staticClass: "setting-label" }, [
        _c("label", { attrs: { for: _vm.id } }, [_vm._t("default")], 2),
        _c(
          "div",
          { staticClass: "setting-switch" },
          [
            _c("FormSwitch", {
              ref: "switch",
              attrs: {
                id: _vm.id,
                busy: _vm.busy,
                checked: _vm.checked,
                disabled: _vm.isDisabled
              },
              on: { change: _vm.handleChange }
            })
          ],
          1
        )
      ]),
      _vm.$slots.description
        ? _c("div", { staticClass: "description" }, [_vm._t("description")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }