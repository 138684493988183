<template>
  <div class="panel" :class="{ 'is-open': isOpen, 'is-active': isActive, 'has-error': hasError }">
    <div class="panel-heading" @click="$emit('open', number)">
      <span class="panel-heading-number">{{ number }}</span>
      <span class="panel-heading-title">
        <slot name="title" />
      </span>
      <span class="panel-heading-toggle-icon">
          <FontAwesomeIcon :icon="isOpen ? $icons.panelToggleOpened : $icons.panelToggleClosed" />
        </span>
    </div>
    <div class="panel-content" v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Panel',
    props: {
      openPanelNumber: {
        type: Number,
      },
      number: {
        type: Number,
        required: true,
      },
      hasError: {
        type: Boolean,
      },
    },
    model: {
      prop: 'openPanelNumber',
      event: 'open',
    },
    computed: {
      isOpen() {
        return this.openPanelNumber === this.number;
      },
      isActive() {
        return this.openPanelNumber >= this.number;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel-heading {
    display: flex;
    background-color: #1F1F1F;
    color: #FFFFFF;
    align-items: center;
    font-size: 2rem;
    padding: 1.5rem 4.5rem;
    cursor: pointer;

    .is-active & {
      background-color: $successColor;
    }

    .has-error & {
      background-color: $errorColor;
    }
  }

  .panel-heading-number {
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin-right: 4rem;
  }

  .panel-heading-toggle-icon {
    margin-left: auto;
  }

  .panel-content {
    padding: 2rem 0;
  }
</style>
