<template>
  <div class="signature-pad">
    <p class="text">Signez dans l’encart ci-dessous à l’aide de la souris ou d’un appareil tactile.</p>
    <VueSignaturePad height="20rem" ref="pad" class="pad" :options="{ onEnd: handleDrawingEnd }" />
    <div class="buttons">
      <ButtonRed v-if="hasDrawing" outlined small @click="clear">Effacer</ButtonRed>
      <ButtonRed v-else outlined small @click="cancel">Annuler</ButtonRed>
      <ButtonGreen v-if="hasDrawing" small @click="save">Valider</ButtonGreen>
    </div>
    <p class="text">
      Vous ne réussissez pas à tracer votre signature ?<br>
      <a href="#" @click.prevent="uploadFile">Envoyez une photo</a> ou utilisez notre application mobile sur
      <a href="https://play.google.com/store/apps/details?id=com.hellocare.connect.pro" target="_blank">Android</a> ou
      <a href="https://apps.apple.com/fr/app/hellocare-pro-télémédecine/id1489699546" target="_blank">iOS</a>
    </p>
  </div>
</template>

<script>
  import ButtonRed from './Button/ButtonRed.vue';
  import ButtonGreen from './Button/ButtonGreen.vue';

  export default {
    name: 'SignaturePad',
    components: { ButtonGreen, ButtonRed },
    data() {
      return {
        hasDrawing: false,
      };
    },
    methods: {
      handleDrawingEnd() {
        this.hasDrawing = !this.$refs.pad.isEmpty();
      },
      async save() {
        const file = await new Promise(resolve => this.$refs.pad.$refs.signaturePadCanvas.toBlob(resolve));

        this.$emit('save', { file });
      },
      clear() {
        this.hasDrawing = false;
        this.$refs.pad.clearSignature();
      },
      cancel() {
        this.$emit('cancel');
      },
      uploadFile() {
        this.$emit('select-file');
      },
    },
  };
</script>

<style scoped lang="scss">
  p.text {
    text-align: center;
  }

  .pad {
    border: 1px solid $lightGrey;
    position: relative;

    &::before {
      height: 0.4rem;
      content: " ";
      background-color: $mainColor;
      width: 100%;
      position: absolute;
      top: calc(50% + 0.2rem);
      pointer-events: none;
    }

    ::v-deep > canvas {
      position: relative;
    }
  }

  .buttons {
    margin-top: 1rem;
    text-align: center;

    button {
      margin: 0 1rem;
    }
  }
</style>
