<template>
  <AppLoader v-if="isLoadingAppointment">Chargement du rendez-vous</AppLoader>
  <FullscreenNotification v-else-if="appointment" :title="title">
    <p>{{ description }}</p>
    <template #buttons>
      <ButtonGreen v-if="!isConfirmed && isEditable" :disabled="isDisabled" :busy="isAccepting" @click="acceptAppointment">Confirmer
      </ButtonGreen>
      <ButtonWhite outlined v-if="isEditable" :disabled="isDisabled" :busy="isCancelling" @click="cancelAppointment">Refuser</ButtonWhite>
    </template>
    <template #links>
      <router-link :to="{ name: 'dashboard' }">
        <span>Retour au tableau de bord</span>
      </router-link>
    </template>
  </FullscreenNotification>
</template>

<script>
import SectionTitle from '../components/SectionTitle';
import AppLayout from '../components/AppLayout';
import FullscreenNotification from '../components/FullscreenNotification';
import ButtonGreen from '../components/Button/ButtonGreen.vue';
import ButtonRed from '../components/Button/ButtonRed.vue';
import ButtonWhite from '../components/Button/ButtonWhite.vue';
import {getFullName} from '@/helpers/format';
import AppLoader from '@/components/AppLoader';
import moment from 'moment';

export default {
  name: 'AppointmentAnswerPage',
  components: {ButtonGreen, ButtonRed, FullscreenNotification, AppLayout, SectionTitle, AppLoader, ButtonWhite},
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.loadAppointment();
  },
  computed: {
    appointment() {
      return this.$store.getters.currentAppointmentWithMeta;
    },
    patient() {
      return this.appointment.payload.patient;
    },
    isLoadingAppointment() {
      return this.$store.state.appointments.isFetchingOne;
    },
    isAccepting() {
      return this.$store.state.appointments.isAccepting;
    },
    isCancelling() {
      return this.$store.state.appointments.isCancelling;
    },
    isConfirmed() {
      return this.appointment.payload.status === 'CONFIRMED';
    },
    isEditable() {
      return ['NEW_BY_PRACTITIONER', 'NEW_BY_PATIENT', 'CONFIRMED'].includes(this.appointment.payload.status);
    },
    isDisabled() {
      return this.isAccepting !== this.isCancelling;
    },
    patientFullName() {
      return getFullName(
          this.patient.firstName,
          this.patient.lastName,
      );
    },
    encounterChannel() {
      return this.appointment.payload.product.channel === 'remote' ? 'téléconsultation' : 'consultation';
    },
    title() {
      if (this.isEditable) {
        if (this.isConfirmed) {
          return "Rendez-vous déjà confirmé"
        }
        return "Demande de rendez-vous"
      }
      return "Rendez-vous déjà annulé"
    },
    description() {
      let date = moment(this.appointment.payload.startAt).format('dddd DD MMMM YYYY') + " " + this.appointment.time;
      if (this.isEditable) {
        if (this.isConfirmed) {
          return "Le rendez-vous du " + date + " avec " + this.patientFullName + " est déjà confirmé.";
        }
        return this.patientFullName + " vous propose un rendez-vous en " + this.encounterChannel +
            " le " + date + ".";
      }
      return "Le rendez-vous du " + date + " avec " + this.patientFullName + " est déjà annulé."
    }
  },
  methods: {
    async loadAppointment() {
      try {
        await this.$store.dispatch('appointmentsFetchOneConnect', {
          id: this.appointmentId,
          abortSignal: this.abortSignal,
          token: this.token,
        });
      } catch (e) {
        if (!this.isAbortError(e)) {
          this.$addError('Erreur lors du chargement du rendez-vous');
          throw e;
        }
      }
    },
    async acceptAppointment() {
      await this.$store.dispatch('appointmentsAccept', {
        id: this.appointment.payload.id,
        token: this.token,
        type: 'practitioner',
      });
      this.$emit('accept');
      await this.$router.push({name: 'appointment-approved'});
    },
    async cancelAppointment() {
      await this.$store.dispatch('appointmentsCancel', {
        id: this.appointment.payload.id,
        token: this.token,
        type: 'practitioner',
      });
      this.$emit('cancel');
      await this.$router.push({name: 'appointment-rejected'});
    },
  },
};
</script>