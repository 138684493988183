var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agenda-calendar-day-column-header",
      class: { today: _vm.today, fade: _vm.fade }
    },
    [_vm._v("\n  " + _vm._s(_vm.shortDayName) + " " + _vm._s(_vm.date) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }