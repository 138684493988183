<template>
  <div class="horizontal-divider">
    <div v-if="!left" class="horizontal-divider-line"></div>
    <div class="horizontal-divider-label">
      <slot />
    </div>
    <div v-if="!right" class="horizontal-divider-line"></div>
  </div>
</template>

<script>
  export default {
    name: 'HorizontalDivider',
    props: {
      left: Boolean,
      right: Boolean,
    },
  };
</script>

<style lang="scss" scoped>
  .horizontal-divider {
    display: flex;
    align-items: center;
  }

  .horizontal-divider-line {
    flex-grow: 1;
    height: 1px;
    background-color: $grey;

    &:first-child {
      margin-right: 2rem;
    }

    &:last-child {
      margin-left: 2rem;
    }
  }

  .horizontal-divider-label {
    font-size: 1.5rem;
    color: #1F1F1F
  }
</style>
