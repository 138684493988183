import Observations from '../../api/medicale_observations';

export default {
    state: {
        observation: null,
    },
    mutations: {
        PATIENTS_FETCH_ONE_SUCCESS(state, { observations }) {
            state.observation = observations;
        },
        PATIENTS_EDIT_PROFILE_SUCCESS(state) {
            state.isUpdatingPatient = false;
        },
        PATIENTS_EDIT_PROFILE_FAIL(state) {
            state.isUpdatingPatient = false;
        },
    },
    actions: {
        async medicalesObservations({ commit }, {data, id}) {
            commit('PATIENTS_EDIT_PROFILE');
            try {
                const observations = await Observations.update(data, id);
                commit('PATIENTS_EDIT_PROFILE_SUCCESS');
                commit('PATIENTS_FETCH_ONE_SUCCESS', { observations })
            } catch (e) {
                commit('PATIENTS_EDIT_PROFILE_FAIL');
                throw e;
            }
        },
    },
};
