import api from './';
import { normalizePractitioner } from '../helpers/normalizers';

export default {
  getOne: (id) => api.get(`practitioners/${id}`).json().then(normalizePractitioner),
  me: () => api.get('practitioners/me').json().then(normalizePractitioner),
  /**
   * @param {object} data
   *
   * @returns {Promise<unknown>}
   */
  update: (data) => api.put('practitioners/me', {
    json: {
      first_name: data.firstName,
      last_name: data.lastName,
      civility: data.civility,
      birth_date: data.birthDate,
      email: data.email,
      phone: data.phone,
      country: data.country,
      rpps: data.rpps,
      adeli: data.adeli,
      health_insurance_number: data.healthInsuranceNumber,
      description: data.description,
      practice_address: data.practiceAddress,
      practice_zip_code: data.practiceZipCode,
      practice_city: data.practiceCity,
      practice_country: data.practiceCountry,
      practice_phone: data.practicePhone,
      secured_email: data.securedEmail,
      timezone: data.timezone,
      convention: data.convention,
      main_specialty: data.mainSpecialty,
      specialties: data.specialties,
      languages: data.languages,
      iban: data.iban,
      territorial_organization_type: data.territorialOrganizationType,
      territorial_organization_name: data.territorialOrganizationName,
      metadata: data.metadata,
      rules: data.information,
      secretaries_of: data.secretariesOf,
      secretaries_of_to_delete: data.secretariesOfToDelete,
      secretaries_to_delete: data.secretariesToDelete,
    },
  }).json().then(normalizePractitioner),
  updateSettings: (data) => api.put('practitioners/me', {
    json: {
      searchable: data.searchable,
      claim_form_enabled: data.claimFormEnabled,
      auto_confirm_appointment: data.autoConfirmAppointment,
      instant_encounters_enabled: data.instantEncountersEnabled,
      card_payment_activated: data.cardPaymentActivated,
      sms_enabled: data.smsEnabled
    },
  }).json().then(normalizePractitioner),
  uploadSignature: (file) => {
    const body = new FormData();
    body.append('file', file);
    return api.post('practitioners/me/signature', { body }).json();
  },
  getSignature: () => {
    return api.get('practitioners/me/signature').then((response) => {
      return response.text()
    }).then((response) => {
      return response;
    })
  },
  uploadIdCard: (file) => {
    const body = new FormData();
    body.append('file', file);
    return api.post('practitioners/me/legal_document', { body }).json();
  },
  uploadAvatar: (file) => {
    const body = new FormData();
    body.append('file', file);
    return api.post('practitioners/me/profile_picture', { body }).json();
  },
  delete: () => api.delete(`practitioners/me`),
  uploadEmploymentAttestation(file) {
    const body = new FormData();
    body.append('file', file);
    return api.post('practitioners/me/employment_attestation', { body }).json();
  }
};
