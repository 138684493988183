import api from './';

export default {
  create: (appointmentId, productId, productPrice) => {
    return api.post('orders', {
      json: {
        appointment: appointmentId,
        items: [
          {
            product: productId,
            unit_price: productPrice,
          },
        ],
      },
    }).json();
  },
};
