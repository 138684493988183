var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "panel",
      class: {
        "is-open": _vm.isOpen,
        "is-active": _vm.isActive,
        "has-error": _vm.hasError
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-heading",
          on: {
            click: function($event) {
              return _vm.$emit("open", _vm.number)
            }
          }
        },
        [
          _c("span", { staticClass: "panel-heading-number" }, [
            _vm._v(_vm._s(_vm.number))
          ]),
          _c(
            "span",
            { staticClass: "panel-heading-title" },
            [_vm._t("title")],
            2
          ),
          _c(
            "span",
            { staticClass: "panel-heading-toggle-icon" },
            [
              _c("FontAwesomeIcon", {
                attrs: {
                  icon: _vm.isOpen
                    ? _vm.$icons.panelToggleOpened
                    : _vm.$icons.panelToggleClosed
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.isOpen
        ? _c("div", { staticClass: "panel-content" }, [_vm._t("default")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }