var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { attrs: { header: false, footer: false } }, [
    _c(
      "div",
      { staticClass: "close-button" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "agenda",
                query: { "appointment-detail": _vm.appointmentId }
              }
            }
          },
          [
            _vm._v("\n      Fermer "),
            _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.closePanel } })
          ],
          1
        )
      ],
      1
    ),
    !_vm.isLoading
      ? _c(
          "main",
          { staticClass: "consultation-report" },
          [
            _c("SectionTitle", { attrs: { center: "" } }, [
              _vm._v("Rapport de consultation")
            ]),
            _c("EditProfil", {
              attrs: { patient: _vm.patient, picture: false }
            }),
            _c("hr"),
            _c("Observations", { attrs: { patient: _vm.patient } }),
            _c("hr"),
            _c(
              "form",
              {
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "FormWidget",
                  {
                    scopedSlots: _vm._u(
                      [
                        _vm.$v.form.motives.$error
                          ? {
                              key: "error",
                              fn: function() {
                                return [
                                  !_vm.$v.form.motives.required
                                    ? _c("span", [
                                        _vm._v(
                                          "\n          Veuillez renseigner le motif de consultation\n        "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("label", { attrs: { for: "motives" } }, [
                      _vm._v("Motif de consultation *")
                    ]),
                    _c("AutoHeightTextarea", {
                      attrs: { id: "motives" },
                      on: {
                        blur: function($event) {
                          return _vm.$v.form.motives.$touch()
                        }
                      },
                      model: {
                        value: _vm.form.motives,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "motives",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.motives"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "FormWidget",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n          Ce champ est uniquement visible par les professionnels de santé.\n          Le patient n'aura pas accès à cette information.\n        "
                              )
                            ]
                          },
                          proxy: true
                        },
                        _vm.$v.form.medicalObservation.$error
                          ? {
                              key: "error",
                              fn: function() {
                                return [
                                  !_vm.$v.form.medicalObservation.required
                                    ? _c("span", [
                                        _vm._v(
                                          "Veuillez renseigner l'observation médicale"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("label", { attrs: { for: "medical-observation" } }, [
                      _vm._v("Observation médicale *")
                    ]),
                    _c("AutoHeightTextarea", {
                      attrs: { id: "medical-observation" },
                      on: {
                        blur: function($event) {
                          return _vm.$v.form.medicalObservation.$touch()
                        }
                      },
                      model: {
                        value: _vm.form.medicalObservation,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "medicalObservation",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.medicalObservation"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "FormWidget",
                  [
                    _c("label", { attrs: { for: "patient-advice" } }, [
                      _vm._v("Conseils au patient")
                    ]),
                    _c("AutoHeightTextarea", {
                      attrs: { id: "patient-advice" },
                      model: {
                        value: _vm.form.patientAdvice,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "patientAdvice",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.patientAdvice"
                      }
                    })
                  ],
                  1
                ),
                _c("hr"),
                _vm.encounter && _vm.appointment && !_vm.isNurse
                  ? _c("PrescriptionModule", {
                      attrs: { "birth-date": this.patient.birthDate }
                    })
                  : _vm._e(),
                _c("hr"),
                _vm.claimFormEnabled && !_vm.isNurse
                  ? _c("div", { staticClass: "claim-form-action" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showClaimForm($event)
                            }
                          }
                        },
                        [
                          _c("FontAwesomeIcon", {
                            attrs: {
                              icon: _vm.claimFormReloading
                                ? _vm.$icons.spinner
                                : _vm.$icons.claimForm,
                              "fixed-width": "",
                              spin: _vm.claimFormReloading
                            }
                          }),
                          _vm.claimFormReloading
                            ? _c("span", [
                                _vm._v(
                                  "Vérification de la feuille de soins en cours…"
                                )
                              ])
                            : _vm.hasClaim
                            ? _c("span", [
                                _vm._v("Modifier la feuille de soins")
                              ])
                            : _c("span", [
                                _vm._v("Ajouter une feuille de soins")
                              ])
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                !_vm.isNurse
                  ? _c(
                      "div",
                      [
                        _c(
                          "ButtonBlue",
                          { attrs: { "link-to": _vm.olaqinLink } },
                          [_vm._v("Feuille de soins Stellair*")]
                        ),
                        _c("br"),
                        _c("span", { staticClass: "mb-5" }, [
                          _vm._v(
                            "*Cette fonctionnalité est en version bêta et nécessite un compte Olaqin Stellair."
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isPaymentActivated
                  ? _c(
                      "div",
                      { staticClass: "total-amount" },
                      [
                        _c("FormWidget", [
                          _c("label", { attrs: { for: "amount" } }, [
                            _vm._v("Montant total de l'acte (€)")
                          ]),
                          _c("input", {
                            attrs: { id: "amount", disabled: _vm.isOrderPaid },
                            domProps: { value: _vm.price },
                            on: {
                              input: function($event) {
                                _vm.price = _vm.$inputToPrice(
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                !this.isOrderPaid && _vm.isPaymentActivated
                  ? _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _vm.price > 0
                          ? _c(
                              "ButtonGreen",
                              {
                                attrs: {
                                  busy: _vm.isSendingReport,
                                  icon: _vm.$icons.encounterReportSendAndBill,
                                  "icon-to-left": ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSubmit({
                                      createOrder: true,
                                      charge: true
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          Envoyer et facturer\n        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "ButtonGreen",
                          {
                            attrs: {
                              busy: _vm.isSendingReport,
                              outlined: _vm.price > 0,
                              "no-border": _vm.price > 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleSubmit({
                                  createOrder: true,
                                  charge: false
                                })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Envoyer sans facturer\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "ButtonGreen",
                          {
                            attrs: { busy: _vm.isSendingReport },
                            on: {
                              click: function($event) {
                                return _vm.handleSubmit({ createOrder: false })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Envoyer les documents\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
              ],
              1
            ),
            _vm.claimFormEnabled && _vm.claimFormVisible && !_vm.isNurse
              ? _c(
                  "div",
                  { staticClass: "claim-form-container" },
                  [
                    _c("SectionTitle", { attrs: { center: "" } }, [
                      _vm._v("Feuille de soin électronique")
                    ]),
                    _c("ClaimForm", {
                      attrs: {
                        "encounter-id": _vm.encounter.id,
                        "edited-claim": _vm.editedClaim,
                        patient: _vm.patient,
                        "appointment-date": _vm.appointment.startAt
                      },
                      on: {
                        close: function($event) {
                          _vm.claimFormVisible = false
                        },
                        success: _vm.handleClaimFormSuccess
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }