<template>
  <label :class="{ disabled, 'has-value': !!value }" class="form-text">
    <span class="label">
      {{ label }}
    </span>
    <input
      v-mask="mask"
      :value="value"
      class="input-component"
      readonly
      type="text"
      v-bind="$attrs"
    />
    <span v-if="$slots.help && !$slots.error" class="help"><slot name="help" /></span>
  </label>
</template>

<script>
  export default {
    name: 'FormText',
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        required: true,
        default: '',
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      mask: {
        required: false,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-text {
    font-family: $inputFont;
    font-weight: 300;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.3rem 0.5rem;
    border-bottom: 1px solid $inputBorderColor;
    background-color: $inputBackgroundColor;

    &.disabled {
      opacity: 0.5;
    }
  }

  .label {
    font-size: $inputLabelFontSize;
    font-weight: 200;
    width: fit-content;
    padding: 0.8rem 0.5rem 0.5rem;
    pointer-events: none;
    color: $inputLabelColor;

    .has-value & {
      transform: translateY(0) scale(1);
      color: $inputLabelFilledColor;
    }
  }

  .input-component {
    font-size: 1.5rem;
    padding: 0.4rem 0.5rem 0.5rem;
    transition: border-color 150ms linear;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
  }

  .help {
    font-size: 1.4rem;
    padding-top: 0.5rem;
    color: $inputHelpColor;
  }
</style>
