var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("main", {}, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm.connectedPractitioner
            ? _c("div", { staticClass: "col _12 information" }, [
                _vm._v(
                  "\n          Vous êtes maintenant connecté au praticien " +
                    _vm._s(
                      _vm.getFullName(
                        _vm.connectedPractitioner.firstName,
                        _vm.connectedPractitioner.lastName
                      )
                    ) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }