<template>
  <FullscreenNotification :icon="$icons.thumbsUp" title="Demande de rendez-vous refusée">
    <p>Vous avez refusé le rendez-vous demandé par votre patient.</p>
    <p>Un mail lui sera envoyé.</p>
    <p>
      Pour fixer un nouveau rendez-vous, nous vous invitons à faire votre demande depuis votre cabinet virtuel<br>
      ou à prendre contact directement avec votre patient.
    </p>
    <template #buttons>
      <ButtonGreen :link-to="{ name: 'dashboard' }">Continuer</ButtonGreen>
    </template>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import ButtonGreen from '../components/Button/ButtonGreen.vue';

  export default {
    name: 'AppointmentRejectedPage',
    components: { ButtonGreen, FullscreenNotification, AppLayout, SectionTitle },
  };
</script>
