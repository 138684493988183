<template>
  <AppLayout>
    <main>
      <div class="container">
        <PatientTabBar :patient-id="$route.params.id" />
        <div class="row">
          <div class="col _7">
            <GroupTitle :icon="$icons.clock">Historique des rendez-vous</GroupTitle>
            <AppointmentList v-if="$route.params.id" :patient-id="$route.params.id" />
          </div>
          <div class="col _1"></div>
          <div class="col _4">
            <GroupTitle :icon="$icons.calendar">Nouveau rendez-vous</GroupTitle>
            <NewAppointmentForm v-if="!isLoadingPatient" :forced-patient="patient" />
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout.vue';
  import AppointmentList from '@/components/AppointmentList.vue';
  import NewAppointmentForm from '@/components/Dashboard/NewAppointmentForm.vue';
  import GroupTitle from '@/components/GroupTitle.vue';
  import PatientTabBar from '@/components/PatientTabBar.vue';

  export default {
    name: 'PatientAppointmentsPage',
    components: { GroupTitle, NewAppointmentForm, AppointmentList, PatientTabBar, AppLayout },
    async beforeMount() {
      await this.$store.dispatch('patientsFetchOne', { id: this.$route.params.id });
    },
    computed: {
      isLoadingPatient() {
        return this.$store.state.patients.isFetchingOne;
      },
      patient() {
        return this.$store.state.patients.current;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../assets/grid";

  .patient-tab-bar {
    margin-bottom: 4rem;
  }
</style>
