<template>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <div v-if="plan" class="columns">
          <div class="left">
            <div class="bordered">
              <div v-if="!isSubscriptionActive" class="warning">
                Votre abonnement est inactif. Pour utiliser de nouveau les services Hellocare, veuillez renouveler votre
                abonnement ci-dessous.
              </div>

              <p class="plan-name">{{ plan.name }}</p>
              <p v-if="!hasTiers" class="plan-price">{{ currentPrice | money }} HT / mois</p>
              <p v-else-if="tiers.length < 3">
                {{ tiers[0].unitAmount | money }} / consultation
                (dans la limite de {{ (tiers[0].unitAmount * tiers[0].upTo) | money }})
              </p>
              <p v-else>Le tarif appliqué sera calculé en fonction du nombre de consultations effectuées</p>
              <div :class="planMetadata.status" class="plan-status">
                <span class="status">
                  <FontAwesomeIcon :icon="planMetadata.statusIcon" />
                  {{ planMetadata.statusLabel }}
                </span>
              </div>

              <div class="plan-cta">
                <a href="#" @click.prevent="handleAction">
                  {{ planMetadata.CTALabel }}
                </a>
              </div>

              <AppConfirmation
                ref="confirmActivation"
                title="Confirmer la réactivation de votre abonnement"
                @confirm="subscribe"
              />

              <AppConfirmation
                ref="confirmCancellation"
                title="Êtes-vous sûr de vouloir résilier votre abonnement ?"
                @confirm="cancel"
              />

<!-- TODO À réactiver lorsque nous aurons accès au nombre de TC pour la période de facturation en cours -->
<!--              <div v-if="isSubscriptionActive && hasTiers" class="consultations-summary">-->
<!--                <div class="line">-->
<!--                  <FontAwesomeIcon :icon="$icons.consultationsCount" class="icon" />-->
<!--                  <div class="label">Nombre de téléconsultations réalisées ce mois-ci</div>-->
<!--                  <div class="value">{{ numberOfConsultations }} téléconsultations</div>-->
<!--                </div>-->
<!--                <div class="line">-->
<!--                  <FontAwesomeIcon :icon="$icons.transaction" class="icon" />-->
<!--                  <div class="label">Montant total de votre facture ce mois-ci</div>-->
<!--                  <div class="value">{{ currentPrice | money }}</div>-->
<!--                </div>-->
<!--              </div>-->
            </div>

            <ul class="plan-description">
              <li>L’abonnement à Hellocare est mensuel et sans engagement.</li>
              <li>
                Vous bénéficiez d’une période d'essai gratuite dès l'ouverture du compte, valable une seule fois.
                Sans résiliation de votre part, votre abonnement payant démarrera à l’issu de cette période et sera
                ensuite renouvelé mensuellement par tacite reconduction.
              </li>
              <li>
                Vous pouvez résilier simplement et à tout moment. La résiliation sera effective à la fin de la période
                en cours.
              </li>
            </ul>
          </div>
          <div class="right">
            <GroupTitle :icon="$icons.creditCard">
              Mode de paiement
            </GroupTitle>
            <div class="selected-payment">
              <FormRadio v-model="paymentTypeToggle" :value=true>Carte de crédit</FormRadio>
              <FormRadio v-model="paymentTypeToggle" :value=false>Prélèvement bancaire</FormRadio>
            </div>
            <CreditCard v-if="paymentTypeToggle"/>
            <SepaDebit v-else-if="!paymentTypeToggle"/>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>

  import AppConfirmation from '@/components/AppConfirmation.vue';
  import AppLayout from '@/components/AppLayout';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import AppLogoAnimated from '@/components/AppLogoAnimated';
  import ProfileSetting from '@/components/ProfileSetting';
  import subscriptions from '@/api/subscriptions';
  import CreditCard from '@/components/CreditCard';
  import FormRadio from "@/components/Form/FormRadio";
  import FormInput from "@/components/Form/FormInput";
  import ButtonGreen from "@/components/Button/ButtonGreen.vue";
  import GroupTitle from "@/components/GroupTitle";
  import SepaDebit from "@/components/SepaDebit";


  export default {
    name: 'ProfileSubscriptionPage',
    components: {GroupTitle, AppConfirmation, CreditCard, ProfileSetting, AppLogoAnimated, ProfileTabBar, AppLayout, FormRadio, FormInput, ButtonGreen, SepaDebit },
    data() {
      return {
        submitting: false,
        busy: false,
        selectedCardId: null,
        numberOfConsultations: 9, // TODO : update with real number of consultations,
        paymentTypeToggle: true,
      };
    },
    created() {
      this.$store.dispatch('plansFetchAll');
    },
    computed: {
      hasTiers() {
        return this.plan.tiers?.length > 0;
      },
      currentPrice() {
        if (!this.hasTiers) {
          return this.plan.price;
        }

        const tier = this.tiers.find(tier => {
          return this.numberOfConsultations <= tier.upTo || tier.upTo === null;
        });

        if (tier.upTo) {
          return tier.flatAmount + (tier.unitAmount * this.numberOfConsultations);
        } else {
          return tier.flatAmount;
        }
      },
      tiers() {
        if (!this.hasTiers) {
          return [];
        }

        return [...this.plan.tiers].sort(({ upTo: upToA }, { upTo: upToB }) => {
          if (upToB === null) {
            return -1;
          }

          if (upToA === null) {
            return 1;
          }

          if (upToA < upToB) {
            return -1;
          }

          if (upToA > upToB) {
            return 1;
          }

          return 0;
        });
      },
      profile() {
        return this.$store.state.practitioner.profile;
      },
      activeSubscription() {
        return this.profile.subscriptions.find(subscription => subscription.active);
      },
      isSubscriptionActive() {
        return !!this.activeSubscription;
      },
      isSubscriptionCanceled() {
        if (!this.isSubscriptionActive) {
          return false;
        }

        return !this.activeSubscription.stripeActive || this.activeSubscription.cancelRequested;
      },
      plan() {
        return this.activeSubscription?.plan ? this.activeSubscription.plan : this.$store.state.plans.all[0];
      },
      planMetadata() {
        const metadata = {
          status: null,
          statusIcon: null,
          statusLabel: null,
          CTALabel: null,
        };

        if (!this.isSubscriptionActive) {
          metadata.status = 'inactive';
          metadata.statusIcon = this.$icons.subscriptionInactive;
          metadata.statusLabel = 'Abonnement inactif';
          metadata.CTALabel = 'Réactiver mon abonnement';
        } else if (this.isSubscriptionCanceled) {
          metadata.status = 'active-canceled';
          metadata.statusIcon = this.$icons.subscriptionActiveCanceled;
          metadata.statusLabel = 'Abonnement en cours de résiliation';
          metadata.CTALabel = 'Réactiver mon abonnement';
        } else {
          metadata.status = 'active';
          metadata.statusIcon = this.$icons.subscriptionActive;
          metadata.statusLabel = 'Abonnement en cours';
          metadata.CTALabel = 'Résilier mon abonnement';
        }

        return metadata;
      },
    },
    methods: {
      async subscribe() {
        this.busy = true;

        try {
          await subscriptions.create(this.plan.id);
          await this.$store.dispatch('practitionerFetchProfile');
        } catch (e) {
          await this.$addError('Une erreur est survenue lors de l\'activation de votre abonnement. Si le problème persiste, veuillez contacter notre support.');
          throw e;
        } finally {
          this.busy = false;
        }
      },
      async cancel() {
        this.busy = true;

        try {
          await subscriptions.cancel(this.activeSubscription.id);
          await this.$store.dispatch('practitionerFetchProfile');
        } catch (e) {
          await this.$addError('Une erreur est survenue lors de la résiliation de votre abonnement. Si le problème persiste, veuillez contacter notre support.');
          throw e;
        } finally {
          this.busy = false;
        }
      },
      async handleAction() {
        if (this.busy) {
          return;
        }

        if (!this.isSubscriptionActive || this.isSubscriptionCanceled) {
          this.$confirm(this.$refs.confirmActivation);
        } else {
          this.$confirm(this.$refs.confirmCancellation);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/grid";

  .information {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 3rem;
    padding: 2rem 0 3rem;
    text-align: justify;
  }

  .group-title {
    margin-top: 0;
  }

  .bordered {
    border-bottom: 1px solid $borderColor;

    p:last-child {
      margin-bottom: 3rem;
    }
  }

  .columns {
    @include onDesktop {
      display: flex;

      .left {
        flex: 1;
        padding-right: 2rem;
        border-right: 1px solid $borderColor;
      }
      .right {
        flex: 0.6;
        padding-left: 2rem;
      }
    }
  }

  .plan-name {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 0 1rem;
  }

  .plan-price {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 1.3rem 0 0.5rem;
  }

  .plan-status {
    font-weight: 100;

    .status {
      font-weight: 700;
    }

    &.active .status {
      color: $successColor;
    }

    &.active-canceled .status {
      color: $errorColor;
    }

    &.inactive .status {
      color: $textColor;
    }
  }

  .plan-cta {
    margin: 3rem 0;
  }

  .plan-description {
    margin-top: 3rem;
    padding-left: 0;
    list-style-type: none;

    li {
      font-size: 1.5rem;
      display: flex;
      margin-bottom: 1.2rem;

      &:before {
        padding-right: 1rem;
        content: "-";
      }
    }
  }

  a.danger {
    color: $errorColor;
  }

  .consultations-summary {
    margin-top: 4rem;
    margin-bottom: 3rem;
    padding: 0 2rem;
    color: white;
    border-radius: 0.8rem;
    background-color: $altColor;

    .line {
      display: flex;
      padding: 2rem 0;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.6);

      &:last-child {
        border-bottom: none;
      }

      .icon {
        color: $successColor;
      }

      .label {
        font-weight: 700;
        margin-left: 1rem;
      }

      .value {
        font-weight: 300;
        margin-left: auto;
      }
    }
  }

  .warning {
    margin-bottom: 3rem;
    padding: 2rem;
    color: $errorColor;
    border: 1px dashed $errorColor;
    border-radius: 0.8rem;
  }
  .selected-payment {
    display: flex;
    gap: 1.5rem;
  }
</style>
