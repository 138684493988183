var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment
    ? _c("div", { staticClass: "appointment-panel-close-link" }, [
        _vm.isRemote
          ? _c("p", [
              _c(
                "a",
                {
                  class: { "is-terminating": _vm.isTerminating },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.redirectToReport($event)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v("Accéder au formulaire post-consultation")
                  ]),
                  _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.arrowCircleRight }
                  })
                ],
                1
              ),
              _c("br"),
              _c("small", [
                _vm._v(
                  "\n      Vous pouvez finir de compléter le rapport de consultation.\n    "
                )
              ])
            ])
          : _c(
              "p",
              [
                _c("AppConfirmation", {
                  ref: "closeAppointment",
                  attrs: {
                    title: "Êtes-vous sûr de vouloir clôturer la consultation ?"
                  },
                  on: { confirm: _vm.closeAppointment }
                }),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$confirm(_vm.$refs.closeAppointment)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v("Clôturer la consultation")]),
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.arrowCircleRight }
                    })
                  ],
                  1
                )
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }