<template>
  <div class="nav-item">
    <component
      :is="external ? 'ExternalLink' : 'router-link'"
      :to="to"
      class="nav-item-label"
      @click.native="toggleSubMenu"
      :class="{ expanded }"
    >
      <span class="nav-item-label-text">{{ label }}</span>
      <component :is="icon ? 'FontAwesomeIcon' : 'span'" class="nav-item-label-icon" :icon="icon" />
    </component>

    <div v-if="hasChildren" class="nav-item-children">
      <slot />
    </div>
  </div>
</template>

<script>
  import ExternalLink from './ExternalLink';

  export default {
    name: 'AppNavigationItem',
    components: { ExternalLink },
    data() {
      return {
        expanded: false,
      };
    },
    mounted() {
      if (!this.hasChildren) {
        return;
      }

      this.$store.watch(
        (state) => {
          return state.appNavigation.isExpanded;
        },
        (isExpanded) => {
          if (!isExpanded) {
            this.expanded = false;
          }
        });
    },
    props: {
      external: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      to: {
        type: String,
        required: true,
      },
      icon: {
        type: Object,
        required: false,
      },
    },
    computed: {
      hasChildren() {
        return 'object' === typeof this.$slots.default;
      },
    },
    methods: {
      toggleSubMenu() {
        if (this.hasChildren) {
          this.expanded = !this.expanded;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .nav-item-label {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
    flex-direction: row-reverse;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      padding-left: 10rem;
    }

    &:hover, &.expanded, &.router-link-active {
      background: $altColor;
      @include onMobile {
        background: linear-gradient(145deg, #27D7AB, #194F68);
        color: white;
      }
    }

    &.expanded, &.router-link-active {
      & + .nav-item-children {
        display: block;
      }
    }
  }

  .nav-item-label-text {
    text-transform: uppercase;
    font-weight: 700;
  }

  .nav-item-label-icon {
    padding: 2.5rem 2.7rem 2.7rem;
    width: 7.4rem;
    height: 7.4rem;
  }

  .nav-item-children {
    background: $altColor;
    display: none;
  }
</style>
