var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practitioner.id
    ? _c("header", { staticClass: "wallet-header" }, [
        _c("div", { staticClass: "practitioner" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "practitioner-item" },
              [
                _c("FontAwesomeIcon", {
                  attrs: { icon: _vm.$icons.userLock, "fixed-width": "" }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("capitalize")(_vm.practitioner.firstName)) +
                    " "
                ),
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("uppercase")(_vm.practitioner.lastName)))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "practitioner-item" },
              [
                _c("FontAwesomeIcon", {
                  attrs: { icon: _vm.$icons.mapMarker, "fixed-width": "" }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.practitioner.practiceAddress) +
                    " - " +
                    _vm._s(_vm.practitioner.practiceZipCode) +
                    " " +
                    _vm._s(_vm.practitioner.practiceCity) +
                    "\n      "
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "practitioner-item" },
              [
                _c("FontAwesomeIcon", {
                  attrs: { icon: _vm.$icons.IBAN, "fixed-width": "" }
                }),
                _c("span", { class: { unknown: !_vm.practitioner.iban } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.practitioner.iban || "IBAN non renseigné") +
                      "\n        "
                  )
                ])
              ],
              1
            )
          ])
        ]),
        _c("div", [
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.transaction, size: "2x" }
              })
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "Vous recevrez un virement chaque lundi si des consultations ont été réalisées la semaine précédente."
            )
          ])
        ]),
        _c("div", [
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.lock, size: "2x" }
              })
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "Votre portefeuille vous permet de voir les mouvements d’argent de votre cabinet virtuel vers votre\n      compte bancaire. Ces informations sont sécurisées"
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }