import PatientAppointmentsPage from '@/pages/PatientAppointmentsPage.vue';
import PatientPage from '@/pages/PatientPage.vue';
import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import DashboardPage from '../pages/DashboardPage';
import ProfilePage from '../pages/ProfilePage';
import ProfileViewPage from '../pages/Profile/ProfileViewPage';
import ProfileEditPage from '../pages/Profile/ProfileEditPage';
import ProfileAccessPage from '../pages/Profile/ProfileAccessPage';
import ProfileSettingsPage from '../pages/Profile/ProfileSettingsPage';
import ProfileTransactionsPage from '../pages/Profile/ProfileTransactionsPage';
import ProfileActPage from '../pages/Profile/ProfileActPage';
import ProfileAddActPage from '../pages/Profile/ProfileAddActPage';
import AppointmentsPage from '../pages/AppointmentsPage';
import NotFoundPage from '../pages/NotFoundPage';
import { extractAccessTokenFromHash, hasAccessToken, logout, redirectToAuth } from '../helpers/auth';
import ConsultationReportPage from '../pages/ConsultationReportPage';
import ConsultationReportConfirmedPage from '../pages/ConsultationReportConfirmedPage';
import AgendaViewPage from '../pages/AgendaViewPage';
import AddAvailabilitiesPage from '../pages/Availability/AddAvailabilitiesPage';
import AvailabilitiesPage from '../pages/Availability/AvailabilitiesPage';
import AddUnavailabilitiesPage from '../pages/Availability/AddUnavailabilitiesPage';
import AgendaPage from '../pages/AgendaPage';
import IncompatibleBrowserPage from '../pages/IncompatibleBrowserPage';
import PatientsPage from '../pages/PatientsPage';
import AppointmentApprovedPage from '../pages/AppointmentApprovedPage';
import AppointmentRejectedPage from '../pages/AppointmentRejectedPage';
import RootPage from '../pages/RootPage';
import { getLocalStorage, getSetting, hasLocalStorage, isDevelopment, setLocalStorage } from '../helpers/tools';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import ProfileSubscriptionPage from '@/pages/Profile/ProfileSubscriptionPage';
import AppointmentAnswerPage from "../pages/AppointmentAnswerPage";
import DocumentSigned from "../pages/DocumentSigned";
import ProfileLinkPage from "@/pages/Profile/ProfileLinkPage.vue";

Vue.use(Router);

/*
Available routes meta:
- public: If true, allow accessing the route without being authenticated
- ignore: If true, the URL will not be accessible directly (used for children)
- title: If set, will be used as the page <title>
- reloadProfile: If true, the profile will be reloaded from server before displaying the page
*/

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: { public: true },
      redirect: { name: 'dashboard' },
      component: RootPage,
      props: (route) => {
        return {
          appointmentId: route.query['appointment-detail'],
        };
      },
      children: [
        {
          path: '/profile',
          component: ProfilePage,
          name: 'profile',
          meta: { ignore: true },
          children: [
            {
              path: 'view',
              name: 'profile-view',
              component: ProfileViewPage,
              meta: { title: 'Mon profil' },
            },
            {
              path: 'transactions',
              name: 'profile-transactions',
              component: ProfileTransactionsPage,
              meta: { title: 'Mes paiements' },
            },
            {
              path: 'edit',
              name: 'profile-edit',
              component: ProfileEditPage,
              meta: { title: 'Editer mon profil', reloadProfile: true },
            },
            {
              path: 'subscription',
              name: 'profile-subscription',
              component: ProfileSubscriptionPage,
              meta: { title: 'Mon abonnement' },
            },
            {
              path: 'settings',
              name: 'profile-settings',
              component: ProfileSettingsPage,
              meta: { title: 'Paramètres' },
            },
            {
              path: 'access',
              name: 'profile-access',
              component: ProfileAccessPage,
              props: (route) => {
                return {
                  accessTokenAction: route.query.accessTokenAction,
                  accessTokenId: route.query.accessTokenId,
                };
              },
              meta: { title: 'Mes accès' },
            },
            {
              path: 'act',
              name: 'profile-act',
              component: ProfileActPage,
              meta: { title: 'Mes Tarifs', reloadProfile: true },
              children: [
                {
                  path: 'add',
                  name: 'profile-act-add',
                  component: ProfileAddActPage,
                  meta: { title: 'Ajouter un tarif' },
                },
              ],
            },
            {
              path: 'link/:id',
              name: 'profile-link',
              component: ProfileLinkPage,
              meta: { title: 'Lier mon compte à un praticien', reloadProfile: true },
              children: [

              ],
            },
            {
              path: 'availabilities',
              name: 'availabilities',
              component: AvailabilitiesPage,
              meta: { title: 'Mes disponibilités' },
            },
            {
              path: 'availabilities/add',
              name: 'add-availabilities',
              component: AddAvailabilitiesPage,
              meta: { title: 'Ajouter des disponibilités' },
              props: (route) => ({
                date: moment(route.query.date, 'YYYY-MM-DD'),
              }),
            },
            {
              path: 'availabilities/remove',
              name: 'add-unavailabilities',
              component: AddUnavailabilitiesPage,
              meta: { title: 'Ajouter des indisponibilités' },
              props: (route) => ({
                date: moment(route.query.date, 'YYYY-MM-DD'),
              }),
            },
          ],
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: DashboardPage,
          meta: { title: 'Tableau de bord' },
          children: [
            {
              path: ':id',
              redirect: (to) => ({ name: 'dashboard', query: { 'appointment-detail': to.params.id } }),
            },
          ],
        },
        {
          path: '/consultation/report-confirmed',
          name: 'consultation-report-confirmed',
          component: ConsultationReportConfirmedPage,
          meta: { title: 'Rapport de confirmation enregistré' },
        },
        {
          path: '/consultation/:appointmentId/report',
          name: 'consultation-report',
          component: ConsultationReportPage,
          meta: { title: 'Rapport de consultation' },
          props: true,
        },
        {
          path: '/consultation/:appointmentId',
          name: 'consultation',
          component: () => ({ component: import('../pages/ConsultationPage') }),
          meta: { title: 'Consultation' },
          props: true,
          beforeEnter(to, from, next) {
            const handleLoaded = async () => {
              if (!store.hasModule('sdk')) {
                store.registerModule('sdk', (await import('./store_modules/sdk')).default);
              }

              next();
            };

            if (window.PlatformSDK) {
              return handleLoaded();
            }

            const SDKScriptElement = document.createElement('script');
            SDKScriptElement.src = isDevelopment
              ? `//assets.hellocareplatform.com/sdk/js/dev/release/0.6/hellocare-sdk.dev.js`
              : `//assets.hellocareplatform.com/sdk/js/v0.6.17/hellocare-sdk.min.js`;
            SDKScriptElement.async = true;
            SDKScriptElement.onload = handleLoaded;
            document.head.appendChild(SDKScriptElement);
          },
        },
        {
          path: '/agenda',
          name: 'agenda',
          component: AgendaPage,
          children: [
            {
              path: 'appointments',
              name: 'appointments',
              component: AppointmentsPage,
              meta: { title: 'Planning' },
              beforeEnter(to, from, next) {
                setLocalStorage('preferred-agenda-view', to.name);
                next();
              },
              children: [
                {
                  path: ':id',
                  redirect: (to) => ({ name: 'appointments', query: { 'appointment-detail': to.params.id } }),
                },
              ],
            },
            {
              path: 'view',
              name: 'agenda-view',
              component: AgendaViewPage,
              meta: { title: 'Agenda' },
              beforeEnter(to, from, next) {
                setLocalStorage('preferred-agenda-view', to.name);
                next();
              },
              children: [
                {
                  path: ':id',
                  redirect: (to) => ({ name: 'agenda-view', query: { 'appointment-detail': to.params.id } }),
                },
              ],
            },
          ],
        },
        {
          path: '/appointment/approved',
          name: 'appointment-approved',
          component: AppointmentApprovedPage,
          meta: {
            title: 'Demande de rendez-vous acceptée',
            public: true,
          },
        },
        {
          path: '/appointment/rejected',
          name: 'appointment-rejected',
          component: AppointmentRejectedPage,
          meta: {
            title: 'Demande de rendez-vous refusée',
            public: true,
          },
        },
        {
          path: '/patients',
          name: 'patients',
          component: PatientsPage,
          meta: { title: 'Mes patients' },
        },
        {
          path: '/patients/:id',
          name: 'patient',
          component: PatientPage,
          meta: { title: 'Patient' },
        },
        {
          path: '/patients/:id/appointments',
          name: 'patient-appointments',
          component: PatientAppointmentsPage,
          meta: { title: 'RDV Patient' },
        }
      ],
    },
    {
      path: '/appointment/:id/answer',
      name: 'appointment-answer',
      component: AppointmentAnswerPage,
      meta: {
        title: 'Réponse de la demande de rendez-vous',
        public: true,
      },
      props: (route) => {
        return {
          appointmentId: route.params.id,
          token: route.query.token,
        }
      }
    },
    {
      path: '/incompatible-browser',
      name: 'incompatible-browser',
      component: IncompatibleBrowserPage,
      meta: { title: 'Erreur - Navigateur incompatible' },
    },
    {
      path: '/appointment/:appointmentid/document-sign/:id',
      name: 'document-sign',
      component: DocumentSigned,
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter() {
        logout();
        redirectToAuth(false);
      },
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundPage,
      meta: { title: 'Erreur - Page inexistante' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'not-found') {
    return next();
  }

  if (extractAccessTokenFromHash(to.hash)) {
    if (hasLocalStorage('postAuthUrl')) {
      return window.location = getLocalStorage('postAuthUrl', true);
    }

    return router.replace({ ...to, hash: undefined });
  }

  if (to.meta.ignore) {
    return next(false);
  }

  if (!to.meta.public && !hasAccessToken()) {
    redirectToAuth();
    return next(false);
  }

  await store.dispatch('appNotificationRedirected');
  await store.dispatch('appNavigationShrink');

  // Fetch profile on first app load or if route is asking for a reload
  if (!to.meta.public && (from.name === null || from.meta.public || to.meta.reloadProfile)) {
    await store.dispatch('appLoaderShow');
    await store.dispatch('practitionerFetchProfile');

    const viewedGuideStepsIds = store.state.practitioner.profile.metadata.viewedGuideStepsIds || [];

    await store.dispatch('vueGuideSetup', {
      viewedStepsIds: viewedGuideStepsIds,
    });

    if (getSetting('SENTRY_ENABLED')) {
      Sentry.configureScope(scope => scope.setUser({
        id: store.state.practitioner.profile.id,
        email: store.state.practitioner.profile.email,
      }));
    }

    await store.dispatch('appLoaderHide');
  }

  if (to.meta.title) {
    document.title = `${store.getters.practitionerLabel} - ${to.meta.title} - Hellocare`;
  } else {
    document.title = `${store.getters.practitionerLabel} - Hellocare`;
  }

  return next();
});

export default router;
