import ky from 'ky';
import { getSynapseJWT, refreshSynapseJWT } from '../helpers/auth';
import * as Sentry from '@sentry/browser';
import { getSetting } from '../helpers/tools.js';

const api = ky.create({
  prefixUrl: 'https://api.synapse-medicine.com/prescription-autocomplete/v1/',
  retry: {
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRetry: [
      async ({ request }) => {
        const synapseJWT = await refreshSynapseJWT();
        request.headers.set('Authorization', `Bearer ${synapseJWT}`);
      },
    ],
    beforeRequest: [
      async (request) => {
        let synapseJWT = getSynapseJWT();

        if (!synapseJWT) {
          synapseJWT = await refreshSynapseJWT();
        }

        request.headers.set('Authorization', `Bearer ${synapseJWT}`);
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status >= 400 && getSetting('SENTRY_ENABLED')) {
          Sentry.configureScope(scope => scope.setTags({
            api_url: request.url,
            api_method: request.method,
          }));

          Sentry.setExtra('response', await response.clone().json());
        }

        return response;
      },
    ],
  },
});

export default api;
