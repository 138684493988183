var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingPatient
    ? _c("AppLoader", [_vm._v("\n  Chargement du patient en cours...\n")])
    : _c("AppLayout", [
        _c("main", [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("PatientTabBar", { attrs: { "patient-id": _vm.patient.id } }),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col _7" },
                  [_c("EditProfil", { attrs: { patient: _vm.patient } })],
                  1
                ),
                _c("div", { staticClass: "col _1" }),
                _c(
                  "div",
                  { staticClass: "col _4" },
                  [
                    _c("GroupTitle", { attrs: { icon: _vm.$icons.calendar } }, [
                      _vm._v("Nouveau rendez-vous")
                    ]),
                    _c("NewAppointmentForm", {
                      attrs: { "forced-patient": _vm.patient }
                    })
                  ],
                  1
                )
              ]),
              _c("hr"),
              _c("Observations", {
                attrs: {
                  patient: _vm.patient,
                  "collapse-block": _vm.collapseBlock
                }
              })
            ],
            1
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }