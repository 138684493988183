var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment && _vm.hasPatientAdvice
    ? _c(
        "div",
        [
          _c(
            "ButtonRed",
            {
              attrs: {
                busy: _vm.isDownloading,
                icon: _vm.$icons.pdf,
                small: ""
              },
              on: { click: _vm.downloadPatientAdvice }
            },
            [_vm._v("\n    Télécharger le compte-rendu\n  ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }