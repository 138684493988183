<script>
  import BaseButton from './BaseButton.vue';

  export default {
    name: 'ButtonGrey',
    extends: BaseButton,
  };
</script>

<style lang="scss" scoped src="../../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
    background-color: #9B9B9B;

    &.gradient {
      background: linear-gradient(to left, #9B9B9B, #7B7B7B);
    }

    &.outlined {
      border-color: #9B9B9B;
      color: #9B9B9B;
    }
  }
</style>
