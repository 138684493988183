var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slots" },
    [
      _vm._l(_vm.channelsAvailable, function(channel) {
        return _vm.channelsAvailable
          ? _c(
              "div",
              { staticClass: "channel-slot" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("channel")(channel)) +
                    "\n        "
                ),
                _vm._l(_vm.eventsDay.slots[channel], function(slot) {
                  return _c("div", { staticClass: "slot" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(slot.start) +
                        " - " +
                        _vm._s(slot.end) +
                        "\n        "
                    )
                  ])
                })
              ],
              2
            )
          : _vm._e()
      }),
      _vm.isLimited
        ? _c("div", { staticClass: "limit" }, [
            _vm._v(
              "\n        (" +
                _vm._s(_vm.availabilitiesCount - 4) +
                " de plus)\n    "
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }