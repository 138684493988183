var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agenda-calendar-hours-column" },
    _vm._l(_vm.hours, function(hour) {
      return _c(
        "div",
        { staticClass: "hour-indicator", style: { height: _vm.height } },
        [_vm._v("\n    " + _vm._s(hour) + ":00\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }