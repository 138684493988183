var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-header" }, [
    _c(
      "p",
      {
        staticClass: "go-today",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.goToToday($event)
          }
        }
      },
      [_vm._v(" Aujourd'hui ")]
    ),
    _c(
      "p",
      {
        staticClass: "prev-month",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.goPrev($event)
          }
        }
      },
      [
        _c("FontAwesomeIcon", {
          staticClass: "nav-item-label-icon",
          attrs: { icon: _vm.$icons.chevronLeft }
        })
      ],
      1
    ),
    _c(
      "p",
      {
        staticClass: "next-month",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.goNext($event)
          }
        }
      },
      [
        _c("FontAwesomeIcon", {
          staticClass: "nav-item-label-icon",
          attrs: { icon: _vm.$icons.chevronRight }
        })
      ],
      1
    ),
    _c("p", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.month) + " " + _vm._s(_vm.year) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }