<!--
Emitted events:
- select(card.id)
-->
<template>
  <div class="credit-cards">
    <div v-if="isLoadingCards">Vérification de la carte bancaire...</div>
    <template v-else>
      <CreditCardsPictograms small />
      <CreditCardDisplayForm
        v-if="card && !showReplaceCardForm"
        :card-expiry="`${card.expirationMonth}/${card.expirationYear}`"
        :card-number="`**** **** **** ${card.lastFourDigit}`"
        @replace="showReplaceCardForm = true"
      />
      <CreditCardReplaceForm
        v-else
        :previous-card-id="card && card.id"
        @close="showReplaceCardForm = false"
        @replace="handleReplaced"
      />
    </template>
  </div>
</template>

<script>
  import CreditCardsPictograms from '@/components/CreditCardsPictograms';
  import CreditCardReplaceForm from '@/components/Form/CreditCardReplaceForm';
  import CreditCardDisplayForm from '@/components/Form/CreditCardDisplayForm';

  export default {
    name: 'CreditCard',
    components: {
      CreditCardDisplayForm,
      CreditCardReplaceForm,
      CreditCardsPictograms,
    },
    data() {
      return {
        showReplaceCardForm: false,
      };
    },
    async created() {
      await this.reloadCards();
    },
    computed: {
      card() {
        if (!this.cards) {
          return null;
        }
        return this.cards[0];
      },
      cards() {
        return this.$store.state.paymentMethods.all;
      },
      isLoadingCards() {
        return this.$store.state.paymentMethods.isFetchingAll;
      },
    },
    methods: {
      async handleReplaced() {
        this.showReplaceCardForm = false;
        await this.reloadCards();
      },
      async reloadCards() {
        try {
          await this.$store.dispatch('paymentMethodsFetchAll', {type: 'card'});
        } catch(e) {
          await this.$addError('Une erreur est survenue lors du chargement des cartes bancaires');
          throw e;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../assets/grid";

  .credit-cards-pictograms.small {
    max-width: none;
    justify-content: flex-start;
  }
</style>
