import Tesly from '../../api-platform/tesly';

export default {
  state: {
    isFetching: false,
    current: null,
    file: {
      sounds: [],
      pictures: [],
      movies: []
    },
    data: null
  },
  mutations: {
    TESLY_GET_DATA(state) {
      state.isFetching = true;
    },
    TESLY_GET_DATA_SUCCESS(state, data) {
      state.file.sounds = [];
      state.file.pictures = [];
      state.file.movies = [];
      state.current = data;
      state.isFetching = false;
    },
    TESLY_GET_DATA_FAIL(state) {
      state.isFetching = false;
    },
    TESLY_DOWNLOAD_FILE(state) {
      state.isFetching = true;
    },
    TESLY_DOWNLOAD_FILE_SUCCESS(state, {data, type}) {
      state.isFetching = false;
      state.file[type].push(data);
    },
    TESLY_DOWNLOAD_FILE_FAIL(state) {
      state.isFetching = false;
    }
  },
  actions: {
    async teslyGetData({ commit }, { code }) {
      commit('TESLY_GET_DATA');
      try {
        let tesly = await Tesly.get(code);
        commit('TESLY_GET_DATA_SUCCESS', tesly);
      } catch (e) {
        commit('TESLY_GET_DATA_FAIL');
        throw e;
      }
    },
    async teslyDownloadFile({ commit }, { code, filename, type }) {
      commit('TESLY_DOWNLOAD_FILE');
      try {
        let data = await Tesly.download(code, filename);
        commit('TESLY_DOWNLOAD_FILE_SUCCESS', {data, type});
      } catch (e) {
        commit('TESLY_DOWNLOAD_FILE_FAIL');
        throw e;
      }
    },
  },
};
