<template>
  <AppLayout>
    <main class="full-panel">
      <div class="control-panel">
        <p>
          <router-link :to="{ name:'availabilities' }">
            <FontAwesomeIcon :icon="$icons.closePanel" />
            Fermer
          </router-link>
        </p>
      </div>
      <div class="container">
        <div class="container-link">
          <router-link :to="{ name:'add-availabilities', query: { date: date.format('YYYY-MM-DD') } }">
            <div class="top-link active">Disponibilités</div>
          </router-link>
        </div>

        <!-- Edit recurrent slot -->
        <AppConfirmation ref="editRecurrent" vertical>
          <template #title>
            Attention cette disponibilité est récurrente, voulez vous éditer uniquement votre disponibilité
            du {{ date | date('dddd D MMMM') }} ?
          </template>

          <ConfirmButton @click="updateAvailability">
            Éditer uniquement le {{ date | date('dddd D MMMM') }}
          </ConfirmButton>

          <ConfirmButton @click="(slot) => updateAvailability(slot, true)">
            Éditer la récurrence
          </ConfirmButton>

          <CancelButton />
        </AppConfirmation>

        <!-- Delete recurrent slot -->
        <AppConfirmation
          ref="deleteRecurrent"
          title="Attention cette disponibilité est récurrente, êtes-vous sûr de vouloir la supprimer ?"
          vertical
        >
          <ConfirmButton @click="createMaskForAvailability">
            Supprimer uniquement le {{ date | date('dddd D MMMM') }}
          </ConfirmButton>

          <ConfirmButton @click="deleteAvailability">
            Supprimer la récurrence
          </ConfirmButton>

          <CancelButton />
        </AppConfirmation>

        <!-- Edit single slot -->
        <AppConfirmation
          ref="editSingle"
          title="Êtes-vous sûr de vouloir modifier cette disponibilité ?"
          @confirm="updateAvailability"
        />

        <!-- Delete single slot -->
        <AppConfirmation
          ref="deleteSingle"
          title="Êtes-vous sûr de vouloir supprimer cette disponibilité ?"
          @confirm="deleteAvailability"
        />

        <form @submit.prevent="handleSubmit">
          <div v-for="slot in slots" :key="slot.sid">
            <div v-if="slot.type !== 'unavailable'" class="container-availabilities">
              <FormWidget class="form-control hours-control">
                <label :for="`start-${slot.sid}`">De</label>
                <input
                  :id="`start-${slot.sid}`"
                  v-model="slot.start_hour"
                  v-mask="['#:##', '##:##']"
                  :disabled="!isEditable(slot)"
                  placeholder="hh:mm"
                  type="text"
                >
                <template v-if="!isHourValid(slot.start_hour)" v-slot:error>
                  <span>Format d'heure incorrect</span>
                </template>
              </FormWidget>

              <FormWidget class="form-control hours-control">
                <label :for="`end-${slot.sid}`">A</label>
                <input
                  :id="`end-${slot.sid}`"
                  v-model="slot.end_hour"
                  v-mask="['#:##', '##:##']"
                  :disabled="!isEditable(slot)"
                  placeholder="hh:mm"
                  type="text"
                >
                <template v-if="!isHourValid(slot.end_hour)" v-slot:error><span>Format d'heure incorrect</span>
                </template>
              </FormWidget>

              <FormWidget class="form-control">
                <label :for="`channel-${slot.sid}`">Canal</label>
                <SelectWrapper>
                  <select :id="`channel-${slot.sid}`" v-model="slot.channel" :disabled="!isEditable(slot)">
                    <option value="remote">Téléconsultation</option>
                    <option value="medical_office">En cabinet</option>
                    <option value="home">À domicile</option>
                  </select>
                </SelectWrapper>
              </FormWidget>
              <template v-if="slot.type === 'weekly'">
                <div class="center icons" @click="$confirm($refs.editRecurrent, slot)">
                  <FontAwesomeIcon :icon="$icons.edit" />
                </div>
                <div class="center icons" @click="$confirm($refs.deleteRecurrent, slot)">
                  <FontAwesomeIcon :icon="$icons.trashOutlined" />
                </div>
              </template>
              <template v-if="slot.type === 'once'">
                <div class="center icons" @click="$confirm($refs.editSingle, slot)">
                  <FontAwesomeIcon :icon="$icons.edit" />
                </div>
                <div class="center icons" @click="$confirm($refs.deleteSingle, slot)">
                  <FontAwesomeIcon :icon="$icons.trashOutlined" />
                </div>
              </template>
              <template v-if="!slot.sid">
                <div class="icons" />
                <div class="center icons" @click="deleteAvailability(slot)">
                  <FontAwesomeIcon :icon="$icons.trashOutlined" />
                </div>
              </template>
            </div>
          </div>

          <div class="container-options">
            <p @click="addSlot" class="addAvailability">+ Ajouter un créneau</p>
            <div class="btn-control">
              <input type="radio" id="once" value="once" v-model="type">
              <label for="once" class="button button-control">
                Uniquement le {{ this.date | date('dddd D MMMM') }}
              </label>
            </div>

            <div class="btn-control">
              <input type="radio" id="weekly" value="weekly" v-model="type">
              <label for="weekly" class="button button-control">
                Tous les {{ this.date | date('dddd') }}s
              </label>
            </div>

            <div class="btn-control">
              <input type="radio" id="multi-weekly" value="multi-weekly" v-model="type">
              <label for="multi-weekly" class="button button-control">
                Sur plusieurs jours de la semaine
              </label>
            </div>

            <div class="btn-control">
              <input type="radio" id="daily" value="daily" v-model="type">
              <label for="daily" class="button button-control">
                Dans un intervalle de date
              </label>
            </div>

            <hr class="separator">

            <div v-if="type === 'multi-weekly'" class="pick-weekly-day">

              <p>Sélectionnez les jours de répétition pour vos disponibilités.</p>

              <div class="week">
                <div class="day">
                  <input type="checkbox" id="lundi" value="1" v-model="selectDays">
                  <label class="pick-a-day" for="lundi">Lun</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="mardi" value="2" v-model="selectDays">
                  <label class="pick-a-day" for="mardi">Mar</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="mercredi" value="3" v-model="selectDays">
                  <label class="pick-a-day" for="mercredi">Mer</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="jeudi" value="4" v-model="selectDays">
                  <label class="pick-a-day" for="jeudi">Jeu</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="vendredi" value="5" v-model="selectDays">
                  <label class="pick-a-day" for="vendredi">Ven</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="samedi" value=6 v-model="selectDays">
                  <label class="pick-a-day" for="samedi">Sam</label>
                </div>
                <div class="day">
                  <input type="checkbox" id="dimanche" value="7" v-model="selectDays">
                  <label class="pick-a-day" for="dimanche">Dim</label>
                </div>
              </div>
            </div>

            <div v-if="type === 'daily'" class="pick-weekly-day">
              <p>Sélectionnez les dates pour régler vos disponibilités dans cet interval.</p>
              <FormWidget class="datetime-widget">
                <label for="dayStart">Date de début</label>
                <DateTimePicker
                  v-model="intervalDays.start"
                  input-id="dayStart"
                  type="date"
                  :min-datetime="new Date().toISOString()"
                  :phrases="{ cancel: 'Annuler', ok: 'OK' }"
                />
              </FormWidget>
              <FormWidget class="datetime-widget">
                <label for="dayEnd">Date de fin</label>
                <DateTimePicker
                  v-model="intervalDays.end"
                  input-id="dayEnd"
                  type="date"
                  :min-datetime="new Date().toISOString()"
                  :phrases="{ cancel: 'Annuler', ok: 'OK' }"
                />
              </FormWidget>
            </div>

            <ButtonGreen
              class="btn-submit save"
              small
              type="submit"
              :icon="$icons.chevronRight"
              :disabled="disableButton"
            >
              Enregistrer
            </ButtonGreen>

            <ButtonRed
              class="btn-submit"
              small
              @click="creatMaskForDay"
            >
              Indisponible toute la journée
              <FontAwesomeIcon :icon="$icons.saveOutlined" />
            </ButtonRed>

            <ButtonTransparent
              class="cancel btn-submit"
              small
              @click="cancelEdition"
            >
              Annuler
            </ButtonTransparent>
          </div>
        </form>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import FormWidget from '../../components/Form/FormWidget';
  import ButtonGreen from '../../components/Button/ButtonGreen.vue';
  import { cloneDeep, remove } from 'lodash-es';
  import moment from 'moment';
  import ButtonRed from '../../components/Button/ButtonRed.vue';
  import SelectWrapper from '../../components/Form/SelectWrapper';
  import ButtonTransparent from '../../components/Button/ButtonTransparent.vue';
  import AppLayout from '../../components/AppLayout';
  import AppConfirmation from '@/components/AppConfirmation';
  import CancelButton from '@/components/AppConfirmation/CancelButton';
  import ConfirmButton from '@/components/AppConfirmation/ConfirmButton';

  export default {
    name: 'AddAvailabilitiesPage',
    components: {
      ConfirmButton,
      CancelButton,
      AppConfirmation,
      AppLayout,
      ButtonTransparent,
      SelectWrapper,
      ButtonGreen,
      FormWidget,
      ButtonRed,
    },
    props: {
      date: {
        type: moment,
        required: true,
      },
    },
    data() {
      return {
        selectSlot: Number,
        showModal: false,
        slots: [],
        selectDays: [],
        intervalDays: {},
        type: 'once',
        form: {
          new: [],
          delete: [],
          update: [],
          updateRecurrency: [],
          mask: [],
        },
      };
    },
    mounted() {
      this.getAvailabilitiesDetails();
    },
    computed: {
      availabilities() {
        return this.$store.state.availability.availabilities;
      },
      disableButton() {
        if (this.form.delete.length > 0) {
          return false;
        }
        if (this.form.new.length > 0) {
          return false;
        }
        if (this.form.mask.length > 0) {
          return false;
        }
        if (this.slots) {
          for (let slot of this.slots) {
            let start = slot.start_hour.replace(/_+/g, '');
            let end = slot.end_hour.replace(/_+/g, '');
            if (start.length >= 4 && end.length >= 4) {
              if (this.isHourValid(slot.start_hour) && this.isHourValid(slot.end_hour)) {
                if (!slot.sid) {
                  return false;
                } else if (this.slotsChanged(slot)) {
                  return false;
                }
              }
            }
          }
        }
        return true;
      },
    },
    methods: {
      init() {
        this.slots = cloneDeep(this.availabilities);
        this.slots.forEach(slot => {
          slot.start_date = moment(slot.start_date).format('YYYY-MM-DD');
          slot.end_date = slot.end_date ? moment(slot.end_date).format('YYYY-MM-DD') : null;
          slot.start_hour = this.getHour(slot.start_hour);
          slot.end_hour = this.getHour(slot.end_hour);
        });
        if (this.slots.length <= 0) {
          this.addSlot();
        }
      },
      isHourValid(hour) {
        if (hour.length >= 4) {
          let regex = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
          return regex.test(hour);
        }
        return true;
      },
      slotsChanged(availability) {
        for (let slot of this.availabilities) {
          if (slot.sid === availability.sid &&
            this.getHour(slot.start_hour) === availability.start_hour &&
            this.getHour(slot.end_hour) === availability.end_hour &&
            slot.channel === availability.channel) {
            return false;
          }
        }
        return true;
      },
      getHour(data) {
        let date = data.split('+');
        return moment(date[0]).format('HH:mm');
      },
      //On rajoute une disponibilité vide
      addSlot() {
        let newSlot = {
          start_hour: '',
          end_hour: '',
          channel: 'remote',
        };
        this.slots.push(newSlot);
      },
      // On supprimer la disponibilité uniquement pour aujourd'hui
      createMaskForAvailability(slot) {
        this.form.mask.push(slot.sid);
        remove(this.slots, s => s.sid === slot.sid);
      },
      // On supprime entièrement une disponibilité
      deleteAvailability(slot) {
        if (slot.sid) {
          this.form.delete.push(slot.sid);
        }
        remove(this.slots, s => s.sid === slot.sid);
      },
      // On supprime toutes les disponibiltiés de la journée
      creatMaskForDay() {
        for (let slot of this.slots) {
          if (slot.sid) {
            this.form.mask.push(slot.sid);
            this.slots = [];
          }
        }
        this.maskAvailabilities();
      },
      parseNewAvailabilities(day = null) {
        let startAvailability = this.date.format('YYYY-MM-DD');
        let endAvailability = null;

        let typeAvailability = this.type;
        if (this.type === 'multi-weekly') {
          startAvailability = this.date.set('day', day).format('YYYY-MM-DD');
          typeAvailability = 'weekly';
        } else if (this.type === 'daily') {
          startAvailability = moment(this.intervalDays.start).format('YYYY-MM-DD');
          endAvailability = moment(this.intervalDays.end).format('YYYY-MM-DD');
          typeAvailability = 'once';
        }

        let newAvailability = {
          start_date: startAvailability,
          end_date: endAvailability,
          type: typeAvailability,
          slots: [],
        };

        for (let availability of this.slots) {
          if (!availability.start_hour || !availability.end_hour) {
            continue;
          }
          if (!availability.sid) {
            let slotsAvailability = {
              start_hour: availability.start_hour.length === 4 ? `0${availability.start_hour}` : availability.start_hour,
              end_hour: availability.end_hour.length === 4 ? `0${availability.end_hour}` : availability.end_hour,
              channel: availability.channel,
            };
            newAvailability.slots.push(slotsAvailability);
          }
        }
        if (newAvailability.slots.length > 0) {
          this.form.new.push(newAvailability);
        }
      },
      isEditable(slot) {
        return !slot.sid || slot.updatable;
      },
      updateAvailability(slot, all = false) {
        if (!slot.sid) {
          return;
        }
        this.$set(slot, 'updatable', true);
        if (all) {
          this.$set(slot, 'all', true);
        }
      },
      parseUpdateAvailabilities() {
        for (let availability of this.slots) {
          if (!availability.updatable) {
            continue;
          }
          if (!availability.sid) {
            continue;
          }
          let slotsAvailability = {
            sid: availability.sid,
            start_hour: availability.start_hour,
            end_hour: availability.end_hour,
            channel: availability.channel,
          };
          if (availability.all) {
            slotsAvailability.all = 'true';
            this.form.updateRecurrency.push(slotsAvailability);
          } else {
            this.form.update.push(slotsAvailability);
          }
        }
      },
      async handleSubmit() {
        //si des disponibilités ont été rajoutés
        if (this.slots.length > 0) {
          this.parseUpdateAvailabilities();
          if (this.selectDays.length > 0 && (this.type === 'multi-weekly' || this.type === 'daily')) {
            this.selectDays.forEach(day => {
              this.parseNewAvailabilities(day);
            });
          } else {
            this.parseNewAvailabilities();
          }
        }
        //si on le supprime uniquemnt un jour (pour une récurrence)
        if (this.form.new.length > 0) {
          await this.sendAvailabilities();
          this.form.new = [];
        }
        //si on supprime la disponibilité
        if (this.form.delete.length > 0) {
          await this.deleteAvailabilities();
          this.form.delete = [];
        }
        //si on créer un mask pour la disponibilité
        if (this.form.mask.length > 0) {
          await this.maskAvailabilities();
          this.form.delete = [];
        }
        //si on modifie la disponibilité
        if (this.form.update.length > 0) {
          await this.updateOneTimeAvailabilities();
          this.form.update = [];
        }
        //si on modifie la disponibilité
        if (this.form.updateRecurrency.length > 0) {
          await this.updateRecurrencyAvailabilities();
          this.form.update = [];
        }
      },
      cancelEdition() {
        this.$router.push({ name: 'availabilities' });
      },
      async sendAvailabilities() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerCreateAvailabilities', this.form.new);
          await this.$router.push({ name: 'availabilities' });
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async deleteAvailabilities() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerDeleteAvailabilities', {
            availabilities: this.form.delete,
            all: true,
          });
          await this.$router.push({ name: 'availabilities' });
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async maskAvailabilities() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerDeleteAvailabilities', {
            date: this.date.format('YYYY-MM-DD'),
            availabilities: this.form.mask,
          });
          await this.$router.push({ name: 'availabilities' });
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async updateOneTimeAvailabilities() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerUpdateAvailabilities', {
            availability: this.form.update,
            dateAt: this.date.format('YYYY-MM-DD'),
          });
          await this.$router.push({ name: 'availabilities' });
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async updateRecurrencyAvailabilities() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerUpdateAvailabilities', {
            availability: this.form.updateRecurrency,
            dateAt: this.date.format('YYYY-MM-DD'),
          });
          await this.$router.push({ name: 'availabilities' });
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async getAvailabilitiesDetails() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('practitionerDetailsAvailabilities', { date: this.date.format('YYYY-MM-DD') });
          await this.init();
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  form {
    display: contents;
  }

  .full-panel {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    transform: translate3d(0, 0, 0);
    transition: transform 0.6s;
    padding: 0 !important;

    a {
      text-decoration: none;
    }
  }

  .control-panel {
    position: absolute;
    right: 10%;
    top: 15%;
    text-align: right;
    cursor: pointer;

    a {
      color: #000;
    }

  }

  .container-link {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .top-link {
      font-family: "Gotham Rounded", sans-serif;
      font-size: 2em;
      color: #1F1F1F;
      font-weight: normal;
      padding: 0.6rem 0;
      margin: 0 2.4rem;

      &.active {
        border-bottom: solid .5rem #27D7AB;
        font-weight: bold;
      }
    }
  }

  .container {
    margin-bottom: 70px;
    display: block;
    overflow: auto;
    height: 100vh;

    .container-options {
      width: 35%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-control {
    margin-top: 15px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #27D7AB;
    cursor: pointer;
  }

  .icons {
    margin-right: 0.2rem;
    width: 3rem;
  }

  .container-availabilities {
    display: flex;
    margin: 15px;
    justify-content: center;

    .form-control {
      margin-right: 15px
    }

    .hours-control {
      width: 13rem;
    }

    .availability {
      display: block;
    }
  }

  .addAvailability {
    color: #27D7AB;
    font-weight: 400;
    cursor: pointer;
  }

  .separator {
    margin: 40px 0 0;
    border: 0;
    border-top: 1px solid #D8D8D8;
  }

  .button {
    display: inline-flex;
    justify-content: center;
    font-family: "Gotham Rounded", sans-serif;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 0.3rem;
    padding: 1.6rem 3.2rem;
    transition: all 0.6s ease-out 0s;
    outline: none;
    border: 1px solid #D8D8D8;
    color: #D8D8D8;
    width: 100%;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    border: 1px solid #27D7AB;
    color: #27D7AB;
  }

  .pick-weekly-day {
    padding-bottom: 30px;

    p {
      display: block;
      margin: 0;
      text-align: center;
      margin-bottom: 15px;
    }

    .week {
      display: flex;
      justify-content: center;
    }

    .day {
      display: inline-block;
    }

    .pick-a-day {
      display: block;
      font-size: 1.5em;
      font-weight: 400;
      padding: 14px;
      background: transparent;
      border: 1px solid #D8D8D8;
      cursor: pointer;
      user-select: none;
    }

    input[type="checkbox"] {
      display: none;
    }

    :checked + label {
      border: 1px solid #27D7AB;
      background: #DFF9F3;
    }

    .datetime-widget {
      width: 43%;
      margin: 10px;
      display: inline-block;
    }
  }

  .container-link {
    padding-top: 100px;
  }

  .cancel {
    padding-bottom: 100px !important;
  }

  .save {
    margin-top: 40px !important;
  }

  .btn-submit {
    margin-top: 20px;
    width: 100%;
  }

  @media (min-width: 1441px) {
    .container-options {
      width: 25% !important;
    }
  }
</style>

