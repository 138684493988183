
<template>
  <div class="credit-cards">
    <div v-if="isLoadingSepa">Vérification de l'iban...</div>
    <template v-else>
      <br/>
      <div v-if="noSepa">
        <SepaDebitReplaceForm />
      </div>
      <div v-else>
        <SepaDebitDisplay
            v-if="sepaDebit && !showReplaceSepaForm"
            :iban="`${sepaDebit.country} ** ${sepaDebit.bankCode} ******* ${sepaDebit.last4}`"
            @replace="showReplaceSepaForm = true"
        />
        <SepaDebitReplaceForm
            v-else
            :previous-iban-id="sepaDebit && sepaDebit.id"
            @close="showReplaceSepaForm = false"
            @replace="handleReplaced"
        />
      </div>
    </template>
  </div>
</template>

<script>
import SepaDebitReplaceForm from '@/components/Form/SepaDebitReplaceForm';
import SepaDebitDisplay from "./Form/SepaDebitDisplayForm";

export default {
  name: 'SepaDebit',
  components: {
    SepaDebitDisplay,
    SepaDebitReplaceForm
  },
  data() {
    return {
      showReplaceSepaForm: false,
      isLoadingSepa: true,
      noSepa: true,
      sepaDebit: null,
    };
  },
  async beforeMount() {
    await this.loadSepa();
    if(!this.$store.state.paymentMethods.isFetchingAll) {
      this.noSepa = !(this.$store.state.paymentMethods.all && this.$store.state.paymentMethods.all.length > 0);
      this.isLoadingSepa = this.$store.state.paymentMethods.isFetchingAll;
      this.sepaDebit = this.$store.state.paymentMethods.all[0];
    }
  },
  methods: {
    async handleReplaced() {
      this.showReplaceSepaForm = false;
      await this.loadSepa();
      if(!this.$store.state.paymentMethods.isFetchingAll) {
        this.noSepa = !(this.$store.state.paymentMethods.all && this.$store.state.paymentMethods.all.length > 0);
        this.isLoadingSepa = this.$store.state.paymentMethods.isFetchingAll;
        this.sepaDebit = this.$store.state.paymentMethods.all[0];
      }
    },
    async loadSepa() {
      try {
        await this.$store.dispatch('paymentMethodsFetchAll', {type: 'sepa'});
      } catch(e) {
        await this.$addError('Une erreur est survenue lors du chargement des iban');
        throw e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/grid";

.credit-cards-pictograms.small {
  max-width: none;
  justify-content: flex-start;
}
</style>
