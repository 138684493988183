var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    _vm._g(
      {
        staticClass: "app-navigation",
        class: { expanded: _vm.expanded, desktop: _vm.desktop }
      },
      _vm.desktop
        ? { mouseenter: _vm.expandNavigation, mouseleave: _vm.shrinkNavigation }
        : {}
    ),
    [
      !_vm.desktop
        ? _c(
            "header",
            { staticClass: "mobile-header" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "toggle",
                attrs: { icon: _vm.toggleIcon },
                on: { click: _vm.toggleNavigation }
              }),
              _c("AppLogoMobile", { attrs: { white: "" } }),
              _c("span", { staticClass: "spacer" })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "primary-links" },
        [
          _c("AppNavigationItem", {
            attrs: {
              icon: _vm.$icons.dashboard,
              label: "Tableau de bord",
              to: "/dashboard"
            }
          }),
          _c(
            "AppNavigationItem",
            {
              attrs: {
                icon: _vm.$icons.user,
                label: "Mon compte",
                to: "/profile"
              }
            },
            [
              _c("AppNavigationItemChild", {
                attrs: { label: "Mon profil", to: "/profile/view" }
              }),
              _vm.profileType !== "secretary"
                ? _c("AppNavigationItemChild", {
                    attrs: {
                      label: "Mes paiements",
                      to: "/profile/transactions"
                    }
                  })
                : _vm._e(),
              _vm.availabilitiesEnabled && _vm.profileType !== "secretary"
                ? _c("AppNavigationItemChild", {
                    attrs: {
                      label: "Mes disponibilités",
                      to: "/profile/availabilities"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("AppNavigationItem", {
            attrs: { icon: _vm.$icons.calendar, label: "Agenda", to: "/agenda" }
          }),
          _c("AppNavigationItem", {
            attrs: {
              icon: _vm.$icons.myPatients,
              label: "Mes patients",
              to: "/patients"
            }
          }),
          _c("AppNavigationItem", {
            attrs: {
              icon: _vm.$icons.info,
              external: "",
              label: "Aide",
              to: "https://kb.hellocare.pro/support/"
            }
          }),
          _c("AppNavigationItem", {
            attrs: {
              icon: _vm.$icons.logout,
              label: "Déconnexion",
              to: "/logout"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "secondary-links" },
        [
          _vm.profileType == "doctor" && !_vm.isAccountRestricted
            ? _c("AppNavigationItem", {
                attrs: {
                  external: "",
                  icon: _vm.$icons.userMd,
                  label: "Hellocare Dr.Remplaçant",
                  to:
                    "https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links"
                }
              })
            : _vm._e(),
          _c("AppNavigationItem", {
            attrs: {
              external: "",
              label: "Hellocare Store",
              to: "https://hellocarestore.com",
              icon: _vm.$icons.store
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }