var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppPanel",
    {
      staticClass: "appointment-panel",
      attrs: { opened: _vm.opened },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.isLoadingAppointment || _vm.isLoadingPatient
        ? _c("AppLoader", [_vm._v("Chargement du rendez-vous")])
        : _vm.appointment
        ? [
            _c("SectionTitle", [_vm._v("Votre rendez-vous en détail")]),
            _c(
              "div",
              { staticClass: "summary" },
              [
                _vm.canBeClosed
                  ? _c("AppointmentPanelCloseLink", {
                      staticClass: "section",
                      on: { close: _vm.loadAppointment }
                    })
                  : _vm._e(),
                _c("AppointmentPanelPatient", { staticClass: "section" }),
                _vm.opened
                  ? _c("AppointmentPanelDetails", {
                      staticClass: "section",
                      attrs: { "can-be-started": _vm.canBeStarted }
                    })
                  : _vm._e(),
                _c("p", { staticClass: "section description" }, [
                  _vm._v(_vm._s(_vm.appointment.payload.description))
                ]),
                _vm.isCancelled
                  ? _c("div", { staticClass: "section cancel-reason" }, [
                      _c("h4", [_vm._v("Motif de l'annulation")]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.appointment.payload.cancelReason ||
                              "Aucun motif renseigné"
                          ) +
                          "\n      "
                      )
                    ])
                  : [
                      _c("div", { staticClass: "doc2u_container" }, [
                        this.$store.state.observations.isFetching
                          ? _c(
                              "div",
                              { staticClass: "container-spinner" },
                              [_c("Spinner")],
                              1
                            )
                          : _c("div", [
                              _vm.searchLength > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("LastObservations", {
                                        attrs: {
                                          "search-length": _vm.searchLength,
                                          observations: this.$store.state
                                            .observations.search
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c("p", [
                                      _vm._v("Aucune mesure enregistrée.")
                                    ])
                                  ])
                            ])
                      ]),
                      _c("AppointmentPanelPrivateNote", {
                        staticClass: "section"
                      }),
                      _vm.isClosed
                        ? [
                            _vm.isLoadingEncounter
                              ? _c(
                                  "p",
                                  { staticClass: "section" },
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: {
                                        icon: _vm.$icons.spinner,
                                        spin: ""
                                      }
                                    }),
                                    _vm._v(
                                      "\n            Vérification des documents disponibles…\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm.encounter
                              ? [
                                  _vm.encounter.medicalObservation
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "section medical-observation-container"
                                        },
                                        [
                                          _c("h4", [
                                            _vm._v("Observation médicale")
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "medical-observation"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.encounter
                                                    .medicalObservation
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isNurse
                                    ? _c("AppointmentPanelPrescriptions", {
                                        staticClass: "section"
                                      })
                                    : _vm._e(),
                                  _c("AppointmentPanelPatientAdvice", {
                                    staticClass: "section"
                                  }),
                                  !_vm.isNurse
                                    ? _c("AppointmentPanelClaims", {
                                        staticClass: "section"
                                      })
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _c("SharedFiles", {
                        staticClass: "section",
                        attrs: {
                          "appointment-id": _vm.appointmentId,
                          "patient-id": _vm.patient.id
                        }
                      })
                    ]
              ],
              2
            ),
            !_vm.isClosed && !_vm.isTerminated && !_vm.isCancelled
              ? _c("AppointmentPanelActions", {
                  attrs: { "can-be-started": _vm.canBeStarted },
                  on: {
                    accept: _vm.reloadAppointments,
                    cancel: _vm.reloadAppointments
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }