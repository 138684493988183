var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "form-text",
      class: { disabled: _vm.disabled, "has-value": !!_vm.value }
    },
    [
      _c("span", { staticClass: "label" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
      ]),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            staticClass: "input-component",
            attrs: { readonly: "", type: "text" },
            domProps: { value: _vm.value }
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm.$slots.help && !_vm.$slots.error
        ? _c("span", { staticClass: "help" }, [_vm._t("help")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }