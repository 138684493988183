var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _vm.plan
            ? _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    { staticClass: "bordered" },
                    [
                      !_vm.isSubscriptionActive
                        ? _c("div", { staticClass: "warning" }, [
                            _vm._v(
                              "\n                Votre abonnement est inactif. Pour utiliser de nouveau les services Hellocare, veuillez renouveler votre\n                abonnement ci-dessous.\n              "
                            )
                          ])
                        : _vm._e(),
                      _c("p", { staticClass: "plan-name" }, [
                        _vm._v(_vm._s(_vm.plan.name))
                      ]),
                      !_vm.hasTiers
                        ? _c("p", { staticClass: "plan-price" }, [
                            _vm._v(
                              _vm._s(_vm._f("money")(_vm.currentPrice)) +
                                " HT / mois"
                            )
                          ])
                        : _vm.tiers.length < 3
                        ? _c("p", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("money")(_vm.tiers[0].unitAmount)
                                ) +
                                " / consultation\n                (dans la limite de " +
                                _vm._s(
                                  _vm._f("money")(
                                    _vm.tiers[0].unitAmount * _vm.tiers[0].upTo
                                  )
                                ) +
                                ")\n              "
                            )
                          ])
                        : _c("p", [
                            _vm._v(
                              "Le tarif appliqué sera calculé en fonction du nombre de consultations effectuées"
                            )
                          ]),
                      _c(
                        "div",
                        {
                          staticClass: "plan-status",
                          class: _vm.planMetadata.status
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "status" },
                            [
                              _c("FontAwesomeIcon", {
                                attrs: { icon: _vm.planMetadata.statusIcon }
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.planMetadata.statusLabel) +
                                  "\n                "
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("div", { staticClass: "plan-cta" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleAction($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.planMetadata.CTALabel) +
                                "\n                "
                            )
                          ]
                        )
                      ]),
                      _c("AppConfirmation", {
                        ref: "confirmActivation",
                        attrs: {
                          title: "Confirmer la réactivation de votre abonnement"
                        },
                        on: { confirm: _vm.subscribe }
                      }),
                      _c("AppConfirmation", {
                        ref: "confirmCancellation",
                        attrs: {
                          title:
                            "Êtes-vous sûr de vouloir résilier votre abonnement ?"
                        },
                        on: { confirm: _vm.cancel }
                      })
                    ],
                    1
                  ),
                  _c("ul", { staticClass: "plan-description" }, [
                    _c("li", [
                      _vm._v(
                        "L’abonnement à Hellocare est mensuel et sans engagement."
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                Vous bénéficiez d’une période d'essai gratuite dès l'ouverture du compte, valable une seule fois.\n                Sans résiliation de votre part, votre abonnement payant démarrera à l’issu de cette période et sera\n                ensuite renouvelé mensuellement par tacite reconduction.\n              "
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                Vous pouvez résilier simplement et à tout moment. La résiliation sera effective à la fin de la période\n                en cours.\n              "
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "GroupTitle",
                      { attrs: { icon: _vm.$icons.creditCard } },
                      [_vm._v("\n              Mode de paiement\n            ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "selected-payment" },
                      [
                        _c(
                          "FormRadio",
                          {
                            attrs: { value: true },
                            model: {
                              value: _vm.paymentTypeToggle,
                              callback: function($$v) {
                                _vm.paymentTypeToggle = $$v
                              },
                              expression: "paymentTypeToggle"
                            }
                          },
                          [_vm._v("Carte de crédit")]
                        ),
                        _c(
                          "FormRadio",
                          {
                            attrs: { value: false },
                            model: {
                              value: _vm.paymentTypeToggle,
                              callback: function($$v) {
                                _vm.paymentTypeToggle = $$v
                              },
                              expression: "paymentTypeToggle"
                            }
                          },
                          [_vm._v("Prélèvement bancaire")]
                        )
                      ],
                      1
                    ),
                    _vm.paymentTypeToggle
                      ? _c("CreditCard")
                      : !_vm.paymentTypeToggle
                      ? _c("SepaDebit")
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }