import moment from 'moment';
import IBAN from 'iban';

/**
 * Check if the number pass the Luhn algorithm
 *
 * @param {string} number The number to validate
 * @returns {number|boolean} Returns `true` is valid, else returns `false` or `0`
 */
function checkLuhn(number) {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let len = number.length;
  let bit = 1;
  let sum = 0;
  let val;

  while (len) {
    val = parseInt(number.charAt(--len), 10);
    sum += (bit ^= 1) ? arr[val] : val;
  }

  return sum && sum % 10 === 0;
}

export function checkSiren(siren) {
  if (!siren) {
    return true;
  }

  if (!siren.match(/^\d{9}$/)) {
    return false;
  }

  return checkLuhn(siren);
}

export function checkIBAN(iban) {
  if (!iban) {
    return true;
  }

  return IBAN.isValid(iban);
}

export function checkDate(value) {
  if (!value) {
    return true;
  }

  if (!value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return false;
  }

  return moment(value, 'DD/MM/YYYY').isValid();
}

export function checkBirthDate(value) {
  if (!checkDate(value)) {
    return false;
  }

  const age = moment().diff(moment(value, 'DD/MM/YYYY'), 'years');

  return age >= 18 && age <= 150;
}

export function hasValue(value) {
  if (value === undefined || value === null) {
    return false;
  }

  if (value === false) {
    return true;
  }

  return value.trim().length > 0;
}
export function checkSsn(value) {
  if (!value) {
    return true;
  }

  return !!value.replace(/\s+/g, '').match(/^\d{6}[\dAB]\d{8}$/);
}

export function isNumeric(num){
  return !isNaN(num)
}
