<template>
  <nav
    :class="{ expanded, desktop }"
    class="app-navigation"
    v-on="desktop ? { mouseenter: expandNavigation, mouseleave: shrinkNavigation } : {}"
  >
    <header v-if="!desktop" class="mobile-header">
      <FontAwesomeIcon :icon="toggleIcon" class="toggle" @click="toggleNavigation" />
      <AppLogoMobile white />
      <span class="spacer" />
    </header>
    <div class="primary-links">
      <AppNavigationItem :icon="$icons.dashboard" label="Tableau de bord" to="/dashboard" />
      <AppNavigationItem :icon="$icons.user" label="Mon compte" to="/profile">
        <AppNavigationItemChild label="Mon profil" to="/profile/view" />
        <AppNavigationItemChild label="Mes paiements" to="/profile/transactions" v-if="profileType !== 'secretary'" />
        <AppNavigationItemChild v-if="availabilitiesEnabled && profileType !== 'secretary'" label="Mes disponibilités" to="/profile/availabilities" />
      </AppNavigationItem>
      <AppNavigationItem :icon="$icons.calendar" label="Agenda" to="/agenda" />
      <AppNavigationItem :icon="$icons.myPatients" label="Mes patients" to="/patients" />
      <AppNavigationItem :icon="$icons.info" external label="Aide" to="https://kb.hellocare.pro/support/" />
      <AppNavigationItem :icon="$icons.logout" label="Déconnexion" to="/logout" />
    </div>
    <div class="secondary-links">
      <AppNavigationItem v-if="profileType == 'doctor' && !isAccountRestricted" external :icon="$icons.userMd" label="Hellocare Dr.Remplaçant" to="https://my.docndoc.fr/register?utm_source=hellocare&utm_medium=website&utm_campaign=hlc-links" />
      <AppNavigationItem external label="Hellocare Store" to="https://hellocarestore.com" :icon="$icons.store" />
<!--      <AppNavigationItem external label="Hellocare Academy" to="https://invivox.com/department/profile/hellocare" />-->
    </div>
  </nav>
</template>

<script>
  import { getSetting } from '../helpers/tools.js';
  import AppLogoMobile from './AppLogoMobile';
  import AppNavigationItem from './AppNavigationItem';
  import AppNavigationItemChild from './AppNavigationItemChild';

  export default {
    name: 'AppNavigation',
    components: { AppLogoMobile, AppNavigationItemChild, AppNavigationItem },
    props: {
      desktop: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      profileType() {
        return this.$store.state.practitioner.profile.type;
      },
      expanded() {
        return this.$store.state.appNavigation.isExpanded;
      },
      toggleIcon() {
        return this.expanded
          ? this.$icons.navigationToggleOff
          : this.$icons.navigationToggle;
      },
      availabilitiesEnabled() {
        return getSetting('AVAILABILITIES_ENABLED');
      },
      isAccountRestricted() {
        return this.$store.state.practitioner.profile.isRestricted;
      },
    },
    methods: {
      toggleNavigation() {
        this.$store.dispatch('appNavigationToggle');
      },
      expandNavigation() {
        if (this.desktop) {
          this.$store.dispatch('appNavigationExpand');
        }
      },
      shrinkNavigation() {
        if (this.desktop) {
          this.$store.dispatch('appNavigationShrink');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-navigation {
    font-family: $titleFont;
    font-size: 1.5rem;
    line-height: 7.4rem;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    overflow-y: hidden;
    width: 100vw;
    height: 70px;
    padding-top: 0;
    transition: height 0.475s ease-out 0s;
    background: $gradientBackground;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.desktop, &.expanded {
      overflow-y: auto;
      height: 100vh;
    }

    &.desktop {
      width: 43rem;
      padding-top: 8rem;
      padding-bottom: 0;
      transition: transform 0.475s ease-out 0s;
      transform: translate3d(-35.6rem, 0px, 0px);

      &.expanded {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .secondary-links {
    margin-bottom: 6rem;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .toggle {
      font-size: 2rem;
      width: 32px;
      margin-left: 1rem;
      cursor: pointer;
      text-align: center;
      color:white;
    }

    .spacer {
      width: 32px;
    }
  }
</style>
