import Practitioners from '../../api/practitioners';
import { formatCivility } from '../../helpers/format';
import { toUpper } from 'lodash-es';

export default {
  state: {
    profile: {},
    isFetchingProfile: false,
    isUpdatingProfile: false,

    conventions: [],
    isFetchingConventions: false,

    specialties: [],
    isFetchingSpecialties: false,

    languages: [],
    isFetchingLanguages : false,

    isUploadingSignature: false,
    isUploadingCardId: false,

    isSignatureLoading: false,
    signature: null,

    isUploadingAvatar: false,
    isAvatarFormOpened: false,
    isDelete: false
  },
  mutations: {
    PRACTITIONER_GET_ONE(state) {
      state.isFetchingProfile = true;
    },
    PRACTITIONER_GET_ONE_SUCCESS(state, { profile }) {
      state.connectedPractitioner = profile;
      state.isFetchingProfile = false;
    },
    PRACTITIONER_GET_ONE_FAIL(state) {
      state.connectedPractitioner = {};
      state.isFetchingProfile = false;
    },
    PRACTITIONER_FETCH_PROFILE(state) {
      state.isFetchingProfile = true;
    },
    PRACTITIONER_FETCH_PROFILE_SUCCESS(state, { profile }) {
      state.profile = profile;
      state.isFetchingProfile = false;
    },
    PRACTITIONER_FETCH_PROFILE_FAIL(state) {
      state.profile = {};
      state.isFetchingProfile = false;
    },
    PRACTITIONER_EDIT_PROFILE(state) {
      state.isUpdatingProfile = true;
    },
    PRACTITIONER_EDIT_PROFILE_SUCCESS(state) {
      state.isUpdatingProfile = false;
    },
    PRACTITIONER_EDIT_PROFILE_FAIL(state) {
      state.isUpdatingProfile = false;
    },
    PRACTITIONER_UPLOAD_SIGNATURE(state) {
      state.isUploadingSignature = true;
    },
    PRACTITIONER_UPLOAD_SIGNATURE_SUCCESS(state) {
      state.isUploadingSignature = false;
    },
    PRACTITIONER_UPLOAD_SIGNATURE_FAIL(state) {
      state.isUploadingSignature = false;
    },
    PRACTITIONER_GET_SIGNATURE(state) {
      state.isSignatureLoading = true;
    },
    PRACTITIONER_GET_SIGNATURE_SUCCESS(state, {signature}) {
      state.signature = signature;
      state.isSignatureLoading = false;
    },
    PRACTITIONER_GET_SIGNATURE_FAIL(state) {
      state.isSignatureLoading = false;
    },
    PRACTITIONER_UPLOAD_CARD_ID(state) {
      state.isUploadingCardId = true;
    },
    PRACTITIONER_UPLOAD_CARD_ID_SUCCESS(state) {
      state.isUploadingCardId = false;
    },
    PRACTITIONER_UPLOAD_CARD_ID_FAIL(state) {
      state.isUploadingCardId = false;
    },
    PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION(state) {
      state.isUploadingEmploymentAttestation = true;
    },
    PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION_SUCCESS(state) {
      state.isUploadingEmploymentAttestation = false;
    },
    PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION_FAIL(state) {
      state.isUploadingEmploymentAttestation = false;
    },
    PRACTITIONER_UPLOAD_AVATAR(state) {
      state.isUploadingAvatar = true;
    },
    PRACTITIONER_UPLOAD_AVATAR_SUCCESS(state) {
      state.isUploadingAvatar = false;
    },
    PRACTITIONER_UPLOAD_AVATAR_FAIL(state) {
      state.isUploadingAvatar = false;
    },
    PRACTITIONER_AVATAR_OPEN_FORM(state) {
      state.isAvatarFormOpened = true;
    },
    PRACTITIONER_AVATAR_CLOSE_FORM(state) {
      state.isAvatarFormOpened = false;
    },
    PRACTITIONER_DELETE_ACCOUNT() {},
    PRACTITIONER_DELETE_ACCOUNT_SUCCESS() {},
    PRACTITIONER_DELETE_ACCOUNT_FAIL() {}
  },
  actions: {
    async practitionerGetOne({ commit }, data) {
      commit('PRACTITIONER_GET_ONE');
      try {
        const profile = await Practitioners.getOne(data);
        commit('PRACTITIONER_GET_ONE_SUCCESS', { profile });
      } catch (e) {
        commit('PRACTITIONER_GET_ONE_FAIL');
        throw e;
      }
    },
    async practitionerFetchProfile({ commit }) {
      commit('PRACTITIONER_FETCH_PROFILE');
      try {
        const profile = await Practitioners.me();
        commit('PRACTITIONER_FETCH_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PRACTITIONER_FETCH_PROFILE_FAIL');
        throw e;
      }
    },
    async practitionerEditProfile({ commit }, data) {
      commit('PRACTITIONER_EDIT_PROFILE');
      try {
        const profile = await Practitioners.update(data);
        commit('PRACTITIONER_EDIT_PROFILE_SUCCESS');
        commit('PRACTITIONER_FETCH_PROFILE_SUCCESS', { profile })
      } catch (e) {
        commit('PRACTITIONER_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
    async practitionerEditSettings({ commit }, data) {
      commit('PRACTITIONER_EDIT_PROFILE');
      try {
        const profile = await Practitioners.updateSettings(data);
        commit('PRACTITIONER_EDIT_PROFILE_SUCCESS');
        commit('PRACTITIONER_FETCH_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PRACTITIONER_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
    async practitionerEditMetadata({ state, commit }, { metadata }) {
      commit('PRACTITIONER_EDIT_PROFILE');
      try {
        const profile = await Practitioners.update({
          metadata: {
            ...state.profile.metadata,
            ...metadata,
          },
        });
        commit('PRACTITIONER_EDIT_PROFILE_SUCCESS');
        commit('PRACTITIONER_FETCH_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PRACTITIONER_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
    async practitionerUploadSignature({ commit }, { file }) {
      commit('PRACTITIONER_UPLOAD_SIGNATURE');
      try {
        await Practitioners.uploadSignature(file);
        commit('PRACTITIONER_UPLOAD_SIGNATURE_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_UPLOAD_SIGNATURE_FAIL');
        throw e;
      }
    },
    async practitionerGetSignature({ commit } ) {
      commit('PRACTITIONER_GET_SIGNATURE');
      try {
        const signature = await Practitioners.getSignature();
        commit('PRACTITIONER_GET_SIGNATURE_SUCCESS', {signature});
      } catch (e) {
        commit('PRACTITIONER_GET_SIGNATURE_FAIL');
        throw e;
      }
    },
    async practitionerUploadIdCard({ commit }, { file }) {
      commit('PRACTITIONER_UPLOAD_CARD_ID');
      try {
        await Practitioners.uploadIdCard(file);
        commit('PRACTITIONER_UPLOAD_CARD_ID_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_UPLOAD_CARD_ID_FAIL');
        throw e;
      }
    },
    async practitionerUploadEmploymentAttestation({ commit }, { file }) {
      commit('PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION');
      try {
        await Practitioners.uploadEmploymentAttestation(file);
        commit('PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_UPLOAD_EMPLOYMENT_ATTESTATION_FAIL');
        throw e;
      }
    },
    async practitionerUploadAvatar({ commit }, { file }) {
      commit('PRACTITIONER_UPLOAD_AVATAR');
      try {
        await Practitioners.uploadAvatar(file);
        commit('PRACTITIONER_UPLOAD_AVATAR_SUCCESS');
      } catch (e) {
        commit('PRACTITIONER_UPLOAD_AVATAR_FAIL');
        throw e;
      }
    },
    practitionerAvatarOpenForm({ commit }) {
      commit('PRACTITIONER_AVATAR_OPEN_FORM');
    },
    practitionerAvatarCloseForm({ commit }) {
      commit('PRACTITIONER_AVATAR_CLOSE_FORM');
    },
    async patientDeleteAccount({commit}) {
      commit('PRACTITIONER_DELETE_ACCOUNT');
      try {
        await Practitioners.delete();
        commit("PRACTITIONER_DELETE_ACCOUNT_SUCCESS")
      } catch (e) {
        commit('PRACTITIONER_DELETE_ACCOUNT_FAIL');
        throw e;
      }
    },
  },
  getters: {
    practitionerLabel(state) {
      return `${formatCivility(state.profile.civility, true)} ${toUpper(state.profile.lastName)}`;
    },
  },
};
