<!--
Emitted events:
- replace
-->

<template>
  <form class="credit-card-display-form" @submit.prevent>
    <div class="row">
      <FormWidget class="col _12">
        <label for="cardNumber">Numéro de carte</label>
        <input id="cardNumber" :value="cardNumber" readonly>
      </FormWidget>
    </div>

    <div class="row">
      <FormWidget class="col _6">
        <label for="cardExpiry">Date d'expiration</label>
        <input id="cardExpiry" :value="cardExpiry" readonly>
      </FormWidget>
      <FormWidget class="col _6">
        <label for="cardCvc">Cryptogramme</label>
        <input id="cardCvc" value="***" readonly>
      </FormWidget>
    </div>

    <a href="#" @click.prevent="$emit('replace')">
      Remplacer ma carte
    </a>

    <div class="security">
      <img alt="" src="@/assets/images/picto-payment.svg" />
      <p>
        Votre moyen de paiement est traité et sécurisé par Stripe, acteur certifié et accrédité par les autorités
        bancaires européennes. Conformément à la directive européenne DSP2 et à l’authentification forte du client,
        Stripe pourra être amené à vous authentifier à l'aide d'un code reçu par SMS.
      </p>
    </div>
  </form>
</template>

<script>
  import FormInput from '@/components/Form/FormInput';
  import FormWidget from '@/components/Form/FormWidget';

  export default {
    name: 'CreditCardDisplayForm',
    components: { FormWidget, FormInput },
    props: {
      cardNumber: {
        type: String,
        required: true,
      },
      cardExpiry: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/grid";

  .security {
    display: flex;
    align-items: flex-start;
    margin-top: 3.5rem;
    padding: 2.5rem 1.5rem 1.5rem;
    border-top: 1px solid $lightGrey;

    p {
      margin: 0 0 0 2rem;
    }
  }

  //
  //.buttons {
  //  display: flex;
  //  justify-content: space-between;
  //  margin-top: 6rem;
  //  text-align: center;
  //}
  //
  //.ignore-button {
  //  margin-top: 1rem;
  //  text-align: center;
  //}
</style>
