var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppPanel",
    {
      staticClass: "new-appointment-panel",
      attrs: { opened: _vm.opened },
      on: {
        close: function($event) {
          return _vm.$emit("close", false)
        }
      }
    },
    [
      _c("SectionTitle", [_vm._v("Proposer un rendez-vous")]),
      _c("NewAppointmentForm", {
        attrs: { date: _vm.startDate },
        on: {
          success: function($event) {
            return _vm.$emit("close", true)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }