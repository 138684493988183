<template>
  <div class="container-iframe__datablock__data" v-if="searchLength > 0">
    <div class="container-iframe__datablock__data__title" @click="toggleConstantes" >
      Voir les dernières constantes de santé du patient <FontAwesomeIcon :icon="isOpen ? $icons.panelToggleOpened : $icons.panelToggleClosed" />
    </div>
    <div class="container-iframe__datablock__data__content toggle__constantes" ref="toggle__constantes">
      <div
          class="container-iframe__datablock__data__content__item"
          v-for="(observation, index) in observations"
      >
        <div v-if="observation.code === '8310-5'">
          <p>Température : <strong>{{observation.display_value}}°C</strong> le {{observation.created_at | date('DD/MM/YYYY')}} à {{observation.created_at | date('HH:mm')}}</p>
        </div>
        <div v-if="observation.code === '8867-4'">
          <p>Rythme cardiaque : <strong>{{observation.display_value}}bpm</strong> le {{observation.created_at | date('DD/MM/YYYY')}} à {{observation.created_at | date('HH:mm')}}</p>
        </div>
        <div v-if="observation.code === '20564-1'">
          <p>Saturation en oxygène : <strong>{{observation.display_value}}%</strong> le {{observation.created_at | date('DD/MM/YYYY')}} à {{observation.created_at | date('HH:mm')}}</p>
        </div>
        <div v-if="observation.code === '8462-4'">
          <p>Pression systolique : <strong>{{observation.display_value}}mmhg</strong> le {{observation.created_at | date('DD/MM/YYYY')}} à {{observation.created_at | date('HH:mm')}}</p>
        </div>
        <div v-if="observation.code === '8480-6'">
          <p>Pression diastolique : <strong>{{observation.display_value}}mmhg</strong> le {{observation.created_at | date('DD/MM/YYYY')}} à {{observation.created_at | date('HH:mm')}}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'LastObservations',
  props: {
    searchLength: {
      type: Number,
      required: true
    },
    observations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isOpen : false
    }
  },
  methods: {
    toggleConstantes() {
      this.$refs.toggle__constantes.classList.toggle('open');
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.container-iframe__datablock__data {
  text-align: left;
  .container-iframe__datablock__data__title {
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    &:hover {
      color: $successColor;
      transition: all 0.3s ease-in-out;
    }
  }
  .container-iframe__datablock__data__content {
    display: block;
    text-align: left;
    position: absolute;
    .container-iframe__datablock__data__content__item {
      p{
        margin: 10px 0;
        font-size: 1.3rem;
      }
      .split{
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.toggle__constantes{
  overflow: hidden;
  height: 0;
  transition: all 0.3s ease-in-out;
  padding: 0;
  &.open{
    border: 1px solid $lighterGrey;
    border-radius: 5px;
    background: $lighterGrey;
    min-height: 70px;
    height: auto;
    padding: 15px;
    transition: all 0.3s ease-in-out;
  }
}
</style>