<!--
Emitted events:
- click(newAppointmentStartAt: moment)
-->
<template>
  <div
    class="agenda-calendar-day-column"
    @mousemove="handleMouseMove"
    @mouseenter.self="isNewAppointmentLinkVisible = true"
    @mouseleave.self="isNewAppointmentLinkVisible = false"
  >
    <AgendaCalendarAvailabilitySlot
      v-for="slot in availabilitySlots"
      :key="`${day.unix()}-${slot.start}-${slot.end}`"
      :day="day"
      :start-hour="slot.start"
      :end-hour="slot.end"
    />
    <AgendaCalendarAppointmentGroup
      v-for="appointmentsGroup in appointmentsGroups"
      :key="appointmentsGroup.startAt.unix()"
      :appointments="appointmentsGroup.appointments"
      :start-at="appointmentsGroup.startAt"
      :end-at="appointmentsGroup.endAt"
    />
    <div
      v-if="canAddAppointment"
      class="new-appointment"
      :style="{ top: newAppointmentLinkTop, height: newAppointmentLinkHeight }"
      @click="$emit('click', newAppointmentStartAt)"
    >
      {{ newAppointmentLinkLabel }}
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { sortBy, throttle } from 'lodash-es';
  import AgendaCalendarAppointment from './AgendaCalendarAppointment';
  import AgendaCalendarAppointmentGroup from './AgendaCalendarAppointmentGroup';
  import AgendaCalendarAvailabilitySlot from './AgendaCalendarAvailabilitySlot';

  export default {
    name: 'AgendaCalendarDayColumn',
    components: { AgendaCalendarAvailabilitySlot, AgendaCalendarAppointmentGroup, AgendaCalendarAppointment },
    props: {
      day: {
        type: moment,
        required: true,
      },
      appointments: {},
    },
    created() {
      this.handleMouseMove = throttle(this.handleMouseMove, 20);
    },
    data() {
      return {
        isNewAppointmentPanelOpened: false,
        isNewAppointmentLinkVisible: false,
        currentSlotIndex: 0,
      };
    },
    computed: {
      appointmentsGroups() {
        const groups = [];

        sortBy(this.appointments, 'startAt').forEach(appointment => {
          const $startAt = moment(appointment.startAt);
          const $endAt = moment(appointment.endAt);

          let group = groups.find(({ startAt, endAt }) => $startAt.isBetween(startAt, endAt, null, '[)'));

          if (!group) {
            return groups.push({
              startAt: $startAt,
              endAt: $endAt,
              appointments: [appointment],
            });
          }

          group.appointments.push(appointment);

          if ($endAt.isAfter(group.endAt)) {
            group.endAt = $endAt;
          }
        });

        return groups;
      },
      availabilitySlots() {
        return this.$store.state.availability.availabilities.find(availability => availability.date === this.day.format('YYYY-MM-DD'))?.slots;
      },
      newAppointmentStartAt() {
        return moment(this.day).startOf('day').minutes(this.currentSlotIndex * 10);
      },
      newAppointmentLinkHeight() {
        return this.$minutesToPixels(10);
      },
      newAppointmentLinkTop() {
        return this.$minutesToPixels(this.currentSlotIndex * 10);
      },
      newAppointmentLinkLabel() {
        return `Ajouter un RDV (${this.newAppointmentStartAt.format('HH:mm')})`;
      },
      canAddAppointment() {
        return this.isNewAppointmentLinkVisible
          && !this.$store.state.practitioner.profile.isRestricted
          && this.newAppointmentStartAt.isAfter()
          && !this.appointmentsGroups.find(({ startAt, endAt }) => this.newAppointmentStartAt.isBetween(startAt, endAt, null, '[)'));
      },
    },
    methods: {
      handleMouseMove($event) {
        this.currentSlotIndex = Math.floor(this.$pixelsToMinutes($event.clientY - this.$el.getBoundingClientRect().top) / 10);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .agenda-calendar-day-column {
    position: relative;
    flex: 1;
    background-color: $lighterGrey;
    border-left: 0.1rem solid white;
    border-right: 0.1rem solid white;
  }

  .new-appointment {
    position: absolute;
    left: 0.1rem;
    right: 0.1rem;
    display: flex;
    align-items: center;
    background-color: white;
    border-left: .5rem solid #27d7ab;
    padding-left: .5rem;
    cursor: pointer;
  }
</style>
