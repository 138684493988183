var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment
    ? _c("div", { staticClass: "appointment-panel-actions" }, [
        _vm.isNewByPractitioner
          ? _c("div", { staticClass: "new-or-cancelled" }, [
              _vm._v(
                "\n    Votre patient n'a toujours pas validé le rendez-vous\n  "
              )
            ])
          : _vm.isNewByPatient && !_vm.isAccountRestricted
          ? _c(
              "div",
              [
                _c("AppConfirmation", {
                  ref: "acceptAppointment",
                  attrs: {
                    title: "Êtes-vous sûr de vouloir accepter ce rendez-vous ?"
                  },
                  on: { confirm: _vm.acceptAppointment }
                }),
                _c(
                  "ButtonGreen",
                  {
                    attrs: { icon: _vm.$icons.accept },
                    on: {
                      click: function($event) {
                        return _vm.$confirm(_vm.$refs.acceptAppointment)
                      }
                    }
                  },
                  [_vm._v("\n      Accepter ce rendez-vous\n    ")]
                )
              ],
              1
            )
          : _vm.canBeStarted
          ? _c(
              "div",
              [
                _c(
                  "ButtonGreen",
                  {
                    staticClass: "start-encounter",
                    attrs: { busy: _vm.goingToConsultation },
                    on: { click: _vm.goToConsultation }
                  },
                  [
                    _vm._v("\n      Votre rendez-vous est accessible\n      "),
                    _c(
                      "span",
                      { staticClass: "go" },
                      [
                        _vm._v("\n        J'y vais !\n        "),
                        _c("FontAwesomeIcon", {
                          attrs: { icon: _vm.$icons.arrowRight }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.canBeCancelled
          ? _c(
              "div",
              { staticClass: "cancel-postpone" },
              [
                _c("AppConfirmation", {
                  ref: "cancelAppointment",
                  attrs: { title: _vm.cancelAppointmentDialogMessage },
                  on: { confirm: _vm.cancelAppointment },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(
                                "Attention, cette action entraîne l'annulation définitive du rendez-vous."
                              )
                            ]),
                            _c("AutoHeightTextarea", {
                              staticClass: "cancel-reason",
                              attrs: {
                                placeholder:
                                  "Décrivez la raison de votre annulation",
                                "max-length": _vm.$options.cancelReasonMaxLength
                              },
                              model: {
                                value: _vm.cancelReason,
                                callback: function($$v) {
                                  _vm.cancelReason = $$v
                                },
                                expression: "cancelReason"
                              }
                            }),
                            _c("div", { staticClass: "cancel-reason-tips" }, [
                              _vm._v(
                                _vm._s(_vm.cancelReason.length) +
                                  "/" +
                                  _vm._s(_vm.$options.cancelReasonMaxLength) +
                                  " caractères maximum"
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    583170221
                  )
                }),
                _c(
                  "span",
                  {
                    staticClass: "link-with-icon cancel-appointment",
                    on: {
                      click: function($event) {
                        return _vm.$confirm(_vm.$refs.cancelAppointment)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.cancelAppointmentButtonMessage))
                    ]),
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.appointmentStatusCancelled }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }