<template>
  <router-link :to="{ name: 'add-availabilities', query: { date: day.date.format('YYYY-MM-DD') } }" class="week-day" @click="handleClick" :class="classes">
    <div class="day-number">
      {{ day.monthDay }}
    </div>
    <CalendarEvent :events-day="filteredEvents" />
  </router-link>
</template>

<script>
  import CalendarEvent from './CalendarEvent';
  import calendarJs from '../../helpers/calendar';
  import moment from 'moment';

  export default {
    name: 'CalendarDay',
    components: { CalendarEvent },
    props: {
      events: {
        type: Array,
        required: true,
      },
      day: {
        type: Object,
      },
    },
    computed: {
      isToday() {
        return this.day.isToday;
      },
      isSaturday() {
        return this.day.isSaturday;
      },
      isSunday() {
        return this.day.isSunday;
      },
      isWeekend() {
        return this.day.isWeekend;
      },
      isCurrentMonth() {
        return this.day.isCurrentMonth;
      },
      isDisabled() {
        return this.day.date.isBefore(moment().days(-13));
      },
      filteredEvents() {
        return calendarJs.filterEventsForDate(this.day.date, this.events);
      },
      classes() {
        return {
          today: this.isToday,
          sunday: this.isSunday,
          weekend: this.isWeekend,
          saturday: this.isSaturday,
          'not-current': !this.isCurrentMonth,
          disable: this.isDisabled,
        };
      },
    },
    methods: {
      handleClick() {
        if (this.isDisabled) {
          return;
        }

        // calendarJs.filterEventsForDate(day.date, this.events)
        this.$emit('day-clicked', this.day);
      },
    },
    watch: {
      events(events) {
        this.events = events;
      },
    },
  };
</script>

<style lang="scss">
  .day-number {
    margin-bottom: 4px;
  }

  .week-day {
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: $textColor;
  }

  .week-day:hover {
    background-color: #DFF9F3;
    cursor: pointer;

    .limit {
      background-color: #DFF9F3;
    }
  }

  .week-day.disable:hover {
    background-color: $lighterGrey;
    cursor: auto;
  }
</style>
