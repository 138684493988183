var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "app-header",
      class: { "with-navigation": _vm.withNavigation }
    },
    [
      _c("div", [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("civility")(_vm.profile.civility, true)) +
            " " +
            _vm._s(_vm.fullName) +
            "\n  "
        )
      ]),
      _c("div", { staticClass: "current-date-time" }, [
        _vm._v("\n    " + _vm._s(_vm.currentDateTime) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }