var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appointment
    ? _c("div", [
        !_vm.editingPrivateNote
          ? _c(
              "div",
              {
                staticClass: "private-note",
                class: { empty: !_vm.hasPrivateNote }
              },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "private-note-icon",
                  attrs: { icon: _vm.$icons.edit },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.editingPrivateNote = true
                    }
                  }
                }),
                _c("div", { staticClass: "private-note-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.hasPrivateNote
                        ? _vm.appointment.payload.privateNote
                        : "Aucune note privée"
                    )
                  )
                ]),
                _vm.hasPrivateNote
                  ? _c("div", { staticClass: "private-note-comment" }, [
                      _vm._v(
                        "\n      Cette note privée n'est pas visible par le patient\n    "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          : _c(
              "form",
              {
                staticClass: "private-note-form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.handlePrivateNoteSubmit($event)
                  }
                }
              },
              [
                _c(
                  "FormWidget",
                  [
                    _c("label", { attrs: { for: "private-note" } }, [
                      _vm._v("Note privée (non visible par le patient)")
                    ]),
                    _c("AutoHeightTextarea", {
                      attrs: {
                        id: "private-note",
                        disabled: _vm.isUpdatingPrivateNote
                      },
                      model: {
                        value: _vm.privateNote,
                        callback: function($$v) {
                          _vm.privateNote =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "privateNote"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "ButtonGreen",
                  {
                    attrs: {
                      busy: _vm.isUpdatingPrivateNote,
                      small: "",
                      type: "submit"
                    }
                  },
                  [_vm._v("Enregistrer")]
                ),
                _vm._v("\n     \n    "),
                _c(
                  "ButtonRed",
                  {
                    attrs: {
                      busy: _vm.isUpdatingPrivateNote,
                      outlined: "",
                      small: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.editingPrivateNote = false
                      }
                    }
                  },
                  [_vm._v("Annuler")]
                )
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }