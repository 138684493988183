<template>
  <AppLayout>
    <main>
      <SectionTitle>Mes paiements</SectionTitle>
      <WalletHeader :practitioner="practitioner" />
      <div class="transactions" v-if="transactions.length > 0">
        <TransactionItem
          v-for="transaction in transactions"
          :key="transaction.id"
          :transaction="transaction"
          @refund="openRefundPanel(transaction)"
        />
      </div>
      <div class="no-transactions" v-else>
        Vous n'avez reçu aucun paiement de la part de vos patients.
      </div>
    </main>
    <AppPanel v-if="transactionToRefund" :opened="isRefundPanelOpen" @close="closeRefundPanel">
      <SectionTitle>Effectuer un remboursement</SectionTitle>
      <form @submit.prevent="handleRefund">
        <div>
          <div class="patient">
            <div class="patient-name"><strong>{{ transactionToRefund.patient | fullName }}</strong></div>
            <p>{{ transactionToRefund.patient.birthDate | age }}</p>
          </div>
          <div class="amount">Montant remboursable <strong>{{ amountAfterRefunded | money }}</strong></div>
          <div class="appointmentDetails">
            <p><strong>{{ transactionToRefund.appointment.start_at | date('DD MMMM YYYY - HH[h]mm') }}</strong></p>
            <p>{{ productName }}</p>
            <p>{{ channel | channel}}</p>
          </div>
          <hr>
          <FormWidget>
            <label for="amount-to-refund">Montant à rembourser (en €)</label>
            <input
              id="amount-to-refund"
              @input="amountToRefund = $inputToPrice($event.target.value)"
              :value="amountToRefund"
            >
          </FormWidget>
        </div>
        <div class="buttons">
          <ButtonTransparent
            :busy="isRefunding"
            :icon="$icons.chevronLeft"
            icon-to-left
            rounded
            @click="closeRefundPanel"
          >
            Annuler
          </ButtonTransparent>
          <ButtonGreen
            :busy="isRefunding"
            center
            class="submit-button"
            rounded
            type="submit"
          >
            Rembourser
          </ButtonGreen>
        </div>
      </form>
    </AppPanel>
  </AppLayout>
</template>

<script>
  import AppPanel from '@/components/AppPanel.vue';
  import ButtonGreen from '@/components/Button/ButtonGreen.vue';
  import ButtonTransparent from '@/components/Button/ButtonTransparent.vue';
  import FormWidget from '@/components/Form/FormWidget.vue';
  import HorizontalDivider from '@/components/HorizontalDivider.vue';
  import SectionTitle from '../../components/SectionTitle';
  import TransactionItem from '../../components/ProfileTransactions/TransactionItem';
  import WalletHeader from '../../components/ProfileTransactions/WalletHeader';
  import AppLayout from '../../components/AppLayout';

  export default {
    name: 'ProfileTransactionsPage',
    components: {
      ButtonGreen,
      ButtonTransparent,
      FormWidget,
      HorizontalDivider,
      AppPanel,
      AppLayout,
      WalletHeader,
      SectionTitle,
      TransactionItem,
    },
    data() {
      return {
        offset: 0,
        count: 9999,
        isRefundPanelOpen: false,
        transactionToRefund: null,
        amountToRefund: 0,
      };
    },
    created() {
      this.loadData();
    },
    computed: {
      practitioner() {
        return this.$store.state.practitioner.profile;
      },
      transactions() {
        return this.$store.state.transactions.slice;
      },
      isRefunding() {
        return this.$store.state.transactions.isRefunding;
      },
      productName() {
        return this.transactionToRefund.appointment.product
            ? this.transactionToRefund.appointment.product.name
            : 'Téléconsultation générale';
      },
      channel() {
        return this.transactionToRefund.appointment.product
            ? this.transactionToRefund.appointment.product.channel
            : 'Téléconsultation';
      },
      amountAfterRefunded() {
        return this.transactionToRefund.amount - this.transactionToRefund.amountRefunded;
      },
      amountRefundable() {
        return this.amountAfterRefunded / 100;
      },
    },
    methods: {
      async loadData() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('transactionsFetchSlice', { offset: this.offset, count: this.count });
        } finally {
          this.$store.dispatch('appLoaderHide');
        }
      },
      openRefundPanel(transaction) {
        this.isRefundPanelOpen = true;
        this.transactionToRefund = transaction;
        this.amountToRefund = this.amountRefundable;
      },
      closeRefundPanel() {
        this.transactionToRefund = null;
        this.isRefundPanelOpen = false;
      },
      async handleRefund() {
        try {
          await this.$store.dispatch('transactionsRefund', {
            id: this.transactionToRefund.id,
            amount: this.amountToRefund * 100,
          });
          this.closeRefundPanel();
        } catch (e) {
          await this.$addError('Une erreur est survenue lors de la tentative de remboursement');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .no-transactions {
    font-weight: bold;
    color: $errorColor;
    margin-top: 4rem;
    text-align: center;
    font-size: 1.5rem;
  }

  .transactions {
    max-width: fit-content;
    margin: 4rem auto 0;
    @include onMobile {
      overflow-x: auto;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    text-align: center;
  }

  .patient {
    margin-bottom: 3rem;
  }

  .amount {
    background-color: $lighterGrey;
    padding: 1rem;
    font-size: 2rem;
  }
  .amount strong {
    margin-left: 1rem;
  }
  .appointmentDetails {
    margin: 3rem 0 3rem 0;
  }
  p {
    margin: 0;
    font-weight: inherit;
  }
</style>
