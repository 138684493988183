<!--
Emitted events:
- success(startAt: string)
-->
<template>
  <form class="new-appointment-form" :class="{ restricted: isAccountRestricted }" @submit.prevent="handleSubmit">
    <div v-if="isSecretary" style="margin-top: 2rem; margin-bottom: 2rem;">
      <p>Pour quel praticien prenez-vous un rendez-vous ?</p>
      <div v-for="(secretaryOf) in secretariesOf" class="">
        <div style="margin-bottom: 1rem;">
          <FormRadio
              v-model="form.practitioner.id"
              :value="secretaryOf.id"
              @change="onPractitionerChange"
          >
            {{getFullName(secretaryOf.first_name, secretaryOf.last_name)}}
          </FormRadio>
        </div>
      </div>
    </div>
    <FormWidget>
      <label for="new-appointment-start-at">Date et heure du rendez-vous</label>
      <DateTimePicker
        v-model="form.startAt"
        input-id="new-appointment-start-at"
        type="datetime"
        :minute-step="5"
        :min-datetime="$store.state.currentTime.format()"
        :phrases="{ cancel: 'Annuler', ok: 'OK' }"
        auto
      />
      <template v-slot:error v-if="$v.form.startAt.$error">
          <span v-if="!$v.form.startAt.required">
            Veuillez renseigner la date du rendez-vous
          </span>
      </template>
    </FormWidget>

    <FormWidget>
      <label for="new-appointment-product">Type d'acte</label>
      <SelectWrapper>
        <select id="new-appointment-product" v-model="form.product">
          <option v-if="products.length > 1 && !form.product" :value="null" />
          <option v-for="product in products" :value="product">
            {{ product.name }} ({{ product.channel | channel }})
          </option>
        </select>
      </SelectWrapper>
      <template v-slot:error v-if="$v.form.product.$error">
          <span v-if="!$v.form.product.required">
            Veuillez choisir un type d'acte
          </span>
      </template>
      <template v-slot:help v-if="form.product && form.product.unitPrice === 0">
          <span class="error">
              Les frais de service de la platforme s'appliquent même sur les actes gratuits
          </span>
      </template>
    </FormWidget>

    <FormWidget>
      <label for="new-appointment-patient-email">Votre patient (email ou nom)</label>
      <AutoCompletedInput
        :value="form.patient"
        id="new-appointment-patient-email"
        :search-method="searchPatient"
        :searching.sync="isSearchingPatient"
        v-slot="{ result }"
        :disabled="!!forcedPatient"
        :typed-text="form.typedText"
        @update:typedText="handleTypedText"
        @change="handleSelectedPatient"
      >
        <p class="patient-search-name">{{ getFullName(result.firstName, result.lastName) }}</p>
        <p class="patient-search-phone">{{ result.phone | phone }}</p>
        <p class="patient-search-email">{{ result.email }}</p>
      </AutoCompletedInput>
      <template v-slot:error v-if="$v.form.patient.email.$error">
          <span v-if="!$v.form.patient.email.required">
            Veuillez renseigner l'adresse email du patient
          </span>
        <span v-else-if="!$v.form.patient.email.email">
            L'adresse email est incorrecte
          </span>
      </template>
      <template #help>
        <template v-if="isSearchingPatient">
          <FontAwesomeIcon spin :icon="$icons.spinner" />
          Recherche en cours...
        </template>
        <template v-else>
          * email obligatoire pour le 1er RDV avec un nouveau patient
        </template>
      </template>
    </FormWidget>

    <div class="advanced-fields">
      <div class="divider" @click="areAdvancedFieldsVisible = !areAdvancedFieldsVisible">
        <FontAwesomeIcon :icon="areAdvancedFieldsVisible ? $icons.shrink : $icons.expand" />
        {{ areAdvancedFieldsVisible ? 'Masquer' : 'Afficher' }} les options avancées
        <FontAwesomeIcon :icon="areAdvancedFieldsVisible ? $icons.shrink : $icons.expand" />
      </div>
      <template v-if="areAdvancedFieldsVisible">
        <FormWidget>
          <label for="new-appointment-message">Raison du rendez-vous</label>
          <AutoHeightTextarea id="new-appointment-message" v-model="form.message" :placeholder="defaultMessage" />
          <template v-slot:help>
            Ces informations seront partagées avec le patient
          </template>
        </FormWidget>

        <FormWidget>
          <label for="new-appointment-private-note">Note privée</label>
          <AutoHeightTextarea id="new-appointment-private-note" v-model="form.privateNote" />
          <template v-slot:help>
            Cette note est strictement confidentielle et n’est pas partagée avec le patient.
          </template>
        </FormWidget>
      </template>
    </div>

    <ButtonRed
      big
      class="new-appointment-submit"
      type="submit"
      :icon="$icons.send"
      :busy="isSubmitting"
    >
      Envoyer le rendez-vous
    </ButtonRed>
  </form>
</template>

<script>
  import ButtonRed from '../Button/ButtonRed.vue';
  import FormWidget from '../Form/FormWidget';
  import SelectWrapper from '../Form/SelectWrapper';
  import moment from 'moment';
  import HorizontalDivider from '../HorizontalDivider';
  import AutoHeightTextarea from '../Form/AutoHeightTextarea';
  import { email, required } from '@byscripts/vuelidate/lib/validators';
  import AutoCompletedInput from '../Form/AutoCompletedInput';
  import Patients from '../../api/patients';
  import { getFullName } from '@/helpers/format';
  import { toUpper } from 'lodash-es';
  import FormCheckbox from "@/components/Form/FormCheckbox.vue";
  import FormRadio from "@/components/Form/FormRadio.vue";

  export default {
    name: 'NewAppointmentForm',
    components: {
      FormRadio,
      FormCheckbox, AutoCompletedInput, AutoHeightTextarea, HorizontalDivider, SelectWrapper, FormWidget, ButtonRed },
    props: {
      date: {
        type: String,
        required: false,
      },
      forcedPatient: {
        type: Object,
        required: false,
      }
    },
    validations: {
      form: {
        patient: {
          email: { required, email },
        },
        product: { required },
        startAt: { required },
      },
    },
    data() {
      const secretariesChecked = this.$store.state.practitioner.profile.secretariesOf.map(s => ({
        id:s.id,
        isChecked:true
      }))
      secretariesChecked.push({id:this.$store.state.practitioner.profile.id, isChecked:true})
      return {
        secretariesChecked,
        form: {
          patient: { id: null, email: null },
          typedText: null,
          product: null,
          startAt: this.date ? this.date : null,
          message: null,
          privateNote: null,
          practitioner: {id:null},
        },
        products: this.$store.state.practitioner.profile.products,
        isSearchingPatient: false,
        areAdvancedFieldsVisible: false,
      };
    },
    async mounted() {
      if (this.products.length === 1) {
        this.form.product = this.products[0].id;
      }
      if (this.forcedPatient) {
        this.handleSelectedPatient(this.forcedPatient);
      }
    },
    computed: {
      secretariesOf() {
        return this.$store.state.practitioner.profile.secretariesOf;
      },
      isSecretary() {
        return this.$store.state.practitioner.profile.type === 'secretary';
      },
      isSubmitting() {
        return this.$store.state.appointments.isCreating;
      },
      profile() {
        return this.$store.state.practitioner.profile;
      },
      isAccountRestricted() {
        return this.profile.isRestricted;
      },
      defaultMessage() {
        const civility = this.$options.filters.civility(this.profile.civility, true) || this.profile.firstName;
        return `Rendez-vous proposé par ${civility} ${toUpper(this.profile.lastName)}`;
      },
    },
    methods: {
      getFullName,
      async searchPatient(typedText, abortSignal) {
        const patients = await Patients.search(typedText, abortSignal);
        return patients.map((patient) => {
          return [
            patient,
            ...patient.children.map(child => ({
              ...child,
              phone: patient.phone,
              email: patient.email,
            })),
          ]
        }).flat();
      },
      onPractitionerChange(practitionerId) {
        this.products = this.secretariesOf.find(s => s.id === practitionerId).products
        this.form.product = null
      },
      handleSelectedPatient(patient) {
        if (patient) {
          this.form.patient = patient;
          if (patient.parent) {
            this.form.patient.email = patient.parent.email;
          }
          this.form.typedText = getFullName(patient.firstName, patient.lastName);

          if (patient.email) {
            this.form.typedText = `${this.form.typedText} (${patient.email})`;
          }
        }
      },
      handleTypedText(typedText) {
        this.form.patient = { id: null, email: typedText };
        this.form.typedText = typedText
      },
      async handleSubmit() {
        if (this.isAccountRestricted || this.isSubmitting) {
          return;
        }

        this.$v.$touch();

        if (this.$v.$anyError) {
          this.$addError('Le formulaire contient des erreurs');
          return;
        }

        const data = {
          startAt: moment(this.form.startAt).format(),
          endAt: moment(this.form.startAt).add(this.form.product.duration, 'minutes').format(),
          privateNote: null,
          message: this.defaultMessage,
          patientId: this.form.patient.id,
          email: this.form.patient.id ? null : this.form.patient.email,
          productId: this.form.product.id,
          practitionerId: this.form.practitioner.id
        };

        if (this.areAdvancedFieldsVisible) {
          if (this.form.message) {
            data.message = this.form.message;
          }
          data.privateNote = this.form.privateNote;
        }

        try {
          await this.$store.dispatch('appointmentsCreate', data);

          this.form = {
            patient: { id: null, email: null },
            typedText: null,
            product: null,
            startAt: null,
            message: null,
            privateNote: null,
          };
          this.$v.$reset();

          this.$addSuccess('Demande de rendez-vous effectuée ! Nous avons contacté votre patient pour vous.');
          this.$emit('success', data.startAt);
        } catch (e) {
          this.$addError('Une erreur s\'est produite lors de la création du rendez-vous.');
          throw e;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #new-appointment-patient-email {
    text-transform: lowercase;
  }

  .new-appointment-form {
    font-size: 1.5rem;

    &.restricted {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .new-appointment-submit {
    width: 100%;
  }

  .advanced-fields {
    hr {
      margin-top: 1rem;
    }

    .divider {
      display: flex;
      justify-content: space-between;
      color: $grey;
      margin-bottom: 2rem;
      cursor: pointer;
    }
  }

  .auto-completed-input p {
    margin: 0.5rem 0;
  }

  .patient-search-name {
    font-weight: 500;
  }

  .patient-search-email {
    font-weight: 500;
  }
</style>
